// @flow
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { embedLinkValidation } from '../../../components/form/validation';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { RedirectTo } from '../../../routes/role/path';
import { setEditorChanges } from '../storage';
import { WistiaEditorIFrame } from '../../wistia/WistiaEditorIFrame';

const EmbedVideoModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [dataIndex, setdataIndex] = useState(null);
    const [att, setatt] = useState(null);
    const [sectionId, setsectionId] = useState(null);
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({ resolver: embedLinkValidation, mode: 'onBlur' });

    const onSubmit = (data) => {
        setDynamicAsset(data?.embedUrl);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    const setDynamicAsset = (src) => {
        const ele = document.getElementById('rootComponent');
        const dataIndex = ele.getAttribute('data-index-root');
        const att = ele?.getAttribute('slidesecclass-id');
        let sectionNum = null;
        const sectionId = ele.getAttribute('section-id');
        setdataIndex(dataIndex);
        setatt(att);
        setsectionId(sectionId.replace('outer', ''));

        if (sectionNum >= 0) {
            // const oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
            const ele = document.getElementById('rootComponent');
            const slides = ele.getAttribute('slidesecclass-id');
            const sectionId = ele.getAttribute('section-id');
            const dataIndex = ele.getAttribute('data-index-root');
            let oldElement = null;
            oldElement = document.querySelectorAll(`#${att} .item.active [sectionid=${sectionId}]`);
            if (window.location.pathname.includes(RedirectTo('/content-broadcast-create'))) {
                oldElement = document.querySelectorAll(`[sectionid=${sectionId}]`);
            }
            if (dataIndex) {
                oldElement = slides
                    ? document.querySelectorAll(
                          `#${slides} .item.active [data-index="${dataIndex}"] [sectionid=${sectionId}]`
                      )
                    : document.querySelectorAll(`.item.active [data-index="${dataIndex}"] [sectionid=${sectionId}]`);
            } else if (!window.location.pathname.includes(RedirectTo('/content-broadcast-create'))) {
                oldElement = slides
                    ? document.querySelectorAll(`#${slides} .item.active  [sectionid=${sectionId}]`)
                    : document.querySelectorAll(`.item.active  [sectionid=${sectionId}]`);
            }
            const sectionid = sectionId;
            let idx = 0;
            let clss = oldElement[0]?.getAttribute('class');
            clss = clss.replace('document-background-color', '');
            clss = clss.replace('background-color-darkbrown', '');
            clss = clss.replace('align-center-wrapper', '');
            WistiaEditorIFrame(src, sectionId, clss, oldElement[idx]);
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <FormInput
                type="text"
                id="embedUrl"
                label="Enter Embed URL *"
                maxLength="500"
                errors={errors}
                register={register}
            />
            <div className="button-list d-flex justify-content-center">
                <ButtonElement name="Link" variant="primary" type="submit" />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default EmbedVideoModal;
