// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import HyperDatepicker from '../../../components/datepicker/Datepicker';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { analyticSystemWideDataExportDownload, userList, analyticDataExportReset } from '@ovrture/react-redux';

const ExportSystemWideDashboardDataModal = (props) => {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [isDate, setIsDate] = useState('all');
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(true);
    const [user, setUser] = useState('');
    const [colTags, setcolTags] = useState([]);
    const [tagInput, settagInput] = useState('');
    const dispatchAction = useDispatch();
    const { systemWideDataExport, users } = useSelector((state) => ({
        systemWideDataExport: state?.analyticDataExport?.systemWideDataExportDownload,
        users: state?.user?.userList,
    }));

    useEffect(() => {
        dispatchAction(
            userList({
                isAnd: true,
                isUnpaged: true,
                sort: 'colLastName,ASC',
            })
        );
    }, []);

    useEffect(() => {
        if (systemWideDataExport) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(analyticDataExportReset());
        }
    }, [systemWideDataExport]);

    const onSelectStartDate = (date) => {
        setSelectedStartDate(date);
    };
    const onSelectEndDate = (date) => {
        setSelectedEndDate(date);
    };

    const downloadWideDashboardData = () => {
        const payload = {
            userId: user,
        };

        if (isDate === 'date') {
            selectedStartDate && (payload.startDate = Moment(selectedStartDate).format('MM/DD/YYYY'));
            selectedEndDate && (payload.endDate = Moment(selectedEndDate).format('MM/DD/YYYY'));
        }
        // Tags
        if (colTags?.length > 0) {
            payload.colTags = colTags.join(',');
        }
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        dispatchAction(analyticSystemWideDataExportDownload(payload));
    };
    const handleTagInput = (e) => {
        if (e.key === 'Enter' || e.keyCode === 9) {
            e.preventDefault();
            if (e.target.value.length > 0) tagValidation(e);
        }
    };

    const tagValidation = (e) => {
        if (e.target.value.length > 0) {
            const arr = [...colTags];
            arr.push(e.target.value.replace('\n', ''));
            setcolTags(arr);
            settagInput('');
        }
    };

    const removeTag = (d, i) => {
        const arr = [...colTags];
        arr.splice(i, 1);
        setcolTags(arr);
    };

    return (
        <>
            <div className="custom-padding custom-change filter-tag export-filter">
                <Col md={6}>
                    <FormInput
                        type="select"
                        className="custom-select"
                        label="User(s)"
                        value={user}
                        onChange={(e) => setUser(e.target.value)}>
                        <option>All Users</option>
                        {users?.content.map((usr) => {
                            return (
                                <option key={usr.id} value={usr.id} data-email-id={usr.email}>
                                    {usr.colFullName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                {process.env.REACT_APP_EXPORT_TAG_SHOW === 'true' && <>
                <Col md={6} className="mt-3">
                    <b className="mb-1 d-block">Tags</b>
                    <div className="custom-filter-style-block">
                        <span className="inner-wrapper">
                            {colTags?.length > 0 &&
                                colTags?.map((d, i) => {
                                    return (
                                        <span className="list">
                                            <span className="value" key={i}>
                                                {d}
                                            </span>
                                            <span className="cross-icon" onClick={() => removeTag(d, i)}>
                                                <svg
                                                    height="17"
                                                    width="17"
                                                    viewBox="0 0 20 20"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    className="css-tj5bde-Svg">
                                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    );
                                })}
                        </span>
                        <FormInput
                            type="text"
                            id="colTags"
                            label="enter tag.."
                            value={tagInput}
                            className="custom-input prevent-form-enter"
                            floatinglabel="false"
                            onChange={(e) => settagInput(e.target.value)}
                            onKeyDown={(e) => handleTagInput(e)}
                            onBlur={(e) => tagValidation(e)}
                        />
                    </div>
                </Col>
                </>}
                <Col md={12} className="mt-3">
                    <p>
                        <b>Date Range:</b>
                    </p>
                    <FormInput
                        type="radio"
                        id="selectedTimeBitForAll"
                        name="selectedTimeBit"
                        label="All"
                        value="all"
                        className="ms-1 mt-1"
                        onChange={(e) => setIsDate(e.target.value)}
                        checked={isDate === 'all' ? true : false}
                    />
                    <Row className="mt-1">
                        <Col md={1}>
                            <FormInput
                                type="radio"
                                id="selectedTimeBitForSelectedDates"
                                name="selectedTimeBit"
                                value="date"
                                label=" "
                                className="ms-1 mt-1"
                                onChange={(e) => setIsDate(e.target.value)}
                                checked={isDate === 'date' ? true : false}
                            />
                        </Col>
                        <Col md={5} className="mt-2 mt-md-0 ps-md-0 ps-2">
                            <HyperDatepicker
                                value={selectedStartDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectStartDate(date);
                                }}
                                disabled={isDate === 'all' ? true : false}
                            />
                        </Col>
                        <Col md={1} className="m-auto">
                            to
                        </Col>
                        <Col md={5} className="ps-md-1 ps-2">
                            <HyperDatepicker
                                value={selectedEndDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectEndDate(date);
                                }}
                                disabled={isDate === 'all' ? true : false}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Export"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            downloadWideDashboardData();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportSystemWideDashboardDataModal;
