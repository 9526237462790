import { customActions } from './actions';

export const CustomIntialState = {
    colReportYear: null,
    reportData: null,
};

export const CustomReducer = (state = CustomIntialState, action) => {
    switch (action.type) {
        case customActions.ADD_REPORT_YEAR:
            return {
                ...state,
                colReportYear: action.payload,
            };
        case customActions.ADD_REPORT_DATA:
            return {
                ...state,
                reportData: action.payload,
            };

        case customActions.REST_REPORT:
            return {
                colReportYear: null,
                reportData: null,
            };
        default:
            return { ...state };
    }
};
