import React, { forwardRef, useRef, useEffect } from 'react';
const RadioButtonSelect = ({ list, setList, item, name }) => {
    const handleChange = (item, e) => {
        let findIndex = list.findIndex((items) => items.title === item.title && items.id === item.id);
        list?.forEach((items) => {
            if (items.title === item.title && items.id === item.id) {
                items.checked = e.target.checked;
            } else {
                items.checked = false;
            }
        });
        setList([...list]);
    };

    const IndeterminateRadioButtonValue = forwardRef(({ indeterminate, ...rest }, ref) => {
        const defaultRef = useRef();
        const resolvedRef = ref || defaultRef;

        useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <div className="form-check">
                    <input type="radio" className="form-check-input" ref={resolvedRef} {...rest} name={name} />
                    <label htmlFor="form-check-input" className="form-check-label"></label>
                </div>
            </>
        );
    });
    return <IndeterminateRadioButtonValue {...item} onChange={(e) => handleChange(item, e)} />;
};
export default RadioButtonSelect;
