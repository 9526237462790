// @flow
import React, { useEffect, useState } from 'react';
import { ListGroup, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import config from '../../../config';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { userListTransfer, userResetTrasfer } from '@ovrture/react-redux';
import ListItemSelection from '../../../components/listItem/ListItem';

const TransferProceed = (props) => {
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    const [list, setList] = useState([]);
    const [data, setData] = useState();
    const name = 'userList';
    let { userRecordList, loadingTrasfer } = useSelector((state) => ({
        userRecordList: state?.user?.userListTransfer,
        loadingTrasfer: state?.user?.loadingTrasfer,
    }));

    const getUserListTransfer = ({ page, searchText }) => {
        let payload = {
            page,
            size: config.PAGE_SIZE,
            isAnd: true,
            sort: ['colLastName'],
            searchText,
        };
        dispatch(userListTransfer(payload));
    };

    useEffect(() => {
        getUserListTransfer({ page: 0, searchText: '' });
    }, []);

    useEffect(() => {
        if (userRecordList?.content) {
            let userList = [];
            if (userRecordList?.number !== 0) userList = list;
            userRecordList?.content?.forEach((item) => {
                if (userList.find((f) => f.id === item.id) === undefined)
                    userList.push({ id: item.id, title: item.colFullName });
            });
            setList([...userList]);
            setData(userRecordList);
            dispatch(userResetTrasfer());
        }
    }, [userRecordList]);

    return (
        <>
            <p>Who will take ownership of all associated records?</p>
            <Col className="px-2 pb-2">
                <ListGroup className="listheader custom-list-header-color">
                    <ListGroup.Item className="active diabled">
                        <b>Users:</b>
                    </ListGroup.Item>
                </ListGroup>
                <ListItemSelection
                    name={name}
                    list={list}
                    data={data}
                    fetchMore={getUserListTransfer}
                    isSearchFilter={true}
                    loading={loadingTrasfer}
                    dynamicSearch={getUserListTransfer}
                    setList={setList}
                    type={'radio'}
                />
            </Col>
            <div className="modal-footer">
                <ButtonElement
                    name="Previous"
                    variant="outline-primary"
                    className="btn-prev"
                    type="button"
                    onClick={() => {
                        props.setActive(props.active - 1);
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
                <ButtonElement
                    name="Transfer"
                    variant="primary"
                    className="btn-next"
                    onClick={() => {
                        props.data.userIdToTransfer = list.find((item) => item.checked)?.id;
                        props.setActive(props.active + 1);
                    }}
                    disabled={list.find((item) => item.checked) ? false : true}
                />
            </div>
        </>
    );
};

export default TransferProceed;
