// @flow
import React, { useState, useReducer, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

//component
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    shiftLeft,
    Shift_Right_Redundent_Item,
    clearAll,
    TableShiftReducer,
    upDownHandler,
    ShiftInitialState,
    updateLeftArray,
    updateRightArray,
    autoReset,
} from '../../../reducers/TableShifterReducer';
import { useHistory, withRouter } from 'react-router-dom';
import {
    reportFundList,
    fundFind,
    fundLink,
    recordFundReset,
    reportUpdate,
    reportUpdateReset,
    reportUpdateLinkFund,
    reportUpdateResetData,
    reportUpdateGetLinkFund,
    reportUpdateAdd,
    reportFundReset,
    reportEditorUnlock,
    reportResetEditorUnlock,
    reportEditorLock,
    reportResetEditorlock,
    recordsNotifyUnlockStatus,
} from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { COMMON_PATH, RedirectTo } from '../../../routes/role/path';
import config from '../../../config';
import { CustomLoader } from '../../../helpers/customLoader';
import service from '../../../constants/service.constant';
import { fundPanelSequenceDetail } from '../../../helpers/editor/migration/TenantMigration';
import { accessRestrictedMessage, getSearchHistory } from '../../../helpers/retainHistory';

const LinkFundsModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [dataList, setdataList] = useState([]);
    const [dataRightList, setdataRightList] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [selectedValueRight, setSelectedValueRight] = useState([]);
    const [resetSelection, setResetSelection] = useState([]);
    const [resetSelectionRight, setResetSelectionRight] = useState([]);
    const [roleId, setRoleId] = useState(null);
    const [searchValue, setSearchValue] = useState('');
    const [loader, setLoader] = useState(false);
    const [loaderLink, setLoaderLink] = useState(false);
    const [pageSize, setPageSize] = useState(config.PAGE_SIZE);
    const dispatchAction = useDispatch();
    const url = new URL(window.location.href);
    const {
        fundList,
        linkedFundList,
        linked,
        updatedReport,
        fundListLoading,
        reportTabLinkedFund,
        getReportTabLinkedFund,
        reportTabLinkedFundAdd,
        permission,
        loadingForBuild,
        editorUnlock,
        editorLock,
    } = useSelector((state) => ({
        editorLock: state?.reportEditor?.editorLock,
        fundList: state?.reportDataFund?.list,
        fundListLoading: state?.reportDataFund?.loading,
        linkedFundList: state?.fund?.find,
        loadingForBuild: state?.fund?.loading,
        updatedReport: state?.report?.updateReport,
        linked: state?.fund?.link,
        reportTabLinkedFund: state?.reportUpdate?.linkFund,
        reportTabLinkedFundAdd: state?.reportUpdate?.add,
        getReportTabLinkedFund: state?.reportUpdate?.getLinkFund,
        permission: state?.user?.tenantPermission,
        editorUnlock: state?.reportEditor?.editorUnlock,
    }));
    const [state, dispatch] = useReducer(TableShiftReducer, ShiftInitialState);
    const history = useHistory();

    useEffect(() => {
        if (updatedReport) {
            dispatchAction(reportEditorUnlock({ pkReportId: props?.data?.original?.pkReportId }));
        }
    }, [updatedReport]);

    useEffect(() => {
        if (window.location.pathname === '/platform/portfolio') {
            history.push('/portfolio');
        }

        if (window.location.pathname.includes('/portfolio-edit-record')) {
            history.push('/portfolio-edit-record' + '/' + props?.data?.id);
        }

        if (window.location.pathname.includes('/report-edit')) {
            history.push({ search: getSearchHistory() });
        }

        setRoleId(sessionStorage.getItem('ROLE_ID'));
    }, []);

    useEffect(() => {
        dispatch(reportFundReset());
        dispatchAction(recordFundReset());
        if (
            props?.data?.type !== 'report-update' &&
            props?.data?.page !== 'add' &&
            !(
                props?.data?.type === 'report-build' &&
                (props?.data?.page === 'potfolio' || props?.data?.page === 'multireports')
            )
        ) {
            if (props?.data?.type !== 'reportTabLinkFund') {
                if (props?.data?.type === 'report-edit-page') {
                    let payload = {
                        page: 0,
                        size: pageSize,
                        isAnd: true,
                        searchText: '',
                        colReportYear: { value: url?.searchParams?.get('reportYear') },
                    };
                    dispatchAction(reportFundList(payload));
                } else {
                    dispatchAction(
                        reportFundList({
                            page: 0,
                            size: pageSize,
                            isAnd: true,
                            searchText: '',
                            colReportYear: { value: url?.searchParams?.get('reportYear') },
                        })
                    );
                }
            } else {
                const data_pk_report_update_id = document
                    ?.getElementById('rootComponent')
                    ?.getAttribute('data_pk_report_update_id');
                dispatchAction(
                    reportUpdateGetLinkFund({
                        reportUpdateId: data_pk_report_update_id,
                        reportYear: url?.searchParams?.get('reportYear'),
                    })
                );
            }
        }

        var payload = {};
        if (props?.data?.type === 'report-update') {
            payload = {
                pkRecordsId: props?.data?.original?.tblRecords?.pkRecordsId,
                reportYear: props?.data?.original?.colFiscalYear,
                page: 0,
                size: pageSize,
                isAnd: true,
                isUnpaged: true,
            };
        } else if (props?.data?.type === 'report-edit-page') {
            payload = {
                pkRecordsId: props?.data?.id,
                reportYear: url?.searchParams?.get('reportYear'),
                page: 0,
                size: pageSize,
                isAnd: true,
                isUnpaged: true,
            };
        } else if (props?.data?.type === 'reportTabLinkFund') {
            payload = {
                pkRecordsId: props?.data?.id,
                reportYear: url?.searchParams?.get('reportYear'),
                page: 0,
                size: pageSize,
                isAnd: true,
                isUnpaged: true,
            };
        } else if (window.location.pathname.includes('/reports-create')) {
            payload = {
                pkRecordsId: props?.data?.id,
                reportYear: props?.data?.filterYear,
                page: 0,
                size: pageSize,
                isAnd: true,
                isUnpaged: true,
            };
        } else {
            payload = {
                pkRecordsId: props?.data?.id,
                page: 0,
                size: pageSize,
                isAnd: true,
                isUnpaged: true,
            };
        }
        dispatchAction(fundFind(payload));
    }, [props.data.id, props?.data?.original?.id]);

    useEffect(() => {
        if (window.location.pathname.includes('/report-edit')) {
            history.push({ search: getSearchHistory() });
        }
        if (
            props?.data?.type !== 'report-build' &&
            props?.data?.type !== 'report-update' &&
            fundList &&
            linkedFundList
        ) {
            setTimeout(() => setLoader(false), 250);
        } else if (props?.data?.type === 'report-build' && linkedFundList) setTimeout(() => setLoader(false), 250);
        else if (props?.data?.type === 'report-update' && linkedFundList) setTimeout(() => setLoader(false), 250);
        else if (props?.data?.type === 'reportTabLinkFund' && linkedFundList) setTimeout(() => setLoader(false), 250);
    }, [fundList, linkedFundList]);

    useEffect(() => {
        if (
            fundList?.content?.length > 0 &&
            !(
                props?.data?.type === 'report-build' &&
                (props?.data?.page === 'potfolio' || props?.data?.page === 'multireports')
            )
        ) {
            fundList?.content.forEach((info) => {
                info['id'] = info?.pkFundId;
                info['status'] = '';
                info['fundName'] = <ButtonElement as="text" name={info?.colFundName} title={info?.colFundName} />;
                info['referenceNumber'] = (
                    <ButtonElement as="text" name={info?.colFundReferenceNo} title={info?.colFundReferenceNo} />
                );
            });
            setPageSize(fundList?.size);
            setdataList(fundList?.content);
            dispatch(updateLeftArray([...fundList?.content]));
        } else {
            setdataList([]);
            dispatch(updateLeftArray([]));
        }
    }, [fundList]);
    useEffect(() => {
        if (props?.data?.type === 'reportTabLinkFund' && props?.data?.page !== 'add') {
            if (getReportTabLinkedFund?.length > 0) {
                getReportTabLinkedFund.forEach((info) => {
                    info['id'] = info?.pkFundId;
                    info['status'] = '';
                    info['fundName'] = <ButtonElement as="text" name={info?.colFundName} title={info?.colFundName} />;
                    info['referenceNumber'] = (
                        <ButtonElement as="text" name={info?.colFundReferenceNo} title={info?.colFundReferenceNo} />
                    );
                });
                dispatch(updateRightArray([...getReportTabLinkedFund]));
            } else {
                setdataList([]);
                dispatch(updateRightArray([]));
            }
        }
    }, [getReportTabLinkedFund]);

    useEffect(() => {
        if (Array.isArray(linkedFundList?.content)) {
            linkedFundList?.content.forEach((info) => {
                info['id'] = info?.pkFundId;
                info['status'] = '';
                info['fundName'] = info?.colFundName;
                info['referenceNumber'] = info?.colFundReferenceNo;
            });
            //setResetSelection([]);
            if (
                props?.data?.type === 'report-build' ||
                props?.data?.type === 'report-update' ||
                props?.data?.type === 'reportTabLinkFund'
            ) {
                props?.data?.type === 'reportTabLinkFund' && setPageSize(100);
                setdataList(linkedFundList?.content);
                const newList = linkedFundList?.content;
                dispatch(updateLeftArray(newList));
            } else {
                setdataRightList(linkedFundList?.content);
                const newList = linkedFundList?.content;
                dispatch(updateRightArray(newList));
            }
        } else {
            if (
                props?.data?.type === 'report-build' ||
                props?.data?.type === 'report-update' ||
                props?.data?.type === 'reportTabLinkFund'
            ) {
                // setdataList([]);
                // dispatch(updateLeftArray([]));
            } else {
                setdataRightList([]);
                dispatch(updateRightArray([]));
            }
        }
    }, [linkedFundList]);

    useEffect(() => {
        if (
            linked === true ||
            linked?.code === service.created ||
            updatedReport?.code === service.created ||
            reportTabLinkedFund ||
            reportTabLinkedFundAdd
        ) {
            closePopup();
        }
    }, [linked, updatedReport, reportTabLinkedFund, reportTabLinkedFundAdd]);

    /*
    form validation schema
    */
    const { handleSubmit } = useForm({});

    useEffect(() => {
        if (editorUnlock?.code === service.success) {
            dispatchAction(reportResetEditorUnlock());
        }
    }, [editorUnlock]);

    const onSubmit = () => {
        if (props?.data?.lockAvailable) {
            sessionStorage.setItem(
                'headersConfigReport',
                JSON.stringify({
                    headers: {
                        referrer: window.location.origin + '/platform/report-update',
                        originReferer: window.location.origin + '/platform',
                    },
                })
            );
            dispatchAction(reportEditorLock({ pkReportId: props?.data?.original.id }));
        } else {
            onSubmitAction();
        }
    };

    useEffect(() => {
        if (editorLock?.code === service.success && props?.data?.lockAvailable) {
            onSubmitAction();
            dispatchAction(reportResetEditorlock());
        } else if (editorLock?.code === service.notfound && props?.data?.lockAvailable) {
            if (accessRestrictedMessage === editorLock?.message) {
                Toast.warning({
                    title: 'Warning',
                    description: editorLock?.message,
                });
            } else {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['editor', 'oneEditorWarning'],
                        currentModalId: (Math.random() + 1).toString(36).substring(7),
                        hasChildModal: true,
                        data: {
                            message: editorLock?.message,
                            notifyMe: () => {
                                dispatch(recordsNotifyUnlockStatus({ fkReportId: props.original.id }));
                                modalDispatch({
                                    type: modalActions.CLOSE_MODAL,
                                });
                            },
                        },
                    },
                });
            }
            dispatchAction(reportResetEditorlock());
        }
    }, [editorLock]);

    const onSubmitAction = () => {
        if (buttonType() === 'Build' && loadingForBuild) return true;
        props?.data?.type === 'report-update' &&
            sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Report update applied.');
        if (props?.data?.type === 'reportTabLinkFund') {
            props?.data?.page === 'add'
                ? sessionStorage.setItem(
                      process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
                      'Report update applied.'
                  )
                : sessionStorage.setItem(
                      process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
                      'Link fund(s) updated.'
                  );
        }
        // props?.data?.type === 'report-build' &&
        //     sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_GENERIC_MSG');
        setLoaderLink(true);
        const list = [];
        state?.rightArry.map((d) => list.push(d.pkFundId));
        let payload = {};
        if (props?.data?.type === 'report-build' && props?.data?.page === 'potfolio') {
            props?.data?.BuildReport(list);
            setTimeout(() => {
                modalDispatch({
                    type: modalActions.CLOSE_MODAL,
                });
            }, 200);
        } else if (props?.data?.type === 'report-update') {
            payload = {
                fkReportId: props?.data?.original?.pkReportId,
                fundIdList: list,
            };
            dispatchAction(reportUpdate(payload));
        } else if (
            props?.data?.type === 'reportTabLinkFund' ||
            (props?.data?.type === 'report-build' && props?.data?.page === 'multireports')
        ) {
            const data_pk_report_update_id = document
                ?.getElementById('rootComponent')
                ?.getAttribute('data_pk_report_update_id');
            if (props?.data?.page === 'add') {
                payload = {
                    fkReportId: props?.data?.reportId,
                    fundIdList: list,
                };
                dispatchAction(reportUpdateAdd(payload));
            } else {
                if (props?.data?.reportId && data_pk_report_update_id) {
                    payload = {
                        reportId: props?.data?.reportId,
                        fundIdList: list,
                        reportUpdateId: data_pk_report_update_id,
                    };
                    dispatchAction(reportUpdateLinkFund(payload));
                } else {
                    //dispatchAction(reportUpdateLinkFund({ skipApi: true }));
                    closePopup();
                }
            }
        } else {
            if (list?.length === 0) fundPanelSequenceDetail();
            payload = {
                data: {
                    pkRecordsId: props?.data?.id,
                    pkFundIdList: list,
                },
            };
            dispatchAction(fundLink(payload));
        }
    };

    const closePopup = () => {
        if (props?.data?.setopenModal) {
            const fndList = [...props?.data?.fundIds];
            fndList.filter((f) => f.pkRecordsId === props?.data?.id || props?.data?.id?.id)[0].fundIdList =
                state?.rightArry.map((d) => d.pkFundId);
            props?.data?.setfundIds(fndList);
            props?.data?.setopenModal(props?.data?.openModal + 1);
        } else {
            dispatchAction(recordFundReset());
            dispatchAction(reportUpdateReset());
            dispatchAction(reportUpdateResetData());
            dispatch(updateRightArray([]));
            dispatch(updateLeftArray([]));
            setLoaderLink(false);
            if (props?.data?.portfolioData?.isPortfolioList) {
                modalDispatch({
                    type: modalActions.CLOSE_MODAL,
                });
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalAction: (e) =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            }),
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'buildReport'],
                        data: props?.data?.portfolioData,
                    },
                });
            } else {
                setTimeout(() => {
                    modalDispatch({
                        type: modalActions.CLOSE_MODAL,
                    });
                }, 200);
            }
        }
    };
    /* Status column detail */
    const StatusColumn = ({ row }) => {
        const status =
            row?.original?.fundImpacts?.length > 0 &&
            row?.original?.fundPerformances?.length > 0 &&
            row?.original?.fundDonorses?.length > 0
                ? 'green'
                : row?.original?.fundImpacts?.length === 0 &&
                  row?.original?.fundPerformances?.length === 0 &&
                  row?.original?.fundDonorses?.length === 0
                ? 'red'
                : 'yellow';
        return (
            <span className={`action-icon`}>
                {status === 'green' && <i className="fa-solid fa-circle-dot green"></i>}
                {status === 'yellow' && <i className="fa-solid fa-circle-dot yellow"></i>}
                {status === 'red' && <i className="fa-solid fa-circle-dot red"></i>}
            </span>
        );
    };
    const updateSelectedValue = (selectedItem) => {
        setTimeout(() => {
            setSelectedValue(selectedItem);
            // setDisabled(true);
        }, 50);
    };
    const updateSelectedValueRight = (selectedItem) => {
        setTimeout(() => {
            setSelectedValueRight(selectedItem);
        }, 50);
    };

    const getSearchValue = (value) => {
        setSearchValue(value);
        dispatchAction(
            reportFundList({
                page: 0,
                size: pageSize,
                isAnd: true,
                searchText: value,
                colReportYear: { value: url?.searchParams?.get('reportYear') },
            })
        );
    };

    const handleModal = () => {
        window.location.href = RedirectTo(COMMON_PATH.CREATE_FUND);
    };

    const buttonType = () => {
        if (
            props?.data?.type === 'report-build' ||
            props?.data?.type === 'report-update' ||
            (props?.data?.type === 'reportTabLinkFund' && props?.data?.page === 'add')
        ) {
            return 'Build';
        } else {
            return 'Save';
        }
    };

    const checkIsButtonDisabled = () => {
        if (buttonType() === 'Save') {
            return state?.leftArry?.length > 0 ? false : true;
        } else if (buttonType() === 'Build') return loadingForBuild ? true : false;
        else {
            return fundListLoading ? true : false;
        }
    };
    return (
        <>
            <CustomLoader loader={loader} />
            <CustomLoader loader={loaderLink} />
            <form onSubmit={handleSubmit(onSubmit)}>
                {(props?.data?.type === 'reportTabLinkFund' ||
                    (props?.data?.type === 'report-build' && props?.data?.page === 'potfolio') ||
                    (props?.data?.type === 'report-build' && props?.data?.page === 'multireports') ||
                    props?.data?.type === 'report-update') && (
                    <p>
                        Report Updates is enabled for record {props?.data?.referenceNo} ({props?.data?.name}). As such,
                        use the selector below to indicate which funds linked to this record you wish to report on via
                        this report.
                    </p>
                )}
                <Row>
                    <Col className="col-lg col-12 small-table-custom-pagination">
                        <Card>
                            <Table
                                columns={TableColumn.Common.LinkFunds([StatusColumn])}
                                data={state?.leftArry}
                                pageSizes={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? linkedFundList?.size
                                        : fundList?.size
                                }
                                pageNumber={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? linkedFundList?.number
                                        : fundList?.number
                                }
                                totalElements={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? linkedFundList?.totalElements
                                        : fundList?.totalElements
                                }
                                totalPages={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? linkedFundList?.totalPages
                                        : fundList?.totalPages
                                }
                                paginationDispatch={reportFundList}
                                sizePerPageList={TableColumn.sizePerPageList(dataList)}
                                isSortable={true}
                                pagination={true}
                                isFilter={false}
                                isSearchable={true}
                                getSearchValue={getSearchValue}
                                searchValue={searchValue}
                                customPaginationProps={{
                                    searchText: searchValue,
                                    colReportYear: { value: url?.searchParams?.get('reportYear') },
                                }}
                                isSelectable={true}
                                theadClass={TableColumn.table_light}
                                tableClass="link-funds shifter-table shifter-left-table"
                                trClass={{
                                    selectedValue: selectedValue,
                                    rightArry: state?.rightArry,
                                    leftArry: state?.leftArry,
                                }}
                                resetSelection={resetSelection}
                                setResetSelection={setResetSelection}
                                updateSelectedValue={updateSelectedValue}
                                tableParentClass={
                                    (props?.data?.type === 'report-build' &&
                                        (props?.data?.page === 'multireports' || props?.data?.page === 'potfolio')) ||
                                    props?.data?.type === 'reportTabLinkFund' ||
                                    props?.data?.type === 'report-update'
                                        ? 'height-fixed'
                                        : fundList?.totalElements >= 10
                                        ? 'height-fixed pagination-visible'
                                        : 'height-fixed'
                                }
                                footerPaginationOff={
                                    (props?.data?.type === 'report-build' &&
                                        (props?.data?.page === 'multireports' || props?.data?.page === 'potfolio')) ||
                                    props?.data?.type === 'reportTabLinkFund' ||
                                    props?.data?.type === 'report-update'
                                        ? true
                                        : false
                                }
                                historyDisable={true}
                                //onPaginationPageChangeEvent={onPaginationPageChangeEvent}
                            />
                        </Card>
                    </Col>

                    <Col className={`col-lg-1 col-sm-12 text-center mt-lg-5 mb-lg-5 d-lg-block outer-wrapper`}>
                        <Col className="inner-wrapper">
                            <Col className="mb-2 mt-lg-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-right"></i>}
                                    className={
                                        selectedValue.length === 0
                                            ? 'disabled action-icon move-right'
                                            : 'action-icon move-right'
                                    }
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Move Right"
                                    tooltipAlign="right"
                                    onClick={() => {
                                        dispatch(Shift_Right_Redundent_Item(selectedValue));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>
                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-left"></i>}
                                    className={
                                        selectedValueRight.length === 0
                                            ? 'disabled action-icon move-left'
                                            : 'action-icon move-left'
                                    }
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Move Left"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length === 0 ? true : false}
                                    onClick={() => {
                                        dispatch(shiftLeft(selectedValueRight));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>

                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-double-left"></i>}
                                    className="action-icon"
                                    as="button"
                                    variant="outline-primary"
                                    tooltip="Clear All"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length === 0 ? true : false}
                                    onClick={() => {
                                        dispatch(clearAll('Right'));
                                        setResetSelection([]);
                                        setResetSelectionRight([]);
                                    }}
                                />
                            </Col>
                            <Col className="mb-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-up"></i>}
                                    as="button"
                                    className={selectedValueRight.length === 0 ? 'disabled action-icon' : 'action-icon'}
                                    variant="outline-primary"
                                    tooltip="Move Up"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length > 1 ? false : true}
                                    onClick={() => {
                                        if (selectedValueRight.length === 1) {
                                            dispatch(upDownHandler('up', selectedValueRight));
                                            setTimeout(() => {
                                                dispatch(autoReset());
                                            }, 500);
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-down"></i>}
                                    as="button"
                                    variant="outline-primary"
                                    className={selectedValueRight.length === 0 ? 'disabled action-icon' : 'action-icon'}
                                    tooltip="Move Down"
                                    tooltipAlign="right"
                                    disabled={state?.rightArry?.length > 1 ? false : true}
                                    onClick={() => {
                                        if (selectedValueRight.length === 1) {
                                            dispatch(upDownHandler('down', selectedValueRight));
                                            setTimeout(() => {
                                                dispatch(autoReset());
                                            }, 500);
                                        }
                                    }}
                                />
                            </Col>
                        </Col>
                    </Col>

                    <Col className="col-lg col-12 link-fund-right-table pt-0 right-table">
                        <Card>
                            <Row>
                                <Col className="mt-4">
                                    <span className="count">
                                        <b>
                                            <span className={state?.rightArry?.length === 70 && 'text-danger'}>
                                                {state?.rightArry?.length}
                                            </span>{' '}
                                            of 70
                                        </b>
                                    </span>
                                </Col>

                                <Col
                                    className={
                                        roleId === 'USER' ||
                                        roleId === 'SUPER_USER' ||
                                        roleId === 'CONTENT_ADMIN' ||
                                        props?.data?.type === 'reportTabLinkFund' ||
                                        props?.data?.type === 'report-edit-page' ||
                                        (props?.data?.type === 'report-build' && props?.data?.page === 'potfolio') ||
                                        (props?.data?.type === 'report-build' &&
                                            props?.data?.page === 'multireports') ||
                                        props?.data?.type === 'report-update'
                                            ? 'me-3 d-flex justify-content-end mt-3 custom-height'
                                            : 'me-3 d-flex justify-content-end mt-3'
                                    }>
                                    {roleId === 'USER' ||
                                    roleId === 'SUPER_USER' ||
                                    roleId === 'CONTENT_ADMIN' ||
                                    props?.data?.type === 'reportTabLinkFund' ||
                                    props?.data?.type === 'report-edit-page' ||
                                    (props?.data?.type === 'report-build' && props?.data?.page === 'potfolio') ||
                                    (props?.data?.type === 'report-build' && props?.data?.page === 'multireports') ||
                                    props?.data?.type === 'report-update' ? (
                                        ''
                                    ) : (
                                        <ButtonElement
                                            name="Add New"
                                            variant="outline-primary"
                                            onClick={handleModal}
                                            disabled={!permission?.colIsReportAccessible}
                                        />
                                    )}
                                </Col>
                            </Row>
                            <Table
                                tableId="linkFundsRight"
                                columns={TableColumn.Common.LinkFunds([StatusColumn])}
                                data={state?.rightArry}
                                pageSize={70}
                                sizePerPageList={TableColumn.sizePerPageList(dataRightList)}
                                isSortable={true}
                                pagination={true}
                                isSearchable={false}
                                isFilter={false}
                                isSelectable={true}
                                theadClass={TableColumn.table_light}
                                tableClass="link-funds link-fund-right-table shifter-table shifter-right-table"
                                resetSelection={resetSelectionRight}
                                setResetSelection={setResetSelectionRight}
                                updateSelectedValue={updateSelectedValueRight}
                                tableParentClass={
                                    props?.data?.type === 'report-build' && props?.data?.page === 'multireports'
                                        ? linkedFundList?.totalElements >= 10
                                            ? 'height-fixed pagination-visible'
                                            : 'height-fixed'
                                        : fundList?.totalElements >= 10
                                        ? 'height-fixed pagination-visible'
                                        : 'height-fixed'
                                }
                                historyDisable={true}
                                shiftSingleItem={true}
                                loading={loader}
                            />
                        </Card>
                    </Col>
                </Row>
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement
                        name={buttonType()}
                        variant="primary"
                        type="submit"
                        disabled={checkIsButtonDisabled()}
                    />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={() => {
                            closePopup();
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default withRouter(LinkFundsModal);
