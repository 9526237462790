// @flow
import React, { useEffect, useState } from 'react';
// components
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { contentPanelTypeList, contentDuplicate, reportDataImpactTypeList } from '@ovrture/react-redux';
export const TYPE_NAME = {
    EDITABLE: {
        ID: 1,
        NAME: 'Editable',
        PARAGRAPH: `This type of panel can be created once centrally and then edit further in individual site, reports, or surveys; central changes to this content panel via this editor WILL NOT cascade to existing sites, reports, or surveys.`,
    },
    INHERITED: {
        ID: 2,
        NAME: 'Inherited',
        PARAGRAPH: `Changes made to the central copy or assets of this type of content panel will cascade to all existing sites, reports, and surveys where it is placed. These panels CAN NOT be personalized.`,
    },
    INTERACTION: {
        ID: 3,
        NAME: 'Interaction',
        PARAGRAPH: `This type of content panel is used to acquire feedback from site, report, or survey recipients. The collected data IS NOT connected to record or fund data.`,
    },
    IMPACT_SURVEYOR: {
        ID: 4,
        NAME: 'Impact Surveyor',
        PARAGRAPH: `This type of panel is used to collect impact data from a wide range of constituents. Data, once collected and if collected correctly, is then routed directly into Ovrture's databases, linked to funds, and a specific reporting year.`,
    },
};

const CreateNewContentPanelModal = (props) => {
    const dispatch = useDispatch();
    const [colImpactSurveyor, setcolImpactSurveyor] = useState(null);
    const { modalDispatch } = useModalContext();
    const {
        handleSubmit,
        register,
        setValue,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    let { typeList, impactTypeList } = useSelector((state) => ({
        typeList: state?.contentPanel?.typeList?.data,
        impactTypeList: state?.reportData?.impactTypeList?.data,
    }));

    useEffect(() => {
        dispatch(contentPanelTypeList({ orgId: props?.data?.state?.orgId }));
        dispatch(reportDataImpactTypeList({ orgId: props?.data?.state?.orgId }));
    }, []);

    useEffect(() => {
        if (typeList?.length > 0) {
            setValue('contentPanelType', typeList[0]?.pkContentPanelTypesId?.toString());
            typeList.map((panel) => {
                switch (panel.colName) {
                    case TYPE_NAME.EDITABLE.NAME:
                        panel.paragraph = TYPE_NAME.EDITABLE.PARAGRAPH;
                        break;
                    case TYPE_NAME.INHERITED.NAME:
                        panel.paragraph = TYPE_NAME.INHERITED.PARAGRAPH;
                        break;
                    case TYPE_NAME.INTERACTION.NAME:
                        panel.paragraph = TYPE_NAME.INTERACTION.PARAGRAPH;
                        break;
                    case TYPE_NAME.IMPACT_SURVEYOR.NAME:
                        panel.paragraph = TYPE_NAME.IMPACT_SURVEYOR.PARAGRAPH;
                        break;
                }
            });
        }
    }, [typeList]);

    const onSubmit = (data) => {
        const obj = typeList.find((f) => f.pkContentPanelTypesId === Number(data?.contentPanelType));
        if (props?.data?.state?.duplicate) {
            let payload = {
                id: props?.data?.state?.contentData?.id,
                fkContentPanelTypeId: obj.pkContentPanelTypesId,
            };
            if (obj.colName === TYPE_NAME.IMPACT_SURVEYOR.NAME) {
                payload.fkImpactTypeId = data?.colImpactType;
            }
            dispatch(contentDuplicate(payload));
        } else {
            props.data.cp = {
                obj: obj,
                state: { ...props?.data?.state, impactTypeList },
            };
            delete props.data.state;
            props.setActive(props.active + 1);
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="createNewContentPanelModal">
            {props?.data?.state?.duplicate ? (
                <h5 className="text-primary mt-0 mb-3">Content Panel Type:</h5>
            ) : (
                <p>What kind of Content Panel would you like to build?</p>
            )}
            <Row>
                {typeList?.map(
                    (panel, index) =>
                        index < (process.env.REACT_APP_SURVEYOR_CONTENT_PANEL_TWO_PANEL_HIDE === 'true' ? 2 : 10) && (
                            <Col lg={12} key={panel.pkContentPanelTypesId}>
                                <FormInput
                                    type="radioWithOptions"
                                    className={'col-md-8 mb-2'}
                                    id="contentPanelType"
                                    value={panel.pkContentPanelTypesId}
                                    label={panel.colName}
                                    iconName={panel.colIcon}
                                    descriptorText={panel.colInformation}
                                    maxLength="30"
                                    errors={errors}
                                    register={register}
                                    onChange={(e) => {
                                        props?.setcontentPanelType(panel?.pkContentPanelTypesId);
                                        if (props?.data?.state?.duplicate) {
                                            if (panel.colName === 'Impact Surveyor') {
                                                setcolImpactSurveyor(panel.colName);
                                            } else {
                                                setcolImpactSurveyor(null);
                                            }
                                        }
                                    }}
                                />
                            </Col>
                        )
                )}
                {colImpactSurveyor === TYPE_NAME.IMPACT_SURVEYOR.NAME && (
                    <>
                        <p>Impact Type:</p>
                        <FormInput
                            id="colImpactType"
                            type="select"
                            className="mb-3"
                            label="Impact Type"
                            errors={errors}
                            register={register}>
                            {impactTypeList?.map((item) => {
                                return (
                                    <option key={item.pkImpactTypeId} value={item?.pkImpactTypeId}>
                                        {item?.colImpactName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </>
                )}
            </Row>
            {props?.data?.state?.duplicate && (
                <div className="button-list d-flex justify-content-center mt-2">
                    <ButtonElement name={'Duplicate'} variant="primary" type="submit" />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            )}
            <div className="modal-footer">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() => props.hideModal()}
                />
                <ButtonElement name="Proceed" variant="primary" className="btn-next" type="submit" />
            </div>
            {props?.data?.state?.duplicate && (
                <div className="footer-text mt-2">
                    <small className="text-danger">Warning: </small>
                    <span>Once the type of Content Panel is selected, this selection cannot be changed.</span>
                </div>
            )}
        </form>
    );
};

export default CreateNewContentPanelModal;
