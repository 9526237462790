import React, { Component } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import 'jstree/dist/jstree.min';
import 'jstree/dist/themes/default/style.css';
import { RedirectTo } from '../../routes/role/path';

export const getInstance = () => {
    if (document.querySelector('.visually-visible-modal.show .jstree')) {
        return $('.visually-visible-modal.show .jstree.active')
            ? '.visually-visible-modal.show .jstree.active'
            : '.visually-visible-modal.show .jstree';
    } else {
        return document?.querySelector('.jstree.active') ? '.jstree.active' : '.jstree';
    }
};

export const getInstanceChangeLocation = () => {
    return '.visually-visible-modal.show .changeLocation';
};

export const openMovedPath = (activeJstreePath) => {
    $(getInstance()).jstree(true).close_all();
    for (let index = 0; index < activeJstreePath?.length; index++) {
        const m = activeJstreePath[index];
        if (index === activeJstreePath?.length - 1) {
            let element = document?.querySelector(`${getInstance()} #${CSS.escape(m)}`);
            element?.classList?.contains('jstree-closed') && element.querySelector('.jstree-icon.jstree-ocl').click();
        } else {
            let node = $(getInstance()).jstree(true)?.get_node(Number(m));
            $(getInstance()).jstree(true)?.open_node(node);
        }
    }
};

export const openNode = () => {
    $(getInstance()).jstree(true).open_all();
};
export const deselectNode = () => {
    let obj = $(getInstance());
    obj?.length > 0 && obj.jstree(true).deselect_all(true);
};
export const closeAll = () => {
    document?.querySelector(getInstance())
        ? $(getInstance()).jstree(true).close_all()
        : $('.jstree').jstree(true).close_all();
};

export const enableCP = (id) => {
    document
        .getElementById(id)
        .querySelectorAll('ul li a.jstree-disabled')
        .forEach((element) => {
            element.classList.remove('jstree-disabled');
        });
};

export const removeNode = (nodeId) => {
    var node = $(getInstance()).jstree(true).get_node(Number(nodeId));
    $(getInstance()).jstree('delete_node', node);
    $(getInstance()).jstree(true).refresh();
};

export const parentCustom = (nodeId) => {
    var node = $(getInstance()).jstree(true).get_node(Number(nodeId));
    return node.text === 'CUSTOMS';
};

export const parentTemplate = (nodeId) => {
    var node = $(getInstance()).jstree(true).get_node(Number(nodeId));
    return node.text === 'TEMPLATES';
};
export const getAllPathIds = (nodeId) => {
    let treeInstance = getInstance();
    // console.log('tree==========>', $(treeInstance), $('.jstree'));
    let node = $(treeInstance).jstree(true).get_node(Number(nodeId));
    // console.log('node==========>', node);
    let path = $(treeInstance).jstree(true).get_path(node, '/', true).split('/');
    // console.log('path==========>', path);
    return path;
};

const historyPage = [
    process.env.REACT_APP_ROUTE_PREFIX + '/content',
    process.env.REACT_APP_ROUTE_PREFIX + '/digitalassets',
    process.env.REACT_APP_ROUTE_PREFIX + '/content-panel-edit',
    process.env.REACT_APP_ROUTE_PREFIX + '/sites-create',
    process.env.REACT_APP_ROUTE_PREFIX + '/reports-create',
    process.env.REACT_APP_ROUTE_PREFIX + '/systemsettings-btd',
];

class TreeView extends Component {
    constructor(props) {
        super(props);
        this.tempData = '';
        this.activateNode = '';
        this.arrowNode = 0;
    }

    static propTypes = {
        treeData: PropTypes.object.isRequired,
        onChange: PropTypes.func,
    };

    static defaultProps = {
        onChange: () => false,
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.treeData !== this.props.treeData;
    }

    componentDidMount() {
        const { treeData } = this.props;
        const addArrowOnTree = (data) => {
            data.node.children.forEach((id) => {
                let dt = data.instance.get_node(id).original;
                let eleArray = document?.querySelectorAll(`${getInstance()} #${CSS.escape(dt.id)}`);
                dt?.child &&
                    eleArray?.forEach((ele) => {
                        if (!ele?.classList?.contains('jstree-open')) {
                            if (ele !== null) {
                                ele?.classList?.remove('jstree-leaf');
                                ele?.classList?.add('jstree-closed');
                            }
                        }
                    });
            });
        };
        if (treeData) {
            $(this.treeContainer).jstree(treeData);
            let jstree = $(this.treeContainer);
            const changeLocation = $(getInstanceChangeLocation());
            if (changeLocation.length > 0) {
                jstree = $(getInstanceChangeLocation());
            }
            // single click feature
            // $(this.treeContainer).on('changed.jstree', (e, data) => {
            //     if (data?.event?.type) data.event.type = 'dblclick';
            //     this.props.onChange(e, data);
            // });
            // dblclick feature
            $(this.treeContainer).on('changed.jstree', (e, data) => {
                //if (data?.event?.type) data.event.type = 'dblclick';
                this.props.onChange(e, data);
                this.tempData = data;
            });
            $(this.treeContainer).on('dblclick.jstree', (e) => {
                if (this?.tempData?.event?.type) {
                    this.tempData.event.type = 'dblclick';
                    this.props.onChange(e, this.tempData);
                }
            });
            // single click feature for arrow
            $(this.treeContainer).on('click.jstree', (e) => {
                if (e.target.classList.contains('jstree-icon')) {
                    let node = e.target.parentElement;
                    var childrens = jstree.jstree(true).get_node(node.id);
                    // console.log('childrens=====>', childrens, jstree, $(getInstance()));
                    if (childrens?.children?.length === 0) this.openArrowOnSingleClick(node.id);
                    this.disableCP();
                }
            });
            $(this.treeContainer).on('activate_node.jstree', function (event, data) {
                if (data?.node) {
                    localStorage.setItem('selectedNode', JSON.stringify(data?.node));
                    try {
                        if (
                            (historyPage.includes(window.location.pathname) ||
                                window?.location?.pathname?.includes('/site-edit') ||
                                window?.location?.pathname?.includes('/reportdata-edit-details') ||
                                window?.location?.pathname?.includes('/reportdata-edit-impact-details') ||
                                window?.location?.pathname?.includes('/reportdata-create-fund') ||
                                window?.location?.pathname?.includes('/report-edit')) &&
                            !document.querySelector('.visually-visible-modal .select-template-with-preview-modal')
                        ) {
                            if (
                                (window.location.pathname === RedirectTo('/content') ||
                                    window.location.pathname === RedirectTo('/content-panel-edit') ||
                                    window.location.pathname === RedirectTo('/sites-create') ||
                                    window.location.pathname === RedirectTo('/reports-create')) &&
                                (data?.node?.icon === 'glyphicon glyphicon-text-background-cp-draft' ||
                                    data?.node?.icon === 'glyphicon glyphicon-text-background-cp' ||
                                    data?.node?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
                                    data?.node?.icon ===
                                        'glyphicon glyphicon-text-background-cp-centrally-managed-draft' ||
                                    data?.node?.icon === 'glyphicon glyphicon-text-background-cp-printable-draft' ||
                                    data?.node?.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
                                    data?.node?.icon ===
                                        'glyphicon glyphicon-text-background-cp-centrally-managed-printable' ||
                                    data?.node?.icon ===
                                        'glyphicon glyphicon-text-background-cp-centrally-managed-printable-draft')
                            ) {
                                localStorage.removeItem('activeJstreePath');
                                let path = data.instance.get_path(data.node.parent, '/', true).split('/');
                                localStorage.setItem('activeJstreePath', JSON.stringify(path));
                            } else if (
                                window.location.pathname === RedirectTo('/digitalassets') &&
                                (data?.node.icon === 'glyphicon glyphicon-image' ||
                                    data?.node.icon === 'glyphicon glyphicon-doc-file' ||
                                    data?.node.icon === 'glyphicon glyphicon-video' ||
                                    data?.node.icon === 'glyphicon glyphicon-pdf-file')
                            ) {
                                localStorage.removeItem('activeJstreePath');
                                let path = data.instance.get_path(data.node.parent, '/', true).split('/');
                                localStorage.setItem('activeJstreePath', JSON.stringify(path));
                            } else {
                                localStorage.removeItem('activeJstreePath');
                                let path = data.instance.get_path(data.node, '/', true).split('/');
                                localStorage.setItem('activeJstreePath', JSON.stringify(path));
                            }
                        }
                    } catch (ex) {
                        console.log('ex', ex);
                    }
                }
            });
            $(this.treeContainer).on('after_open.jstree', function (event, data) {
                addArrowOnTree(data);
                setTimeout(() => addArrowOnTree(data), 500);
            });
            $(this.treeContainer).on('ready.jstree', function (event, data) {
                if (
                    historyPage.includes(window.location.pathname) ||
                    window?.location?.pathname?.includes('/site-edit') ||
                    window?.location?.pathname?.includes('/reportdata-edit-details') ||
                    window?.location?.pathname?.includes('/reportdata-edit-impact-details') ||
                    window?.location?.pathname?.includes('/reportdata-create-fund') ||
                    window?.location?.pathname?.includes('/report-edit')
                ) {
                    try {
                        setTimeout(() => {
                            try {
                                let jstree = $(getInstance());
                                const changeLocation = $(getInstanceChangeLocation());
                                if (changeLocation.length > 0) {
                                    jstree = $(getInstanceChangeLocation());
                                }

                                $(getInstance()).jstree(true).close_all();
                            } catch (ex) {
                                console.log('ex', ex);
                            }
                            let cpObj = JSON.parse(localStorage.getItem('setCPSaved'));
                            if (
                                (window.location.pathname === RedirectTo('/content') ||
                                    window.location.pathname === RedirectTo('/content-panel-edit')) &&
                                cpObj?.draft
                            ) {
                                localStorage.removeItem('setCPSaved');
                                let draftElement = document.querySelector('.jstree ul li:last-child');

                                draftElement.classList.contains('jstree-closed') &&
                                    draftElement.querySelector('.jstree-icon.jstree-ocl').click();
                            } else {
                                let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
                                localStorage.removeItem('activeJstreePath');
                                for (let index = 0; index < activeJstreePath?.length; index++) {
                                    const m = activeJstreePath[index];
                                    let node = data?.instance?.get_node(Number(m));
                                    data?.instance?.open_node(node);
                                }
                            }
                        }, 2000);
                    } catch (ex) {
                        console.log('ex', ex);
                    }
                }
            });
        }
    }

    openArrowOnSingleClick(id) {
        const changeLocation = $(getInstanceChangeLocation());
        let element = null;
        if (changeLocation?.length > 0) {
            element = $(getInstanceChangeLocation())
                ?.find('#' + id)
                ?.find('.jstree-anchor');
        } else {
            element = $(getInstance())
                ?.find('#' + id)
                ?.find('.jstree-anchor');
        }
        if (element) {
            element.click();
            element.dblclick();
        }
    }

    componentDidUpdate() {
        const { treeData } = this.props;
        if (treeData) {
            let jstree = $(this.treeContainer);
            this.setInstance(this.treeContainer);
            const changeLocation = $(getInstanceChangeLocation());
            if (changeLocation.length > 0) {
                jstree = $(getInstanceChangeLocation());
            }
            jstree.jstree(true).settings = treeData;
            jstree.jstree(true).refresh();
            let selectedNode = JSON.parse(localStorage.getItem('selectedNode'));
            //localStorage.removeItem('selectedNode');
            setTimeout(() => {
                if (selectedNode?.state?.opened === false) jstree.jstree(true).open_node(selectedNode);
            }, 500);
            treeData?.core?.data && this.addIcons(treeData?.core?.data);
            this.disableCP();
        }
    }

    disableCP() {
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setTimeout(() => {
                const folderViewList = document.querySelectorAll('.slideSecClass [folder_view_id]');
                folderViewList?.forEach((folderView) => {
                    try {
                        const folderViewId = folderView.getAttribute('folder_view_id');
                        document
                            ?.getElementById(folderViewId)
                            ?.querySelector('.jstree-anchor')
                            ?.classList.add('jstree-disabled');
                    } catch (ex) {
                        console.log('ex', ex);
                    }
                });
            }, 1000);
        }
    }

    addIcons(data) {
        const changeLocation = $(getInstanceChangeLocation());
        if (changeLocation?.length > 0) {
            data.forEach((dt) => {
                if (dt?.child && dt?.children?.length === 0) {
                    const ele = changeLocation.find('#' + dt.id);
                    ele.removeClass('jstree-leaf');
                    ele.addClass('jstree-closed');
                }
            });
        } else {
            data.forEach((dt) => {
                if (dt?.child && dt?.children?.length === 0) {
                    let ele = document?.querySelector(`${getInstance()} #${CSS.escape(dt.id)}`);
                    ele?.classList?.remove('jstree-leaf');
                    ele?.classList?.add('jstree-closed');
                }
            });
        }
    }

    setInstance = (treeContainer) => {
        if (!$(treeContainer).hasClass('active')) {
            $(getInstance()).removeClass('active');
            $(treeContainer).addClass('active');
        }
    };

    render() {
        const containerClassName = this?.props?.treejsClass
            ? this.props.treejsClass + ' ' + this.props?.identifier
            : 'custom-card-height' + ' ' + this.props?.identifier;
        return (
            <div
                ref={(div) => (this.treeContainer = div)}
                className={containerClassName}
                onMouseEnter={() => {
                    this.setInstance(this.treeContainer);
                }}
            />
        );
    }
}

export default TreeView;
