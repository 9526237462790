// @flow
import React, { useEffect } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { COMMON_PATH, RedirectTo } from '../../../routes/role/path';
import { useNotification, contentSendNotification, contentReset } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { setRetainHistory } from '../../../helpers/retainHistory';

const SendNotificationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { notificationDispatch } = useNotificationContex();
    const { Toast } = useNotification();
    const dispatch = useDispatch();
    const { sentNotification } = useSelector((state) => ({
        sentNotification: state?.content?.sendNotification,
    }));

    const onSubmit = (isNotification = true) => {
        if (isNotification) {
            dispatch(contentSendNotification({ cpFolderViewId: props?.cpFolderViewId }));
        } else {
            if (props?.name === 'contentPanel') {
                Toast.success({
                    description: `${
                        isNotification ? 'Content Panel published; Notification sent.' : 'Content Panel published.'
                    }`,
                });
                setTimeout(() => {
                    setRetainHistory(true);
                    window.location.href = RedirectTo(COMMON_PATH.CONTENT_PANELS);
                }, 2000);
            } else if (props?.name === 'broadcast') {
                Toast.success({ description: 'Broadcast alert activated.' });
                setTimeout(() => {
                    props?.data?.redirectToLandingPage();
                }, 2000);
            }
        }
    };

    useEffect(() => {
        if (sentNotification) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
                payload: { noActive: true },
            });
            dispatch(contentReset());
            setTimeout(() => {
                setRetainHistory(true);
                window.location.href = RedirectTo(COMMON_PATH.CONTENT_PANELS);
            }, 500);
        }
    }, [sentNotification]);

    return (
        <>
            {props?.name === 'contentPanel' ? (
                <p>Would you like to send a notification to all users that this content panel has been created?</p>
            ) : (
                <p>Would you like to send a notification to all users that this alert has been broadcast?</p>
            )}

            <div className="modal-footer">
                <ButtonElement
                    name="No"
                    variant="outline-primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        });
                        setTimeout(() => {
                            onSubmit(false);
                        }, 500);
                    }}
                />
                <ButtonElement
                    name="Yes"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    onClick={() => {
                        if (props?.name === 'contentPanel') onSubmit(true);
                        else if (props?.name === 'broadcast') props?.data?.handleSendNotification();
                    }}
                />
            </div>
        </>
    );
};
export default SendNotificationModal;
