// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const OneEditorWarning = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p>{props?.data?.message}</p>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Notify me when available"
                    variant="primary"
                    type="button"
                    onClick={() => props?.data?.notifyMe()}
                />
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default OneEditorWarning;
