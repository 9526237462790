import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};
const DragnDrop = (props) => {
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const localItems = reorder(props.dragItems, result.source.index, result.destination.index);
        props.setToggle && props.setToggle(true);
        props?.setdragItems(localItems);
    };
    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={props?.className}>
                            <div className="">
                                {props?.dragItems &&
                                    props?.dragItems?.map((item, index) => (
                                        <Draggable
                                            key={item.id + '-' + index}
                                            draggableId={item?.name ? `${item.id}-${index}` : `${index}`}
                                            index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                    <ul>
                                                        {
                                                            <props.DragDropItem
                                                                index={item}
                                                                i={index}
                                                                value="list"
                                                                action={props?.action}
                                                            />
                                                        }
                                                    </ul>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                {provided.placeholder}
                                {props?.loading === false && props?.dragItems?.length === 0 && (
                                    <p className="text-center">
                                        <h5>No Matching Results.</h5>
                                    </p>
                                )}
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default DragnDrop;
