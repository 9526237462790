import {
    sitesModule,
    reportsModule,
    surveysModule,
    engagementModule,
    exportsModule,
    accountModule,
    reviewAndApprovalModule,
    usersModule,
    assetsModule,
    reportDataModule,
    privacyPolicyModule,
    deletedDataModule,
    systemLogModule,
} from '../module/generalModules';
import { portfolioModule } from '../module/portfolioModule';
import { contentPanelModule } from '../module/contentPanelModule';
import { systemSettingsModule } from '../module/systemSettingsModule';

// System Admin left sidebar
export const SystemAdminRoutes = [
    { ...usersModule },
    { ...reviewAndApprovalModule },
    { ...portfolioModule },
    { ...sitesModule },
    { ...reportsModule },
    { ...surveysModule },
    { ...assetsModule },
    { ...contentPanelModule },
    { ...reportDataModule },
    { ...systemSettingsModule },
    { ...engagementModule },
    { ...exportsModule },
    { ...deletedDataModule },
    { ...systemLogModule },
    { ...accountModule },
    { ...privacyPolicyModule },
];
