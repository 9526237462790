// @flow
import React from 'react';
import { Row, Col } from 'react-bootstrap';

// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { useNotificationContex } from '../../../contexts/NotificationContext';

const PortfolioRevealPasscodeModal = (props) => {
    const { notificationDispatch } = useNotificationContex();
    const { modalDispatch } = useModalContext();

    const handleCopyUrl = (text, msg) => {
        navigator.clipboard.writeText(text);
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: {
                modalAction: notificationDispatch({
                    type: notificationActions.OPEN_NOTIFICATION,
                    payload: {
                        role: 'success',
                        title: 'Success',
                        iconClass: 'mdi mdi-check-circle h2',
                        className: 'icon',
                        message: msg,
                    },
                }),
            },
        });
    };

    return (
        <div>
            <Row>
                <b className="d-block mb-1">Passcode:</b>
                <Col lg={8}>
                    <FormInput
                        type="text"
                        id="urlEnabled"
                        className="urlEnabled"
                        floatinglabel="false"
                        readOnly
                        value={props?.data}
                    />
                </Col>
                <Col lg={4} className="px-1">
                    <ButtonElement
                        name="Copy"
                        variant="outline-primary"
                        type="button"
                        className="mb-3"
                        onClick={() =>
                            props?.data
                                ? handleCopyUrl(props?.data, 'Passcode copied.')
                                : modalDispatch({
                                      type: modalActions.CLOSE_MODAL,
                                  })
                        }
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </div>
    );
};

export default PortfolioRevealPasscodeModal;
