// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import {
    userBulkHardDelete,
    userSoftDelete,
    bulkSoftDelete,
    bulkHardDelete,
    siteBulkDeleteMultiple,
    bulkReportDelete,
    userBulkSoftDelete,
    bulkReportSoftDelete,
    siteBulkSoftDelete,
    bulkSoftDeleteSites,
} from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';

const BulkSoftDeleteSite = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    return (
        <>
            <p>Are you sure you want to delete the selected Sites?</p>

            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        const isSelectAll = props.data.isSelectAll;
                        dispatch(
                            bulkSoftDeleteSites({
                                pkSiteIdList: props?.data.isSelectAll
                                    ? []
                                    : props.data.selectedUserArr.map((item) => item?.id || item?.orgId),
                                isSelectAll: props?.data.isSelectAll,
                                isAnd: true,
                                filterCriteria: props?.data?.filterCriteria,
                                idsToSkip: props?.data?.idsToSkip,
                            })
                        );
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BulkSoftDeleteSite;
