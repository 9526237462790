// @flow
import React, { useEffect } from 'react';
// components
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { surveyExportDownload, tenantImportReset } from '@ovrture/react-redux';
import { downloadFileUrl } from '../../../helpers/downloadFile/downloadFile';
import { useModalContext } from '../../../contexts/ModalContext';
import ButtonElement from '../../../components/button/ButtonElement';

const DataSchemaUploader = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const { exported, pdfResponse } = useSelector((state) => ({
        exported: state?.surveyExport?.download,
        pdfResponse: state?.socket?.pdfResponse,
    }));

    useEffect(() => {
        if (exported && pdfResponse) {
            downloadFileUrl(pdfResponse);
            dispatch(tenantImportReset());
        }
    }, [exported, pdfResponse]);

    return (
        <div className="DataSchemaUploader">
            {props?.showUpload && (
                <ButtonElement
                    name="Manage Data Mapping"
                    variant="outline-primary"
                    type="button"
                    className="action-btn enterKeyFormSubmitDisabled mt-0"
                    tooltip="Import"
                    onClick={() => {
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) =>
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    }),
                                modalVariant: 'primary',
                                modalDataKey: ['systemAdmin', 'importDataSchema'],
                                data: {
                                    title: 'Manage Data Mapping',
                                    subtitle: props?.module?.name + ' Uploader',
                                    module: props?.module,
                                    importSuccess: props?.importSuccess,
                                },
                            },
                        });
                    }}
                />
            )}
            {props?.showExport && (
                <ButtonElement
                    name="Export Schema"
                    variant="outline-primary"
                    type="button"
                    className="action-btn enterKeyFormSubmitDisabled mt-0"
                    tooltip="Export"
                    onClick={() => {
                        dispatch(
                            surveyExportDownload({
                                dataScopeId: props?.module?.tab?.DATASCOPEID,
                            })
                        );
                    }}
                />
            )}
        </div>
    );
};

export default DataSchemaUploader;
