//@flow
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
//components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { bulkReportFundDetailList } from '@ovrture/react-redux';
import config from '../../../config';
import { FormInput } from '../../../components/form/input';
import { CustomLoader } from '../../../helpers/customLoader';

const SelectFundReportWithReportUpdateOnModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [fundDetailListState, setFundDetailListState] = useState([]);
    const [numberOfFunds, setNumberOfFunds] = useState(null);
    const [resetSelection, setResetSelection] = useState([]);
    const [pageSize, setPageSize] = useState(config.PAGE_SIZE_MAX);
    const [sortObj, setSortObj] = useState([{ sort: ['colRecordFullName,ASC'] }]);
    const [searchValue, setSearchValue] = useState('');
    const [allHistoryData, setAllHistoryData] = useState([]);

    let { fundDetailListData, loading } = useSelector((state) => ({
        fundDetailListData: state?.records?.fundDetailList,
        loading: state?.records?.loading,
    }));

    useEffect(() => {
        dispatch(
            bulkReportFundDetailList({
                reportYear: props?.data?.reportYear,
                isAnd: true,
                page: 0,
                size: pageSize,
                sortObj: sortObj,
                recordIdList: props?.data?.pkRecordsIdArray,
            })
        );
    }, []);

    useEffect(() => {
        getFundDetailList();
    }, [fundDetailListData]);

    const getFundDetailList = () => {
        if (fundDetailListData?.data?.content?.length > 0) {
            let funds = 0;
            let history = [];
            fundDetailListData.data.content.forEach((data) => {
                if (funds < data?.linkedFundList?.length) funds = data?.linkedFundList?.length;
                history.push(data);
            });
            setNumberOfFunds(funds);
            setAllHistoryData(history);
            setFundDetailListState(fundDetailListData.data.content);
            highlightTheRow();
        } else {
            setNumberOfFunds(null);
            setAllHistoryData([]);
            setFundDetailListState([]);
        }
    };

    const getSearchValue = (value) => {
        setSearchValue(value);
        getFundDetailList();
    };

    const FundColumn = (row, flag) => {
        const status =
            row?.original?.linkedFundList[flag]?.fundPerformanceExists &&
            row?.original?.linkedFundList[flag]?.fundImpactExists &&
            row?.original?.linkedFundList[flag]?.fundDonorsExists
                ? 'green'
                : !row?.original?.linkedFundList[flag]?.fundPerformanceExists &&
                  !row?.original?.linkedFundList[flag]?.fundImpactExists &&
                  !row?.original?.linkedFundList[flag]?.fundDonorsExists
                ? 'red'
                : 'yellow';
        return (
            <Row>
                {row?.original?.linkedFundList[flag]?.colFundName ? (
                    <>
                        <Col md={1} className="custom-checkbox">
                            <FormInput
                                type="checkbox"
                                id={'FundColumn_Record_' + row?.original?.pkRecordsId + '_' + flag}
                                defaultChecked={
                                    allHistoryData.find((element) => element.pkRecordsId === row?.original?.pkRecordsId)
                                        .linkedFundList[flag].fundChecked
                                }
                                onChange={(e) => {
                                    if (!e?.target?.checked) {
                                        row.original.allFundChecked = e.target.checked;
                                        allHistoryData.find(
                                            (element) => element.pkRecordsId === row?.original?.pkRecordsId
                                        ).allFundChecked = e.target.checked;
                                        document.getElementById(
                                            'AllFundColumn_Record_' + row?.original?.pkRecordsId
                                        ).checked = false;
                                    }
                                    row.original.linkedFundList[flag].fundChecked = e.target.checked;
                                    allHistoryData.find(
                                        (element) => element.pkRecordsId === row?.original?.pkRecordsId
                                    ).linkedFundList[flag].fundChecked = e.target.checked;
                                    uncheckAllSelect();
                                }}
                                className={'mb-0 border-secondary'}
                            />
                        </Col>
                        <Col md={11} className="custom-text">
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: highlightTheText(
                                        textTruncated(row?.original?.linkedFundList[flag]?.colFundName)
                                    ),
                                }}
                                title={row?.original?.linkedFundList[flag]?.colFundName}></span>

                            <p className="mb-0 small-text">
                                {status === 'green' && <i className="fa-solid fa-circle-dot update green"></i>}
                                {status === 'yellow' && <i className="fa-solid fa-circle-dot update yellow"></i>}
                                {status === 'red' && <i className="fa-solid fa-circle-dot update red"></i>}
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: highlightTheText(
                                            textTruncated(row?.original?.linkedFundList[flag]?.colFundReferenceNo)
                                        ),
                                    }}
                                    title={row?.original?.linkedFundList[flag]?.colFundReferenceNo}></span>
                            </p>
                        </Col>
                    </>
                ) : (
                    <spam className="text-center">_</spam>
                )}
            </Row>
        );
    };

    const AllFundColumn = ({ row }) => {
        return (
            <div>
                {row?.original?.linkedFundList?.length > 0 ? (
                    <FormInput
                        type="checkbox"
                        id={'AllFundColumn_Record_' + row?.original?.pkRecordsId}
                        className={'mb-0 border-secondary ml-c-4'}
                        defaultChecked={
                            allHistoryData.find((element) => element.pkRecordsId === row?.original?.pkRecordsId)
                                .allFundChecked
                        }
                        onChange={(e) => {
                            row.original.allFundChecked = e.target.checked;
                            allHistoryData.find(
                                (element) => element.pkRecordsId === row?.original?.pkRecordsId
                            ).allFundChecked = e.target.checked;

                            row?.original?.linkedFundList?.forEach((element, index) => {
                                element.fundChecked = e.target.checked;

                                allHistoryData.find(
                                    (element) => element.pkRecordsId === row?.original?.pkRecordsId
                                ).linkedFundList[index].fundChecked = e.target.checked;

                                let elementCheckbox = document.getElementById(
                                    'FundColumn_Record_' + row?.original?.pkRecordsId + '_' + index
                                );
                                elementCheckbox.checked = e.target.checked;
                            });
                            uncheckAllSelect();
                        }}
                    />
                ) : (
                    <spam className="text-center">_</spam>
                )}
            </div>
        );
    };

    const RecordColumn = ({ row }) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: highlightTheText(textTruncated(row?.original?.colRecordReferenceNo)),
                }}
                title={row?.original?.colRecordReferenceNo}></div>
        );
    };

    const RecordNameColumn = ({ row }) => {
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: highlightTheText(textTruncated(row?.original?.colRecordFullName)),
                }}
                title={row?.original?.colRecordFullName}></div>
        );
    };

    const textTruncated = (data) => {
        return data?.length > 18 ? data?.substring(0, 18) + '...' : data;
    };

    const highlightTheText = (str) => {
        let searchText = document.querySelector('.selectFundReportWithReportUpdateOn [aria-label="Search"]').value;
        return searchText && str?.includes(searchText)
            ? str.split(searchText).join('<mark class="highlight">' + searchText + '</mark>')
            : str;
    };

    const highlightTheRow = () => {
        const searchText = document?.querySelector('.selectFundReportWithReportUpdateOn [aria-label="Search"]')?.value;
        const rows = document?.querySelectorAll('.selectFundReportWithReportUpdateOnTable tr');
        rows?.forEach((row) => {
            const str = row?.innerText;
            if (searchText && str?.includes(searchText)) {
                row.classList.add('isRowHighlight');
            } else {
                row.classList.remove('isRowHighlight');
            }
        });
    };

    const eventHaveCheckbox = (e) => {
        const updatedFundDetailListState = fundDetailListState.map((item) => {
            return {
                ...item,
                allFundChecked: e.target.checked,
                linkedFundList: item.linkedFundList.map((fund) => {
                    return {
                        ...fund,
                        fundChecked: e.target.checked,
                    };
                }),
            };
        });
        setFundDetailListState(updatedFundDetailListState);
        const updatedAllHistoryData = allHistoryData.map((item) => {
            return {
                ...item,
                allFundChecked: e.target.checked,
                linkedFundList: item.linkedFundList.map((fund) => {
                    return {
                        ...fund,
                        fundChecked: e.target.checked,
                    };
                }),
            };
        });
        setAllHistoryData(updatedAllHistoryData);
    };

    const uncheckAllSelect = () => {
        const allfundUncheck = allHistoryData?.find((f) => f.allFundChecked === false);
        if (allfundUncheck) {
            document.getElementById('tableSelectAll').checked = false;
        }
    };

    return (
        <>
            <CustomLoader loader={loading} />
            <Card>
                <Table
                    tableId="selectFundReport"
                    columns={TableColumn.systemAdmin.selectFundWithReportUpdate([
                        AllFundColumn,
                        FundColumn,
                        numberOfFunds,
                        RecordColumn,
                        RecordNameColumn,
                        eventHaveCheckbox,
                    ])}
                    data={fundDetailListState}
                    pageSizes={fundDetailListData?.data?.size}
                    pageNumber={fundDetailListData?.data?.number}
                    totalElements={fundDetailListData?.data?.totalElements}
                    totalPages={fundDetailListData?.data?.totalPages}
                    sizePerPageList={TableColumn.sizePerPageList(fundDetailListState)}
                    isSortable={true}
                    pagination={false}
                    isFilter={false}
                    isSearchable={true}
                    isSelectable={false}
                    searchValue={searchValue}
                    getSearchValue={getSearchValue}
                    customPaginationProps={{
                        recordIdList: props?.data?.pkRecordsIdArray,
                        searchText: searchValue,
                    }}
                    loading={loading}
                    theadClass={TableColumn.table_light}
                    resetSelection={resetSelection}
                    setResetSelection={setResetSelection}
                    tableClass="selectFundReport selectFundReportWithReportUpdateOnTable"
                    tableParentClass="table-snapshot table-resize"
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                        const mergedList = [...fundDetailListState, ...props?.data?.reportUpdateOFFArray];
                        props?.data?.FinalBuildWithReportUpdateOn(mergedList);
                    }}
                />
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        setNumberOfFunds(null);
                        setAllHistoryData([]);
                        setFundDetailListState([]);
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default SelectFundReportWithReportUpdateOnModal;
