// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
    fundPrintBridgeExportReport,
    userList,
    reportDataExportReset,
    reportFundtDataYearList,
} from '@ovrture/react-redux';
import { getFiscalYearStore } from '../../../helpers/dataFiltering';
import { useForm } from 'react-hook-form';

const ExportFyReportsPrintBridgeModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [isForPrintFundsAsSinglePDF, setIsForPrintFundsAsSinglePDF] = useState(1);
    const [reportPreferenceId, setReportPreferenceId] = useState(1);
    const [fkUserIdList, setFkUserIdList] = useState('');
    const [isForNarrativePDFDocument, setisForNarrativePDFDocument] = useState(false);
    const [isForMostRecentUpdates, setisForMostRecentUpdates] = useState(2);
    const [isForImpactPDFDocument, setisForImpactPDFDocument] = useState(false);
    const [toggle1, setToggle1] = useState(true);
    const [organized, setOrganized] = useState('One continuous listing (i.e. one folder)');
    const [colTags, setcolTags] = useState([]);
    const [tagInput, settagInput] = useState('');

    const dispatchAction = useDispatch();
    const [reportYear, setReportYear] = useState(null);

    const { recordUserActivity, users, yearList, recordUserActivityError } = useSelector((state) => ({
        recordUserActivity: state?.reportDataExport?.fundPrintBridgeExport,
        recordUserActivityError: state?.reportDataExport?.error,
        users: state?.user?.userList,
        yearList: state?.reportDataFund?.yearList,
    }));

    const {
        setValue,
        register,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        setValue('fiscalYear', getFiscalYearStore());
    }, [yearList]);

    const organizedList = ['One continuous listing (i.e. one folder)', 'By record reference number'];

    useEffect(() => {
        dispatchAction(
            userList({
                isAnd: true,
                isUnpaged: true,
                sort: 'colLastName,ASC',
            })
        );
        dispatchAction(reportFundtDataYearList());
        setReportYear(getFiscalYearStore());
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (recordUserActivity || recordUserActivityError) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportDataExportReset());
        }
        //eslint-disable-next-line
    }, [recordUserActivity, recordUserActivityError]);

    const downloadFyReportPrintBridge = () => {
        const payload = {
            isForOrganizeByReferenceNumber: organized !== 'One continuous listing (i.e. one folder)' ? true : false,
            reportYear: reportYear || yearList[0],
            isForPrintFundsAsSinglePDF: isForPrintFundsAsSinglePDF === 1 ? true : false,
            reportPreferenceId: reportPreferenceId,
            forNarrativePDFDocument: isForNarrativePDFDocument,
            forImpactPDFDocument: isForImpactPDFDocument,
            forMostRecentUpdates: isForMostRecentUpdates === 1 ? true : false,
        };
        // Tags
        if (colTags?.length > 0) {
            payload.colTags = colTags.join(',');
        }
        fkUserIdList && (payload.fkUserIdList = fkUserIdList);

        dispatchAction(fundPrintBridgeExportReport(payload));
    };

    const handleTagInput = (e) => {
        if (e.key === 'Enter' || e.keyCode === 9) {
            e.preventDefault();
            if (e.target.value.length > 0) tagValidation(e);
        }
    };

    const tagValidation = (e) => {
        if (e.target.value.length > 0) {
            const arr = [...colTags];
            arr.push(e.target.value.replace('\n', ''));
            setcolTags(arr);
            settagInput('');
        }
    };

    const removeTag = (d, i) => {
        const arr = [...colTags];
        arr.splice(i, 1);
        setcolTags(arr);
    };

    return (
        <>
            <form>
                <div className="custom-padding export-report-modal filter-tag">
                    <p>Generate a zipped gallery of all PDF/printable fund reports for a specific fiscal year.</p>
                    <Row className="mt-2">
                        <Col md={6}>
                            <FormInput
                                type="select"
                                className="custom-select"
                                onChange={(e) => setFkUserIdList(e.target.value)}
                                label="User(s)">
                                <option>All Users</option>
                                {users?.content.map((usr) => {
                                    return (
                                        <option key={usr.id} value={usr.id} data-email-id={usr.email}>
                                            {usr.colFullName}
                                        </option>
                                    );
                                })}
                            </FormInput>
                        </Col>
                        <Col md={6}>
                            <FormInput
                                id="fiscalYear"
                                register={register}
                                type="select"
                                className="custom-select"
                                onChange={(e) => setReportYear(e.target.value)}
                                label="Fiscal Year">
                                {yearList &&
                                    yearList.map((y) => {
                                        return (
                                            <option key={y} value={y}>
                                                {y}
                                            </option>
                                        );
                                    })}
                            </FormInput>
                        </Col>
                    </Row>
                    <Card className="mb-2 mt-3">
                        <Card.Body>
                            <Row className="mt-0">
                                <Col md={6}>
                                    <b className="mt-0">PDF Options:</b>

                                    <FormInput
                                        type="radio"
                                        id="printAllSinglePdf"
                                        name="printType"
                                        className="mt-1"
                                        value={1}
                                        label="Print all funds as a single PDF."
                                        onChange={(e) => setIsForPrintFundsAsSinglePDF(e.target.value)}
                                        checked={isForPrintFundsAsSinglePDF === 1 || isForPrintFundsAsSinglePDF === '1'}
                                    />

                                    <FormInput
                                        type="radio"
                                        id="printAllSeparatePdf"
                                        name="printType"
                                        value={2}
                                        label="Print all funds as separate PDFs."
                                        className="mt-1"
                                        onChange={(e) => setIsForPrintFundsAsSinglePDF(e.target.value)}
                                        checked={isForPrintFundsAsSinglePDF === 2 || isForPrintFundsAsSinglePDF === '2'}
                                    />

                                    <b className="mt-2 d-block">Report Preference:</b>
                                    <FormInput
                                        type="radio"
                                        id="digital"
                                        name="reportPreferences"
                                        label="Digital"
                                        value={1}
                                        className="pe-5 mt-1"
                                        onChange={(e) => setReportPreferenceId(e.target.value)}
                                        checked={reportPreferenceId === 1 || reportPreferenceId === '1'}
                                    />

                                    <FormInput
                                        type="radio"
                                        id="print"
                                        name="reportPreferences"
                                        label="Print"
                                        value={2}
                                        className="pe-5 mt-1"
                                        onChange={(e) => setReportPreferenceId(e.target.value)}
                                        checked={reportPreferenceId === 2 || reportPreferenceId === '2'}
                                    />
                                    <FormInput
                                        type="radio"
                                        id="digitalAndPrint"
                                        name="reportPreferences"
                                        label="Both"
                                        value={3}
                                        className="pe-5 mt-1"
                                        onChange={(e) => setReportPreferenceId(e.target.value)}
                                        checked={reportPreferenceId === 3 || reportPreferenceId === '3'}
                                    />
                                </Col>
                                <Col md={6} className="mt-0">
                                    <div className="mt-0 mb-1 alert alert-info">
                                        <FormInput
                                            type="checkbox"
                                            id="printSupplementDocument"
                                            className="mt-0"
                                            label="Include supplemental documents."
                                            onChange={(e) => setisForImpactPDFDocument(e.target.checked)}
                                        />
                                        <FormInput
                                            type="checkbox"
                                            id="printNarrative"
                                            className="mt-1"
                                            label="Include narrative report content."
                                            onChange={(e) => setisForNarrativePDFDocument(e.target.checked)}
                                        />
                                        <FormInput
                                            type="radio"
                                            id="mostRecentUpdate"
                                            name="reportUpdates"
                                            value={1}
                                            label="Most Recent Update"
                                            className="mt-1 ms-2"
                                            onChange={(e) => setisForMostRecentUpdates(e.target.value)}
                                            checked={isForMostRecentUpdates === 1 || isForMostRecentUpdates === '1'}
                                            disabled={!isForNarrativePDFDocument}
                                        />
                                        <FormInput
                                            type="radio"
                                            id="allUpdates"
                                            value={2}
                                            name="reportUpdates"
                                            label="All Updates"
                                            className="ms-2 mt-1"
                                            onChange={(e) => setisForMostRecentUpdates(e.target.value)}
                                            checked={isForMostRecentUpdates === 2 || isForMostRecentUpdates === '2'}
                                            disabled={!isForNarrativePDFDocument}
                                        />
                                    </div>
                                    {process.env.REACT_APP_EXPORT_TAG_SHOW === 'true' && <>
                                    <b className="mb-1 d-block">Tags</b>
                                    <div className="custom-filter-style-block">
                                        <span className="inner-wrapper">
                                            {colTags?.length > 0 &&
                                                colTags?.map((d, i) => {
                                                    return (
                                                        <span className="list">
                                                            <span className="value" key={i}>
                                                                {d}
                                                            </span>
                                                            <span
                                                                className="cross-icon"
                                                                onClick={() => removeTag(d, i)}>
                                                                <svg
                                                                    height="17"
                                                                    width="17"
                                                                    viewBox="0 0 20 20"
                                                                    aria-hidden="true"
                                                                    focusable="false"
                                                                    className="css-tj5bde-Svg">
                                                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    );
                                                })}
                                        </span>

                                        <FormInput
                                            type="text"
                                            id="colTags"
                                            label="enter tag.."
                                            value={tagInput}
                                            className="custom-input prevent-form-enter"
                                            floatinglabel="false"
                                            onChange={(e) => settagInput(e.target.value)}
                                            onKeyDown={(e) => handleTagInput(e)}
                                            onBlur={(e) => tagValidation(e)}
                                        />
                                    </div></>}
                                </Col>
                                <Col md={12}>
                                    <FormInput
                                        type="select"
                                        className="mt-3"
                                        onChange={(e) => setOrganized(e.target.value)}
                                        label="How would you like the gallery of PDF's organized?">
                                        {organizedList &&
                                            organizedList.map((y) => {
                                                return (
                                                    <option key={y} value={y}>
                                                        {y}
                                                    </option>
                                                );
                                            })}
                                    </FormInput>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
                <div className="d-flex justify-content-center modal-footer">
                    <ButtonElement
                        name="Generate"
                        variant="primary"
                        type="button"
                        onClick={() => {
                            if (toggle1) {
                                setToggle1(false);
                                downloadFyReportPrintBridge();
                            }
                        }}
                    />

                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            </form>
        </>
    );
};

export default ExportFyReportsPrintBridgeModal;
