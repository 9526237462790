import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ApmRoute } from '@elastic/apm-rum-react';
import { useDispatch, useSelector } from 'react-redux';
import { userActivities } from '@ovrture/react-redux';
import { clearBrowserCache } from '../helpers/browserCache';
import HandleCache from '../helpers/handleCache/HandleCache';
import { RemoveTenantStyleSheet } from '../helpers/editor/script/ScriptHandler';
import { removeBodyTag } from '../helpers/editor/migration/TenantMigration';
import { useModalContext } from '../contexts/ModalContext';
import { modalActions } from '../reducers/actions';

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => ({
        user: state?.auth?.user,
    }));
    const editors = ['/content-panel-edit', '/content-broadcast-create', '/site-edit', '/report-edit'];

    useEffect(() => {
        window.scrollTo(0, 0);
        clearBrowserCache();

        setTimeout(() => {
            if (!editors.find((f) => window.location.pathname.includes(f))) {
                dispatch(
                    userActivities({
                        colStatusCode: 200,
                        colRequestCompleteUrl: window.location.href,
                        colHttpVariables: null,
                    })
                );
                RemoveTenantStyleSheet();
                addTitle();
                removeBodyTag();
                modalDispatch({
                    type: modalActions.RESET_MODAL,
                });
            }
        }, 1000);
    }, []);

    const addTitle = () => {
        document.title = 'OVRTURE';
        var link = document.querySelector("link[rel~='icon']");
        if (!link) {
            link = document.createElement('link');
            link.rel = 'icon';
        }
        link.href = process.env.PUBLIC_URL + '/favicon_ovrture.png';
        document.getElementsByTagName('head')[0].appendChild(link);
    };

    return (
        <HandleCache>
            <ApmRoute
                {...rest}
                render={(props) => {
                    if (!user) {
                        return <Redirect to={{ pathname: '/public/login', state: { from: props.location } }} />;
                    }
                    return <Component {...props} />;
                }}
            />
        </HandleCache>
    );
};

export default PrivateRoute;
