// @flow
import React, { useEffect, useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector } from 'react-redux';

const PublishContentPanelModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const { created, updated, updatePublish } = useSelector((state) => ({
        created: state?.content?.create,
        updated: state?.content?.update,
        updatePublish: state?.content?.updatePublish,
    }));

    useEffect(() => {
        if (created || updated || updatePublish) {
            if (created?.data?.cpFolderViewId || updatePublish?.data?.cpFolderViewId || updated?.data?.cpFolderViewId) {
                props?.setCpFolderViewId &&
                    props.setCpFolderViewId(
                        created?.data?.cpFolderViewId ||
                            updatePublish?.data?.cpFolderViewId ||
                            updated?.data?.cpFolderViewId
                    );
            }
            props?.data?.publishCP(false);
            setTimeout(() => {
                props.setActive(props.active + 1);
            }, 500);
        }
    }, [created, updated, updatePublish]);

    return (
        <>
            <p>Are you sure you want to publish this edited content panel?</p>
            {(props?.data?.panelType === true || props?.data?.panelType === 'true') && (
                <div className="note-section-cmp">
                    <strong className="text-warning">Note: </strong>
                    <span className="message">
                        As a Inherited Content Panel all saved changes will automatically affect all sites/reports.
                    </span>
                </div>
            )}
            <div className="modal-footer">
                {props.active !== 0 && (
                    <ButtonElement
                        name="Previous"
                        variant="outline-primary"
                        className="btn-prev"
                        type="button"
                        onClick={() => props.setActive(props.active - 1)}
                    />
                )}
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    className="btn-skip skippable"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        })
                    }
                />
                <ButtonElement
                    name="Publish"
                    variant="primary"
                    className="btn-next"
                    type="button"
                    disabled={toggle}
                    onClick={() => {
                        setToggle(true);
                        props?.data?.publishCP(true, props?.data?.selectedNode);
                    }}
                />
            </div>
        </>
    );
};
export default PublishContentPanelModal;
