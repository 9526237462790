// @flow
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
//component
import Logo from '../../assets/images/login-logo.svg';
import OvrtureLogo from '../../assets/images/logo/favicon_ovrture.svg';
import ButtonElement from '../../components/button/ButtonElement';
import { checkOrgIdForPngLogo } from '../../helpers/checkers';
import { fetchS3LoginLogoUrl } from '../../helpers/s3Mapping';

const AccountLayout = ({ bottomLinks, children, userLogin }) => {
    const { tenantDetails } = useSelector((state) => ({
        tenantDetails: state?.platformKey?.tenantDetails,
    }));

    //For login page design layout change Start
    const [orgId, setOrgId] = useState('0');
    const logoS3Url = fetchS3LoginLogoUrl();

    useEffect(() => {
        if (userLogin == 'true') {
            setOrgId('0');
        } else {
            setOrgId(tenantDetails?.data?.id);
        }
    }, [tenantDetails]);

    useEffect(() => {
        if (orgId && document.body && Number(orgId) !== 0) {
            document.body.classList.add(`theme_${orgId}`);
            document.body.classList.add('authentication-bg');
            document.body.classList.add('common-login-wrapper');
        } else {
            document.body.classList.add('authentication-bg');
        }

        return () => {
            if (orgId && document.body && Number(orgId) !== 0) {
                document.body.classList.remove(`theme_${orgId}`);
                document.body.classList.remove('common-login-wrapper');
                document.body.classList.add('authentication-bg');
            } else {
                document.body.classList.add('authentication-bg');
            }
        };
    }, [orgId]);

    //End

    return (
        <>
            {/* <div className={orgId === '0' ? "account-pages pt-2 pt-sm-5 pb-4 pb-sm-5" : ""}> */}
            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <Container>
                    <div class="text-center visitLinkWrapper">
                        {Number(orgId) === 50 ? (
                            <h4 class="visitLinkText">
                                Looking for our UD Giving website? Visit the Alumni & Friends website{' '}
                                <a href="https://www.udel.edu/alumni-friends/" target="_blank" class="visitLink">
                                    here
                                </a>
                                .
                            </h4>
                        ) : orgId && Number(orgId) !== 0 && Number(orgId) !== 18 ? (
                            <h4 class="visitLinkText">FOR INTERNAL USE ONLY</h4>
                        ) : (
                            ''
                        )}
                    </div>
                    <Row className="justify-content-center login-container">
                        <Col md={8} lg={6} xl={5} xxl={4}>
                            <Card className="login-content">
                                <Card.Header className="pt-4 pb-4 text-center bg-primary image-container">
                                    <div>
                                        <span>
                                            <img
                                                src={
                                                    Number(orgId) === 0
                                                        ? Logo
                                                        : checkOrgIdForPngLogo.includes(orgId)
                                                        ? logoS3Url + `${orgId}` + '.png'
                                                        : logoS3Url + `${orgId}` + '.svg'
                                                }
                                                alt=""
                                                width="218"
                                                className="tenant-logo"
                                            />
                                        </span>
                                    </div>
                                </Card.Header>
                                {userLogin && (
                                    <div className="text-center m-auto h-75 w-100 p-1 text-black bg-warning">
                                        <h4 className="login-text">System Redirect</h4>
                                    </div>
                                )}
                                <Card.Body className="p-xl-4 login-body">{children}</Card.Body>
                            </Card>
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer footer-alt text-primary">
                <div>
                    <ButtonElement
                        type="button"
                        name="Privacy & Security"
                        as="href"
                        href="https://ovrture.com/application-privacy-policy/"
                        className="link-primary btn-link shadow-none mx-1 p-0 privacy-security"
                        target="_blank"
                    />
                    |
                    <ButtonElement
                        type="button"
                        name="Ovrture Status"
                        className="link-primary shadow-none mx-1 p-0"
                        target="_blank"
                        as="href"
                        href="https://status.ovrture.com"
                    />
                </div>
                <div className="ovrture-back-link" onClick={() => window.open('https://ovrture.com/', '_blank')}>
                    <a>
                        <img src={OvrtureLogo}></img>
                        <p>ovrture.com</p>
                    </a>
                </div>
            </footer>
        </>
    );
};

export default AccountLayout;
