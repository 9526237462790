// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { FormInput } from '../../../components/form/input';

const BuildReportWithReportUpdateOnModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState('All');
    const [disabled, setDisabled] = useState(false);

    return (
        <>
            <p>
                <b>
                    {props?.data?.xData} out of {props?.data?.yData}
                </b>{' '}
                selected records are “report update” enabled, requiring you to select which funds to include in this
                build. How would you like to proceed?
            </p>
            <FormInput
                type="radio"
                id="FundsCheck"
                label="Include all funds in all reports."
                className="mt-2 ms-2"
                defaultValue="All"
                defaultChecked={true}
                onChange={(e) => {
                    setToggle(e.target.value);
                }}
            />
            <FormInput
                type="radio"
                id="FundsCheck"
                label="Select which funds to include, per record."
                className="mt-2 ms-2"
                defaultValue="Select"
                onChange={(e) => {
                    setToggle(e.target.value);
                }}
            />
            <FormInput
                type="radio"
                id="FundsCheck"
                label="Do not include funds in any of these reports."
                className="mt-2 ms-2"
                defaultValue="No"
                onChange={(e) => {
                    setToggle(e.target.value);
                }}
            />
            <div className="modal-footer  d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="button"
                    disabled={disabled}
                    onClick={() => {
                        setDisabled(true);
                        props?.data?.BuildWithReportUpdateOn(toggle);
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BuildReportWithReportUpdateOnModal;
