// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useForm } from 'react-hook-form';
import { COMMON_PATH, RedirectTo } from '../../../routes/role/path';
import { createContentPanel } from '../../../components/form/validation';
import { setCPEdit, setContentPanelRendared } from '../../../helpers/retainHistory';
import { TYPE_NAME } from './CreateNewContentPanelModal';

const CreateNewEditableContentPanelModal = (props) => {
    const [contentData, setContentData] = useState();
    const [draftId, setDraftId] = useState();
    const [name, setName] = useState('');
    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: createContentPanel, mode: 'onBlur' });

    useEffect(() => {
        if (props?.data?.cp?.state?.contentData?.id) {
            setContentData(props?.data?.cp?.state?.contentData);
            setDraftId(props?.data?.cp?.state?.draftId);
        }
    }, []);

    useEffect(() => {
        if (props?.data?.cp?.state?.impactTypeList?.length > 0) {
            setValue('colImpactType', props?.data?.cp?.state?.impactTypeList[0]?.pkImpactTypeId?.toString());
        }
    }, [props?.data?.cp?.state?.impactTypeList]);

    const onSubmit = (data) => {
        document.querySelectorAll('.custom-card-height')[0].classList.remove('active');
        if (props?.data?.cp?.obj?.colName === TYPE_NAME.IMPACT_SURVEYOR.NAME) {
            props.data.cp.obj = {
                ...props.data.cp.obj,
                ...data,
                activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
                handleSelectTemplate: handleSelectTemplate,
                template: null,
            };
            props.setActive(props.active + 1);
        } else {
            props.data.cp = {
                handleSelectTemplate,
                obj: {
                    ...data,
                    ...props?.data?.cp?.obj,
                    activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
                },
            };
            props.setActive(props.active + 1);
        }
    };

    const handleSelectTemplate = (el, data) => {
        localStorage.setItem('activeJstreePath', JSON.stringify(data?.obj?.activeJstreePath));
        let selectedTemplate = el;
        const formData = {
            surveyDataSchemaIdList: data?.surveyDataSchemaIdList,
            impactTypeList: props?.data?.cp?.state?.impactTypeList,
            fkContentPanelTypesId: data?.obj?.pkContentPanelTypesId,
            obj: data?.obj,
            cpName: data?.obj?.newCpName,
            cpId: selectedTemplate?.id,
            cpRootNodeId: selectedTemplate?.fkCpRootNodeId,
            cpParentId: selectedTemplate?.parent_id || selectedTemplate?.parentId,
            previousCpParentId: contentData?.parent_id,
            previousCpRootNodeId: contentData?.fkCpRootNodeId,
            parent_id: contentData?.id,
            draftId: draftId,
            colIsCentrallyManagedCp: props?.data?.cp?.obj?.colName === TYPE_NAME.INHERITED.NAME ? true : false,
            activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
        };
        if (props?.data?.cp?.obj?.colName === TYPE_NAME.IMPACT_SURVEYOR.NAME) {
            setCPEdit(formData);
            setContentPanelRendared(false);
            window.location.href = RedirectTo(COMMON_PATH.EDIT_PANEL + '?new');
        } else {
            setCPEdit(formData);
            setContentPanelRendared(false);
            window.location.href = RedirectTo(COMMON_PATH.EDIT_PANEL + '?new');
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)} className="createNewEditableContentPanelModal">
                <Row>
                    <Col md={2} className="icon">
                        <i className={props?.data?.cp?.obj?.colIcon}></i>
                    </Col>
                    <Col md={10} className="paragraph-center">
                        <p>
                            <strong className="text-orange">Note: </strong>
                            {props?.data?.cp?.obj?.paragraph}
                        </p>
                    </Col>
                    <Col lg={12} className="mt-1">
                        <h5>Content Panel Name:</h5>
                        <FormInput
                            type="text"
                            id="newCpName"
                            maxLength="30"
                            errors={errors}
                            register={register}
                            label="Content Panel Name *"
                            onChange={(e) => {
                                setName(e?.target?.value);
                            }}
                        />
                        {props?.data?.cp?.obj?.colName === 'Impact Surveyor' && (
                            <>
                                <h5>What type of impact data are you collecting?</h5>
                                <FormInput
                                    id="colImpactType"
                                    type="select"
                                    className="mb-3"
                                    label="Impact Type"
                                    errors={errors}
                                    register={register}>
                                    {props?.data?.cp?.state?.impactTypeList?.map((item) => {
                                        return (
                                            <option key={item.pkImpactTypeId} value={item?.pkImpactTypeId}>
                                                {item?.colImpactName}
                                            </option>
                                        );
                                    })}
                                </FormInput>
                            </>
                        )}
                    </Col>
                    <div className="modal-footer">
                        <ButtonElement
                            name="Cancel"
                            variant="light"
                            className="btn-skip skippable"
                            type="button"
                            onClick={() => props.hideModal()}
                        />
                        <ButtonElement
                            name="Proceed"
                            variant="primary"
                            className="btn-next"
                            type="submit"
                            disabled={!name}
                        />
                    </div>
                </Row>
            </form>
        </>
    );
};

export default CreateNewEditableContentPanelModal;
