import { Nav } from 'react-bootstrap';
export const TabNavigation = ({ href, eventKey, title, icon, ...otherProps }) => {
    return (
        <Nav.Item className="d-md-flex">
            {otherProps?.prompt ? (
                <Nav.Link className="d-md-flex align-items-center justify-content-center" {...otherProps}>
                    <span>{icon}</span>
                    <span className="d-none d-md-block">{title}</span>
                </Nav.Link>
            ) : (
                <Nav.Link
                    className="d-md-flex align-items-center justify-content-center"
                    href={href}
                    eventKey={eventKey}
                    {...otherProps}>
                    <span>{icon}</span>
                    <span className="d-none d-md-block">{title}</span>
                </Nav.Link>
            )}
        </Nav.Item>
    );
};
