// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import Select from 'react-select';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import HyperDatepicker from '../../../components/datepicker/Datepicker';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    userList,
    analyticDataExportReset,
    reportFundtDataYearList,
    brandList,
    analyticRecordsExportDownloadSite,
    analyticRecordsExportDownloadReport,
} from '@ovrture/react-redux';
import { Controller, useForm } from 'react-hook-form';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import service from '../../../constants/service.constant';
import { getFiscalYearStore } from '../../../helpers/dataFiltering';

const ExportSystemWideSnapshotsDataModal = (props) => {
    const { Toast } = useNotification();
    const [disable, setdisable] = useState(false);
    const [colTags, setcolTags] = useState([]);
    const [tagInput, settagInput] = useState('');
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [isDate, setIsDate] = useState('all');
    const [lastModified, setLastModified] = useState('false');
    const [yearArray, setYearArray] = useState([]);
    const [userArray, setUserArray] = useState([]);
    const [brandArray, setbrandArray] = useState([]);
    const [editArray, setEditArray] = useState([
        { label: 'Unedited', value: 'Unedited' },
        { label: 'Edited', value: 'Edited' },
        { label: 'Restricted', value: 'Restricted' },
        { label: 'Locked', value: 'Locked' },
    ]);
    const [approvedArray, setApprovedArray] = useState([
        { label: 'Submit', value: 'Submit' },
        { label: 'Pending', value: 'Pending' },
        { label: 'Approved', value: 'Approved' },
    ]);
    const [statusArray, setStatusArray] = useState([
        { label: 'Live', value: 'Live' },
        { label: 'Paused', value: 'Paused' },
    ]);
    const [reportPreferenceArray, setReportPreferenceArray] = useState([
        { label: 'Digital', value: '1' },
        { label: 'Print', value: '2' },
        { label: 'Both', value: '3' },
    ]);
    const { modalDispatch } = useModalContext();
    const [user, setUser] = useState('');
    const dispatchAction = useDispatch();
    const { users, yearList, authInfo, brands, recordExportDownloadSite, recordExportDownloadReport } = useSelector(
        (state) => ({
            users: state?.user?.userList,
            yearList: state?.reportDataFund?.yearList,
            brands: state?.brand?.list?.data,
            authInfo: state?.auth?.me,
            recordExportDownloadSite: state?.analyticDataExport?.recordExportDownloadSite,
            recordExportDownloadReport: state?.analyticDataExport?.recordExportDownloadReport,
            success: state?.socket?.success,
            error: state?.socket?.errorr,
        })
    );
    useEffect(() => {
        if (props?.data?.type === 'report' && recordExportDownloadReport?.code === service.notfound) {
            Toast.warning({
                description: recordExportDownloadReport?.data,
            });
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(analyticDataExportReset());
        } else if (props?.data?.type === 'site' && recordExportDownloadSite?.code === service.notfound) {
            Toast.warning({
                description: recordExportDownloadSite?.data,
            });
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(analyticDataExportReset());
        } else if (recordExportDownloadSite || recordExportDownloadReport) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(analyticDataExportReset());
        }
    }, [recordExportDownloadSite, recordExportDownloadReport]);

    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        dispatchAction(reportFundtDataYearList());
        dispatchAction(
            userList({
                isAnd: true,
                isUnpaged: true,
                sort: 'colLastName,ASC',
            })
        );
        dispatchAction(
            brandList({
                isAnd: true,
                orgId: authInfo?.organaisationId,
                isUnpaged: true,
                userId: authInfo?.id,
            })
        );
    }, []);

    const onSubmit = (data) => {
        let payload = {};

        // User
        if (data?.fkUserIdList?.length > 0) {
            let temp = [];
            data?.fkUserIdList.forEach((element) => {
                temp.push(element?.value);
            });
            payload.fkUserIdList = temp.join(',');
        }

        // Brand
        if (data?.fkTemplateIdList?.length > 0) {
            let temp = [];
            data?.fkTemplateIdList.forEach((element) => {
                temp.push(element?.value);
            });
            payload.fkTemplateIdList = temp.join(',');
        }

        // Approval
        if (data?.colApprovalList?.length > 0 && data?.colApprovalList?.length < 3) {
            data?.colApprovalList.forEach((element) => {
                if (element?.value === 'Submit') {
                    payload.isSubmited = true;
                } else if (element?.value === 'Pending') {
                    payload.colApprovedByAdmin = false;
                } else if (element?.value === 'Approved') {
                    payload.colApprovedByAdmin = true;
                }
            });
        }

        // Edit
        if (data?.colEditList?.length > 0) {
            data?.colEditList.forEach((element) => {
                if (element?.value === 'Restricted') {
                    payload.colIsRestricted = true;
                }
                if (element?.value === 'Locked') {
                    payload.colIsLocked = true;
                }
                if (data?.colEditList?.filter((f) => f.value === 'Unedited' || f.value === 'Edited')?.length !== 2) {
                    if (element?.value === 'Unedited') {
                        payload.colIsEdited = false;
                    } else if (element?.value === 'Edited') {
                        payload.colIsEdited = true;
                    }
                }
            });
        }

        // Status
        if (data?.colIsActive?.length > 0 && data?.colIsActive?.length < 2) {
            data?.colIsActive.forEach((element) => {
                if (element?.value === 'Live') {
                    payload.colIsActive = true;
                } else if (element?.value === 'Paused') {
                    payload.colIsActive = false;
                }
            });
        }

        // Tags
        if (colTags?.length > 0) {
            payload.colTags = colTags.join(',');
        }

        // Report Year
        if (data?.reportYear?.length > 0) {
            let temp = [];
            data?.reportYear.forEach((element) => {
                temp.push(element?.value);
            });
            payload.reportYearList = temp.join(',');
        }

        // Report Preference
        if (data?.reportPreferenceIdList?.length > 0) {
            let temp = [];
            data?.reportPreferenceIdList.forEach((element) => {
                temp.push(element?.value);
            });
            payload.reportPreferenceIdList = temp.join(',');
        }

        //startDate endDate
        if (isDate === 'dateRange') {
            selectedStartDate && (payload.startDate = Moment(selectedStartDate).format('MM/DD/YYYY'));
            selectedEndDate && (payload.endDate = Moment(selectedEndDate).format('MM/DD/YYYY'));
        }

        // Last Modified
        payload.forEditedDate = lastModified;

        if (props?.data?.type === 'report') {
            dispatchAction(analyticRecordsExportDownloadReport({ ...payload }));
        } else {
            dispatchAction(analyticRecordsExportDownloadSite({ ...payload }));
        }
    };

    const handleTagInput = (e) => {
        if (e.key === 'Enter' || e.keyCode === 9) {
            e.preventDefault();
            if (e.target.value.length > 0) tagValidation(e);
        }
    };

    const tagValidation = (e) => {
        if (e.target.value.length > 0) {
            const arr = [...colTags];
            arr.push(e.target.value.replace('\n', ''));
            setcolTags(arr);
            settagInput('');
        }
    };

    const removeTag = (d, i) => {
        const arr = [...colTags];
        arr.splice(i, 1);
        setcolTags(arr);
    };

    useEffect(() => {
        if (users?.content?.length > 0) {
            let temp = [];
            users?.content?.forEach((element) => {
                temp.push({
                    label: element.colFullName,
                    value: element?.id,
                });
            });
            setUserArray(temp);
        } else setUserArray([]);
    }, [users]);

    useEffect(() => {
        if (brands?.content?.length > 0) {
            let temp = [];
            brands?.content?.forEach((element) => {
                temp.push({ label: element?.name, value: element?.id });
            });
            setbrandArray(temp);
        } else setbrandArray([]);
    }, [brands]);

    useEffect(() => {
        if (yearList?.length > 0) {
            let temp = [];
            yearList?.forEach((element) => {
                temp.push({ label: element, value: element });
            });
            setYearArray(temp);
            setValue('reportYear', { label: getFiscalYearStore(), value: getFiscalYearStore() });
        } else setYearArray([]);
    }, [yearList]);

    useEffect(() => {
        if (isDate === 'dateRange' && (!selectedStartDate || !selectedEndDate)) {
            setdisable(true);
        } else {
            setdisable(false);
        }
    }, [isDate, selectedStartDate, selectedEndDate]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="custom-padding filter-tag export-filter">
                    <p>
                        Generate a zipped gallery of all {props?.data?.type} snapshots based on the filters below. The
                        snapshots generated will be based on what the {props?.data?.type}s look like when the export is
                        initiated.
                    </p>
                    <Row className="mt-3">
                        <Col md={6}>
                            <b className="mb-1 d-block">User(s)</b>
                            <Controller
                                control={control}
                                name={'fkUserIdList'}
                                defaultValue={''}
                                render={({ field }) => (
                                    <Select
                                        name="fkUserIdList"
                                        onChange={(val) => {
                                            field.onChange(val);
                                        }}
                                        isMulti={true}
                                        options={userArray}
                                        className={'react-select'}
                                        classNamePrefix={'react-select'}
                                        value={field.value}
                                        defaultValue={''}
                                        placeholder={'All'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <b className="mb-1 d-block">Brand</b>
                            <Controller
                                control={control}
                                name={'fkTemplateIdList'}
                                defaultValue={''}
                                render={({ field }) => (
                                    <Select
                                        name="fkTemplateIdList"
                                        onChange={(val) => {
                                            field.onChange(val);
                                        }}
                                        isMulti={true}
                                        options={brandArray}
                                        className={'react-select'}
                                        classNamePrefix={'react-select'}
                                        value={field.value}
                                        defaultValue={''}
                                        placeholder={'All'}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <b className="mb-1 d-block">Approval</b>
                            <Controller
                                control={control}
                                name={'colApprovalList'}
                                defaultValue={''}
                                render={({ field }) => (
                                    <Select
                                        name="colApprovalList"
                                        onChange={(val) => {
                                            field.onChange(val);
                                        }}
                                        isMulti={true}
                                        options={approvedArray}
                                        className={'react-select'}
                                        classNamePrefix={'react-select'}
                                        value={field.value}
                                        defaultValue={''}
                                        placeholder={'All'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <b className="mb-1 d-block">Edit</b>
                            <Controller
                                control={control}
                                name={'colEditList'}
                                defaultValue={''}
                                render={({ field }) => (
                                    <Select
                                        name="colEditList"
                                        onChange={(val) => {
                                            field.onChange(val);
                                        }}
                                        isMulti={true}
                                        options={editArray}
                                        className={'react-select'}
                                        classNamePrefix={'react-select'}
                                        value={field.value}
                                        defaultValue={''}
                                        placeholder={'All'}
                                    />
                                )}
                            />
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col md={6}>
                            <b className="mb-1 d-block">Status</b>
                            <Controller
                                control={control}
                                name={'colIsActive'}
                                defaultValue={''}
                                render={({ field }) => (
                                    <Select
                                        name="colIsActive"
                                        onChange={(val) => {
                                            field.onChange(val);
                                        }}
                                        isMulti={true}
                                        options={statusArray}
                                        className={'react-select'}
                                        classNamePrefix={'react-select'}
                                        value={field.value}
                                        defaultValue={''}
                                        placeholder={'All'}
                                    />
                                )}
                            />
                        </Col>
                        <Col md={6}>
                            <b className="mb-1 d-block">Tags</b>
                            <div className="custom-filter-style-block">
                                <span className="inner-wrapper">
                                    {colTags?.length > 0 &&
                                        colTags?.map((d, i) => {
                                            return (
                                                <span className="list">
                                                    <span className="value" key={i}>
                                                        {d}
                                                    </span>
                                                    <span className="cross-icon" onClick={() => removeTag(d, i)}>
                                                        <svg
                                                            height="17"
                                                            width="17"
                                                            viewBox="0 0 20 20"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            className="css-tj5bde-Svg">
                                                            <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            );
                                        })}
                                </span>

                                <FormInput
                                    type="text"
                                    id="colTags"
                                    label="enter tag.."
                                    value={tagInput}
                                    className="custom-input prevent-form-enter"
                                    floatinglabel="false"
                                    onChange={(e) => settagInput(e.target.value)}
                                    onKeyDown={(e) => handleTagInput(e)}
                                    onBlur={(e) => tagValidation(e)}
                                />
                            </div>
                        </Col>
                    </Row>
                    {props?.data?.type === 'report' && (
                        <Row className="mt-3">
                            <Col md={6}>
                                <b className="mb-1 d-block">Fiscal Year</b>
                                <Controller
                                    control={control}
                                    name={'reportYear'}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Select
                                            name="reportYear"
                                            onChange={(val) => {
                                                field.onChange(val);
                                            }}
                                            isMulti={true}
                                            options={yearArray}
                                            className={'react-select'}
                                            classNamePrefix={'react-select'}
                                            value={field.value}
                                            defaultValue={''}
                                            placeholder={'All'}
                                        />
                                    )}
                                />
                            </Col>
                            <Col md={6}>
                                <b className="mb-1 d-block">Report Preference</b>
                                <Controller
                                    control={control}
                                    name={'reportPreferenceIdList'}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Select
                                            name="reportPreferenceIdList"
                                            onChange={(val) => {
                                                field.onChange(val);
                                            }}
                                            isMulti={true}
                                            options={reportPreferenceArray}
                                            className={'react-select'}
                                            classNamePrefix={'react-select'}
                                            value={field.value}
                                            defaultValue={''}
                                            placeholder={'All'}
                                        />
                                    )}
                                />
                            </Col>
                        </Row>
                    )}
                    <Row className={props?.data?.type === 'report' ? 'mt-3' : ''}>
                        <b className="mb-1 d-block">Date Range</b>
                        <FormInput
                            type="radio"
                            name="DateRange"
                            label="All"
                            value="all"
                            className="ms-2 mt-1"
                            onChange={(e) => {
                                setIsDate(e.target.value);
                                setSelectedStartDate('');
                                setSelectedEndDate('');
                            }}
                            checked={isDate === 'all' ? true : false}
                        />
                        <FormInput
                            type="radio"
                            name="DateRange"
                            value="dateRange"
                            label="Select Date Range"
                            className="ms-2 mt-1"
                            onChange={(e) => setIsDate(e.target.value)}
                            checked={isDate === 'dateRange' ? true : false}
                        />
                        <Row className={isDate === 'all' ? 'disabled mt-2' : 'mt-2'}>
                            <Col md={6}>
                                <p className="mb-1 d-block">Start Date</p>
                                <Controller
                                    control={control}
                                    name="dateRangeStart"
                                    //defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <HyperDatepicker
                                                    disabled={isDate === 'all' ? true : false}
                                                    value={selectedStartDate}
                                                    inputClass="form-control-light"
                                                    hideAddon={true}
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                        setSelectedStartDate(date);
                                                    }}
                                                    maxDate={selectedEndDate}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </Col>
                            <Col md={6}>
                                <p className="mb-1 d-block">End Date</p>
                                <Controller
                                    control={control}
                                    name="dateRangeEnd"
                                    //defaultValue={new Date()}
                                    render={({ field }) => {
                                        return (
                                            <>
                                                <HyperDatepicker
                                                    disabled={isDate === 'all' ? true : false}
                                                    value={selectedEndDate}
                                                    inputClass="form-control-light"
                                                    hideAddon={true}
                                                    onChange={(date) => {
                                                        field.onChange(date);
                                                        setSelectedEndDate(date);
                                                    }}
                                                    minDate={selectedStartDate}
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                    </Row>
                    <Row className="mt-3">
                        <FormInput
                            type="radio"
                            name="lastModified"
                            label="Created"
                            value="false"
                            className="ms-2 mt-1"
                            onChange={(e) => setLastModified(e.target.value)}
                            checked={lastModified === 'false' ? true : false}
                        />
                        <FormInput
                            type="radio"
                            name="lastModified"
                            label="Last Modified/Updated"
                            value="true"
                            className="ms-2 mt-1"
                            onChange={(e) => setLastModified(e.target.value)}
                            checked={lastModified === 'true' ? true : false}
                        />
                    </Row>
                </div>
                <div className="d-flex justify-content-center modal-footer">
                    <ButtonElement name="Export" variant="primary" type="submit" disabled={disable} />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            </form>
        </>
    );
};

export default ExportSystemWideSnapshotsDataModal;
