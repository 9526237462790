import { ALLOW_WISTIA } from './WistiaEditorIFrame';

const WistiaPreviewIFrame = (props) => {
    return (
        <>
            <iframe
                className={props?.assetUrl ? (ALLOW_WISTIA ? 'video-block wistia_embed' : 'video-block') : ''}
                src={props?.assetUrl}
                alt="preview"
                style={{
                    display: props?.assetUrl ? 'block' : 'none',
                }}
                allow="fullscreen"
                scrolling="no"></iframe>
        </>
    );
};

export default WistiaPreviewIFrame;
