import React, { useState, useEffect } from 'react';
import ButtonElement from '../button/ButtonElement';
import { Row, Col } from 'react-bootstrap';
import Cropper from 'cropperjs';
import { useSelector } from 'react-redux';
import { assetUploadCroppedImage, assetUploadReset } from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';
import { toDataURL } from '../../utility';
import { FormInput } from '../form/input';
import { useForm } from 'react-hook-form';
import { CustomLoader } from '../../helpers/customLoader';

function ImageCropper(props) {
    const {
        image,
        setCropData,
        hideModal,
        canvasData,
        cropBoxData,
        minCanvasHeight,
        minCanvasWidth,
        minContainerWidth,
        minContainerHeight,
    } = props;

    const {
        register,
        handleSubmit,
        reset,
        control,
        watch,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();

    const [zoomTo, setZoomTo] = useState(0.3);
    const [cropper, setCropper] = useState('');
    const [loading, setLoading] = useState(true);
    const [altText, setAltText] = useState('');
    const [sectionId, setSectionId] = useState(null);
    const [called, setCalled] = useState(false);
    const [uploadLoading, setuploadLoading] = useState(false);

    const dispatch = useDispatch();
    const { uploadCroppedImage } = useSelector((state) => ({
        uploadCroppedImage: state?.assetUpload?.uploadCroppedImage?.data,
    }));

    useEffect(() => {
        if (props?.otherProps?.altTextVal) {
            document.querySelector('#altText').value = props?.otherProps?.altTextVal;
            setAltText(props?.otherProps?.altTextVal);
        }
    }, []);

    useEffect(() => {
        const ele = document.getElementById('rootComponent');
        if (!sectionId && !called) {
            setSectionId(ele.getAttribute('section-id')?.replace('outer', ''));
        }
    }, [called]);

    useEffect(() => {
        if (uploadCroppedImage) {
            toDataURL(uploadCroppedImage, (data) => {
                setCropData({
                    base64Image: uploadCroppedImage,
                    blob: null,
                    filename: props?.filename,
                    editorReplaceCropImageApiNeeded: props?.otherProps?.editorReplaceCropImageApiNeeded,
                    imgForImpactAPI: { base64Image: data, filename: props?.filename },
                    altText: altText,
                    sectionId: sectionId,
                    identifierKey: props?.otherProps?.identifierKey,
                });
                dispatch(assetUploadReset());
                hideModal && props?.hideModal();
            });
        }
    }, [uploadCroppedImage, altText]);

    const getCropData = () => {
        setCalled(true);
        const ele = document.getElementById('rootComponent');
        if (typeof cropper !== 'undefined') {
            cropper?.getCroppedCanvas({ imageSmoothingQuality: 'high' })?.toBlob(
                (blob) => {
                    const imageExtension = cropper?.url?.split('.').pop();
                    if (props?.otherProps?.editorReplaceCropImageApiNeeded) {
                        let payload = {
                            requestFileBytes: cropper
                                .getCroppedCanvas({ imageSmoothingQuality: 'high' })
                                .toDataURL(imageExtension === 'png' ? 'image/png' : 'image/jpeg', 1.0)
                                ?.replace(/^data:image\/[a-z]+;base64,/, ''),
                            fileName: props?.filename,
                        };
                        sessionStorage.setItem(
                            process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
                            'SKIP_GENERIC_ALERT'
                        );
                        setuploadLoading(true);
                        dispatch(assetUploadCroppedImage(payload));
                    } else {
                        setCropData({
                            base64Image: cropper
                                ?.getCroppedCanvas({ imageSmoothingQuality: 'high' })
                                .toDataURL(imageExtension === 'png' ? 'image/png' : 'image/jpeg', 1.0),
                            blob: blob,
                            filename: props?.filename,
                            altText: altText,
                            identifierKey: props?.otherProps?.identifierKey,
                        });
                        hideModal && props?.hideModal();
                    }
                },
                'image/jpeg',
                1.0
            );
        }
    };

    const handleAction = (actionFor = '') => {
        const imgData = cropper?.getImageData();
        actionFor === 'rotate-clock'
            ? cropper?.rotate(90)
            : actionFor === 'rotate-anti-clock'
            ? cropper?.rotate(-90)
            : actionFor === 'scaleX'
            ? cropper?.scaleX(imgData.scaleX === 1 ? -1 : 1)
            : actionFor === 'scaleY'
            ? cropper?.scaleY(imgData.scaleY === 1 ? -1 : 1)
            : cropper?.rotate(0);
    };

    const zoomInOut = (val) => {
        setZoomTo(val);
        const containerData = cropper?.getContainerData();
        if (containerData?.width && containerData?.height) {
            cropper?.zoomTo(val, {
                x: containerData.width / 2,
                y: containerData.height / 2,
            });
        }
    };

    document.addEventListener('zoom', (e) => {
        if (e.detail.ratio > 2) {
            e.preventDefault();
        } else {
            setZoomTo(e.detail.ratio);
        }
    });

    document.addEventListener('ready', function (e) {
        // let style_ = document?.querySelector('.cropper-canvas')?.style;
        // style_.transform = 'none';
        // style_.margin = 'auto';
        // let cropperBoxStyle = document.querySelector('.cropper-crop-box').style.transform;
        // var matrix = new WebKitCSSMatrix(cropperBoxStyle);
        // cropperBoxStyle = 'translateX(' + matrix.m41 - 15 + 'px) translateY(' + matrix.m42 - 15 + 'px)';
        setLoading(false);
        if (cropper && cropper?.initialCanvasData?.height) {
            cropBoxData && cropper?.setCropBoxData(cropBoxData);
            canvasData && cropper?.setCanvasData(canvasData);
        }
    });

    useEffect(() => {
        if (image) {
            const img = document.getElementById('cropperImage');
            const cropper = new Cropper(img, {
                viewMode: 1,
                aspectRatio:
                    props?.otherProps?.minCropBoxHeight > 0 && props?.otherProps?.minCropBoxWidth > 0
                        ? props?.otherProps?.minCropBoxWidth / props?.otherProps?.minCropBoxHeight
                        : 1 / 1,
                autoCropArea: 0,
                cropBoxMovable: false,
                dragMode: 'move',
                cropBoxResizable: false,
                toggleDragModeOnDblclick: false,
                minCanvasWidth: minCanvasHeight || 0,
                minCanvasHeight: minCanvasWidth || 0,
                minCropBoxWidth: props?.otherProps?.minCropBoxWidth || 0,
                minCropBoxHeight: props?.otherProps?.minCropBoxHeight || 0,
                minContainerWidth: minContainerWidth || 200,
                minContainerHeight: minContainerHeight || 100,
                crop: (e) => {
                    // let style_ = document?.querySelector('.cropper-canvas')?.style;
                    // style_.margin = 'inherit';
                },
            });
            setCropper(cropper);
        }
    }, [image]);
    return (
        <>
            <CustomLoader loader={uploadLoading} />
            <div className={uploadLoading ? 'cropper-main-container disabled' : 'cropper-main-container'}>
                <Row>
                    <center>
                        <strong>{loading && 'Loading...'}</strong>
                    </center>

                    <Col md={12}>
                        <Col md={12} className="p-0">
                            <img
                                id="cropperImage"
                                src={image}
                                alt=""
                                style={{
                                    visibility: cropper?.initialCanvasData?.height ? 'visible' : 'hidden',
                                }}
                            />
                        </Col>
                    </Col>
                </Row>
                {image && (
                    <Row className="mt-2">
                        <Col sm={6} md={7}>
                            <>
                                <span>
                                    <i className="mdi mdi-image-outline font-20"></i>
                                    <input
                                        type="range"
                                        value={zoomTo}
                                        onChange={(e) => zoomInOut(e.target.value)}
                                        className="form-range w-70 m-3 vertical-sub"
                                        min="0"
                                        max="2"
                                        step="0.01"
                                        id="customRange2"></input>

                                    <i className="mdi mdi-image-outline font-24"></i>
                                </span>
                            </>
                        </Col>
                        <Col sm={6} md={5} className="text-end cropper-icons-right">
                            <ButtonElement
                                icon={<i className="mdi mdi-rotate-right font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Rotate clockwise"
                                onClick={() => {
                                    handleAction('rotate-clock');
                                }}
                            />
                            <ButtonElement
                                icon={<i className="mdi mdi-rotate-left font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Rotate counterclockwise"
                                onClick={() => {
                                    handleAction('rotate-anti-clock');
                                }}
                            />
                            <ButtonElement
                                icon={<i className="mdi mdi-arrow-left-right-bold font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Flip horizontally"
                                onClick={() => {
                                    handleAction('scaleX');
                                }}
                            />
                            <ButtonElement
                                icon={<i className="mdi mdi-arrow-up-down-bold font-20"></i>}
                                className="action-icon hover-primary p-0"
                                tooltip="Flip vertically"
                                onClick={() => {
                                    handleAction('scaleY');
                                }}
                            />
                        </Col>

                        <Col sm={12} md={12}>
                            <div className="mt-2">
                                <FormInput
                                    type="text"
                                    id="altText"
                                    label="Asset Alt Text"
                                    maxLength="120"
                                    name="altText"
                                    //className="mdi-tooltip-text"
                                    onChange={(e) => setAltText(e.target.value)}

                                    // errors={errors}
                                    // register={register}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="text-center">
                        {image && (
                            <div className="box">
                                <ButtonElement
                                    type="button"
                                    name="Save"
                                    variant="primary"
                                    disabled={uploadLoading}
                                    onClick={() => getCropData()}
                                />
                            </div>
                        )}
                        {hideModal && (
                            <ButtonElement
                                type="button"
                                name="Cancel"
                                variant="light"
                                onClick={() => {
                                    props?.hideModal();
                                }}
                            />
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ImageCropper;
