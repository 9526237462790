// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';

const DeleteUserConfirmationModal = (props) => {
    const { modalDispatch } = useModalContext();
    return (
        <>
            <p className="mt-2 mb-0">
                <b>Are you sure you want to delete this user's data permanently?</b>
            </p>
            <p className="mb-2">
                Once deleted, this user's actions will be logged at "Anonymous Deleted User." Please consider this
                decision carefully, as deleted user data does not count toward your organization's provisioned user
                total.
            </p>

            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        props?.data?.onDeleteAction(props?.data?.actionType, props?.data?.actionData);
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default DeleteUserConfirmationModal;
