// @flow
import React, { useEffect, useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useSelector, useDispatch } from 'react-redux';
import { addReport, reportReset } from '@ovrture/react-redux';

const BuildReportModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, settoggle] = useState(false);

    const dispatchAction = useDispatch();

    const { report } = useSelector((state) => ({
        report: state?.report?.addReport,
    }));

    useEffect(() => {
        if (report) {
            modalDispatch({ type: modalActions.CLOSE_MODAL });
            dispatchAction(reportReset());
        }
    }, [report]);

    const buildReport = () => {
        if (props?.data?.colAllowReportUpdates) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
            setTimeout(() => {
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalVariant: 'primary',
                        modalDataKey: ['common', 'linkFunds'],
                        data: {
                            id: props?.data?.id,
                            username: props?.data?.username,
                            name: props?.data?.username,
                            referenceNo: props?.data?.referenceNo,
                            type: 'report-build',
                            page: 'potfolio',
                            BuildReport: BuildReport,
                        },
                    },
                });
            }, 500);
        } else {
            BuildReport();
        }
    };

    const BuildReport = (fundIdList) => {
        dispatchAction(
            addReport({
                fkRecordId: props?.data?.id,
                fkTemplateId: props?.data?.fkTemplateId,
                fundIdList: fundIdList,
            })
        );
    };

    return (
        <>
            <p>
                This report will be built by pulling the funds (if any) linked to this record and will inherit the
                content panels per the default sequence set by the Administrators. Once built, the report can be edited.
            </p>
            <div className="button-list d-flex justify-content-center mt-2">
                <ButtonElement
                    name="View/Edit Linked Funds"
                    variant="outline-primary"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalVariant: 'primary',
                                modalDataKey: ['common', 'linkFunds'],
                                data: {
                                    id: props?.data?.id,
                                    username: props?.data?.username,
                                    portfolioData: props?.data,
                                    roleId: props?.data?.roleId,
                                },
                            },
                        })
                    }
                />
            </div>
            <div className="modal-footer  d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Build"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        settoggle(true);
                        buildReport();
                    }}
                    disabled={toggle}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default BuildReportModal;
