// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const SaveDraftModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);

    return (
        <>
            <p>How do you want to proceed?</p>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Save Draft & Exit"
                    variant="outline-primary"
                    type="button"
                    disabled={toggle}
                    onClick={(e) => {
                        setToggle(true);
                        props?.data?.saveDraft(true, true);
                    }}
                />
                <ButtonElement
                    name="Save Draft & Continue"
                    variant="outline-primary"
                    type="button"
                    disabled={toggle}
                    onClick={(e) => {
                        setToggle(true);
                        props?.data?.saveDraft(true, false);
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        });
                    }}
                />
            </div>
        </>
    );
};
export default SaveDraftModal;
