// @flow
import React, { Suspense, useEffect } from 'react';
import Routes from './routes/Routes';
import { NotificationProvider } from './contexts/NotificationContext';
import { AuthProvider } from './contexts/AuthContext';
// Themes

// For Saas import Saas.scss
import './assets/scss/Saas.scss';
import ModalProvider from './contexts/ModalContext';
import { TreeViewProvider } from './contexts/TreeViewContext';
import HandleCache from './helpers/handleCache/HandleCache';
import EnterKeyFormSubmit from './helpers/autoSubmit/enterKeyFormSubmit';
import HandleGlobalUploader from './helpers/handleGlobalUploader/HandleGlobalUploader';
import { errorHandler } from './helpers/errorHandler';

/**
 * Main app component
 */
const App = (props) => {
    useEffect(() => {
        errorHandler();
    }, []);
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <EnterKeyFormSubmit>
                <HandleCache>
                    <NotificationProvider>
                        <AuthProvider>
                            <ModalProvider>
                                <HandleGlobalUploader>
                                    <TreeViewProvider>
                                        <Routes></Routes>
                                    </TreeViewProvider>
                                </HandleGlobalUploader>
                            </ModalProvider>
                        </AuthProvider>
                    </NotificationProvider>
                </HandleCache>
            </EnterKeyFormSubmit>
        </Suspense>
    );
};

export default App;
