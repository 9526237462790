import { modalActions } from './actions';

export const modalIntialState = {
    currentModalId: null,
    modalList: [],
};

const handleModalAutoAddition = (action) => {
    setTimeout(() => {
        let element = document?.querySelector('body');
        let current = document.querySelector('.visually-visible-modal.modal.show');
        if (action === 'open') {
            current.focus();
            element.classList.remove('modal-open');
            element.removeAttribute('style', 'overflow: hidden; padding-right: 15px;');
        } else {
            element.classList.remove('modal-open');
            element.removeAttribute('style', 'overflow: hidden; padding-right: 15px;');
        }
    }, 10);
};

export const ModalReducer = (state = modalIntialState, action) => {
    switch (action.type) {
        case modalActions.OPEN_MODAL:
            handleModalAutoAddition('open');
            let id = action.payload.modalDataKey[0] + action.payload.modalDataKey[1];
            let item = {
                modalDataKey: action.payload.modalDataKey,
                modalAction: action.payload.modalAction,
                modalCancelAction: action.payload.modalCancelAction,
                data: action.payload.data,
                modalColor: action.payload.modalVariant,
                isHiddenModal: action.payload.isHiddenModal,
                currentModalId: id,
            };
            state.modalList = state?.modalList?.find((f) => f?.currentModalId === id)
                ? state.modalList
                : [...state.modalList, item];

            if (action?.payload?.isHiddenModal) {
                for (let index = 0; index < state?.modalList?.length; index++) {
                    const element = state?.modalList[index];
                    if (element?.currentModalId === id) {
                        element.isHiddenModalActive = true;
                    } else {
                        element.isHiddenModalActive = false;
                    }
                }
            }
            return {
                ...state,
                currentModalId: id,
                modalList: state?.modalList,
            };
        case modalActions.CLOSE_MODAL:
            handleModalAutoAddition();
            let noActive =
                action?.payload?.noActive ||
                document?.querySelector('.modal.visually-visible-modal.show .preview.fundPreviewModal');
            let id_ = action?.payload?.modalDataKey
                ? action.payload.modalDataKey[0] + action.payload.modalDataKey[1]
                : null;
            if (id_) {
                return {
                    ...state,
                    currentModalId: null,
                    modalList: state?.modalList?.filter((f) => f?.currentModalId !== id_),
                };
            } else {
                if (
                    state?.modalList?.length > 0 &&
                    state?.modalList[state?.modalList?.length - 1]?.isHiddenModal === true
                ) {
                    return {
                        ...state,
                        currentModalId: null,
                    };
                } else {
                    let list = state?.modalList?.slice(0, -1);
                    return {
                        ...state,
                        currentModalId: noActive
                            ? null
                            : list?.length > 0
                            ? list[list?.length - 1]?.isHiddenModal === true
                                ? list?.find((f) => f.isHiddenModalActive === true)?.currentModalId
                                : list[list.length - 1]?.currentModalId
                            : null,
                        modalList: list,
                    };
                }
            }
        case modalActions.HIDE_MODAL:
            handleModalAutoAddition();
            for (let index = 0; index < state?.modalList?.length; index++) {
                state.modalList[index].isHiddenModalActive = false;
            }
            return {
                ...state,
                currentModalId: null,
                modalList: state?.modalList,
            };
        case modalActions.RESET_MODAL:
            return {
                ...state,
                currentModalId: null,
                modalList: [],
            };
        default:
            throw new Error();
    }
};
