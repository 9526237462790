import { RedirectTo } from '../../../routes/role/path';
import { fetchBuildFolderTenantName } from '../../s3Mapping';

export const ScriptHandler = (scriptsArray) => {
    loadScriptsSequentially(scriptsArray)
        .then(() => {})
        .catch((error) => {
            console.error('Error loading scripts:', error);
        });
};

function loadScriptsSequentially(urls) {
    return urls.reduce((chain, url) => {
        return chain.then(() => loadScript(url));
    }, Promise.resolve());
}

function loadScript(url) {
    const existingScript = document.querySelector(`script[src="${url}"]`);
    return new Promise((resolve, reject) => {
        if (existingScript) {
            resolve();
            return;
        }
        const script = document.createElement('script');
        script.src = url;
        script.defer = true;
        script.async = true;
        script.onload = () => {
            // if (url?.includes('common.js')) {
            //     setTimeout(() => window?.editorAndOutputLib && window.editorAndOutputLib(), 2000);
            // } else if (url?.includes('editor.js')) {
            //     setTimeout(() => window?.editorOnlyLib && window.editorOnlyLib(), 2000);
            // }
            resolve();
        };
        script.onerror = reject;
        script.nonce = '2646294A404E6352';
        script.async = true;
        document.getElementsByTagName('body')[0].appendChild(script);
    });
}

export const RemoveOldScriptAndLink = () => {
    let commonJsFile = document.querySelectorAll(`script[src*="/ui/js/"]`);
    let commonCssFile = document.querySelectorAll(`link[href*="/ui/css/"]`);
    for (let i = 0; i < commonJsFile.length; i++) {
        commonJsFile[i].remove();
    }
    for (let i = 0; i < commonCssFile.length; i++) {
        commonCssFile[i].remove();
    }
};

export const RemoveTenantStyleSheet = () => {
    const ui = document.querySelectorAll(
        `link[href*="/ui"], link[href*="/static/css/style.css"], link[href*="/static/css/migration.css"]`
    );
    if (ui?.length > 0) {
        for (let i = 0; i < ui?.length; i++) {
            ui[i].remove();
        }
    }
    const tenantStyleSheet = document.querySelectorAll(`link[href*="/tenantStylesheet"]`);
    if (tenantStyleSheet.length > 0) {
        for (let i = 0; i < tenantStyleSheet.length; i++) {
            tenantStyleSheet[i].remove();
        }
    }
};

export const linkTagHandler = (orgId) => {
    // if (
    //     !window.location.pathname.includes(RedirectTo('/report-edit')) &&
    //     !window.location.pathname.includes(RedirectTo('/site-edit'))
    // ) {
    const folderName = fetchBuildFolderTenantName(orgId);
    let linksArray = [`/${folderName}/static/css/migration.css`, `/${folderName}/static/css/style.css`];
    if (process.env.REACT_APP_ACCESS_LOCAL_FILE === 'true') {
        linksArray = [`${RedirectTo(`/ui/common/migration.css`)}`, `${RedirectTo(`/ui/${orgId}/style.css`)}`];
    }
    linksArray.forEach((item) => {
        const link = document.createElement('link');
        link.href = item;
        link.rel = 'stylesheet';
        // Check if the link is already added
        const existingLink = document.querySelector(`link[href="${item}"]`);
        if (!existingLink) {
            document.head.appendChild(link);
        }
    });
    // }
};
