import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import TinyMceTextEditor from '../../../../components/form/TinyMceTextEditor';
import { useEffect, useState } from 'react';

const TinymceField = (props) => {
    const [delay, setdelay] = useState(false);
    useEffect(() => {
        if (props?.data?.defaultSurveyQuestion?.value) {
            props?.data?.setValue('defaultSurveyQuestion', props?.data?.defaultSurveyQuestion?.value);
        }
        if (props?.data?.defaultSurveyQualifier?.value) {
            props?.data?.setValue('defaultSurveyQualifier', props?.data?.defaultSurveyQualifier?.value);
        }
        setTimeout(() => {
            setdelay(true);
        }, 1000);
    }, [props?.data?.defaultSurveyQuestion?.value, props?.data?.defaultSurveyQualifier?.value]);

    return (
        <>
            {props?.data?.defaultSurveyQuestion && (
                <Row className="mt-2 mb-2">
                    {props?.data?.showTitle && (
                        <Col md={12} className="mb-0">
                            <p className='mb-1'>{props?.data?.defaultSurveyQuestion?.label}</p>
                        </Col>
                    )}
                    <Col md={7}>
                        <Controller
                            control={props?.data?.control}
                            name="defaultSurveyQuestion"
                            render={({ field }) =>
                                delay && (
                                    <TinyMceTextEditor
                                        disableEnter={false}
                                        maxLength={props?.data?.defaultSurveyQuestion?.max_length}
                                        errors={props?.data?.errors}
                                        id="defaultSurveyQuestion"
                                        className="editor-height"
                                        register={props?.data?.register}
                                        value={field?.value}
                                        setValue={(val, id) => setTimeout(() => setValue(id, val), 1000)}
                                    />
                                )
                            }
                        />
                    </Col>
                </Row>
            )}
            {props?.data?.defaultSurveyQualifier && (
                <Row className="mt-1">
                    {props?.data?.showTitle && (
                        <Col md={12} className="mb-0">
                            <p className='mb-1'>{props?.data?.defaultSurveyQualifier?.label}</p>
                        </Col>
                    )}
                    <Col md={7}>
                        <Controller
                            control={props?.data?.control}
                            name="defaultSurveyQualifier"
                            render={({ field }) =>
                                delay && (
                                    <TinyMceTextEditor
                                        disableEnter={false}
                                        maxLength={props?.data?.defaultSurveyQualifier?.max_length}
                                        errors={props?.data?.errors}
                                        id="defaultSurveyQualifier"
                                        className="editor-height"
                                        register={props?.data?.register}
                                        value={field?.value}
                                        setValue={(val, id) => setValue(id, val)}
                                    />
                                )
                            }
                        />
                    </Col>
                </Row>
            )}
        </>
    );
};
export default TinymceField;
