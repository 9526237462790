import 'easymde/dist/easymde.min.css';
import React, { useEffect } from 'react';
import { tinymceInlineInit, tinymceRemove } from '../../helpers/editor/tinymce/TinyMceInlineTextEditor';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';

const TinyMceTextEditor = ({ label, id, value, setValue, maxLength, plugins, height, disableEnter, disabled }) => {
    const { Toast, setAutoClose } = useNotification();
    useEffect(() => {
        setTimeout(() => {
            const data = {
                isPlatform: true,
                initObj: {
                    height: height ? height : 300,
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    content_css: 'https://old.asset.common.ovrture.com/shared/style/tinymce-custom-style.css',
                    entity_encoding: 'raw',
                    entities: '&nbsp',
                },
                getContentChange,
            };
            tinymceInlineInit({ Toast, setAutoClose }, data);
            addAreaLable();
        }, 500);
        return () => {
            tinymceRemove();
        };
    }, []);

    const getContentChange = (content, editorId) => {
        setValue(content, editorId);
    };

    const addAreaLable = () => {
        const ele = document?.querySelectorAll('.tox-toolbar__group');
        const resizeEle = document?.querySelectorAll('.tox-statusbar__resize-handle');
        if (ele?.length) {
            hideModalOnInsertLink();
            for (let i = 0; i < ele.length; i++) {
                let buttons = ele[i]?.getElementsByTagName('button');
                if (buttons?.length) {
                    for (let j = 0; j < buttons.length; j++) {
                        const ariaLabel = buttons[j]?.getAttribute('aria-label');
                        const svg = buttons[j]?.getElementsByTagName('svg')[0];
                        svg?.setAttribute('aria-label', ariaLabel);
                        svg?.setAttribute('role', 'img');
                    }
                }
            }
        }
        if (resizeEle?.length) {
            for (let i = 0; i < resizeEle.length; i++) {
                const ariaLabel = resizeEle[i]?.getAttribute('title');
                const svg = resizeEle[i]?.getElementsByTagName('svg')[0];
                svg?.setAttribute('aria-label', ariaLabel);
                svg?.setAttribute('role', 'img');
            }
        }
    };

    const hideModalOnInsertLink = () => {
        document.querySelector('[title="Insert/edit link"]').addEventListener('click', function (e) {
            document.querySelector('.fade.modal.show').style.display = 'none';
            setTimeout(() => {
                document.querySelector('.tox-dialog__footer [title="Save"]').addEventListener('click', function (e) {
                    document.querySelector('.fade.modal.show').style.display = 'block';
                });
                document.querySelector('.tox-dialog__footer [title="Cancel"]').addEventListener('click', function (e) {
                    document.querySelector('.fade.modal.show').style.display = 'block';
                });
                document.querySelector('.tox-dialog__header [title="Close"]').addEventListener('click', function (e) {
                    document.querySelector('.fade.modal.show').style.display = 'block';
                });
            }, 200);
        });
    };

    return (
        <div className={disabled ? 'areaTinyInputEditor disabled' : 'areaTinyInputEditor'}>
            {label && <label>{label}</label>}
            <div
                style={{ height: height ? height : 300 }}
                maxLength={maxLength}
                className={'inline-textEditor tox-tinymce-textarea'}
                id={id}
                contentEditable={true}
                dangerouslySetInnerHTML={{ __html: value }}></div>
            <p className="text-limit ms-2">{maxLength} characters</p>
        </div>
    );
};

export default TinyMceTextEditor;
