import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Row, Col, Card } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { assetBulkUploadResourceFiles } from '@ovrture/react-redux';
import { useTimeRemainingContext } from '../contexts/TimeRemainingContext';
import { timeRemainingActions } from '../reducers/actions';

const GlobalFileUploader = (props) => {
    const { routeMatch } = useRouteMatch();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const { timeRemainingDispatch } = useTimeRemainingContext();
    const dispatch = useDispatch();

    let { uploadResourceFiles, uploadResourceFilesData } = useSelector((state) => ({
        uploadResource: state?.assetBulkUpload?.uploadResource,
        uploadResourceFiles: state?.assetBulkUpload?.uploadResourceFiles,
        socketSuccess: state?.socket?.success,
        progressBar: state?.socket?.loadingList,
        uploadResourceFilesData: state?.assetBulkUpload?.uploadResourceFilesData,
    }));

    useEffect(() => {
        if (uploadResourceFiles) setSelectedFiles(uploadResourceFiles);
    }, [uploadResourceFiles]);

    useEffect(() => {
        uploadResourceFiles?.files === null && setSelectedFiles([]);
    }, [uploadResourceFiles?.files]);

    /**
     * Handled the accepted files and shows the preview
     */
    const handleAcceptedFiles = (files) => {
        var allFiles = files;

        if (props?.showPreview) {
            files?.map((file) => {
                return Object.assign(file, {
                    preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
                    formattedSize: formatBytes(file.size),
                    fileSizeMore32MB: file['type'].split('/')[0] === 'image' ? file.size > 33554432 : null,
                    fileSizeMore1GB: file['type'].split('/')[0] === 'video' ? file.size > 1073741824 : null,
                    typeFile: file['type'].split('/')[0],
                });
            });

            allFiles = [...selectedFiles];
            allFiles.push(...files);
            setSelectedFiles(allFiles);
        }

        if (props?.onFileUpload) {
            dispatch(assetBulkUploadResourceFiles(allFiles));
        }
    };
    useEffect(() => {
        let sizeArr = [];
        selectedFiles?.forEach((x) => {
            if (x?.fileSizeMore1GB || x?.fileSizeMore32MB) {
                sizeArr.push(2000);
            } else {
                sizeArr.push(x.size);
            }
        });
        let sum = 0;
        timeRemainingDispatch({
            type: timeRemainingActions.TOTAL_FILES,
            payload: sizeArr?.length,
        });
        sizeArr.forEach((x) => {
            sum += x;
        });
        timeRemainingDispatch({
            type: timeRemainingActions.ADD_TOTAL_SIZE,
            payload: sum,
        });
    }, [selectedFiles]);

    /**
     * Formats the size
     */
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    const removeFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        dispatch(assetBulkUploadResourceFiles(newFiles));
    };

    return (
        <>
            {!uploadResourceFilesData?.isSubmit && (
                <Dropzone {...props} onDrop={(acceptedFiles) => handleAcceptedFiles(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone">
                            <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <i className="h3 text-muted dripicons-cloud-upload"></i>
                                <h5>Drop files here or click to upload.</h5>
                            </div>
                        </div>
                    )}
                </Dropzone>
            )}

            {props.showPreview && selectedFiles.length > 0 && (
                <div
                    className={uploadResourceFilesData?.isSubmit ? 'dropzone-previews' : 'dropzone-previews mt-3'}
                    id="uploadPreviewTemplate">
                    {(selectedFiles || []).map((f, i) => {
                        return (
                            <>
                                <Card className="mt-1 mb-0 shadow-none border" key={i + '-file'}>
                                    <div className="p-2">
                                        <Row className="align-items-center">
                                            {f?.preview &&
                                                f?.name.split('.').pop().toLowerCase() !== 'heic' &&
                                                f?.name.split('.').pop().toLowerCase() !== 'jfif' && (
                                                    <Col className="col-auto">
                                                        <img
                                                            data-dz-thumbnail=""
                                                            className="avatar-sm rounded bg-light"
                                                            alt={f?.name}
                                                            src={f?.preview}
                                                        />
                                                    </Col>
                                                )}
                                            {!f.preview &&
                                                f?.name.split('.').pop().toLowerCase() !== 'heic' &&
                                                f?.name.split('.').pop().toLowerCase() !== 'jfif' && (
                                                    <>
                                                        <Col className="col-auto">
                                                            <div className="avatar-sm">
                                                                <span className="avatar-title bg-primary rounded">
                                                                    {f.type.split('/')[0] === 'application'
                                                                        ? 'PDF'
                                                                        : f.type.split('/')[0]}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </>
                                                )}
                                            {(f?.name.split('.').pop().toLowerCase() === 'heic' ||
                                                f?.name.split('.').pop().toLowerCase() === 'jfif') && (
                                                <>
                                                    <Col className="col-auto">
                                                        <div className="avatar-sm">
                                                            <span className="avatar-title bg-primary rounded">
                                                                image
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </>
                                            )}
                                            <Col className="ps-0">
                                                <Link to={routeMatch || null} className="text-muted fw-bold">
                                                    {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                                {uploadResourceFilesData?.isSubmit && (
                                                    <div className="progress">
                                                        <div
                                                            className={`progress-bar progress-bar-striped progress-bar-animated ${
                                                                f?.progressBar?.error && 'bg-danger'
                                                            }`}
                                                            role="progressbar"
                                                            aria-valuemin="0"
                                                            aria-valuemax="100"
                                                            style={
                                                                f?.progressBar?.error
                                                                    ? { width: '100%' }
                                                                    : f?.progressBar?.length > 0
                                                                    ? {
                                                                          width: `${Math.round(
                                                                              18 + f?.progressBar[0]?.progress?.percent
                                                                          )}%`,
                                                                      }
                                                                    : i === uploadResourceFilesData?.index &&
                                                                      uploadResourceFilesData?.isSubmit
                                                                    ? { width: '50px' }
                                                                    : { width: '0' }
                                                            }>
                                                            {f?.progressBar?.error
                                                                ? f?.progressBar?.error
                                                                : f?.progressBar?.length > 0
                                                                ? 'Process completed ' +
                                                                  Math.round(f?.progressBar[0]?.progress?.percent) +
                                                                  ' %'
                                                                : i === uploadResourceFilesData?.index &&
                                                                  uploadResourceFilesData?.isSubmit
                                                                ? 'Uploading'
                                                                : ''}
                                                        </div>
                                                    </div>
                                                )}
                                            </Col>
                                            <Col className="text-end">
                                                <Link
                                                    to={routeMatch || null}
                                                    className={
                                                        uploadResourceFilesData?.isSubmit
                                                            ? 'btn btn-link btn-lg text-muted shadow-none hover-danger disabled cross-icon-hide'
                                                            : 'btn btn-link btn-lg text-muted shadow-none hover-danger'
                                                    }>
                                                    <i
                                                        className="dripicons-cross"
                                                        onClick={() => {
                                                            removeFile(i);
                                                        }}></i>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </>
                        );
                    })}
                </div>
            )}
        </>
    );
};

GlobalFileUploader.defaultProps = {
    showPreview: true,
};

export default GlobalFileUploader;
