//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import ClearSystem from './ClearSystemDataModal';
import DeleteSystem from './SoftDeleteSystemModal';
import ClearAndDeleteSecureModal from './ClearAndDeleteSecureModal';

const ClearDeleteMultiStepForm = (props) => {
    const [active, setActive] = useState(0);

    const steps = [
        {
            name: 'Confirmation',
            component:
                props.data.item === 'clear' ? (
                    <ClearSystem active={active} setActive={setActive} data={props.data} />
                ) : (
                    <DeleteSystem active={active} setActive={setActive} data={props.data} />
                ),
        },
        {
            name: props.data.item === 'clear' ? 'Clear' : 'Delete',
            component: <ClearAndDeleteSecureModal active={active} setActive={setActive} data={props.data} />,
        },
    ];

    return <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />;
};

export default ClearDeleteMultiStepForm;
