import React, { useEffect, useState } from 'react';
import { authRefreshToken } from '@ovrture/react-redux';
import { BrowserRouter, Redirect, Switch, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import * as layoutConstants from '../constants/layout';

// Elastic APM
import { init as initApm } from '@elastic/apm-rum';

// All layouts/containers
import DefaultLayout from '../layouts/Default';
import VerticalLayout from '../layouts/Vertical';
import {
    CommonRoute,
    publicProtectedFlattenRoutes,
    PlatformAdminRoute,
    SystemAdminRoute,
    ApplicationAdminRoute,
    ReportAdminRoute,
    BrandAdminRoute,
    ContentAdminRoute,
    DataAdminRoute,
    UserRoute,
} from './index';
import { changeSidebarType, authLogout } from '@ovrture/react-redux';
import PrivateRoute from './PrivateRoute';
import { useAuth } from '../contexts/AuthContext';
import { useModalContext } from '../contexts/ModalContext';
import { modalActions, notificationActions } from '../reducers/actions';
import { useNotificationContex } from '../contexts/NotificationContext';
import ButtonElement from '../components/button/ButtonElement';
import { COMMON_PATH, PLATFORM_ADMIN_PATH, PUBLIC_PATH, RedirectTo, SYSTEM_ADMIN_PATH } from './role/path';
import { checkIfKeyExistOrNotInLocalstorage, loadFreshworksWidget } from '../helpers/editor/migration/TenantMigration';
import { differenceInMinutes } from 'date-fns';
const Routes = (props) => {
    const [authProtectedRoutes, setAuthProtectedRoutes] = useState([{}]);
    const { notificationDispatch } = useNotificationContex();
    const { user, isOnline, error, refreshToken, setDefaultLayout, defaultLayout, appStatus, handleRefreshTokenApi } =
        useAuth();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const history = useHistory();
    const [confirm, setConfirm] = useState(false);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [intervalId, setIntervalId] = useState(null);
    const pathName = window.location.pathname;
    const [offSetHeight, setOffsetHeight] = useState(0);
    const [startActiveTime, setStartActiveTime] = useState(null);

    useEffect(() => {
        if (
            user &&
            user?.roleName !== 'PLATFORM ADMIN' &&
            !(
                window.location.pathname.includes('site-edit') ||
                window.location.pathname.includes('report-edit') ||
                window.location.pathname.includes('content-panel-edit') ||
                window.location.pathname.includes('content-broadcast-create')
            )
        ) {
            loadFreshworksWidget();
        }
    }, [user, window.location.pathname]);

    function getConfirmation(message, callback) {
        setConfirmCallback(() => callback);
        setConfirm(message);
    }

    if (process.env.REACT_APP_APM_ENABLE === 'true') {
        const apm = initApm({
            serviceName: process.env.REACT_APP_APM_SERVICE_NAME,
            serverUrl: process.env.REACT_APP_APM_URL,
            serviceVersion: process.env.REACT_APP_APM_SERVICE_VERSION,
        });
    }
    useEffect(() => {
        checkIfKeyExistOrNotInLocalstorage('loadingListForRecord', []);
        checkIfKeyExistOrNotInLocalstorage('loadingList', []);
    }, []);

    useEffect(() => {
        if (appStatus === 'Active') {
            let currentTime = new Date();
            const diffTime = startActiveTime === null ? 0 : differenceInMinutes(currentTime, new Date(startActiveTime));
            if (startActiveTime === null) {
                setStartActiveTime(currentTime);
            } else if (diffTime > 10) {
                if (user) dispatch(authRefreshToken());
                setStartActiveTime(currentTime);
            }
        } else if (appStatus === 'Idle') {
        }
    }, [appStatus]);

    useEffect(() => {
        if (
            window.location.pathname.includes('site-edit') ||
            window.location.pathname.includes('report-edit') ||
            window.location.pathname.includes('content-panel-edit') ||
            window.location.pathname.includes('content-broadcast-create')
        ) {
            setOffsetHeight(0);
        } else if (window.innerHeight < 900) {
            setOffsetHeight(900 - window.innerHeight);
        } else {
            setOffsetHeight(0);
        }
    }, [window.innerHeight, window.location.pathname]);

    useEffect(() => {
        if (
            (user?.token && error === 'Invalid credentials.') ||
            (!user?.token && error === 'Authentication token is missing.')
        ) {
            setTimeout(() => {
                dispatch(authLogout());
                window.location.href = RedirectTo(PUBLIC_PATH.LOGIN);
            }, 3000);
        }
    }, [user, error]);

    useEffect(() => {
        if (window.location.pathname !== PUBLIC_PATH.LOGIN) {
            modalDispatch({
                type: isOnline ? modalActions.CLOSE_MODAL : modalActions.OPEN_MODAL,
                payload: {
                    modalDataKey: ['systemNotification', 'networkError'],
                    data: {
                        title: 'Connection Error!',
                        content: 'Please check your internet connection and try again.',
                        saveBtnDisabled: true,
                        cancelBtnDisabled: true,
                        closeBtnDisabled: true,
                    },
                    modalVariant: 'danger',
                },
            });
        }
    }, [isOnline]);

    const hanldeSessionExpired = () => {
        dispatch(authLogout());
    };
    const { logedinUser } = useSelector((state) => ({
        logedinUser: state?.auth?.user,
    }));

    useEffect(() => {
        modalDispatch({
            type:
                refreshToken === 'expired' || error === 'Invalid token.'
                    ? modalActions.OPEN_MODAL
                    : modalActions.CLOSE_MODAL,
            payload: {
                modalAction: (e) => console.log(''),
                modalDataKey: ['systemNotification', 'sessionExpired'],
                data: {
                    title: 'Session Expired',
                    content: 'Your session has expired due to inactivity. Please sign in again.',
                    saveBtnDisabled: true,
                    closeBtnDisabled: true,
                    cancelBtnDisabled: true,
                    element: [
                        {
                            pos: 'center',
                            elm: <ButtonElement variant="light" name="Ok" onClick={hanldeSessionExpired} />,
                        },
                    ],
                },
                modalVariant: 'danger',
            },
        });
    }, [refreshToken]);

    useEffect(() => {
        if (user) {
            let rootRoute = {
                path: ['/', '/public/login'],
                exact: true,
                component: () =>
                    user?.roleId === 'PLATFORM_ADMIN' ? (
                        <Redirect to={PLATFORM_ADMIN_PATH.TENANT_SYSTEMS} />
                    ) : user?.roleId === 'SYSTEM_ADMIN' || user?.roleId === 'APPLICATION_ADMIN' ? (
                        <Redirect to={SYSTEM_ADMIN_PATH.USERS} />
                    ) : user?.roleId === 'USER' || user?.roleId === 'SUPER_USER' || user?.roleId === 'DATA_ADMIN' ? (
                        <Redirect to={COMMON_PATH.RECORD_PORTFOLIO} />
                    ) : user?.roleId === 'REPORT_ADMIN' ? (
                        <Redirect to={COMMON_PATH.REVIEW_AND_APPROVAL} />
                    ) : user?.roleId === 'BRAND_ADMIN' ? (
                        <Redirect to={COMMON_PATH.REVIEW_AND_APPROVAL} />
                    ) : user?.roleId === 'CONTENT_ADMIN' ? (
                        <Redirect to={COMMON_PATH.REVIEW_AND_APPROVAL} />
                    ) : (
                        ''
                    ),
                route: PrivateRoute,
            };
            setAuthProtectedRoutes(
                user?.roleId === 'PLATFORM_ADMIN'
                    ? [rootRoute, ...PlatformAdminRoute, ...CommonRoute]
                    : user?.roleId === 'SYSTEM_ADMIN'
                    ? [rootRoute, ...SystemAdminRoute, ...CommonRoute]
                    : user?.roleId === 'REPORT_ADMIN'
                    ? [rootRoute, ...ReportAdminRoute, ...CommonRoute]
                    : user?.roleId === 'BRAND_ADMIN'
                    ? [rootRoute, ...BrandAdminRoute, ...CommonRoute]
                    : user?.roleId === 'CONTENT_ADMIN'
                    ? [rootRoute, ...ContentAdminRoute, ...CommonRoute]
                    : user?.roleId === 'APPLICATION_ADMIN'
                    ? [rootRoute, ...ApplicationAdminRoute, ...CommonRoute]
                    : user?.roleId === 'DATA_ADMIN'
                    ? [rootRoute, ...DataAdminRoute, ...CommonRoute]
                    : [rootRoute, ...UserRoute, ...CommonRoute]
            );
        }
    }, [user]);

    useEffect(() => {
        if (defaultLayout && document.title !== '404') {
            setDefaultLayout(false);
        }
    }, [pathName]);

    useEffect(() => {
        dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
    }, [dispatch]);
    useEffect(() => {
        if (!logedinUser && history.location.pathname === '/') {
            window.location.href = RedirectTo(PUBLIC_PATH.LOGIN);
        } else if (!logedinUser && !checkPassedPathIsValid(history.location.pathname)) {
            window.location.href = RedirectTo(PUBLIC_PATH.LOGIN);
        }
    }, [logedinUser]);

    const checkPassedPathIsValid = (pathname) => {
        var flag = false;
        publicProtectedFlattenRoutes.forEach((item) => {
            if (pathname === item.pathExact) {
                flag = true;
            }
        });
        return flag;
    };

    function allowTransition() {
        if (confirm.includes('/account/logout')) {
            dispatch(authLogout());
            return null;
        }
        setConfirm(false);
        confirmCallback(true);
        modalDispatch({ type: modalActions.CLOSE_MODAL });
    }

    function blockTransition() {
        setConfirm(false);
        confirmCallback(false);
        modalDispatch({ type: modalActions.CLOSE_MODAL });
    }

    useEffect(() => {
        if (confirm) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: (e) => modalDispatch({ type: modalActions.CLOSE_MODAL }),
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'leavingRouteMsg'],
                    data: {
                        allowTransition,
                        blockTransition,
                        title: false,
                    },
                },
            });
        }
        if (pathName === RedirectTo('/account/logout') && !confirm) {
            dispatch(authLogout());
        }
    }, [confirm, pathName]);

    return (
        <BrowserRouter basename={process.env.REACT_APP_ROUTE_PREFIX + '/'} getUserConfirmation={getConfirmation}>
            <Switch>
                {!user && (
                    <>
                        <DefaultLayout {...props} layout={VerticalLayout}>
                            <Switch>
                                {publicProtectedFlattenRoutes.map((route, index) => {
                                    return (
                                        route.path && (
                                            <route.route
                                                key={index}
                                                path={route.path}
                                                roles={route.roles}
                                                exact={route.exact}
                                                component={route.component}
                                            />
                                        )
                                    );
                                })}
                            </Switch>
                        </DefaultLayout>
                    </>
                )}

                {authProtectedRoutes && (
                    <VerticalLayout {...props} layout={VerticalLayout}>
                        <Switch>
                            {authProtectedRoutes.map((route, index) => {
                                return (
                                    route.path && (
                                        <route.route
                                            key={index}
                                            path={route.path}
                                            roles={route.roles}
                                            exact={route.exact}
                                            component={route.component}
                                            bredcrumbs={route.bredcrumbs}
                                        />
                                    )
                                );
                            })}
                        </Switch>
                    </VerticalLayout>
                )}
            </Switch>
            <Container className="offSetHeight">
                <div style={{ height: `${offSetHeight}px` }}></div>
            </Container>
        </BrowserRouter>
    );
};

export default Routes;
