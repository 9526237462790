// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import {
    userSoftDeletedList,
    userSoftRestore,
    userBulkSoftDeleteRestore,
    reportSoftDeleteRestore,
    bulkReportSoftDeleteRestore,
    siteSoftDeleteRestore,
    siteBulkSoftDeleteRestore,
} from '@ovrture/react-redux';
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';
import { userSoftDelete } from '@ovrture/react-redux';

const RestoreDeletedDataActionModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const restoreItem = (data) => {
        props.data.restoreItem(data);
    };
    return (
        <>
            {props?.data?.name === 'bulkUserRestore' ? (
                <p>Are you sure you want to restore this/these user(s)?</p>
            ) : props?.data?.name === 'UserRestore' ? (
                <p>Are you sure you want to restore this user?</p>
            ) : props?.data?.name === 'reportRestore' ? (
                <p>Are you sure you want to restore this report?</p>
            ) : props?.data?.name === 'siteRestore' ? (
                <p>Are you sure you want to restore this site?</p>
            ) : props?.data?.name === 'bulkReportsRestore' ? (
                <p>Are you sure you want to restore this/these report(s)?</p>
            ) : props?.data?.name === 'bulkSitesRestore' ? (
                <p>Are you sure you want to restore this/these site(s)?</p>
            ) : (
                ''
            )}
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Restore"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        const isSelectAll = props?.data?.payload?.isSelectAll || props?.data?.isSelectAll;
                        if (props?.data?.name === 'bulkUserRestore') {
                            dispatch(
                                userBulkSoftDeleteRestore({
                                    // userIdList: props?.data?.selectedUserArr,
                                    userIdList: isSelectAll ? [] : props?.data?.selectedUserArr,
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props.data.filterCriteria,
                                })
                            );
                        } else if (props?.data?.name === 'UserRestore') {
                            dispatch(
                                userSoftRestore({
                                    userId: props?.data?.selectedUserArr,
                                })
                            );
                        } else if (props?.data?.name === 'reportRestore') {
                            dispatch(
                                reportSoftDeleteRestore({
                                    // pkReportId: props?.data?.selectedArr,
                                    // fkUserId: props?.data?.userId,
                                    reportId: props?.data?.selectedArr,
                                    reportYear: props?.data?.fiscalYear,
                                    // userId: props?.data?.userId,
                                })
                            );
                        } else if (props?.data?.name === 'bulkReportsRestore') {
                            dispatch(
                                bulkReportSoftDeleteRestore({
                                    // userIdList: props?.data?.selectedUserArr,
                                    reportIdList: props?.data?.reportIdList,
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props.data.filterCriteria,
                                })
                            );
                        } else if (props?.data?.name === 'bulkSitesRestore') {
                            dispatch(
                                siteBulkSoftDeleteRestore({
                                    // userIdList: props?.data?.selectedUserArr,
                                    siteIdList: props?.data?.siteIdList,
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props.data.filterCriteria,
                                })
                            );
                        } else if (props?.data?.name === 'siteRestore') {
                            dispatch(
                                siteSoftDeleteRestore({
                                    // pkReportId: props?.data?.selectedArr,
                                    // fkUserId: props?.data?.userId,
                                    siteId: props?.data?.selectedArr,
                                    // userId: props?.data?.userId,
                                })
                            );
                        }
                    }}
                />

                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default RestoreDeletedDataActionModal;
