// @flow
import React from 'react';

const NotEnabled = (props) => {
    return (
        <div className="not-enabled m-5 pb-1 text-center">
            <i className="mdi mdi-lock-outline"></i>
            <h3 className="mb-4">Not Enabled</h3>
            <p>
                Please{' '}
                <a href="mailto:support@ovrture.com" class="anchor-ovrture-blue">
                    contact the platform administrator
                </a>{' '}
                to correct any errors or to request any <br /> alterations to your account permissions within the
                system.
            </p>
        </div>
    );
};
export default NotEnabled;
