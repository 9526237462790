// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import {
    rgcpHtmlMapAdd,
    rgcpHtmlMapUpdate,
    rgcpHtmlMapList,
    rgcpHtmlDefaultHtmlList,
    rgcpHtmlList,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../../../config';
import { ReportPanelMapValidation } from '../../../../components/form/validation';

const ReportPanelMapModal = (props) => {
    const [isEdit, setIsEdit] = useState(props?.data?.original);
    const [toggle, setToggle] = useState(false);
    const [rgcpType, setRgcpType] = useState(
        props?.data?.original?.fkRgcpTypeId
            ? props?.data?.original?.fkRgcpTypeId + ''
            : props?.data?.original?.fkRgcpTypeId
    );
    const [palette, setPalette] = useState(props?.data?.original?.fkPaletteId);
    const [templateName, setTemplateName] = useState(props?.data?.original?.fkTemplateId);
    const [colDefault, setColDefault] = useState(true);
    const dispatch = useDispatch();
    const [isValidated, setIsValidated] = useState(false);
    const [defaultHtmlList, setDefaultHtmlList] = useState([]);
    const { list, brandlist, paletteList, add, update, typesList, fundTypeList, impactTypeList, defaultHtmlListData } =
        useSelector((state) => ({
            list: state?.rgcpHtmlMap?.list,
            brandlist: state?.brand?.allList?.data?.content,
            paletteList: state?.palette?.list?.data,
            add: state?.rgcpHtmlMap?.add,
            update: state?.rgcpHtmlMap?.update,
            typesList: state?.rgcpHtml?.types?.data,
            fundTypeList: state?.fundType?.list?.data,
            impactTypeList: state?.impactType?.list?.data,
            rgcpHtmlList: state?.rgcpHtml?.list?.data?.content,
            // defaultHtmlList: state?.rgcpHtml?.defaultHtmlList?.data,
            defaultHtmlListData: state?.rgcpHtml?.list?.data?.content,
        }));
    useEffect(() => {
        if (defaultHtmlListData) {
            setDefaultHtmlList(defaultHtmlListData);
        }
    }, [defaultHtmlListData]);

    useEffect(() => {
        if (templateName >= 0 && palette >= 0 && rgcpType >= 0) {
            dispatch(
                rgcpHtmlList({
                    fkPalletId: palette,
                    fkTemplateId: templateName,
                    rgcpTypeId: rgcpType,
                    isUnpaged: true,
                    orgId: props?.data?.orgId || props?.data?.original?.fkOrgId,
                    colDefault: colDefault,
                })
            );
        } else {
            setDefaultHtmlList([]);
        }
    }, [templateName, palette, rgcpType, colDefault]);

    useEffect(() => {
        if (add) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                rgcpHtmlMapList({
                    orgId: props?.data?.orgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }
        if (update) {
            reset();
            props.hideModal();
            window.scrollTo(0, 0);
            dispatch(
                rgcpHtmlMapList({
                    orgId: props?.data?.original?.fkOrgId,
                    isAnd: true,
                    page: list?.data?.number,
                    size: config.PAGE_SIZE,
                })
            );
        }

        if (props?.data?.original) {
            if (props?.data?.original?.fkImpactTypeId || props?.data?.original?.fkFundTypeId) {
                setValue('defaultHtmlEnabler', 'true');
                setValue('colHtmlTypeName', '');
                setToggle(true);
                setColDefault(false);
            } else {
                setValue('defaultHtmlEnabler', 'false');
                setValue('colHtmlTypeName', props?.data?.original?.tblRgcpHtmlByColHtmlId);
                setToggle(false);
                setColDefault(true);
            }
            setValue('fkRgcpTypeId', props.data.original.fkRgcpTypeId);
            setValue('fkPaletteId', props.data.original.fkPaletteId);
            setValue('fkTemplateId', props.data.original.fkTemplateId);
            setValue('tblRgcpHtmlByColHtmlId', props.data.original.tblRgcpHtmlByColHtmlId);
            setValue('fkFundTypeId', props.data.original.fkFundTypeId);
            setValue('fkImpactTypeId', props.data.original.fkImpactTypeId);
            // setValue('pkRgcpHtmlId', props.data.original.pkRgcpHtmlId);
        }
        if (props?.data?.orgId) setValue('orgId', props.data.orgId);
    }, [props?.data, add, update]);

    const {
        register,
        handleSubmit,
        watch,
        clearErrors,
        setValue,
        reset,
        formState: { errors },
    } = useForm({ resolver: ReportPanelMapValidation, mode: 'onChange' });
    const watchAll = watch();
    useEffect(() => {
        if (watchAll.fkTemplateId === '' || watchAll.fkPaletteId === '' || watchAll.fkRgcpTypeId === '') {
            //setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        data.tblRgcpHtmlByColHtmlId = null;
        data.tblRgcpHtmlByColDefaultHtmlId = null;
        if (!toggle) {
            data.defaultHtmlEnabler = true;
            data.tblRgcpHtmlByColDefaultHtmlId = data?.colHtmlTypeName === '' ? null : data?.colHtmlTypeName;
        } else {
            data.defaultHtmlEnabler = false;
            data.tblRgcpHtmlByColHtmlId = data?.colHtmlTypeName === '' ? null : data?.colHtmlTypeName;
        }

        if (!toggle || rgcpType !== '4' || rgcpType === null || rgcpType === '') {
            data.fkImpactTypeId = null;
        }

        if (!toggle || rgcpType === '4' || rgcpType === null || rgcpType === '') {
            data.fkFundTypeId = null;
        }
        if (props?.data?.orgId) {
            data.fkOrgId = props?.data?.orgId;

            if (!(!data?.defaultHtmlEnabler && !data?.fkImpactTypeId && !data?.fkFundTypeId)) {
                dispatch(rgcpHtmlMapAdd(data));
            }
        } else {
            data.fkOrgId = props.data.original.fkOrgId;
            data.pkRgcpId = props.data.original.pkRgcpId;
            if (!(!data?.defaultHtmlEnabler && !data?.fkImpactTypeId && !data?.fkFundTypeId)) {
                dispatch(rgcpHtmlMapUpdate(data));
            }
        }
        dispatch(
            rgcpHtmlList({
                orgId: props?.data?.orgId || props?.data?.original?.fkOrgId,
            })
        );
    };

    const toggleCancel = () => {
        props.hideModal();
        dispatch(
            rgcpHtmlList({
                orgId: props?.data?.orgId || props?.data?.original?.fkOrgId,
            })
        );
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={4}>
                    <FormInput
                        onChange={(e) => setTemplateName(e.target.value)}
                        type="select"
                        disabled={isEdit}
                        id="fkTemplateId"
                        label="Template Name *"
                        register={register}>
                        <option value="">Select</option>
                        {brandlist?.map((item, index) => {
                            return (
                                <option key={index} value={item?.id}>
                                    {item?.name}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput
                        onChange={(e) => setPalette(e.target.value)}
                        disabled={isEdit}
                        type="select"
                        id="fkPaletteId"
                        label="Palette *"
                        register={register}>
                        <option value="">Select</option>
                        {paletteList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkPaletteId}>
                                    {item?.colPaletteName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput
                        onChange={(e) => setRgcpType(e.target.value)}
                        disabled={isEdit}
                        type="select"
                        id="fkRgcpTypeId"
                        label="RGCP Type *"
                        register={register}>
                        <option value="">Select</option>
                        {typesList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkRgcpTypeId}>
                                    {item?.colRgcpTypeName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </Row>
            <Row className="pb-1">
                <Col md={3}>
                    <FormInput
                        disabled={isEdit}
                        type="radio"
                        id="defaultHtmlEnabler"
                        label="Default"
                        register={register}
                        value={false}
                        onChange={(e) => {
                            setToggle(!toggle);
                            setColDefault(true);
                        }}
                        checked={toggle === false}
                        onClick={(e) => {
                            clearErrors('fkFundTypeId');
                            clearErrors('fkImpactTypeId');
                        }}
                    />
                </Col>
                <Col md={9}>
                    <FormInput
                        disabled={isEdit}
                        type="radio"
                        id="defaultHtmlEnabler"
                        register={register}
                        value={true}
                        onChange={(e) => {
                            setToggle(!toggle);
                            setColDefault(false);
                        }}
                        label="According to type"
                        checked={toggle === true}
                    />
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormInput
                        type="select"
                        id="fkFundTypeId"
                        errors={errors}
                        register={register}
                        label="Fund Type"
                        disabled={!toggle || rgcpType === '4' || rgcpType === null || rgcpType === ''}>
                        <option value="">Select</option>
                        {fundTypeList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkFundTypeId}>
                                    {item?.colFundTypeName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
                <Col md={4}>
                    <FormInput
                        type="select"
                        id="fkImpactTypeId"
                        register={register}
                        errors={errors}
                        label="Impact Type"
                        disabled={!toggle || rgcpType !== '4' || rgcpType === null || rgcpType === ''}>
                        <option value="">Select</option>
                        {impactTypeList?.map((item, index) => {
                            return (
                                <option key={index} value={item?.pkImpactTypeId}>
                                    {item?.colImpactName}
                                </option>
                            );
                        })}
                    </FormInput>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <FormInput
                        type="select"
                        errors={errors}
                        id="colHtmlTypeName"
                        register={register}
                        // disabled={toggle ? true : false}
                        label="Default Html">
                        <option value="">Select</option>
                        {defaultHtmlList
                            ? defaultHtmlList?.map((item, index) => {
                                  return (
                                      <option
                                          key={index}
                                          selected={
                                              props?.data?.original?.tblRgcpHtmlByColHtmlId === item?.pkRgcpHtmlId ||
                                              props?.data?.original?.tblRgcpHtmlByColDefaultHtmlId ===
                                                  item?.pkRgcpHtmlId
                                          }
                                          value={item?.pkRgcpHtmlId}>
                                          {item?.colHtmlTypeName}
                                      </option>
                                  );
                              })
                            : null}
                        {/* {defaultHtmlList ? (
                            <option key={0} value={defaultHtmlList[0]?.pkRgcpHtmlId}>
                                {defaultHtmlList[0]?.colHtmlTypeName}
                            </option>
                        ) : null} */}
                    </FormInput>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name={props?.data?.orgId ? 'Save' : 'Update'}
                    variant="primary"
                    type="submit"
                    disabled={isValidated === true ? true : false}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggleCancel} />
            </div>
        </form>
    );
};

export default ReportPanelMapModal;
