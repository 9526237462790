import { Col, Row } from 'react-bootstrap';
import { FormInput } from '../../../../components/form/input';
import { useEffect, useState } from 'react';

const MandatoryField = (props) => {
    const [mandatoryNullvalueBoolean, setmandatoryNullvalueBoolean] = useState(null);
    useEffect(() => {
        if (props?.data?.info?.colReportRenderingFields) {
            setmandatoryNullvalueBoolean(
                JSON.parse(props?.data?.info?.colReportRenderingFields).mandatoryNullvalueBoolean
            );
        }
    }, [props?.data?.info?.colReportRenderingFields]);

    useEffect(() => {
        if (mandatoryNullvalueBoolean) {
            props.data.setValue('mandatoryNullvalueBoolean', mandatoryNullvalueBoolean?.value);
            props?.data?.settinymceToggle(mandatoryNullvalueBoolean?.value);
        }
    }, [mandatoryNullvalueBoolean]);

    return (
        <>
            <Row>
                <Col md={12}>
                    <span>{mandatoryNullvalueBoolean?.label}</span>
                    <FormInput
                        type={mandatoryNullvalueBoolean?.type}
                        id="mandatoryNullvalueBoolean"
                        className="mb-0 mandatoryField"
                        errors={props?.data?.errors}
                        register={props?.data?.register}
                        label={mandatoryNullvalueBoolean?.value === true ? 'ON' : 'OFF'}
                        aria-label={mandatoryNullvalueBoolean?.value === true ? 'ON' : 'OFF'}
                        defaultChecked={mandatoryNullvalueBoolean?.value}
                        onChange={(e) => {
                            props?.data?.settinymceToggle(e.target.checked);
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};
export default MandatoryField;
