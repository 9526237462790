// @flow
import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Tab, Nav } from 'react-bootstrap';
import { useParams, useLocation } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { TabNavigation } from '../../../../components/form/tabnavigation';
import { FormInput } from '../../../../components/form/input';
import HyperDatepicker from '../../../../components/datepicker/Datepicker';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import TinyMceTextEditor from '../../../../components/form/TinyMceTextEditor';
import { Prompt } from 'react-router-dom';
import { checkPromptValues, covertFileUrlToByte } from '../../../../utility';
import {
    performanceListByIdYear,
    performanceCreate,
    performanceUpdate,
    performanceDelete,
    donorsListBysearch,
    donorsReset,
    performanceReset,
    donorsBulkDelete,
    assePreview,
    assetReset,
    assePreviewDoc,
    performanceSoftDelete,
    donorBulkSoftDelete,
    crmDownloadMappingCC,
    assetFolderViewTempDataSet,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { setRetainHistory, spacePreventDefault } from '../../../../helpers/retainHistory';
import { COMMON_PATH, RedirectTo } from '../../../../routes/role/path';
import { useHistory } from 'react-router-dom';
import { fundPerformanceValidation } from '../../../../components/form/validation';
import FileInput from '../../../../components/form/input/fileInput';
import { FloatingLabel, Form } from 'react-bootstrap';
import { CustomLoader } from '../../../../helpers/customLoader';
import { crmInlineMandatoryChange } from '../../../../helpers/genericFunction';

const DonorsDetails = (props) => {
    const [keydown, setKeydown] = useState(false);
    const history = useHistory();
    const { modalDispatch, modal } = useModalContext();
    const [selectedDate, setSelectedDate] = useState(null);
    const [checked, toggleCheckbox] = useState();
    const [currentTab, setCurrentTab] = useState();
    const [selectedDonors, setselectedDonors] = useState([]);
    const { id } = useParams();
    const search = useLocation().search;
    const pkFundPerformanceId = new URLSearchParams(search).get('pkFundPerformanceId');
    const colFpReportYear = new URLSearchParams(search).get('colFpReportYear');
    const isDonorUpdate = new URLSearchParams(search).get('isDonorUpdate');
    const colFdReportYear = new URLSearchParams(search).get('colFdReportYear') || new URLSearchParams(search).get('reportyear');
    const colFundReferenceNo = new URLSearchParams(search).get('colFundReferenceNo');
    const reportYear = new URLSearchParams(search).get('reportyear');
    const [donorList, setDonorList] = useState([]);
    const [isWhen, setIsWhen] = useState(false);
    const [initialVal, setInitialVal] = useState(false);
    const [downloadFileName, setDownloadFileName] = useState();
    const [downloadFile, setDownloadFile] = useState('');
    const [customError, setCustomError] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isUpdate, setIsUpdate] = useState(new URLSearchParams(search).get('isUpdate'));
    const [isdelayed, setIsDelayed] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();
    const {
        details,
        donorItems,
        deleted,
        created,
        updated,
        donorUpdated,
        donorCreated,
        donorBulkDeleted,
        assetPreview,
        previewDoc,
        fetch,
    } = useSelector((state) => ({
        details: state?.performance?.listByIdYear,
        donorItems: state?.donors?.donorSearchList?.data,
        deleted: state?.performance?.softDelete,
        // deleted: state?.performance?.deleted,
        created: state?.performance?.created,
        updated: state?.performance?.updated,
        donorUpdated: state?.donors?.donorUpdated,
        donorCreated: state?.donors?.donorCreated,
        donorBulkDeleted: state?.donors?.bulkSoftDelete,
        // donorBulkDeleted: state?.donors?.donorBulkDeleted,
        assetPreview: state?.asset?.preview,
        previewDoc: state?.asset?.previewDoc,
        fetch: state?.crmFieldMapping?.list?.data,
    }));
    /*
    form validation schema
    */
    const {
        control,
        setValue,
        setError,
        register,
        getValues,
        reset,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        resolver: props?.someFieldsShouldHideOrChangeForCrm ? null : fundPerformanceValidation,
        mode: 'onBlur',
    });
    const values = watch();

    // crm features
    useEffect(() => {
        if (props?.someFieldsShouldHideOrChangeForCrm) props?.onLoad();
    }, []);

    useEffect(() => {
        if (fetch?.content && props?.someFieldsShouldHideOrChangeForCrm) {
            fetch?.content?.forEach((element) => {
                if (element?.colOvrtureFieldName === 'colFdReportYear') {
                    setValue(element?.colOvrtureFieldName, props?.crmYear);
                } else {
                    setValue(element?.colOvrtureFieldName, element?.colCrmFieldName);
                }
            });
            crmInlineMandatoryChange(null, fetch, props?.setDisable);
        }
    }, [fetch]);

    // Record features
    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (
                window.location.pathname.includes(RedirectTo('/reportdata-edit-performance')) &&
                document.querySelector('.modal.visually-visible-modal .preview') &&
                e.target.closest('.visually-visible-modal.modal')
            ) {
                if ((!keydown && e.key === ' ') || e.code === 'Space' || e.keyCode === 32) {
                    setKeydown(true);
                    spacePreventDefault(e);
                }
                window.addEventListener('keyup', function () {
                    setKeydown(false);
                    e.preventDefault();
                });
            }
        });
        dispatch(donorsReset());
        if (isUpdate === 'true') {
            setIsWhen(false);
            dispatch(
                performanceListByIdYear({ pkFundPerformanceId: pkFundPerformanceId, reportYear: colFpReportYear })
            );
        }
        !props?.someFieldsShouldHideOrChangeForCrm &&
            dispatch(donorsListBysearch({ isAnd: true, colFdReportYear: colFpReportYear, fkFundId: id }));
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (keydown) {
            if (modal.data.type === 'fund-performance-preview') {
                if (modal?.isOpen) {
                    // const styleSheet = document.getElementsByTagName('link');
                    // const lastItem = styleSheet.length - 1;
                    // styleSheet[lastItem].remove();
                    const tenantStyleSheet = document.querySelectorAll(`link[href*="/tenantStylesheet"]`);
                    if (tenantStyleSheet.length > 0) {
                        for (let i = 0; i < tenantStyleSheet.length; i++) {
                            tenantStyleSheet[i].remove();
                        }
                    }
                    modalDispatch({
                        type: modalActions.CLOSE_MODAL,
                    });
                }
                return false;
            }
        }
        //eslint-disable-next-line
    }, [keydown]);

    const handleDeleteFundPerformance = () => {
        isUpdate === 'true' &&
            dispatch(performanceSoftDelete({ fundPerformanceId: pkFundPerformanceId, reportYear: colFpReportYear }));
        // isUpdate === 'true' &&
        //     dispatch(performanceDelete({ pkFundPerformanceId: pkFundPerformanceId, reportYear: colFpReportYear }));
    };
    useEffect(() => {
        if (donorCreated || donorUpdated || donorBulkDeleted) {
            setselectedDonors([]);
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
            dispatch(performanceReset());
            dispatch(donorsReset());
            setRetainHistory();
            if (donorCreated || donorUpdated || donorBulkDeleted) {
                dispatch(donorsListBysearch({ isAnd: true, colFdReportYear: colFdReportYear, fkFundId: id }));
            }
        }
        if (assetPreview) {
            convertDownloadFile(assetPreview?.data);
            dispatch(assetReset());
        } else if (previewDoc) {
            convertDownloadFile(previewDoc?.data);
            dispatch(assetReset());
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }

        //eslint-disable-next-line
    }, [donorCreated, donorBulkDeleted, donorUpdated, assetPreview, previewDoc]);

    useEffect(() => {
        if (created || updated || deleted) {
            setIsWhen(false);
            setInitialVal(getValues());
            setTimeout(() => {
                modalDispatch({
                    type: modalActions.CLOSE_MODAL,
                });
                if (created?.code === 201 && isEdit) {
                    setIsUpdate(true);
                } else {
                    dispatch(performanceReset());
                }
                dispatch(donorsReset());
                setRetainHistory();
                deleted
                    ? history.push(COMMON_PATH.REPORT_DATA)
                    : isEdit === false && history.push(COMMON_PATH.REPORT_DATA);
            }, 100);
        }
        //eslint-disable-next-line
    }, [deleted, created, updated, donorCreated, donorBulkDeleted]);

    useEffect(() => {
        setTimeout(() => {
            setIsDelayed(true);
        }, 100);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (colFundReferenceNo) {
                setValue('colFundReferenceNo', colFundReferenceNo);
                setValue('colFundReferenceNo1', colFundReferenceNo);
                setValue('colFundReferenceNo2', colFundReferenceNo);
                setValue('colFundReferenceNo3', colFundReferenceNo);
            }
            if (reportYear) {
                setValue('colFpReportYearFinance', reportYear);
                setValue('colFpReportYear', reportYear);
                setValue('colFpReportYear1', reportYear);
                setValue('colFpReportYear2', reportYear);
            }
            if (isDonorUpdate === 'true') {
                setValue('colFdReportYear', colFdReportYear);
                dispatch(donorsListBysearch({ isAnd: true, colFdReportYear: colFdReportYear, fkFundId: id }));
            } else {
                setValue('colFdReportYear', reportYear);
            }
        }, 200);

        if (details?.data) {
            setValue('fkFundId', details?.data?.fkFundId);
            details?.data?.colFundReferenceNo && setValue('colFundReferenceNo', details?.data?.colFundReferenceNo);
            details?.data?.colFundReferenceNo && setValue('colFundReferenceNo1', details?.data?.colFundReferenceNo);
            details?.data?.colFundReferenceNo && setValue('colFundReferenceNo2', details?.data?.colFundReferenceNo);
            details?.data?.colFundReferenceNo3 && setValue('colFundReferenceNo3', details?.data?.colFundReferenceNo3);
            setValue('colFpLastGiftDate', details?.data?.colFpLastGiftDate);
            setSelectedDate(details?.data?.colFpLastGiftDate ? new Date(details?.data?.colFpLastGiftDate) : null);
            details?.data?.colFpReportYear && setValue('colFpReportYearFinance', details?.data?.colFpReportYear);
            details?.data?.colFpReportYear && setValue('colFpReportYear', details?.data?.colFpReportYear);
            details?.data?.colFpReportYear && setValue('colFpReportYear1', details?.data?.colFpReportYear);
            details?.data?.colFpReportYear && setValue('colFpReportYear2', details?.data?.colFpReportYear);
            setValue('colFpTotalGifts', details?.data?.colFpTotalGifts);
            setValue('colFpProjectedDistributionFy1', details?.data?.colFpProjectedDistributionFy1);
            setValue('colFpBeginningValueFy0', details?.data?.colFpBeginningValueFy0);
            setValue('colFpBeginningValueFy1', details?.data?.colFpBeginningValueFy1);
            setValue('colFpBeginningValueFy2', details?.data?.colFpBeginningValueFy2);
            setValue('colFpBeginningValueFy3', details?.data?.colFpBeginningValueFy3);
            setValue('colFpBeginningValueFy4', details?.data?.colFpBeginningValueFy4);
            setValue('colFpGiftsFy0', details?.data?.colFpGiftsFy0);
            setValue('colFpGiftsFy1', details?.data?.colFpGiftsFy1);
            setValue('colFpGiftsFy2', details?.data?.colFpGiftsFy2);
            setValue('colFpGiftsFy3', details?.data?.colFpGiftsFy3);
            setValue('colFpGiftsFy4', details?.data?.colFpGiftsFy4);
            setValue('colCurrentUseGiftsFy0', details?.data?.colCurrentUseGiftsFy0);
            setValue('colFpMatchFy0', details?.data?.colFpMatchFy0);
            setValue('colFpMatchNameFy0', details?.data?.colFpMatchNameFy0);
            setValue('colFpGainsLossesFy0', details?.data?.colFpGainsLossesFy0);
            setValue('colFpDistributionsFy0', details?.data?.colFpDistributionsFy0);
            setValue('colFpFeesFy0', details?.data?.colFpFeesFy0);
            setValue('colFpPayoutReturnedFy0', details?.data?.colFpPayoutReturnedFy0);
            setValue('colFpPayoutReturnedFy1', details?.data?.colFpPayoutReturnedFy1);
            setValue('colFpPayoutReturnedFy2', details?.data?.colFpPayoutReturnedFy2);
            setValue('colFpPayoutReturnedFy3', details?.data?.colFpPayoutReturnedFy3);
            setValue('colFpPayoutReturnedFy4', details?.data?.colFpPayoutReturnedFy4);
            setValue('colFpOtherTransfersFy0', details?.data?.colFpOtherTransfersFy0);
            setValue('colFpOtherTransfersFy1', details?.data?.colFpOtherTransfersFy1);
            setValue('colFpOtherTransfersFy2', details?.data?.colFpOtherTransfersFy2);
            setValue('colFpOtherTransfersFy3', details?.data?.colFpOtherTransfersFy3);
            setValue('colFpOtherTransfersFy4', details?.data?.colFpOtherTransfersFy4);
            setValue('colFpEndingValueFy0', details?.data?.colFpEndingValueFy0);
            setValue('colFy0PercentageGrowth', details?.data?.colFy0PercentageGrowth);
            setValue('colFpEndingValuePriorYearFy1', details?.data?.colFpEndingValuePriorYearFy1);
            setValue('colFpEndingValuePriorYearFy2', details?.data?.colFpEndingValuePriorYearFy2);
            setValue('colFpEndingValuePriorYearFy3', details?.data?.colFpEndingValuePriorYearFy3);
            setValue('colFpEndingValuePriorYearFy4', details?.data?.colFpEndingValuePriorYearFy4);
            setValue('colFy1PercentageGrowth', details?.data?.colFy1PercentageGrowth);
            setValue('colFy2PercentageGrowth', details?.data?.colFy2PercentageGrowth);
            setValue('colFy3PercentageGrowth', details?.data?.colFy3PercentageGrowth);
            setValue('colFy4PercentageGrowth', details?.data?.colFy4PercentageGrowth);
            setValue('colFpDistributionsFy1', details?.data?.colFpDistributionsFy1);
            setValue('colFpDistributionsFy2', details?.data?.colFpDistributionsFy2);
            setValue('colFpDistributionsFy3', details?.data?.colFpDistributionsFy3);
            setValue('colFpDistributionsFy4', details?.data?.colFpDistributionsFy4);

            setValue('colFpFooterNote', details?.data?.colFpFooterNote);
            setValue('colFpLongFooterNote', details?.data?.colFpLongFooterNote);

            setDownloadFile({
                base64Image: details?.data?.colDownloadUrl,
                filename: details?.data?.colDownloadFileName,
            });
            setInitialVal(getValues());
        }
        //eslint-disable-next-line
    }, [details?.data]);

    const onDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        setTimeout(() => {
            setValue('selectedDonors', selectedDonors?.length);
            setInitialVal(getValues());
        }, 200);
        //eslint-disable-next-line
    }, [selectedDonors]);

    const deleteBtnHandler = () => {
        const payload = {
            reportyear: 2021,
            pkFundDonorsIdList: [...selectedDonors],
            isSelectAll: true,
            isAnd: true,
            filterCriteria: {},
        };
        // dispatch(donorsBulkDelete({ ...payload }));
        dispatch(donorBulkSoftDelete({ ...payload }));
    };

    useEffect(() => {
        if (donorItems?.content) {
            setInitialVal(getValues());
            setDonorList([...donorItems?.content]);
            setselectedDonors([]);
            toggleCheckbox(false);
        }
        //eslint-disable-next-line
    }, [id, donorItems]);

    useEffect(() => {
        if (id) reset();
        //eslint-disable-next-line
    }, [window?.location?.pathname]);

    useEffect(() => {
        let subscription = true;
        async function compareValues() {
            const checkValues = await checkPromptValues(initialVal, values);
            if (subscription) {
                setIsWhen(checkValues);
            }
        }
        compareValues();
        return () => (subscription = false);
    }, [initialVal, values]);

    const onSubmit = (data) => {
        if (props?.someFieldsShouldHideOrChangeForCrm) {
            props?.onSubmit(data, fetch);
        } else {
            data.fkFundId = id;
            data?.colFundReferenceNo1 && (data.colFundReferenceNo = data.colFundReferenceNo1);
            data?.colFundReferenceNo2 && (data.colFundReferenceNo = data.colFundReferenceNo2);
            data?.colFpReportYearFinance && (data.colFpReportYear = data.colFpReportYearFinance);
            data?.colFpReportYear && (data.colFpReportYear = data.colFpReportYear);
            data?.colFpReportYear1 && (data.colFpReportYear = data.colFpReportYear1);
            data?.colFpReportYear2 && (data.colFpReportYear = data.colFpReportYear2);
            data?.colFpDistributionsFy1 === '' && (data.colFpDistributionsFy1 = null);
            data?.colFpEndingValuePriorYearFy2 === '' && (data.colFpEndingValuePriorYearFy2 = null);
            data?.colFpDistributionsFy4 === '' && (data.colFpDistributionsFy4 = null);
            data?.colFy2PercentageGrowth === '' && (data.colFy2PercentageGrowth = null);
            data?.colFpDistributionsFy3 === '' && (data.colFpDistributionsFy3 = null);
            data?.colFy4PercentageGrowth === '' && (data.colFy4PercentageGrowth = null);
            data?.colFpEndingValuePriorYearFy1 === '' && (data.colFpEndingValuePriorYearFy1 = null);
            data?.colFpEndingValuePriorYearFy3 === '' && (data.colFpEndingValuePriorYearFy3 = null);
            data?.colFy3PercentageGrowth === '' && (data.colFy3PercentageGrowth = null);
            data?.colFpEndingValuePriorYearFy4 === '' && (data.colFpEndingValuePriorYearFy4 = null);
            data?.colFy1PercentageGrowth === '' && (data.colFy1PercentageGrowth = null);
            data?.colFpDistributionsFy2 === '' && (data.colFpDistributionsFy2 = null);
            data?.colFpTotalGifts === '' && (data.colFpTotalGifts = null);

            data?.colFpProjectedDistributionFy1 === '' && (data.colFpProjectedDistributionFy1 = null);
            data?.colFpBeginningValueFy0 === '' && (data.colFpBeginningValueFy0 = null);
            data?.colFpBeginningValueFy1 === '' && (data.colFpBeginningValueFy1 = null);
            data?.colFpBeginningValueFy2 === '' && (data.colFpBeginningValueFy2 = null);
            data?.colFpBeginningValueFy3 === '' && (data.colFpBeginningValueFy3 = null);
            data?.colFpBeginningValueFy4 === '' && (data.colFpBeginningValueFy4 = null);
            data?.colFpGiftsFy0 === '' && (data.colFpGiftsFy0 = null);
            data?.colFpGiftsFy1 === '' && (data.colFpGiftsFy1 = null);
            data?.colFpGiftsFy2 === '' && (data.colFpGiftsFy2 = null);
            data?.colFpGiftsFy3 === '' && (data.colFpGiftsFy3 = null);
            data?.colFpGiftsFy4 === '' && (data.colFpGiftsFy4 = null);
            data?.colCurrentUseGiftsFy0 === '' && (data.colCurrentUseGiftsFy0 = null);
            data?.colFpMatchFy0 === '' && (data.colFpMatchFy0 = null);
            data?.colFpMatchNameFy0 === '' && (data.colFpMatchNameFy0 = null);
            data?.colFpGainsLossesFy0 === '' && (data.colFpGainsLossesFy0 = null);
            data?.colFpDistributionsFy0 === '' && (data.colFpDistributionsFy0 = null);
            data?.colFpFeesFy0 === '' && (data.colFpFeesFy0 = null);
            data?.colFpPayoutReturnedFy0 === '' && (data.colFpPayoutReturnedFy0 = null);
            data?.colFpPayoutReturnedFy1 === '' && (data.colFpPayoutReturnedFy1 = null);
            data?.colFpPayoutReturnedFy2 === '' && (data.colFpPayoutReturnedFy2 = null);
            data?.colFpPayoutReturnedFy3 === '' && (data.colFpPayoutReturnedFy3 = null);
            data?.colFpPayoutReturnedFy4 === '' && (data.colFpPayoutReturnedFy4 = null);
            data?.colFpOtherTransfersFy0 === '' && (data.colFpOtherTransfersFy0 = null);
            data?.colFpOtherTransfersFy1 === '' && (data.colFpOtherTransfersFy1 = null);
            data?.colFpOtherTransfersFy2 === '' && (data.colFpOtherTransfersFy2 = null);
            data?.colFpOtherTransfersFy3 === '' && (data.colFpOtherTransfersFy3 = null);
            data?.colFpOtherTransfersFy4 === '' && (data.colFpOtherTransfersFy4 = null);
            data?.colFpEndingValueFy0 === '' && (data.colFpEndingValueFy0 = null);
            data?.colFy0PercentageGrowth === '' && (data.colFy0PercentageGrowth = null);
            data?.colFpEndingValuePriorYearFy1 === '' && (data.colFpEndingValuePriorYearFy1 = null);
            data?.colFpEndingValuePriorYearFy2 === '' && (data.colFpEndingValuePriorYearFy2 = null);
            data?.colFpEndingValuePriorYearFy3 === '' && (data.colFpEndingValuePriorYearFy3 = null);
            data?.colFpEndingValuePriorYearFy4 === '' && (data.colFpEndingValuePriorYearFy4 = null);
            data?.colFy1PercentageGrowth === '' && (data.colFy1PercentageGrowth = null);
            data?.colFy2PercentageGrowth === '' && (data.colFy2PercentageGrowth = null);
            data?.colFy3PercentageGrowth === '' && (data.colFy3PercentageGrowth = null);
            data?.colFy4PercentageGrowth === '' && (data.colFy4PercentageGrowth = null);
            data?.colFpDistributionsFy1 === '' && (data.colFpDistributionsFy1 = null);
            data?.colFpDistributionsFy2 === '' && (data.colFpDistributionsFy2 = null);
            data?.colFpDistributionsFy3 === '' && (data.colFpDistributionsFy3 = null);
            data?.colFpDistributionsFy4 === '' && (data.colFpDistributionsFy4 = null);

            data.colFpLastGiftDate = selectedDate ? moment(selectedDate).format('MM/DD/YYYY') : null;

            if (downloadFile?.filename) {
                if (downloadFileName?.text) {
                    let base64data = downloadFile.base64Image?.split(',');
                    data.colDownloadFileName = downloadFileName.text;
                    if (base64data && base64data.length > 1)
                        data.fundPerformanceDownloadsFileBytes = base64data[1].trim();
                }
            } else if (downloadFile === null) {
                data.colDownloadFileName = null;
                data.colDownloadUrl = null;
            }

            setInitialVal(getValues());
            data.colFpLongFooterNote = data?.colFpLongFooterNote?.replaceAll('\n', '');
            data.colFpFooterNote = data?.colFpFooterNote?.replaceAll('\n', '');

            // if (currentTab != 'Donors') {
            if (isUpdate === 'false') {
                dispatch(performanceCreate({ ...data }));
            } else {
                // data.pkFundPerformanceId = details?.data?.pkFundPerformanceId;
                data.pkFundPerformanceId =
                    Number(new URLSearchParams(search).get('pkFundPerformanceId')) ||
                    created?.data?.pkFundPerformanceId;
                dispatch(performanceUpdate({ ...data }));
            }
            // }
        }
    };

    useEffect(() => {
        if (props?.someFieldsShouldHideOrChangeForCrm) props?.onLoad();
    }, []);

    useEffect(() => {
        if (fetch?.content && props?.someFieldsShouldHideOrChangeForCrm) {
            fetch?.content?.forEach((element) => {
                if (element?.colOvrtureFieldName === 'colFpReportYear') {
                    props?.setCRMYear(element?.colCrmFieldName);
                }
                setValue(element?.colOvrtureFieldName, element?.colCrmFieldName);
            });
            crmInlineMandatoryChange(null, fetch, props?.setDisable);
        }
    }, [fetch]);

    const onclickHandler = (event, eventFor) => {
        dispatch(
            assetFolderViewTempDataSet({
                handleActions: handleDownloadActions,
                isImageDisable: true,
                page: 'edit-impact',
            })
        );
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                isHiddenModal: true,
                modalAction: (e) => modalDispatch({ type: modalActions.HIDE_MODAL }),
                modalVariant: 'primary',
                modalDataKey: ['common', 'replaceAssetEditorCommon'],
                data: {
                    handleActions: handleDownloadActions,
                    isImageDisable: true,
                    page: 'edit-impact',
                },
            },
        });
    };

    const convertDownloadFile = (file) => {
        covertFileUrlToByte(file, setDownloadFile, downloadFileName?.text);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    };

    const handleDownloadActions = (data) => {
        try {
            setLoading(true);
            if (data?.icon === 'glyphicon glyphicon-pdf-file' || data?.icon === 'glyphicon glyphicon-doc-file') {
                dispatch(assePreviewDoc({ assetFolderViewId: data?.id }));
            } else {
                dispatch(assePreview({ assetFolderViewId: data?.id }));
            }
            setDownloadFileName(data);
        } catch (error) {}
    };

    const onRemove = (type) => {
        if (type === 'colDownloadFileName') {
            setDownloadFile(null);
        }
    };

    return (
        <>
            {<CustomLoader loader={loading}></CustomLoader>}
            {!props?.someFieldsShouldHideOrChangeForCrm && (
                <Prompt when={isWhen} message={(location) => `Are you sure you want to go to ${location.pathname}`} />
            )}
            <form onSubmit={handleSubmit(onSubmit)}>
                {props?.someFieldsShouldHideOrChangeForCrm && (
                    <div className="button-list mb-1">
                        <ButtonElement
                            name="Upload Custom Column Names"
                            variant="outline-primary"
                            type="button"
                            onClick={() => {
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) =>
                                            modalDispatch({
                                                type: modalActions.CLOSE_MODAL,
                                            }),
                                        modalVariant: 'primary',
                                        modalDataKey: ['systemAdmin', 'crmMappingUpload'],
                                        currentModalId: 'intiator',
                                        hasChildModal: true,
                                        data: {
                                            name: props?.original?.colDescription_,
                                            currentTab: props?.currentTab,
                                            serviceId: props?.original?.pk3pServiceId,
                                        },
                                    },
                                });
                            }}
                        />
                        <ButtonElement
                            name="Download Custom Column Names"
                            variant="outline-primary"
                            type="button"
                            onClick={() => {
                                dispatch(
                                    crmDownloadMappingCC({
                                        type: props?.currentTab,
                                        serviceId: props?.original?.pk3pServiceId,
                                    })
                                );
                            }}
                        />
                    </div>
                )}
                <Card>
                    <Row>
                        <Col>
                            <Card.Body style={{ overflow: 'hidden' }}>
                                <div style={{ display: 'none' }}>
                                    <Col lg={12}>
                                        <Row>
                                            <Col lg={6}>
                                                <Row>
                                                    <Col md={12} className="mb-1">
                                                        <b>Fund Reference Number *</b>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormInput
                                                            type="text"
                                                            onChange={(e) =>
                                                                props?.someFieldsShouldHideOrChangeForCrm &&
                                                                crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                            }
                                                            id="colFundReferenceNo"
                                                            aria-label={
                                                                props?.someFieldsShouldHideOrChangeForCrm
                                                                    ? 'example: CRM Col Name'
                                                                    : 'example: Fund Reference No'
                                                            }
                                                            className="mb-xs-0"
                                                            label={
                                                                props?.someFieldsShouldHideOrChangeForCrm
                                                                    ? 'example: CRM Col Name'
                                                                    : 'example: T01'
                                                            }
                                                            floatinglabel="false"
                                                            errors={errors}
                                                            register={register}
                                                            readOnly={
                                                                props?.someFieldsShouldHideOrChangeForCrm ? false : true
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg={6}>
                                                <Row>
                                                    <Col md={12} className="mb-1">
                                                        <b>Report Year *</b>
                                                    </Col>
                                                    <Col md={12}>
                                                        <FormInput
                                                            type="text"
                                                            onChange={(e) => {
                                                                if (props?.someFieldsShouldHideOrChangeForCrm) {
                                                                    crmInlineMandatoryChange(
                                                                        e,
                                                                        fetch,
                                                                        props?.setDisable
                                                                    );
                                                                    props?.setCRMYear(e?.target?.value);
                                                                }
                                                            }}
                                                            id={
                                                                props?.someFieldsShouldHideOrChangeForCrm
                                                                    ? 'colFpReportYear'
                                                                    : 'colFpReportYearFinance'
                                                            }
                                                            aria-label={
                                                                props?.someFieldsShouldHideOrChangeForCrm
                                                                    ? 'example: CRM Col Name'
                                                                    : 'example: Fp Report Year Finance'
                                                            }
                                                            className="mb-xs-0"
                                                            label={
                                                                props?.someFieldsShouldHideOrChangeForCrm
                                                                    ? 'example: CRM Col Name'
                                                                    : 'example: 2021'
                                                            }
                                                            floatinglabel="false"
                                                            errors={errors}
                                                            register={register}
                                                            readOnly={
                                                                props?.someFieldsShouldHideOrChangeForCrm ? false : true
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        {props?.someFieldsShouldHideOrChangeForCrm ? (
                                            <Col className="mt-2">
                                                <Row>
                                                    <Col md={7} className="mb-1">
                                                        <b>Last Gift Date</b>
                                                    </Col>
                                                    <Col md={5} className="text-md-end text-sm-start mb-1 custom-font">
                                                        {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                            'Limit: 15 characters'}
                                                    </Col>
                                                </Row>
                                                <Col md={12}>
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFpLastGiftDate"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: mm/dd/yyyy'
                                                        }
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: mm/dd/yyyy'
                                                        }
                                                        errors={errors}
                                                        register={register}
                                                        className="mb-sm-0"
                                                        floatinglabel="false"
                                                        maxLength="15"
                                                    />
                                                </Col>
                                            </Col>
                                        ) : (
                                            <Row className="mt-2">
                                                <Col lg={12} className="mb-1">
                                                    <b> Last Gift Date </b>{' '}
                                                </Col>
                                                <Col md={12}>
                                                    <FloatingLabel
                                                        controlId={id}
                                                        className={customError?.selectedDate ? ' is-invalid' : ''}>
                                                        <HyperDatepicker
                                                            value={selectedDate}
                                                            inputClass={`form-control-light`}
                                                            onChange={(date) => {
                                                                onDateChange(date);
                                                            }}
                                                        />
                                                    </FloatingLabel>
                                                    {customError?.selectedDate && (
                                                        <>
                                                            <Form.Control.Feedback type="invalid">
                                                                {customError?.selectedDate?.message &&
                                                                    customError?.selectedDate?.message}
                                                            </Form.Control.Feedback>
                                                        </>
                                                    )}
                                                </Col>
                                            </Row>
                                        )}
                                        <Row className={props?.someFieldsShouldHideOrChangeForCrm ? 'mt-2' : 'mt-4'}>
                                            <Col md={9} className="mb-1">
                                                <b>Total Gifts</b>
                                            </Col>
                                            <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpTotalGifts"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Total Gifts'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col lg={9} className="mb-1">
                                                <b> Projected Distribution</b>
                                            </Col>
                                            <Col lg={3} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpProjectedDistributionFy1"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Projected Distribution Fy1'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Beginning Value FY-0</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpBeginningValueFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Beginning Value Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Beginning Value FY-1</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpBeginningValueFy1"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Beginning Value Fy1'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Beginning Value FY-2</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpBeginningValueFy2"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Beginning Value Fy2'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Beginning Value FY-3</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpBeginningValueFy3"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Beginning Value Fy3'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Beginning Value FY-4</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpBeginningValueFy4"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Beginning Value Fy4'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Gifts FY-0</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpGiftsFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Gifts Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Gifts FY-1</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpGiftsFy1"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Gifts Fy1'
                                                    }
                                                    className="mb-xs-0 "
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Gifts FY-2</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpGiftsFy2"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Gifts Fy2'
                                                    }
                                                    className="mb-xs-0 "
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Gifts FY-3</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpGiftsFy3"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Gifts Fy3'
                                                    }
                                                    className="mb-xs-0 "
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Gifts FY-4</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpGiftsFy4"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Gifts Fy4'
                                                    }
                                                    className="mb-xs-0 "
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={9} className="mb-1">
                                                <b>Current Use Gifts</b>
                                            </Col>
                                            <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colCurrentUseGiftsFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Current Use Gifts Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Match</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpMatchFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Match Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Match Name</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 40 characters'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type="text"
                                                    onChange={(e) =>
                                                        props?.someFieldsShouldHideOrChangeForCrm &&
                                                        crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                    }
                                                    id="colFpMatchNameFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Match Name Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: Powell Match'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="40"
                                                    register={register}
                                                    errors={errors}
                                                />
                                            </Col>
                                        </Row>

                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Gains/(Losses)</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpGainsLossesFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Gains Losses Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Distributions FY-0</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpDistributionsFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Distributions Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: -3000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Fees</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpFeesFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Fees Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 0'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Payout Returned FY-0</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpPayoutReturnedFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Payout Returned Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 2000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Payout Returned FY-1</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpPayoutReturnedFy1"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Payout Returned Fy1'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 2000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Payout Returned FY-2</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpPayoutReturnedFy2"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Payout Returned Fy2'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 2000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Payout Returned FY-3</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpPayoutReturnedFy3"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Payout Returned Fy3'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 2000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Payout Returned FY-4</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpPayoutReturnedFy4"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Payout Returned Fy4'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 2000'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Other Transfers FY-0</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpOtherTransfersFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Other Transfers Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 0'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Other Transfers FY-1</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpOtherTransfersFy1"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Other Transfers Fy1'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 0'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Other Transfers FY-2</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpOtherTransfersFy2"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Other Transfers Fy2'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 0'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Other Transfers FY-3</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpOtherTransfersFy3"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Other Transfers Fy3'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 0'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Other Transfers FY-4</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpOtherTransfersFy4"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Other Transfers Fy4'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 0'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={6} className="mb-1">
                                                <b>Ending Value</b>
                                            </Col>
                                            <Col md={6} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFpEndingValueFy0"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fp Ending Value Fy0'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 1500'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={9} className="mb-1">
                                                <b>FY-0 Percentage Growth</b>
                                            </Col>
                                            <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                            </Col>
                                            <Col md={12}>
                                                <FormInput
                                                    type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                    id="colFy0PercentageGrowth"
                                                    aria-label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'Fy0 Percentage Growth'
                                                    }
                                                    className="mb-xs-0"
                                                    label={
                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                            ? 'example: CRM Col Name'
                                                            : 'example: 4.20'
                                                    }
                                                    floatinglabel="false"
                                                    maxLength="15"
                                                    register={register}
                                                    errors={errors}
                                                    negative="true"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="mt-2">
                                            <Col md={9} className="mb-1">
                                                <b>Footnotes(s) </b>
                                            </Col>
                                            <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 200 characters'}
                                            </Col>
                                            <Col md={12}>
                                                {props?.someFieldsShouldHideOrChangeForCrm ? (
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFpFooterNote"
                                                        className="mb-xs-0"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: this is sample text.'
                                                        }
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'Footnotes(s)'
                                                        }
                                                        floatinglabel="false"
                                                        errors={errors}
                                                        register={register}
                                                        maxLength="200"
                                                    />
                                                ) : (
                                                    <Controller
                                                        control={control}
                                                        name="colFpFooterNote"
                                                        render={({ field }) =>
                                                            isdelayed && (
                                                                <TinyMceTextEditor
                                                                    disableEnter={false}
                                                                    maxLength="200"
                                                                    className="editor-height"
                                                                    id="colFpFooterNote"
                                                                    value={field?.value}
                                                                    setValue={(val, id) => setValue(id, val)}
                                                                />
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        <Row className={props?.someFieldsShouldHideOrChangeForCrm ? 'mt-2' : 'mt-4'}>
                                            <Col md={9} className="mb-1">
                                                <b>Long Footnote </b>
                                            </Col>
                                            <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                                {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 400 characters'}
                                            </Col>
                                            <Col md={12}>
                                                {props?.someFieldsShouldHideOrChangeForCrm ? (
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFpLongFooterNote"
                                                        className="mb-xs-0"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: this is sample text.'
                                                        }
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'Long Footnote'
                                                        }
                                                        floatinglabel="false"
                                                        errors={errors}
                                                        register={register}
                                                        maxLength="400"
                                                    />
                                                ) : (
                                                    <Controller
                                                        control={control}
                                                        name="colFpLongFooterNote"
                                                        render={({ field }) =>
                                                            isdelayed && (
                                                                <TinyMceTextEditor
                                                                    maxLength="400"
                                                                    className="editor-height"
                                                                    id="colFpLongFooterNote"
                                                                    value={field?.value}
                                                                    setValue={(val, id) => setValue(id, val)}
                                                                />
                                                            )
                                                        }
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                        {!props?.someFieldsShouldHideOrChangeForCrm && (
                                            <div className="button-list mt-4">
                                                <ButtonElement
                                                    name="Preview"
                                                    variant="outline-primary"
                                                    onClick={() => {
                                                        modalDispatch({
                                                            type: modalActions.OPEN_MODAL,
                                                            payload: {
                                                                modalAction: (e) =>
                                                                    modalDispatch({
                                                                        type: modalActions.CLOSE_MODAL,
                                                                    }),
                                                                modalVariant: 'primary',
                                                                modalDataKey: ['reportData', 'fundPreview'],
                                                                data: {
                                                                    cancelBtnDisabled: true,
                                                                    type: 'fund-performance-preview',
                                                                    fromPage: 'fund_soa',
                                                                    id: id,
                                                                    reportYear: reportYear,
                                                                },
                                                            },
                                                        });
                                                    }}
                                                    disabled={isUpdate === 'false' ? true : false}
                                                />
                                            </div>
                                        )}
                                    </Col>
                                </div>
                                <div style={{ display: 'none' }}>
                                    <Row>
                                        <Col lg={6}>
                                            <Row>
                                                <Col md={12} className="mb-1">
                                                    <b>Fund Reference Number *</b>
                                                </Col>
                                                <Col md={12}>
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFundReferenceNo1"
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'colFundReferenceNo1'
                                                        }
                                                        className="mb-xs-0"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: T01'
                                                        }
                                                        floatinglabel="false"
                                                        errors={errors}
                                                        register={register}
                                                        //value={colFundReferenceNo}
                                                        readOnly={true}
                                                        value={getValues('colFundReferenceNo')}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6}>
                                            <Row>
                                                <Col md={12} className="mb-1">
                                                    <b>Report Year *</b>
                                                </Col>
                                                <Col md={12}>
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFpReportYearFinance1"
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'colFpReportYearFinance1'
                                                        }
                                                        className="mb-xs-0"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: 2021'
                                                        }
                                                        floatinglabel="false"
                                                        errors={errors}
                                                        register={register}
                                                        //value={reportYear}
                                                        value={
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            getValues('colFpReportYear')
                                                        }
                                                        readOnly={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-1 Ending Value/Prior Year Gift</b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpEndingValuePriorYearFy1"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpEndingValuePriorYearFy1'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 28000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-2 Ending Value/Prior Year Gift </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpEndingValuePriorYearFy2"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpEndingValuePriorYearFy2'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 28000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-3 Ending Value/Prior Year Gift </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpEndingValuePriorYearFy3"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpEndingValuePriorYearFy3'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 28000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-4 Ending Value/Prior Year Gift </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpEndingValuePriorYearFy4"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpEndingValuePriorYearFy4'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 28000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-1 Percentage Growth </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFy1PercentageGrowth"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFy1PercentageGrowth'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 4.20'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-2 Percentage Growth </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFy2PercentageGrowth"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFy2PercentageGrowth'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 4.20'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-3 Percentage Growth </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFy3PercentageGrowth"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFy3PercentageGrowth'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 4.20'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>FY-4 Percentage Growth </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFy4PercentageGrowth"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFy4PercentageGrowth'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: 4.20'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    {/* <div className="button-list mt-2">
                                                <ButtonElement
                                                    name="Save"
                                                    variant="primary"
                                                    type="submit"
                                                    link="/"
                                                    onClick={() => setIsEdit(true)}
                                                />
                                                <ButtonElement
                                                    name="Save & Close"
                                                    variant="outline-primary"
                                                    type="submit"
                                                    // link="/"
                                                    onClick={() => setIsEdit(false)}
                                                />
                                                <ButtonElement
                                                    name="Delete"
                                                    variant="danger"
                                                    type="button"
                                                    disabled={isUpdate === 'true' ? false : true}
                                                    onClick={() =>
                                                        modalDispatch({
                                                            type: modalActions.OPEN_MODAL,
                                                            payload: {
                                                                modalAction: (e) => handleDeleteFundPerformance(),
                                                                modalVariant: 'danger',
                                                                modalDataKey: ['reportData', 'deleteFunds'],
                                                                data: {
                                                                    type: 'fund-performance-time',
                                                                },
                                                            },
                                                        })
                                                    }
                                                />
                                                <ButtonElement
                                                    name="Cancel"
                                                    variant="outline-danger"
                                                    onClick={() => {
                                                        setRetainHistory();
                                                        history.push(COMMON_PATH.REPORT_DATA);
                                                    }}
                                                />
                                            </div> */}
                                </div>
                                <div style={{ display: 'none' }}>
                                    <Row>
                                        <Col lg={6}>
                                            <Row>
                                                <Col md={12} className="mb-1">
                                                    <b>Fund Reference Number *</b>
                                                </Col>
                                                <Col md={12}>
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFundReferenceNo2"
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'colFundReferenceNo2'
                                                        }
                                                        className="mb-xs-0"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: T01'
                                                        }
                                                        floatinglabel="false"
                                                        errors={errors}
                                                        register={register}
                                                        //value={colFundReferenceNo}
                                                        value={getValues('colFundReferenceNo')}
                                                        readOnly={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg={6}>
                                            <Row>
                                                <Col md={12} className="mb-1">
                                                    <b>Report Year *</b>
                                                </Col>
                                                <Col md={12}>
                                                    <FormInput
                                                        type="text"
                                                        onChange={(e) =>
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            crmInlineMandatoryChange(e, fetch, props?.setDisable)
                                                        }
                                                        id="colFpReportYearFinance2"
                                                        aria-label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'colFpReportYear'
                                                        }
                                                        className="mb-xs-0"
                                                        label={
                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                ? 'example: CRM Col Name'
                                                                : 'example: 2021'
                                                        }
                                                        floatinglabel="false"
                                                        errors={errors}
                                                        register={register}
                                                        //value={reportYear}
                                                        value={
                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                            getValues('colFpReportYear')
                                                        }
                                                        readOnly={true}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>Distributions FY-1 </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpDistributionsFy1"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpDistributionsFy1'
                                                }
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: -3000'
                                                }
                                                floatinglabel="false"
                                                className="mb-xs-0"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>Distributions FY-2 </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpDistributionsFy2"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpDistributionsFy2'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: -3000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>Distributions FY-3 </b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpDistributionsFy3"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpDistributionsFy3'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: -3000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col md={9} className="mb-1">
                                            <b>Distributions FY-4</b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            {!props?.someFieldsShouldHideOrChangeForCrm && 'Limit: 15 numerals'}
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type={props?.someFieldsShouldHideOrChangeForCrm ? 'text' : 'number'}
                                                id="colFpDistributionsFy4"
                                                aria-label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'colFpDistributionsFy4'
                                                }
                                                className="mb-xs-0"
                                                label={
                                                    props?.someFieldsShouldHideOrChangeForCrm
                                                        ? 'example: CRM Col Name'
                                                        : 'example: -3000'
                                                }
                                                floatinglabel="false"
                                                maxLength="15"
                                                register={register}
                                                errors={errors}
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                    {/* <div className="button-list mt-2">
                                                <ButtonElement
                                                    name="Save"
                                                    variant="primary"
                                                    type="submit"
                                                    link="/"
                                                    onClick={() => setIsEdit(true)}
                                                />
                                                <ButtonElement
                                                    name="Save & Close"
                                                    variant="outline-primary"
                                                    type="submit"
                                                    // link="/"
                                                    onClick={() => setIsEdit(false)}
                                                />
                                                <ButtonElement
                                                    name="Delete"
                                                    variant="danger"
                                                    type="button"
                                                    disabled={isUpdate === 'true' ? false : true}
                                                    onClick={() =>
                                                        modalDispatch({
                                                            type: modalActions.OPEN_MODAL,
                                                            payload: {
                                                                modalAction: (e) => handleDeleteFundPerformance(),
                                                                modalVariant: 'danger',
                                                                modalDataKey: ['reportData', 'deleteFunds'],
                                                                data: {
                                                                    type: 'fund-distribution-time',
                                                                },
                                                            },
                                                        })
                                                    }
                                                />
                                                <ButtonElement
                                                    name="Cancel"
                                                    variant="outline-danger"
                                                    onClick={() => {
                                                        setRetainHistory();
                                                        history.push(COMMON_PATH.REPORT_DATA);
                                                    }}
                                                />
                                            </div> */}
                                </div>
                                {!props?.someFieldsShouldHideOrChangeForCrm && (
                                    <div style={{ display: 'none' }}>
                                        <Col lg={6}>
                                            <label>
                                                <b>Download</b>
                                            </label>
                                            {downloadFile?.filename ? (
                                                <FileInput
                                                    name="colDownloadFileName"
                                                    placeholder="colDownloadFileName"
                                                    errors={errors}
                                                    register={register}
                                                    className={'mb-3'}
                                                    defaultValue={downloadFile.filename}
                                                    accept=".vcf"
                                                    buttonValue="Delete"
                                                    actionHander={() => onRemove('colDownloadFileName')}
                                                />
                                            ) : (
                                                <FileInput
                                                    name="colDownloadFileName"
                                                    placeholder="colDownloadFileName"
                                                    errors={errors}
                                                    register={register}
                                                    className={'mb-3'}
                                                    onclickHandler={(e) => onclickHandler(e, 'Download')}
                                                    defaultValue={'No file chosen'}
                                                    buttonValue="onClickModal"
                                                />
                                            )}
                                        </Col>
                                        {/* <div className="button-list mt-4">
                                                <ButtonElement
                                                    name="Save"
                                                    variant="primary"
                                                    type="submit"
                                                    link="/"
                                                    onClick={() => setIsEdit(true)}
                                                />
                                                <ButtonElement
                                                    name="Save & Close"
                                                    variant="outline-primary"
                                                    type="submit"
                                                    // link="/"
                                                    onClick={() => setIsEdit(false)}
                                                />
                                                <ButtonElement
                                                    name="Delete"
                                                    variant="danger"
                                                    type="button"
                                                    disabled={isUpdate === 'true' ? false : true}
                                                    onClick={() =>
                                                        modalDispatch({
                                                            type: modalActions.OPEN_MODAL,
                                                            payload: {
                                                                modalAction: (e) => handleDeleteFundPerformance(),
                                                                modalVariant: 'danger',
                                                                modalDataKey: ['reportData', 'deleteFunds'],
                                                                data: {
                                                                    type: 'fund-distribution-time',
                                                                },
                                                            },
                                                        })
                                                    }
                                                />
                                                <ButtonElement
                                                    name="Cancel"
                                                    variant="outline-danger"
                                                    onClick={() => {
                                                        setRetainHistory();
                                                        history.push(COMMON_PATH.REPORT_DATA);
                                                    }}
                                                />
                                            </div> */}
                                    </div>
                                )}
                                {props?.someFieldsShouldHideOrChangeForCrm ? (
                                    <div>
                                        <Col>
                                            <Row className="mt-2 border-bottom3">
                                                <Col md={12} className="donor-list">
                                                    <Row>
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col md={12} className="mb-1">
                                                                    <b>Fund Reference Number *</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        id="colFundReferenceNo"
                                                                        aria-label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: Fund Reference No'
                                                                        }
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: T01'
                                                                        }
                                                                        floatinglabel="false"
                                                                        errors={errors}
                                                                        register={register}
                                                                        readOnly={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? false
                                                                                : true
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col md={12} className="mb-1">
                                                                    <b>Report Year *</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        id="colFdReportYear"
                                                                        aria-label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: Fp Report Year Finance'
                                                                        }
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: 2021'
                                                                        }
                                                                        floatinglabel="false"
                                                                        errors={errors}
                                                                        register={register}
                                                                        readOnly={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? false
                                                                                : true
                                                                        }
                                                                        disabled={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? true
                                                                                : false
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={12} className="mb-1">
                                                                    <b>Donor - Record Reference Number *</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        register={register}
                                                                        id="colRecordReferenceNo"
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'ACC05'
                                                                        }
                                                                        floatinglabel="false"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* <Col md={6}>
                                                            <Row>
                                                                <Col md={12} className="mb-1">
                                                                    <b>Donor - Record Name *</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        register={register}
                                                                        id="colRecordFirstName"
                                                                        className="mb-xs-0"
                                                                        label="ACC05 ttee A. McCormick"
                                                                        floatinglabel="false"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col> */}
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={9} className="mb-1">
                                                                    <b>Donor - Amount </b>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    className="text-md-end text-sm-start mb-1 custom-font">
                                                                    {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                        'Limit: 15 numerals'}
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        register={register}
                                                                        id="colFdAmount"
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: 28000'
                                                                        }
                                                                        floatinglabel="false"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={9} className="mb-1">
                                                                    <b>Donor - Date Of Last Gift </b>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    className="text-md-end text-sm-start mb-1 custom-font">
                                                                    {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                        'Limit: 15 numerals'}
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        register={register}
                                                                        id="colFdDateOfLastGift"
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: 12/30/17'
                                                                        }
                                                                        floatinglabel="false"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={9} className="mb-1">
                                                                    <b>Donor - Total Amount Given To Fund </b>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    className="text-md-end text-sm-start mb-1 custom-font">
                                                                    {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                        'Limit: 15 numerals'}
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        register={register}
                                                                        id="colFdTotalAmountGivenToFund"
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: 28000'
                                                                        }
                                                                        floatinglabel="false"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col md={12}>
                                                            <Row>
                                                                <Col md={9} className="mb-1">
                                                                    <b>Donor - Match FY </b>
                                                                </Col>
                                                                <Col
                                                                    md={3}
                                                                    className="text-md-end text-sm-start mb-1 custom-font">
                                                                    {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                        'Limit: 15 numerals'}
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        register={register}
                                                                        id="colFdMatchFy0"
                                                                        className="mb-xs-0"
                                                                        label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'example: 28000'
                                                                        }
                                                                        floatinglabel="false"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </div>
                                ) : (
                                    <div eventKey="Donors">
                                        <div className="donors-tab">
                                            <Row className="border-bottom3">
                                                <Col md={1} className="checkbox">
                                                    <FormInput
                                                        type="checkbox"
                                                        id="addDonor"
                                                        aria-label="addDonorCheckbox"
                                                        className="mb-2"
                                                        onChange={(e) => {
                                                            let lst = [];
                                                            if (e.target.checked) {
                                                                lst = donorList
                                                                    ? donorList?.map((d) => d.pkFundDonorsId)
                                                                    : [];
                                                            }
                                                            setselectedDonors(lst);
                                                        }}
                                                    />
                                                </Col>
                                                <Col md={11} className="donor-list">
                                                    <Row>
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col md={12} className="mb-1">
                                                                    <b>Fund Reference Number *</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        id="colFundReferenceNo3"
                                                                        aria-label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'colFundReferenceNo3'
                                                                        }
                                                                        className="mb-xs-0"
                                                                        floatinglabel="false"
                                                                        value={colFundReferenceNo}
                                                                        readOnly
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={6}>
                                                            <Row>
                                                                <Col md={12} className="mb-1">
                                                                    <b>Report Year *</b>
                                                                </Col>
                                                                <Col md={12}>
                                                                    <FormInput
                                                                        type="text"
                                                                        onChange={(e) =>
                                                                            props?.someFieldsShouldHideOrChangeForCrm &&
                                                                            crmInlineMandatoryChange(
                                                                                e,
                                                                                fetch,
                                                                                props?.setDisable
                                                                            )
                                                                        }
                                                                        id="colFdReportYear"
                                                                        aria-label={
                                                                            props?.someFieldsShouldHideOrChangeForCrm
                                                                                ? 'example: CRM Col Name'
                                                                                : 'colFdReportYear'
                                                                        }
                                                                        className="mb-xs-0"
                                                                        floatinglabel="false"
                                                                        errors={errors}
                                                                        register={register}
                                                                        value={reportYear}
                                                                        readOnly
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            {donorList?.map((d, i) => {
                                                return (
                                                    <Col>
                                                        <Row className="mt-2 border-bottom3">
                                                            <Col md={1} className="checkbox">
                                                                <FormInput
                                                                    type="checkbox"
                                                                    id="addDonor"
                                                                    className="mb-2"
                                                                    checked={
                                                                        selectedDonors?.filter((a) => {
                                                                            return a === Number(d.pkFundDonorsId);
                                                                        })?.length > 0
                                                                    }
                                                                    onChange={(e) => {
                                                                        let ids = [...selectedDonors];
                                                                        const hasChecked = ids?.filter((a) => {
                                                                            return a === Number(e.target.value);
                                                                        });
                                                                        if (hasChecked?.length > 0) {
                                                                            ids = ids?.filter((a) => {
                                                                                return a !== Number(e.target.value);
                                                                            });
                                                                        } else {
                                                                            ids.push(parseInt(e.target.value));
                                                                        }

                                                                        const list = ids.filter(function (
                                                                            value,
                                                                            index,
                                                                            array
                                                                        ) {
                                                                            return array.indexOf(value) === index;
                                                                        });
                                                                        setValue('selectedDonors', list?.length);
                                                                        setselectedDonors(list);
                                                                    }}
                                                                    value={d.pkFundDonorsId}
                                                                />
                                                            </Col>
                                                            <Col md={11} className="donor-list">
                                                                <Row>
                                                                    <Col md={6}>
                                                                        <Row>
                                                                            <Col md={12} className="mb-1">
                                                                                <b>
                                                                                    Donor {i + 1} - Record Reference
                                                                                    Number *
                                                                                </b>
                                                                                <ButtonElement
                                                                                    icon={
                                                                                        <i
                                                                                            className={
                                                                                                'icon-large mdi change-log-icon font-20 ' +
                                                                                                process.env
                                                                                                    .REACT_APP_CHANGE_LOG_ICON
                                                                                            }></i>
                                                                                    }
                                                                                    className={
                                                                                        process.env
                                                                                            .REACT_APP_CHANGE_LOG_HIDE ===
                                                                                            true ||
                                                                                        process.env
                                                                                            .REACT_APP_CHANGE_LOG_HIDE ===
                                                                                            'true'
                                                                                            ? 'd-none action-icon hover-primary custom-svg change-log p-0'
                                                                                            : 'action-icon hover-primary custom-svg change-log p-0'
                                                                                    }
                                                                                    tooltip="Change Log"
                                                                                    onClick={() =>
                                                                                        modalDispatch({
                                                                                            type: modalActions.OPEN_MODAL,
                                                                                            payload: {
                                                                                                modalAction: (e) =>
                                                                                                    modalDispatch({
                                                                                                        type: modalActions.CLOSE_MODAL,
                                                                                                    }),
                                                                                                modalVariant: 'primary',
                                                                                                modalDataKey: [
                                                                                                    'changeLog',
                                                                                                    'changeLogCommonModule',
                                                                                                ],
                                                                                                data: {
                                                                                                    changeLogObj: {
                                                                                                        key: `fkFundDonorsId`,
                                                                                                        value: d.pkFundDonorsId,
                                                                                                    },
                                                                                                },
                                                                                            },
                                                                                        })
                                                                                    }
                                                                                />
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <FormInput
                                                                                    type="text"
                                                                                    onChange={(e) =>
                                                                                        props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                        crmInlineMandatoryChange(
                                                                                            e,
                                                                                            fetch,
                                                                                            props?.setDisable
                                                                                        )
                                                                                    }
                                                                                    id="colRecordReferenceNo"
                                                                                    className="mb-xs-0"
                                                                                    label={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'example: CRM Col Name'
                                                                                            : 'ACC05'
                                                                                    }
                                                                                    floatinglabel="false"
                                                                                    value={
                                                                                        d?.tblRecords
                                                                                            ?.colRecordReferenceNo
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={6}>
                                                                        <Row>
                                                                            <Col md={12} className="mb-1">
                                                                                <b>Donor {i + 1} - Record Name *</b>
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <FormInput
                                                                                    type="text"
                                                                                    onChange={(e) =>
                                                                                        props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                        crmInlineMandatoryChange(
                                                                                            e,
                                                                                            fetch,
                                                                                            props?.setDisable
                                                                                        )
                                                                                    }
                                                                                    id="colRecordFirstName"
                                                                                    className="mb-xs-0"
                                                                                    label={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'example: CRM Col Name'
                                                                                            : 'ACC05 ttee A. McCormick'
                                                                                    }
                                                                                    floatinglabel="false"
                                                                                    value={
                                                                                        d?.tblRecords?.colRecordFullName
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={9} className="mb-1">
                                                                                <b>Donor {i + 1} - Amount </b>
                                                                            </Col>
                                                                            <Col
                                                                                md={3}
                                                                                className="text-md-end text-sm-start mb-1 custom-font">
                                                                                {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                    'Limit: 15 numerals'}
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <FormInput
                                                                                    type={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'text'
                                                                                            : 'number'
                                                                                    }
                                                                                    id="colFdAmount"
                                                                                    className="mb-xs-0"
                                                                                    label={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'example: CRM Col Name'
                                                                                            : 'example: 28000'
                                                                                    }
                                                                                    floatinglabel="false"
                                                                                    value={d?.colFdAmount || ''}
                                                                                    readOnly
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={9} className="mb-1">
                                                                                <b>
                                                                                    Donor {i + 1} - Date Of Last Gift{' '}
                                                                                </b>
                                                                            </Col>
                                                                            <Col
                                                                                md={3}
                                                                                className="text-md-end text-sm-start mb-1 custom-font">
                                                                                {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                    'Limit: 15 numerals'}
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <FormInput
                                                                                    type="text"
                                                                                    onChange={(e) =>
                                                                                        props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                        crmInlineMandatoryChange(
                                                                                            e,
                                                                                            fetch,
                                                                                            props?.setDisable
                                                                                        )
                                                                                    }
                                                                                    id="colFdDateOfLastGift"
                                                                                    className="mb-xs-0"
                                                                                    label={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'example: CRM Col Name'
                                                                                            : 'example: 12/30/17'
                                                                                    }
                                                                                    floatinglabel="false"
                                                                                    value={d?.colFdDateOfLastGift || ''}
                                                                                    readOnly
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={9} className="mb-1">
                                                                                <b>
                                                                                    Donor {i + 1} - Total Amount Given
                                                                                    To Fund{' '}
                                                                                </b>
                                                                            </Col>
                                                                            <Col
                                                                                md={3}
                                                                                className="text-md-end text-sm-start mb-1 custom-font">
                                                                                {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                    'Limit: 15 numerals'}
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <FormInput
                                                                                    type={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'text'
                                                                                            : 'number'
                                                                                    }
                                                                                    id="colTotalAmountGivenToFund"
                                                                                    className="mb-xs-0"
                                                                                    label={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'example: CRM Col Name'
                                                                                            : 'example: 28000'
                                                                                    }
                                                                                    floatinglabel="false"
                                                                                    value={
                                                                                        d?.colFdTotalAmountGivenToFund ||
                                                                                        ''
                                                                                    }
                                                                                    readOnly
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md={12}>
                                                                        <Row>
                                                                            <Col md={9} className="mb-1">
                                                                                <b>Donor {i + 1} - Match FY </b>
                                                                            </Col>
                                                                            <Col
                                                                                md={3}
                                                                                className="text-md-end text-sm-start mb-1 custom-font">
                                                                                {!props?.someFieldsShouldHideOrChangeForCrm &&
                                                                                    'Limit: 15 numerals'}
                                                                            </Col>
                                                                            <Col md={12}>
                                                                                <FormInput
                                                                                    type={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'text'
                                                                                            : 'number'
                                                                                    }
                                                                                    id="colFdMatchFy0"
                                                                                    className="mb-xs-0"
                                                                                    label={
                                                                                        props?.someFieldsShouldHideOrChangeForCrm
                                                                                            ? 'example: CRM Col Name'
                                                                                            : 'example: 28000'
                                                                                    }
                                                                                    floatinglabel="false"
                                                                                    value={d?.colFdMatchFy0 || ''}
                                                                                    readOnly
                                                                                />
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>

                {props?.someFieldsShouldHideOrChangeForCrm ? (
                    <div className="button-list">
                        <ButtonElement name="Save" variant="outline-primary" type="submit" disabled={props?.disable} />
                        <ButtonElement
                            name="Test"
                            variant="outline-primary"
                            type="button"
                            onClick={() => props?.onTest()}
                        />
                    </div>
                ) : (
                    <div className="button-list mt-2">
                        <ButtonElement
                            name="Add New Donor"
                            variant="primary"
                            type="button"
                            onClick={() =>
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) =>
                                            modalDispatch({
                                                type: modalActions.CLOSE_MODAL,
                                            }),
                                        modalVariant: 'primary',
                                        modalDataKey: ['reportData', 'addDonor'],
                                        data: {
                                            title: 'Add New Donor',
                                            type: 'add',
                                            pkFundId: id,
                                            colFdReportYear: reportYear,
                                            donorList: donorList,
                                        },
                                    },
                                })
                            }
                        />
                        <ButtonElement
                            name="Edit Donor"
                            variant="primary"
                            type="button"
                            disabled={selectedDonors.length === 1 ? false : true}
                            onClick={() => {
                                if (selectedDonors.length === 1) {
                                    modalDispatch({
                                        type: modalActions.OPEN_MODAL,
                                        payload: {
                                            modalAction: (e) =>
                                                modalDispatch({
                                                    type: modalActions.CLOSE_MODAL,
                                                }),
                                            modalVariant: 'primary',
                                            modalDataKey: ['reportData', 'addDonor'],
                                            data: {
                                                title: 'Edit Donor',
                                                type: 'edit',
                                                pkFundId: id,
                                                colFdReportYear: reportYear,
                                                pkFundDonorsId: selectedDonors,
                                            },
                                        },
                                    });
                                }
                            }}
                        />
                        <ButtonElement
                            name="View Linked Records"
                            variant="outline-primary"
                            onClick={() =>
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) => console.log(''),
                                        modalVariant: 'primary',
                                        modalDataKey: ['reportData', 'viewLinkedRecords'],
                                        data: {
                                            pkFundId: id,
                                        },
                                    },
                                })
                            }
                        />
                        <ButtonElement
                            name="Delete Donor(s)"
                            variant="danger"
                            type="button"
                            disabled={!selectedDonors?.length > 0}
                            onClick={() =>
                                modalDispatch({
                                    type: modalActions.OPEN_MODAL,
                                    payload: {
                                        modalAction: (e) => {
                                            deleteBtnHandler();
                                        },
                                        modalVariant: 'danger',
                                        modalDataKey: ['reportData', 'deleteDonor'],
                                    },
                                })
                            }
                        />
                        <ButtonElement
                            name="Cancel"
                            variant="outline-danger"
                            onClick={() => {
                                setRetainHistory();
                                history.push(COMMON_PATH.REPORT_DATA);
                            }}
                        />
                    </div>
                )}
            </form>
        </>
    );
};

export default DonorsDetails;
