// @flow
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import {
    printBridgeSitePreview,
    printBridgeReportPreview,
    printBridgeSiteDownload,
    printBridgeReportDownload,
    printBridgeReset,
    printBridgeContentPanelPreview,
    printBridgeContentPanelDownload,
} from '@ovrture/react-redux';
import service from '../../../constants/service.constant';
import { downloadFile } from '../../downloadFile/downloadFile';

const PrintPreview = (props) => {
    const { modalDispatch } = useModalContext();
    const [cpId, setCpId] = useState(null);
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({});
    const { details, sitePreview, downloaded } = useSelector((state) => ({
        sitePreview:
            state?.printBridge?.sitePreview ||
            state?.printBridge?.reportPreview ||
            state?.printBridge?.contentPanelPreview,
        details: state?.auth?.me,
        downloaded:
            state?.printBridge?.siteDownload ||
            state?.printBridge?.reportDownload ||
            state?.printBridge?.contentPanelDownload,
    }));
    const slideNumber =
        document?.querySelector('#rootComponent[slidesecclass-id]')?.getAttribute('slidesecclass-id') &&
        document.querySelector('#rootComponent[slidesecclass-id]').getAttribute('slidesecclass-id');
    useEffect(() => {
        const cpId = document?.querySelector('#' + slideNumber + ' [cpid]')?.getAttribute('cpid')
            ? document.querySelector('#' + slideNumber + ' [cpid]').getAttribute('cpid')
            : document.querySelector('[cpid]').getAttribute('cpid');
        setCpId(cpId);
        let payload = {
            cpHeadeing: props?.data?.panelHeading,
            cpSubHeadeing: props?.data?.panelSubHeading || 'This is Sub Heading',
            cpHtml: document?.querySelector('#' + slideNumber)?.innerHTML
                ? document.querySelector('#' + slideNumber).innerHTML
                : document.querySelector('[cpid]').outerHTML,
            // url: props?.data?.colUrl,
        };
        if (window.location.pathname.includes('report-edit')) {
            payload.reportYear = props?.data?.reportYear;
            (payload.url = props?.data?.colUrl), dispatch(printBridgeReportPreview(payload));
        } else if (window.location.pathname.includes('content-panel-edit')) {
            payload.url = window.location.pathname;
            dispatch(printBridgeContentPanelPreview(payload));
        } else {
            payload.url = props?.data?.colUrl;
            dispatch(printBridgeSitePreview(payload));
        }
    }, []);
    useEffect(() => {
        if (sitePreview?.code === service.success) {
            document.getElementById('previewOnly').innerHTML = sitePreview?.data;
            // Printing Print bridge html on console
            console.log(sitePreview?.data);
        }
    }, [sitePreview]);
    useEffect(() => {
        if (downloaded) {
            let contentdisposition = decodeURIComponent(downloaded?.headers['content-disposition']);
            downloadFile(
                downloaded?.data,
                'application/zip',
                contentdisposition.includes(`filename*=UTF-8' '`)
                    ? `${contentdisposition?.split(`filename*=UTF-8' '`)[1]}`
                    : contentdisposition.split('filename=')[1]
            );
            dispatch(printBridgeReset());
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
                payload: { noActive: true },
            });
        }
    }, [downloaded]);
    const onSubmit = (data) => {
        let payload = {
            param: false,
            data: {
                cpHeadeing: props?.data?.panelHeading,
                cpSubHeadeing: props?.data?.panelSubHeading || 'This is Sub Heading',
                cpHtml: document?.querySelector('#' + slideNumber)?.innerHTML
                    ? document.querySelector('#' + slideNumber).innerHTML
                    : document.querySelector('[cpid]').outerHTML,
                // url: props?.data?.colUrl,
            },
        };
        if (window.location.pathname.includes('report-edit')) {
            (payload.data.url = props?.data?.colUrl), (payload.data.reportYear = props?.data?.reportYear);
            dispatch(printBridgeReportDownload(payload));
        } else if (window.location.pathname.includes('content-panel-edit')) {
            payload.data.cpName = props?.data?.cpName;
            payload.data.url = window.location.pathname;
            dispatch(printBridgeContentPanelDownload(payload));
        } else {
            payload.data.url = props?.data?.colUrl;
            dispatch(printBridgeSiteDownload(payload));
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <iframe className="previewOnly" id="previewOnly" srcDoc={sitePreview?.data}></iframe>
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Download"
                    variant="primary"
                    type="submit"
                    disabled={!sitePreview?.data ? true : false}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        })
                    }
                />
            </div>
        </form>
    );
};

export default PrintPreview;
