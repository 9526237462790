// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import TreeView from '../../../components/treeview';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { assetFolderViewInitialList, assetFolderViewReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { manageTreeData } from '../../../helpers/genericFunction';

const SelectAssetFolderModel = (props) => {
    const [movedPath, setMovedPath] = useState(null);
    const [loading, setLoading] = useState(true);
    const { modalDispatch, modal } = useModalContext();
    const [currentSelectedData, setCurrentSelectedData] = useState(null);
    const [data, setData] = useState([]);
    const [isSelectable, setIsSelectable] = useState(false);
    const dispatch = useDispatch();
    const { initialList, auth } = useSelector((state) => ({
        initialList: state?.assetFolderView?.initialList,
        auth: state?.auth?.me,
    }));

    useEffect(() => {
        setData([]);
        getInitialFolderList();
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        let eleId = document.querySelector('.card-body');
        if (ele && !eleId.children[0].classList.contains('.changeLocation'))
            ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        dispatch(
            assetFolderViewInitialList({
                processId:
                    auth?.role?.name === 'REPORT ADMIN' ||
                    auth?.role?.name === 'CONTENT ADMIN' ||
                    auth?.role?.name === 'BRAND ADMIN'
                        ? 2
                        : '',
                parentId,
            })
        );
    };

    useEffect(() => {
        if (initialList?.data) {
            dispatch(assetFolderViewReset());
            setLoading(false);
            async function createInitialData() {
                await manageTreeData(data);
            }
            if (data.length > 0) {
                //createInitialData(initialList?.data);
                manageTreeData(initialList?.data, setData, data, 'AssetsJstreeRendaring', movedPath, setMovedPath);
            } else {
                initialList?.data?.forEach((el) => {
                    if (props?.data?.page === 'restoreDeletedAssets') {
                        if (el?.text !== 'RECYCLE BIN') {
                            data.push({
                                id: el.id,
                                parent_id: el.id,
                                text: el?.name || el?.text,
                                icon: el.icon,
                                children: [],
                                child: el.child,
                                adminId: el.adminId,
                                assetId: el.assetId,
                                brandId: el.brandId,
                                fkRoleId: el.fkRoleId,
                                fkOrgId: el.fkOrgId,
                                fkPaletteId: el.fkPaletteId,
                                assetRootNodeId: el.assetRootNodeId,
                                userId: el.userId,
                                permissionbyId: el.permissionbyId,
                                canBeDeleted: el.canBeDeleted,
                            });
                        }
                    } else {
                        data.push({
                            id: el.id,
                            parent_id: el.id,
                            text: el?.name || el?.text,
                            icon: el.icon,
                            children: [],
                            child: el.child,
                            adminId: el.adminId,
                            assetId: el.assetId,
                            brandId: el.brandId,
                            fkRoleId: el.fkRoleId,
                            fkOrgId: el.fkOrgId,
                            fkPaletteId: el.fkPaletteId,
                            assetRootNodeId: el.assetRootNodeId,
                            userId: el.userId,
                            permissionbyId: el.permissionbyId,
                            canBeDeleted: el.canBeDeleted,
                        });
                    }
                });
                setData([...data]);
            }
        }
    }, [initialList]);

    // let countTotalJsTreeChildren = 0;
    // const manageTreeData = (list) => {
    //     const initialListData = initialList?.data;
    //     for (let i = 0; i < initialListData.length; i++) {
    //         const el = initialListData[i];
    //         const children = list;
    //         for (let j = 0; j < children.length; j++) {
    //             const d = children[j];
    //             if (d?.state?.disabled) d.state.disabled = false;
    //             if (el?.parentId === d?.id) {
    //                 d.child = false;
    //                 d.children.push({
    //                     id: el.id,
    //                     parent_id: el.parentId,
    //                     text: el?.text || el?.name,
    //                     icon: el.icon,
    //                     children: [],
    //                     child: el.child,
    //                     adminId: el.adminId,
    //                     assetId: el.assetId,
    //                     brandId: el.brandId,
    //                     fkRoleId: el.fkRoleId,
    //                     fkOrgId: el.fkOrgId,
    //                     fkPaletteId: el.fkPaletteId,
    //                     userId: el.userId,
    //                     assetRootNodeId: el.assetRootNodeId,
    //                     permissionbyId: el.permissionbyId,
    //                     canBeDeleted: el.canBeDeleted,
    //                 });
    //                 const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    //                 const ids = initialListData.map((d) => d.id);
    //                 const newList = filterArry.filter((f) => ids.indexOf(f.id) !== -1);
    //                 d.children = [...newList];
    //             } else {
    //                 if (d?.children?.length > 0) {
    //                     manageTreeData(d?.children);
    //                 }
    //             }
    //         }
    //         countTotalJsTreeChildren++;
    //         if (countTotalJsTreeChildren > 5000) {
    //             // countTotalJsTreeChildren = 0;
    //             break;
    //         }
    //     }
    //     if (list[0]?.text === 'SYSTEM CLOUD') {
    //         setData([...list]);
    //     }
    // };

    function handleGetList(e, list) {
        let isEnable;
        if (props?.data?.page === 'restoreDeletedAssets') {
            isEnable =
                list?.node?.original.icon === 'fa-fa-folder-brand-closed' ||
                list?.node?.original.icon === 'mdi mdi-folder' ||
                list?.node?.original.icon === 'mdi mdi-folder-account' ||
                (list?.node?.original.icon === 'mdi mdi-folder-lock' &&
                    (list?.node?.original?.text === 'SITE' || list?.node?.original?.text === 'REPORT'));
        } else {
            isEnable = list?.node?.original.icon === 'mdi mdi-folder';
        }
        setIsSelectable(!isEnable);
        setCurrentSelectedData(list?.node?.original);
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
    }

    const handleSelect = () => props?.data?.handleData(currentSelectedData, props?.data?.original);

    return (
        <Row>
            {loading && (
                <div className="jstree-default">
                    <i className="loading jstree-icon jstree-ocl" />
                    <span className="loading-text">Loading...</span>
                </div>
            )}
            <TreeView data={data} handleChange={handleGetList} />
            <p className="mb-0 mt-1">
                {/* <span className="ms-2 w-auto">Select folder and press spacebar for preview</span> */}
            </p>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Select"
                    variant="outline-primary"
                    type="button"
                    disabled={isSelectable}
                    onClick={() => handleSelect()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </Row>
    );
};

export default SelectAssetFolderModel;
