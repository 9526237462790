import React, { useEffect, useState } from 'react';
import { useTreeViewContext } from '../../contexts/TreeViewContext';
import TreeView from './react-simple-jstree';

const TreeViewList = (props) => {
    const { treeState, treeDispatch } = useTreeViewContext();
    const [data, SetData] = useState(props?.data);
    const [selectedData, setSelectedData] = useState([]);
    useEffect(() => {
        if (props?.data) {
            SetData({
                data: {
                    core: {
                        data: props.data,
                        dblclick_toggle: true,
                        animation: true,
                        themes: {
                            dots: false,
                        },
                    },
                    plugins: ['wholerow'],
                },
            });
        }
    }, [props?.data]);

    const handleChange = (e, list) => {
        setSelectedData(list);
        props.handleChange(e, list);
    };

    return (
        data?.data !== undefined && (
            <TreeView
                treeData={data.data}
                onChange={handleChange}
                treejsClass={props.treejsClass}
                identifier={props?.identifier}
            />
        )
    );
};

export default TreeViewList;
