import React, { createContext, useReducer, useContext } from 'react';
import { CustomReducer, CustomIntialState } from '../reducers/CustomReducer';

const CustomContext = createContext('');

export const CustomProvider = (props) => {
    const [customState, customDispatch] = useReducer(CustomReducer, CustomIntialState);
    let customData = { customState, customDispatch };

    return <CustomContext.Provider value={customData}>{props.children}</CustomContext.Provider>;
};

export function useCustomContext() {
    const context = useContext(CustomContext);
    if (context === undefined) {
        throw new Error('useCustomContext must be used within a CustomProvider');
    }
    return context;
}
