// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// components
import { FormInput } from '../../../../components/form/input';
import Table from '../../../../components/table/Table';
import ButtonElement from '../../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../../constants/table';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import service from '../../../../constants/service.constant';
import {
    impactTypeGetList,
    impactTypeSoftDelete,
    impactTypeUpdate,
    impactTypeReset,
    impactTypeBulkSoftDelete,
} from '@ovrture/react-redux';
import { dateTimeFormat } from '../../../../utility';

/**
 * Modal component for managing impact types.
 * @param {Object} props - The component props.
 * @returns {JSX.Element} - The rendered component.
 */
const ImpactTypeModal = (props) => {
    const [resetSelection, setResetSelection] = useState([]);
    const [selectedValue, setSelectedValue] = useState([]);
    const [isSelectAll, setIsSelectAll] = useState(false);
    const [uncheckedSelection, setUncheckedSelection] = useState([]);
    const { modalDispatch } = useModalContext();
    const [data, setData] = useState([]);
    const [updatedAt, setUpdatedAt] = useState();
    const dispatch = useDispatch();
    const { list, deleted, update, orgFetch, loading } = useSelector((state) => ({
        list: state?.impactType?.list,
        deleted: state?.impactType?.softDelete,
        update: state?.impactType?.updated,
        orgFetch: state?.organization?.fetch,
        loading: state?.impactType?.loading,
    }));

    /**
     * Updates the 'updatedAt' state with the formatted date and time.
     */
    useEffect(() => {
        if (list?.data) {
            list?.data?.forEach((item) => {
                setUpdatedAt(dateTimeFormat(item?.colUpdatedAt));
                item.id = item?.pkImpactTypeId;
            });
        }
    }, [list]);

    /**
     * Updates the 'data' state and triggers the 'impactTypeReset' and 'impactTypeGetList' actions when 'list' or 'update' changes.
     */
    useEffect(() => {
        if (list?.data) setData(list.data);
        if (update) {
            dispatch(impactTypeReset());
            dispatch(impactTypeGetList({ orgId: orgFetch?.data?.id }));
        }
    }, [list, update]);

    /**
     * Triggers the 'impactTypeGetList' action when 'deleted' changes.
     */
    useEffect(() => {
        if (deleted) {
            dispatch(impactTypeGetList({ orgId: orgFetch?.data?.id }));
        }
    }, [deleted]);
    useEffect(() => {
        if (props?.data?.isSuccess === true) {
            dispatch(impactTypeReset());
            dispatch(impactTypeGetList({ orgId: orgFetch?.data?.id }));
        }
    }, [props?.data?.isSuccess]);

    const { register } = useForm({});

    /**
     * Hides the modal.
     */
    const toggle = () => props?.hideModal();

    /**
     * Renders the input field for the 'Type' column in the table.
     * @param {Object} row - The row data.
     * @returns {JSX.Element} - The rendered input field.
     */
    const ImpactType = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Type"
                className="mb-0"
                maxLength="75"
                id={'impactType' + row.id}
                register={register}
                defaultValue={row.original.colImpactName}
                onKeyUp={(e) => (row.original.colImpactName = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    /**
     * Renders the input field for the 'Code' column in the table.
     * @param {Object} row - The row data.
     * @returns {JSX.Element} - The rendered input field.
     */
    const UploaderImpactCode = ({ row }) => {
        return (
            <FormInput
                type="text"
                label="Code"
                maxLength="10"
                className="mb-0"
                id={'uploaderImpactCode' + row.id}
                register={register}
                defaultValue={row.original.colImpactKey}
                onKeyUp={(e) => (row.original.colImpactKey = e.target.value)}
                feedback="hide"
                floatinglabel="false"
            />
        );
    };

    /**
     * Renders the action button for the 'Action' column in the table.
     * @param {Object} row - The row data.
     * @returns {JSX.Element} - The rendered action button.
     */
    const Action = ({ row }) => {
        return (
            <div className="d-flex justify-content-center hstack gap-2">
                <ButtonElement
                    icon={<i className="mdi mdi-delete"></i>}
                    className="action-icon hover-danger"
                    tooltip="Delete"
                    onClick={() =>
                        dispatch(
                            impactTypeSoftDelete({
                                orgId: row.original.fkOrgId,
                                fundImpactTypeId: row.original.pkImpactTypeId,
                            })
                        )
                    }
                />
                <div className="vr"></div>
                <ButtonElement
                    icon={<i className={'mdi icon-large change-log-icon ' + process.env.REACT_APP_CHANGE_LOG_ICON}></i>}
                    className={
                        process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === true ||
                        process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === 'true'
                            ? 'd-none'
                            : 'action-icon hover-primary custom-svg change-log'
                    }
                    tooltip="Change Log"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) =>
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    }),
                                modalVariant: 'primary',
                                modalDataKey: ['changeLog', 'changeLogCommonModulePlatform'],
                                data: {
                                    changeLogObj: {
                                        key: `fkImpactTypeId`,
                                        value: row?.original?.id,
                                        orgId: row.original.fkOrgId,
                                    },
                                },
                            },
                        })
                    }
                />
            </div>
        );
    };

    /**
     * Updates the 'selectedValue' state.
     * @param {Array} d - The selected values.
     */
    const updateSelectedValue = (d) => {
        setTimeout(() => {
            setSelectedValue(d);
        }, 50);
    };

    /**
     * Handles the select all functionality.
     * @param {boolean} val - The select all value.
     */
    const handleSelectAll = (val) => {
        setIsSelectAll(val);
        if (!val) setData([...data]);
    };

    const tableFooterAction = {
        title: 'impact type(s) selected',
        selectedValue: selectedValue,
        action: [
            <ButtonElement
                size="sm"
                name="Delete"
                variant="outline-danger"
                onClick={() => {
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalAction: (e) => {
                                let ids = [];
                                let filterCriteria = {
                                    // name: searchValue,
                                };
                                selectedValue?.forEach((id) => {
                                    ids.push(id.orgId);
                                });
                                dispatch(
                                    impactTypeBulkSoftDelete({
                                        fundImpactTypeIdList: isSelectAll ? [] : ids,
                                        fkOrgId: Number(orgFetch?.data?.id),
                                        isSelectAll,
                                        isAnd: true,
                                        filterCriteria,
                                        idsToSkip: uncheckedSelection?.map((m) => m.id),
                                    })
                                );
                            },
                            modalVariant: 'danger',
                            modalDataKey: ['platformAdmin', 'deleteActionsPA'],
                            data: {
                                name: 'bulkHardDeleteBroadcast',
                                title: 'Delete Impact Type(s)',
                                message: 'Are you sure you want to delete this/these impact type(s)?',
                            },
                        },
                    });
                }}
            />,
        ],
    };

    return (
        <>
            {list?.data?.length > 0 ? (
                <p className="text-end custom-font mb-1">Last Edited: {updatedAt}</p>
            ) : (
                <p className="mb-3"></p>
            )}
            <Card>
                <Table
                    columns={TableColumn.editElent.reportData.impactType([ImpactType, UploaderImpactCode, Action])}
                    data={data}
                    pageSize={list?.data?.size}
                    pageNumber={list?.data?.number}
                    totalElements={list?.data?.totalElements}
                    totalPages={list?.data?.totalPages}
                    paginationDispatch={impactTypeGetList}
                    sizePerPageList={TableColumn.sizePerPageList(data)}
                    isSortable={true}
                    pagination={true}
                    isSearchable={false}
                    isFilter={false}
                    isSelectable={true}
                    loading={loading}
                    theadClass={TableColumn.table_light}
                    tableClass="impact-type-modal"
                    tableParentClass="height-fixed"
                    customPaginationProps={{
                        orgId: orgFetch?.data?.id,
                        sort: ['colImpactName'],
                    }}
                    tableFooterActionProps={tableFooterAction}
                    updateSelectedValue={updateSelectedValue}
                    handleSelectAll={handleSelectAll}
                    isSelectAll={isSelectAll}
                    resetSelection={resetSelection}
                    setResetSelection={setResetSelection}
                    uncheckedSelection={uncheckedSelection}
                    setUncheckedSelection={setUncheckedSelection}
                    searchKey={'searchText'}
                />
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Add New"
                    variant="outline-primary"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) =>
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    }),
                                modalVariant: 'primary',
                                modalDataKey: ['platformAdmin', 'newImpactType'],
                                data: {
                                    title: orgFetch?.data?.name + ' > Report Data > Impact Type ',
                                    fkOrgId: orgFetch?.data?.id,
                                },
                            },
                        })
                    }
                />
                <ButtonElement
                    name="Update"
                    variant="outline-primary"
                    disabled={data?.length <= 0 ? true : false}
                    onClick={() => {
                        data.forEach((item) => {
                            item.orgId = item.fkOrgId;
                        });
                        dispatch(impactTypeUpdate(data));
                    }}
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </>
    );
};

export default ImpactTypeModal;
