export const fileValidation = (f, chooseFile, setCustom) => {
    if (!f) {
        let err = {};
        err[chooseFile] = {
            message: 'No file selected.',
            ref: 'input#' + chooseFile + '.is-invalid.form-control',
            type: 'required',
        };
        setCustom(err);
        return false;
    }
    return true;
};
