import { Col, Row } from 'react-bootstrap';
import { FormInput } from '../../../../components/form/input';
import { useEffect, useState } from 'react';

const NamingField = (props) => {
    const [colNamingFields, setcolNamingFields] = useState(null);

    useEffect(() => {
        if (props?.data?.info?.colNamingFields) {
            setcolNamingFields(JSON.parse(props?.data?.info?.colNamingFields));
        }
    }, [props?.data?.info?.colNamingFields]);

    useEffect(() => {
        if (colNamingFields) {
            props.data.setValue('customLabel', colNamingFields?.customLabel?.value);
            props.data.setValue('crmFieldName', colNamingFields?.crmFieldName?.value);
        }
    }, [colNamingFields]);

    return (
        <>
            <Row>
                <h5 className="text-primary mb-2">NAMING:</h5>
                <Col md={12} className="mb-1 mt-1">
                    <b>{colNamingFields?.customLabel?.label}</b>
                </Col>
                <Col md={6}>
                    <FormInput
                        type={colNamingFields?.customLabel?.type}
                        id="customLabel"
                        className="mb-xs-0"
                        label={colNamingFields?.customLabel?.label}
                        aria-label={colNamingFields?.customLabel?.label}
                        floatinglabel="false"
                        errors={props?.data?.errors}
                        register={props?.data?.register}
                        maxLength={colNamingFields?.customLabel?.max_length}
                    />
                </Col>
                <Col md={12} className="mb-1">
                    <b>{colNamingFields?.crmFieldName?.label}</b>
                </Col>
                <Col md={6}>
                    <FormInput
                        type={colNamingFields?.crmFieldName?.type}
                        id="crmFieldName"
                        className="mb-xs-0"
                        label={colNamingFields?.crmFieldName?.label}
                        aria-label={colNamingFields?.crmFieldName?.label}
                        floatinglabel="false"
                        errors={props?.data?.errors}
                        register={props?.data?.register}
                        maxLength={colNamingFields?.crmFieldName?.max_length}
                    />
                </Col>
            </Row>
        </>
    );
};
export default NamingField;
