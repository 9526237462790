// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { FormInput } from '../../../components/form/input';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const PrintBridgeHeading = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(false);
    const {
        register,
        watch,
        setValue,
        formState: { errors },
    } = useForm({});
    const values = watch();
    const ele = document.getElementById('rootComponent');
    const replacerId = ele.getAttribute('slideSecClass-id');
    // panelHeader
    let panelHeading =
        document
            ?.getElementById(replacerId)
            ?.querySelector('.panelHeader .inner-positioner-header-panel-heading [onblur]')?.innerText ||
        document?.querySelector('.panelHeader .inner-positioner-header-panel-heading [onblur]')?.innerText;

    // Panel sun heading // Stanford
    let panelSubHeading = document
        ?.getElementById(replacerId)
        ?.querySelector('.panelHeader .sub-heading-inner-positioner-header')
        ? document
              ?.getElementById(replacerId)
              ?.querySelector('.panelHeader .sub-heading-inner-positioner-header [onblur]')?.innerText
        : document?.querySelector('.panelHeader .sub-heading-inner-positioner-header [onblur]')?.innerText;

    if (panelHeading?.trim('')?.length === 0 || panelHeading?.trim('')?.length < 0) panelHeading = 'MORE INFORMATION';
    if (panelSubHeading?.trim('')?.length === 0 || panelSubHeading?.trim('')?.length < 0)
        panelSubHeading = 'MORE INFORMATION';
    const warningModalAction = () => {
        modalDispatch({ type: modalActions.CLOSE_MODAL, payload: { noActive: true }});
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) =>
                    modalDispatch({
                        type: modalActions.CLOSE_MODAL,
                    }),
                modalVariant: 'primary',
                modalDataKey: ['editor', 'printPreview'],
                data: {
                    colUrl: props?.data?.colUrl,
                    reportYear: props?.data?.reportYear,
                    panelHeading: values.heading,
                    panelSubHeading: values.subHeading,
                    cpName: props?.data?.cpName,
                },
            },
        });
    };
    const printPreviewAction = () => {
        const cpHtml = document.querySelector('#' + replacerId) || document.querySelector('[cpid]');
        const getAllIframe = cpHtml?.getElementsByTagName('iframe');
        const getAllDocument = cpHtml?.querySelector('img.documentImg');
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: { modalDataKey: ['editor', 'printBridgeHeading'] },
        });
        if (getAllIframe.length > 0 || getAllDocument !== null) {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: (e) => warningModalAction(),
                    modalVariant: 'primary',
                    modalDataKey: ['editor', 'printWarning'],
                    data: {
                        colUrl: props?.data?.colUrl,
                        panelHeading: values.heading,
                        panelSubHeading: values.subHeading,
                        cpName: props?.data?.cpName,
                    },
                },
            });
        } else {
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: (e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        }),
                    modalVariant: 'primary',
                    modalDataKey: ['editor', 'printPreview'],
                    data: {
                        colUrl: props?.data?.colUrl,
                        reportYear: props?.data?.reportYear,
                        panelHeading: values.heading,
                        panelSubHeading: values.subHeading,
                        cpName: props?.data?.cpName,
                    },
                },
            });
        }
    };
    useEffect(() => {
        setValue('heading', panelHeading.toUpperCase());
        setValue('subHeading', panelSubHeading);
        setToggle(panelHeading || panelSubHeading ? true : false);
    }, []);
    const textChangeHandle = (e) => {
        if (values?.heading === e.target.value) {
            setToggle(false);
        } else {
            setValue('heading', e.target.value);
            setToggle(true);
        }
    };
    const textChangeHandleSubHeading = (e) => {
        if (values?.subHeading === e.target.value) {
            setToggle(false);
        } else {
            setValue('subHeading', e.target.value);
            setToggle(true);
        }
    };

    return (
        <>
            <p>
                This function will let you print this Content Panel. Before printing, please provide the heading you
                would like to use.
            </p>
            <Row className="printBridgeHeading">
                <Col lg={12}>
                    <FormInput
                        type="text"
                        id="heading"
                        label="Heading"
                        maxLength="80"
                        errors={errors}
                        register={register}
                        onChange={(e) => textChangeHandle(e)}
                    />
                    {panelSubHeading && (
                        <FormInput
                            type="text"
                            id="subHeading"
                            label="Sub Heading"
                            maxLength="80"
                            errors={errors}
                            register={register}
                            onChange={(e) => textChangeHandleSubHeading(e)}
                        />
                    )}
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Print"
                    variant="primary"
                    type="button"
                    disabled={!toggle ? true : false}
                    onClick={(e) => printPreviewAction()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        })
                    }
                />
            </div>
        </>
    );
};

export default PrintBridgeHeading;
