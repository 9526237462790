// @flow
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { modal as modalData } from '../../constants/modal';
import { useModalContext } from '../../contexts/ModalContext';
import { modalActions } from '../../reducers/actions';
import ButtonElement from '../button/ButtonElement';

const Modals = () => {
    let { modal, modalDispatch } = useModalContext();
    const { currentModalId, modalList } = modal;

    useEffect(() => {
        try {
            handleResize();
            function handleResize() {
                document
                    ?.getElementsByClassName('modal-resize')[0]
                    ?.querySelector('.modal-dialog')
                    ?.setAttribute('style', 'height: calc(' + window.innerHeight + 'px - 10%) ; min-height: auto');
                document
                    ?.getElementsByClassName('modal-resize')[0]
                    ?.querySelector('.modal-dialog')
                    ?.querySelector('.table-responsive')
                    ?.setAttribute('style', 'height:' + window.innerHeight / 2 + 'px ; min-height: auto');
            }
            window.addEventListener('resize', handleResize);
            const handleKeyDown = (e) => {
                if (
                    (e.code == 'Space' || e.keyCode == 32) &&
                    (document?.querySelector('.modal.visually-visible-modal.show .preview') ||
                        document?.querySelector('.modal.visually-visible-modal.show .asset-preview')) &&
                    window?.location?.pathname !== '/platform/content' &&
                    window?.location?.pathname !== '/platform/digitalassets'
                ) {
                    modalDispatch({
                        type: modalActions.CLOSE_MODAL,
                    });
                    e.preventDefault();
                }
            };
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        } catch (ex) {}
    }, []);

    return (
        <>
            {currentModalId && <div className="fade modal-backdrop show"></div>}
            {modalList.map((m, modalListIndex) => {
                let obj = modalList.find((f) => f.currentModalId === m.currentModalId);
                const hideModal = {
                    modalAction: () => (obj?.modalAction ? obj?.modalAction() : {}),
                    modalCancelAction: () => modalCancelAction(),
                    data: m.data,
                };
                const handleModal = (e) => {
                    obj?.modalAction(e);
                };

                const handleCancelActionModal = () => {
                    modalCancelAction();
                };
                const modalObj = m.modalDataKey
                    ? modalData[m.modalDataKey[0]][m.modalDataKey[1]](hideModal)[0]
                    : undefined;

                const toggle = () => {
                    modalDispatch({
                        type: modalActions.CLOSE_MODAL,
                    });
                };
                let isFooter = typeof modalObj?.modalContent === 'string' || !!modalObj?.modalMessageText;
                return (
                    <>
                        <Modal
                            key={modalListIndex}
                            show={true}
                            onHide={toggle}
                            size={modalObj?.modalSize + ' modal-dialog-centered'}
                            backdrop={false}
                            className={
                                currentModalId === m.currentModalId
                                    ? modalObj?.modalResize + ' visually-visible-modal'
                                    : modalObj?.modalResize + ' visually-hidden-modal'
                            }>
                            <Modal.Header
                                className={obj?.modalColor ? `bg-${obj?.modalColor} text-white modal-variant` : null}
                                onHide={toggle}
                                closeButton={modalObj?.crossIcon}>
                                <h4 className="modal-title">
                                    {modalObj?.modalTitle}
                                    {modalObj?.modalInfoIcon && (
                                        <span className="d-inline-block">
                                            <ButtonElement
                                                className="btn p-0 custom-tooltip-align modal-info-icon"
                                                icon={<i className="dripicons-information"></i>}
                                                tooltipAlign="right"
                                                tooltip={modalObj?.modalInfoMsg}
                                            />
                                        </span>
                                    )}
                                </h4>
                            </Modal.Header>
                            <Modal.Body className={modalObj?.modalBodyClass}>
                                <div>{modalObj?.modalContent}</div>
                            </Modal.Body>
                            {isFooter && (
                                <Modal.Footer
                                    className={`d-flex justify-content-center ${
                                        typeof modalObj?.modalContent === 'string' ? '' : 'border-top-0 py-0'
                                    }`}>
                                    {typeof modalObj?.modalContent === 'string' && (
                                        <>
                                            {m?.data?.element &&
                                                m?.data?.element?.map((item) =>
                                                    item.pos === 'start' ? <span key={item}>{item.elm}</span> : null
                                                )}
                                            {!m?.data?.saveBtnDisabled && (
                                                <ButtonElement
                                                    disabled={m?.data?.loading}
                                                    loading={String(m?.data?.loading)}
                                                    name={modalObj?.modalButton}
                                                    variant={obj?.modalColor ? obj?.modalColor : 'primary'}
                                                    onClick={(e) => handleModal(e)}></ButtonElement>
                                            )}
                                            {m?.data?.element &&
                                                m?.data?.element?.map((item) =>
                                                    item.pos === 'center' ? <span key={item}>{item.elm}</span> : null
                                                )}
                                            {!m?.data?.cancelBtnDisabled && (
                                                <ButtonElement
                                                    name={
                                                        modalObj?.modalCancelButton
                                                            ? modalObj?.modalCancelButton
                                                            : 'Cancel'
                                                    }
                                                    variant="light"
                                                    onClick={() => {
                                                        modalObj?.modalActionOnClickCancelButton &&
                                                            modalObj?.modalActionOnClickCancelButton();
                                                        modalObj?.modalCancelButtonAction
                                                            ? handleCancelActionModal()
                                                            : toggle();
                                                    }}></ButtonElement>
                                            )}

                                            {m?.data?.element &&
                                                m?.data?.element?.map((item) =>
                                                    item.pos === 'end' ? <span key={item}>{item.elm}</span> : null
                                                )}
                                        </>
                                    )}
                                    {modalObj?.modalMessageText && (
                                        <div
                                            className={
                                                modalObj?.modalMessageTextAlignmentLeft
                                                    ? 'text-left mt-0 w-100 warning-wrapper'
                                                    : 'text-center mt-0 w-100 warning-wrapper'
                                            }>
                                            <div className="footer-text">
                                                {modalObj?.modalMessageNote && (
                                                    <small className="text-warning">{modalObj?.modalMessageNote}</small>
                                                )}
                                                {modalObj?.modalMessageWarning && (
                                                    <small className="text-danger">
                                                        {modalObj?.modalMessageWarning}
                                                    </small>
                                                )}
                                                <span>{modalObj?.modalMessageText}</span>
                                            </div>
                                        </div>
                                    )}
                                </Modal.Footer>
                            )}
                        </Modal>
                    </>
                );
            })}
        </>
    );
};

export default Modals;
