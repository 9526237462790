// @flow
import React, { useState } from 'react';
// components
import { Row, Col } from 'react-bootstrap';
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch } from 'react-redux';
import { contentDuplicate } from '@ovrture/react-redux';

const DuplicateContentPanelModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [panelType, setPanelType] = useState(props?.data?.panelType);
    const dispatch = useDispatch();

    return (
        <>
            {/* <p>Are you sure you want to create a duplicate of this Content Panel?</p> */}
            <h5 className="text-primary mt-0">Content Panel Type:</h5>
            <Row>
                <Col className="d-inline-block p-0">
                    <FormInput
                        type="radio"
                        id="colIsCentrallyManagedCp"
                        label="Editable Content Panel"
                        className="mt-2 ms-2"
                        value={false}
                        onChange={(e) => {
                            setPanelType(e.target.value);
                        }}
                        checked={panelType === false || panelType === 'false'}
                    />
                    <span className="d-inline-block">
                        <ButtonElement
                            className="btn p-0 custom-tooltip-align"
                            icon={<i className="dripicons-information"></i>}
                            tooltipAlign="right"
                            tooltip="This type of panel can be created once centrally and then edited further in individual sites/reports only; no changes to the central copy will cascade to existing sites/reports."></ButtonElement>
                    </span>
                </Col>
            </Row>
            <Row>
                <Col className="d-inline-block p-0 mb-2 mt-1">
                    <FormInput
                        type="radio"
                        id="colIsCentrallyManagedCp"
                        label="Inherited Content Panel"
                        className="mt-1 ms-2"
                        value={true}
                        onChange={(e) => {
                            setPanelType(e.target.value);
                        }}
                        checked={panelType === true || panelType === 'true'}
                    />
                    <span className="d-inline-block">
                        <ButtonElement
                            className="btn p-0 custom-tooltip-align"
                            icon={<i className="dripicons-information"></i>}
                            tooltipAlign="right"
                            tooltip="Changes made to the central copy of this type of panel will cascade to all existing sites/reports where it is placed. These panels cannot be personalized in a site/report."></ButtonElement>
                    </span>
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Duplicate"
                    variant="primary"
                    type="submit"
                    onClick={() => {
                        dispatch(
                            contentDuplicate({
                                id: props?.data?.id,
                                colIsCentrallyManagedCp: panelType === 'true' || panelType === true ? true : false,
                                icon:
                                    panelType === true || panelType === 'true'
                                        ? 'glyphicon glyphicon-text-background-cp-centrally-managed-draft'
                                        : 'glyphicon glyphicon-text-background-cp',
                            })
                        );
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};
export default DuplicateContentPanelModal;
