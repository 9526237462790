// @flow
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import DataSchemaFields from '../surveyor/DataSchemaFields';
import DataSchemaFieldDetails from '../surveyor/DataSchemaFieldDetails';
import { useAuth } from '../../../contexts/AuthContext';
import NotEnabled from '../../commonpages/commonModals/NotEnabled';
import { TABS } from './DataSchema';

const DataSchemaCommonComponent = (props) => {
    const [selectedField, setSelectedField] = useState(null);
    const [closeTab, setcloseTab] = useState(null);
    const { tenantPermission } = useAuth();

    return (
        <>
            {tenantPermission?.colIsReportAccessible === false &&
            TABS.USERS.DATASCOPEID !== props?.tab?.DATASCOPEID &&
            TABS.RECORDS.DATASCOPEID !== props?.tab?.DATASCOPEID ? (
                <>
                    <NotEnabled />
                </>
            ) : (
                <>
                    <Col md={12}>
                        <Row className="mt-3">
                            <DataSchemaFields
                                data={{
                                    setSelectedField: setSelectedField,
                                    selectedField: selectedField,
                                    setcloseTab: setcloseTab,
                                    closeTab: closeTab,
                                    ...props,
                                }}
                            />
                            <DataSchemaFieldDetails
                                data={{
                                    setSelectedField: setSelectedField,
                                    selectedField: selectedField,
                                    setcloseTab: setcloseTab,
                                    closeTab: closeTab,
                                    ...props,
                                }}
                            />
                        </Row>
                    </Col>
                </>
            )}
        </>
    );
};

export default DataSchemaCommonComponent;
