import { dateTimeFormat } from '../../utility';

export const downloadFile = (arrayBuffer, type, fileName) => {
    var blob = new Blob([arrayBuffer], { type: type });
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display:none';
    var url = URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();
};

export const downloadFileUrl = (url) => {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display:none';
    a.href = url;
    a.click();
    URL.revokeObjectURL(url);
    a.remove();

    // var xhr = new XMLHttpRequest();
    // xhr.open('GET', url, true);
    // xhr.responseType = 'blob';

    // xhr.onload = function () {
    //     var blob = new Blob([xhr.response], { type: 'application/pdf' });
    //     var url = window.URL.createObjectURL(blob);

    //     var a = document.createElement('a');
    //     a.href = url;
    //     a.download = dateTimeFormat(new Date(), 'ddMMyyyyHHmmss') + '.pdf';

    //     document.body.appendChild(a);
    //     a.click();

    //     document.body.removeChild(a);
    //     window.URL.revokeObjectURL(url);
    // };

    // xhr.send();
};
