const service = {
    noContent: 204,
    success: 200,
    accepted: 202,
    created: 201,
    bulkUpdated: 207,
    notfound: 400,
    empty: 404,
    forbidden: 403,
    notModified: 304,
    alreadyExist: 453,
    failed: 'Something went wrong!',
    enableSystem: 'Enable System',
    disableSystem: 'Disable System',
    cleanSystem: 'Clean System Data',
    deleteSystem: 'Delete System',
    purgeSystem: 'Purge System',
};
export default service;
