import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';

const DatepickerInput = forwardRef((props, ref) => {
    const onDateValueChange = () => {};
    return (
        <input
            type="text"
            className="form-control date"
            onClick={props.onClick}
            value={props.value}
            onChange={onDateValueChange}
            ref={ref}
        />
    );
});

const DatepickerInputWithAddon = forwardRef((props, ref) => (
    <div className="input-group" ref={ref}>
        <input
            type="text"
            className="form-control form-control-light"
            onClick={props.onClick}
            value={props.value}
            readOnly
            aria-label="Select date"
        />
        <div className="input-group-append">
            <span className="input-group-text bg-primary border-primary text-white">
                <i className="mdi mdi-calendar-range font-13"></i>
            </span>
        </div>
    </div>
));

const HyperDatepicker = (props) => {
    const input = (props.hideAddon || false) === true ? <DatepickerInput /> : <DatepickerInputWithAddon />;
    const renderDayContents = (day, date) => {
        return (
            <span className={` ${props.value == null ? 'custom-selected-date' : ''}`}>{moment(date).format('DD')}</span>
        );
    };
    return (
        <>
            {/* date picker control */}
            <DatePicker
                customInput={input}
                timeIntervals={props.tI}
                className={classNames('form-control', props.inputClass)}
                selected={props.value}
                onChange={(date) => props.onChange(date)}
                showTimeSelect={props.showTimeSelect}
                timeFormat="hh:mm a"
                timeCaption={props.timeCaption}
                dateFormat={props.dateFormat || 'MM/dd/yyyy'}
                minDate={props?.minDate && props.minDate}
                maxDate={props?.maxDate && props?.maxDate}
                monthsShown={props.monthsShown}
                showTimeSelectOnly={props.showTimeSelectOnly}
                inline={props.inline}
                autoComplete="off"
                disabled={props?.disabled}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={1000}
                yearDropdownRange={[100, 0]}
                renderDayContents={renderDayContents}>
                <div className="text-center clear-date-block">
                    <a
                        href={'javascript:void(0)'}
                        onClick={() => {
                            props.onChange(null);
                            document.querySelector('.react-datepicker__tab-loop').style.display = 'none';
                        }}>
                        Clear Date
                    </a>
                </div>
            </DatePicker>
        </>
    );
};

export default HyperDatepicker;
