//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import PublishContentPanelModal from './PublishContentPanelModal';
import SendNotificationModal from './SendNotificationModal';

const PublishEditedContentPanelMultiStepForm = (props) => {
    const [active, setActive] = useState(0);
    const [cpFolderViewId, setCpFolderViewId] = useState(null);

    const steps = [
        {
            name: 'Publish',
            component: (
                <PublishContentPanelModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                />
            ),
        },
        {
            name: 'Send Notification',
            component: (
                <SendNotificationModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    name="contentPanel"
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                />
            ),
        },
    ];

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
        </>
    );
};

export default PublishEditedContentPanelMultiStepForm;
