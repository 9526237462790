// @flow
import React, { useState, useEffect } from 'react';

// components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import _ from 'underscore';

import {
    deleteArchiveByArchiveId,
    findArchiveListBasedOnSearch,
    exportDownloadFromArchive,
    assetDownload,
    systemDataExportReset,
    deleteArchivExportReset,
} from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import config from '../../../config';
import { downloadFileUrl } from '../../../helpers/downloadFile/downloadFile';
import { dateTimeFormat } from '../../../utility';
import { Card } from 'react-bootstrap';
import service from '../../../constants/service.constant';

const ExportArchiveDataModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatchAction = useDispatch();
    const [dataList, setDataList] = useState([]);
    const [sortObj, setSortObj] = useState([{ sort: ['colCreatedAt,DESC'] }]);

    const { findArchiveBasedSearch, downloadAsset, deleteArchiveByArchiveID } = useSelector(
        (state) => ({
            findArchiveBasedSearch: state?.archiveExport?.findsArchiveListBasedOnSearchParams,
            deleteArchiveByArchiveID: state?.archiveExport?.deleteArchiveByArchiveID,
            downloadAsset: state?.systemDataExport?.exportDownloadFromArchive,
        }),
        _.isEqual
    );
    useEffect(() => {
        if (downloadAsset?.code === service.success) {
            downloadFileUrl(downloadAsset?.data);
            dispatchAction(systemDataExportReset());
        }
    }, [downloadAsset]);
    useEffect(() => {
        if (deleteArchiveByArchiveID) {
            dispatchAction(deleteArchivExportReset());
            dispatchAction(
                findArchiveListBasedOnSearch({
                    isAnd: true,
                    page: props?.data?.page || 0,
                    size: config.PAGE_SIZE,
                    sortObj: sortObj,
                })
            );
            modalDispatch({ type: modalActions.RESET_MODAL });
        }
    }, [deleteArchiveByArchiveID]);

    useEffect(() => {
        !props?.data?.page &&
            dispatchAction(
                findArchiveListBasedOnSearch({
                    isAnd: true,
                    page: props?.data?.page || 0,
                    size: config.PAGE_SIZE,
                    sortObj: sortObj,
                })
            );
    }, []);

    useEffect(() => {
        if (findArchiveBasedSearch?.data?.content.length > 0) {
            findArchiveBasedSearch?.data?.content?.forEach((data, index) => {
                data['export'] = (
                    <ButtonElement as="text" name={data.colExportHistoryName} title={data.colExportHistoryName} />
                );
                findArchiveBasedSearch.data.content[index].user.colFullName = (
                    <ButtonElement as="text" name={data.user.colFullName} title={data.user.colFullName} />
                );
                data['date'] = dateTimeFormat(data?.colUpdatedAt);
                data['fileSize'] = data.colFileSize;
            });
            setDataList(findArchiveBasedSearch?.data?.content);
        } else {
            setDataList([]);
        }
    }, [findArchiveBasedSearch]);

    const DownloadArchive = (payload) => {
        dispatchAction(exportDownloadFromArchive(payload));
    };

    const deleteArchiveHandler = (payload) => {
        dispatchAction(deleteArchiveByArchiveId(payload));
    };

    const ActionsColumn = ({ row }) => {
        const { modalDispatch } = useModalContext();

        return (
            <>
                <div className="button-list d-flex justify-content-center">
                    {row?.original?.assetPath ? (
                        <ButtonElement
                            icon={<i className="mdi mdi-cloud-download icon-large" />}
                            className="action-icon hover-primary p-0"
                            type="button"
                            as="href"
                            tooltip="Download"
                            onClick={() => DownloadArchive({ pkExportHistoryId: row?.original?.pkExportsHistoryId })}
                        />
                    ) : (
                        <ButtonElement
                            icon={<i className="mdi mdi-cloud-download icon-large" />}
                            className="action-icon hover-primary p-0 disable-show-tooltip"
                            as="href"
                            tooltip="In Progress"
                            onClick={() => {}}
                        />
                    )}

                    <ButtonElement
                        icon={<i className="mdi mdi-delete" />}
                        className="action-icon hover-danger p-0  mx-0"
                        tooltip="Delete"
                        onClick={() =>
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) => {
                                        deleteArchiveHandler({
                                            pkExportHistoryId: row?.original?.pkExportsHistoryId,
                                        });
                                    },
                                    data: {
                                        modalActionOnClickCancelButton: () => {
                                            setTimeout(() => {
                                                modalDispatch({
                                                    type: modalActions.OPEN_MODAL,
                                                    payload: {
                                                        modalVariant: 'primary',
                                                        modalDataKey: ['systemAdmin', 'exportArchiveData'],
                                                        data: {
                                                            page: findArchiveBasedSearch?.data?.number,
                                                        },
                                                    },
                                                });
                                            }, 200);
                                        },
                                    },
                                    modalVariant: 'danger',
                                    modalDataKey: ['systemAdmin', 'deleteExportArchiveData'],
                                },
                            })
                        }
                    />
                </div>
            </>
        );
    };
    return (
        <>
            <Card>
                <Table
                    columns={TableColumn.systemAdmin.exportArchiveData([ActionsColumn])}
                    data={dataList}
                    pageSizes={findArchiveBasedSearch?.data?.size}
                    pageNumber={findArchiveBasedSearch?.data?.number}
                    totalElements={findArchiveBasedSearch?.data?.totalElements}
                    totalPages={findArchiveBasedSearch?.data?.totalPages}
                    paginationDispatch={findArchiveListBasedOnSearch}
                    customPaginationProps={{
                        isAnd: true,
                        sortObj: sortObj,
                        //fkUserId: userInfo.id,
                    }}
                    sizePerPageList={TableColumn.sizePerPageList(dataList)}
                    isSortable={true}
                    pagination={true}
                    isSearchable={false}
                    isFilter={false}
                    theadClass={TableColumn.table_light}
                    tableClass="export-archive-data"
                    tableParentClass={
                        findArchiveBasedSearch?.data?.content?.length <= 3
                            ? 'table-resize reduce-height'
                            : 'table-resize'
                    }
                />
            </Card>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportArchiveDataModal;
