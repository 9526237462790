import {
    tenantSystemsModule,
    platformManagementModule,
    tenantDataModule,
    usersModule,
    accountModule,
    latestBuildModule,
    cloudDetailsModule,
    privacyPolicyModule,
    siteReportFilesStatusModule,
    deletedDataPAModule,
} from '../module/platformAdminModules';
// Platform Admin left sidebar Routes
export const PlatformAdminRoutes = [
    { ...tenantSystemsModule },
    { ...platformManagementModule },
    { ...tenantDataModule },
    { ...usersModule },
    { ...accountModule },
    { ...latestBuildModule },
    { ...cloudDetailsModule },
    { ...privacyPolicyModule },
    { ...siteReportFilesStatusModule },
    { ...deletedDataPAModule },
];
