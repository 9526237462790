export const boldString = (subStr) => subStr.bold();
// Common Error Message for Form Inputs
export const Message = {
    GenericMsg: 'Required.',
    urlValidatorMessage: 'Please enter valid url.',
    phoneValidatorMessage: 'Please enter 10 digit phone number (with area code, if applicable).',
    emailValidatorMessage: 'Please enter a valid Email.',
    spaceAndSpecialCharacterValidatiorMessage: 'Incompatible special characters.',
    nameValidatorMessage:
        'Incompatible special characters; ' + boldString("space '. - * ( ) : & _ !") + ' accent permitted.',
    prefixAndSuffixValidatorMessage: 'Incompatible special characters; ' + boldString(". , - : _'") + ' permitted.',
    titleValidatorMessage:
        'Incompatible special characters; ' + boldString("@ & , _ - ; : / . '") + ' accent permitted.',
    infoValidatorMessage:
        'Incompatible special characters; ' +
        boldString("~ ! @ # $ % & * ( ) _ + - = [ ] | ; : ' / ? . ,") +
        ' permitted.',
    classYearValidatorMessage: 'Incompatible special characters; ' + boldString(", ; : - _ '") + ' permitted.',
    addressValidatorMessage:
        'Incompatible special characters; ' + boldString('` ! @ # & * ( ) - _ = + | : ; \' , / . "') + ' permitted.',
    cityStateZipCountryValidatorMessage:
        'Incompatible special characters; ' + boldString('space , . : - ( ) /') + ' permitted.',
    numberWithHyphenAndDotValidatorMessage: 'Incompatible special character; ' + boldString('- .') + ' only permitted.',
    ALPHANUMERIC_WITH_SPECIAL_CHARACTERS:
        'Incompatible special characters; ' +
        boldString('~ ! @ # $ % & * ( ) _ + | : ? , . / ; \' ‘ [ ] \\ = - ’ – ‚ „ ® © ℗ ™ ℠ "') +
        ' permitted.',
    ADDRESS:
        'Incompatible special characters; ' +
        boldString('` ~ ! @ # $ % & * ( ) _ + | : ? , . / ; \' ‘ [ ] \\ = - ’ – ‚ „ ® © ℗ ™ ℠ "') +
        ' permitted.',
    NAME_AND_IMPACT_FIELDS:
        'Incompatible special characters; ' +
        boldString('space ~ ! @ # $ % & * ( ) _ + | : ? , . / ; \' ‘ [ ] \\ = - ’ – ‚ „ ® © ℗ ™ ℠ "') +
        ' permitted.',
    CONTENT_PANEL_NAME_CHECK: 'Incompatible special characters.',
    FREE_TEXT: 'Incompatible special characters.',
    MATCH_NAME: 'Incompatible special characters; ' + boldString('space & * ( ) _ : ’ accent') + ' permitted.',
    PREFIX_AND_SUFFIX:
        'Incompatible special characters; ' +
        boldString('space ~ ! @ # $ % & * ( ) _ + | : ? , . / ; \' ‘ [ ] \\ = - ’ – ‚ „ ® © ℗ ™ ℠ "') +
        ' permitted.',
    CITY_STATE_COUNTRY: 'Incompatible special characters; ' + boldString("space , . : ' - ( ) / ’") + ' permitted.',
    ZIP_OR_POSTAL: 'Incompatible special characters; ' + boldString('space , . : - ( ) / ’') + ' permitted.',
    CLASS_YEAR:
        'Incompatible special characters; ' +
        boldString("~ ! @ # $ % & * ( ) _ + | : ? , . / ; ' ‘ [ ] \\ = - ’ – ‚ „") +
        ' permitted.',
    IMPACT_YEAR: 'Incompatible special characters; ' + boldString("space , ; : _ ' - ’") + ' permitted.',
    TITLE:
        'Incompatible special characters; ' +
        boldString('space ~ ! @ # $ % & * ( ) _ + | : ? , . / ; \' ‘ [ ] \\ = - ’ – ‚ „ ® © ℗ ™ ℠ "') +
        ' permitted.',
    GROUP_NAME: 'Incompatible special characters.',
};
