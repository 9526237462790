// @flow
import React, { useEffect, useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useModalContext } from '../../../contexts/ModalContext';
import { userReset, userSendEmail } from '@ovrture/react-redux';
import { FormInput } from '../../../components/form/input';
const SystemAdminResetPasswordWithMfa = (props) => {
    const [resetMfa, setResetMfa] = useState(false);
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    const { resetPassword } = useSelector((state) => ({
        resetPassword: state?.user?.emailSentForNewAccount,
    }));

    useEffect(() => {
        if (resetPassword) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
            dispatch(userReset());
        }
    }, [resetPassword]);

    return (
        <>
            <p>Are you sure you want to reset the password for this user?</p>
            <FormInput
                type="checkbox"
                label="Reset MFA Paired device also"
                ariaLabel="Reset MFA Paired device also"
                className="px-2 pb-2"
                onChange={(e) => {
                    setResetMfa(e.target.checked);
                }}
            />
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        sessionStorage.setItem(
                            process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
                            'Reset password link sent.'
                        );
                        dispatch(userSendEmail({ resetMfa: resetMfa, userId: props?.data?.id }));
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default SystemAdminResetPasswordWithMfa;
