// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import {
    userBulkHardDelete,
    userSoftDelete,
    bulkSoftDelete,
    bulkHardDelete,
    siteBulkDeleteMultiple,
    bulkReportDelete,
    userBulkSoftDelete,
    bulkReportSoftDelete,
    siteBulkSoftDelete,
} from '@ovrture/react-redux';
import { useDispatch } from 'react-redux';
import { modalActions } from '../../../reducers/actions';
import { useModalContext } from '../../../contexts/ModalContext';

const DeleteActions = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    return (
        <>
            {props?.data?.name === 'softDeleteUserandGroups' ? (
                <p>Last chance. Are you sure you want to delete this user?</p>
            ) : props?.data?.name === 'bulkDeleteUserandGroups' ? (
                <p>Last chance. Are you sure you want to delete this user?</p>
            ) : props?.data?.name === 'bulkRecordsDelete' ? (
                <p>Are you sure you want to delete the selected Record data?</p>
            ) : props?.data?.item === 'deleteRecord' ? (
                <p>Are you sure you want to delete the selected Record data?</p>
            ) : props?.data?.name === 'bulkHardDeleteRecords' ||
              props?.data?.name === 'hardDeleteRecord' ||
              props?.data?.name === 'hardDeleteReport' ||
              props?.data?.name === 'hardDeleteSite' ? (
                <p>
                    Are you sure you want to delete this data permanently? Once deleted, this data (and any and all
                    associated elements) can not be retrieved.
                </p>
            ) : props?.data?.item === 'deleteSite' ? (
                <p>Are you sure you want to delete the selected Sites?</p>
            ) : props?.data?.item === 'deleteReport' || props?.data?.name === 'bulkReportsSoftDelete' ? (
                <p>Are you sure you want to delete the selected Reports?</p>
            ) : props?.data?.name === 'bulkReportsDelete' ||
              props?.data?.name === 'bulkSitesDelete' ||
              props?.data?.name === 'bulkSitesSoftDelete' ? (
                <p>
                    Are you sure you want to delete this data permanently? Once deleted, this data (and any and all
                    associated elements) can not be retrieved.
                </p>
            ) : props?.data?.name === 'softDeleteUser' || props?.data?.name === 'bulkSoftDeleteUser' ? (
                <p>Are you sure you want to delete the selected User data?</p>
            ) : (
                ''
            )}

            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    onClick={() => {
                        const isSelectAll = props.data.isSelectAll;
                        if (props?.data?.name === 'softDeleteUserandGroups') {
                            dispatch(
                                userSoftDelete({
                                    userId: props.data.selectedUserArr,
                                    filterCriteria: props?.data?.filterCriteria,
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        } else if (props?.data?.name === 'softDeleteUser') {
                            dispatch(
                                userSoftDelete({
                                    userId: props.data.selectedUserArr,
                                })
                            );
                        } else if (props?.data?.name === 'bulkSoftDeleteUser') {
                            const isSelectAll = props.data.payload.isSelectAll;
                            dispatch(
                                userBulkSoftDelete({
                                    userIdList: isSelectAll ? [] : props?.data?.selectedUserArr,
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props?.data?.payload?.filterCriteria,
                                })
                            );
                        } else if (props?.data?.name === 'bulkDeleteUserandGroups') {
                            dispatch(userBulkHardDelete(props.data.selectedUserArr));
                        } else if (props?.data?.name === 'bulkRecordsDelete') {
                            dispatch(bulkSoftDelete(props.data.payload));
                        } else if (props?.data?.item === 'deleteRecord') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.name === 'bulkHardDeleteRecords') {
                            dispatch(bulkHardDelete(props.data.payload));
                        } else if (props?.data?.item === 'deleteSite') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.name === 'hardDeleteReport') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.name === 'hardDeleteSite') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.item === 'deleteReport') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                        } else if (props?.data?.name === 'bulkSitesDelete') {
                            dispatch(
                                siteBulkDeleteMultiple({
                                    pkSiteIdList: props?.data.isSelectAll
                                        ? []
                                        : props.data.selectedUserArr.map((item) => item?.id || item?.orgId),
                                    isSelectAll: props?.data.isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props?.data?.filterCriteria,
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        } else if (props?.data?.name === 'bulkReportsSoftDelete') {
                            dispatch(
                                bulkReportSoftDelete({
                                    pkReportIdList: props?.data?.isSelectAll
                                        ? []
                                        : props?.data?.selectedUserArr?.map((item) => item?.id || item?.orgId),
                                    isSelectAll: props?.data?.isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props?.data?.filterCriteria,
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        } else if (props?.data?.name === 'bulkReportsDelete') {
                            dispatch(
                                bulkReportDelete({
                                    pkReportIdList: props?.data?.isSelectAll
                                        ? []
                                        : props?.data?.selectedUserArr?.map((item) => item?.id || item?.orgId),
                                    isSelectAll: props?.data?.isSelectAll,
                                    isAnd: true,
                                    filterCriteria: props?.data?.filterCriteria,
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        } else if (props?.data?.name === 'hardDeleteRecord') {
                            props.data.onDelete(true, props?.data?.item, props.data.original);
                            props?.hideModal();
                            props?.data?.callDispatchForListDelete();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default DeleteActions;
