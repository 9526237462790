import { Row } from 'react-bootstrap';
import TinymceField from './TinymceField';
import { useEffect, useState } from 'react';

const SurveyDefaultField = (props) => {
    const [colSurveyDefaultsFieldsObj, setcolSurveyDefaultsFieldsObj] = useState(null);

    useEffect(() => {
        if (props?.data?.info?.colSurveyDefaultsFields) {
            setcolSurveyDefaultsFieldsObj(JSON.parse(props?.data?.info?.colSurveyDefaultsFields));
        }
    }, [props?.data?.info?.colSurveyDefaultsFields]);

    return (
        <>
            <Row className="mt-3">
                <h5 className="text-primary mb-0">SURVEY DEFAULTS:</h5>
                {colSurveyDefaultsFieldsObj?.defaultSurveyQuestion && (
                    <TinymceField
                        data={{
                            ...props?.data,
                            defaultSurveyQuestion: colSurveyDefaultsFieldsObj?.defaultSurveyQuestion,
                        }}
                    />
                )}
                {colSurveyDefaultsFieldsObj?.defaultSurveyQualifier && (
                    <TinymceField
                        data={{
                            ...props?.data,
                            defaultSurveyQualifier: colSurveyDefaultsFieldsObj?.defaultSurveyQualifier,
                        }}
                    />
                )}
            </Row>
        </>
    );
};
export default SurveyDefaultField;
