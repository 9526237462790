// @flow
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { crmUpdate, crmGet, crmReset } from '@ovrture/react-redux';
import { dateTimeFormat } from '../../../utility';
import { useAuth } from '../../../contexts/AuthContext';

const ConfigureDataModal = (props) => {
    const dispatch = useDispatch();
    const { tenantPermission } = useAuth();
    const {
        setValue,
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ mode: 'onBlur' });

    const { update, get } = useSelector((state) => ({
        update: state?.crm?.update,
        get: state?.crm?.get?.data,
    }));

    useEffect(() => {
        dispatch(crmGet({ pk3pServiceId: props?.data?.original?.pk3pServiceId }));
    }, []);

    useEffect(() => {
        if (get) {
            setValue('colDataScopeRecord', get?.colDataScopeRecord);
            setValue('colDataScopeFundDetail', get?.colDataScopeFundDetail);
            setValue('colDataScopeFundPerformance', get?.colDataScopeFundPerformance);
            setValue('colDataScopeFundDonor', get?.colDataScopeFundDonor);
            setValue('colDataScopeFundImpact', get?.colDataScopeFundImpact);
            setValue('colImportFrequencyForRecord', get?.colImportFrequencyForRecord?.toString());
            setValue('colImportFrequencyForFundDetail', get?.colImportFrequencyForFundDetail?.toString());
            setValue('colImportFrequencyForFundPerformance', get?.colImportFrequencyForFundPerformance?.toString());
            setValue('colImportFrequencyForFundDonor', get?.colImportFrequencyForFundDonor?.toString());
            setValue('colImportFrequencyForFundImpact', get?.colImportFrequencyForFundImpact?.toString());
        }
    }, [get]);

    useEffect(() => {
        if (update) {
            dispatch(crmReset());
            props.hideModal();
        }
    }, [update]);

    const values = watch();
    const onSubmit = (formData) => {
        sessionStorage.setItem(
            process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY,
            'Configuration data flow updated.'
        );
        formData.pk3pServiceId = props?.data?.original?.pk3pServiceId;
        dispatch(crmUpdate(formData));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <b className="flow-title">Data Scope & Frequency</b>
            <Row className="border-0 mt-3">
                <Col>
                    <FormInput
                        type="switch"
                        id="colDataScopeRecord"
                        name="colDataScopeRecord"
                        label={'Records'}
                        aria-label={values?.colDataScopeRecord === true ? 'Scope Record ON' : 'Scope Record OFF'}
                        errors={errors}
                        register={register}
                        //defaultChecked={values?.colDataScopeRecord}
                    />
                    <FormInput
                        id="colImportFrequencyForRecord"
                        type="select"
                        register={register}
                        floatinglabel="false"
                        disabled={!values?.colDataScopeRecord}>
                        <option key={'DAILY'} value={'DAILY'}>
                            {'Import Daily'}
                        </option>
                        <option key={'WEEKLY'} value={'WEEKLY'}>
                            {'Import Weekly'}
                        </option>
                        <option key={'MONTHLY'} value={'MONTHLY'}>
                            {'Import Monthly'}
                        </option>
                    </FormInput>
                    <p className="form-select-para">
                        Last refreshed at {dateTimeFormat(props?.data?.original?.colUpdatedAt)}
                    </p>
                </Col>
            </Row>
            <Row className="border-0">
                <Col>
                    <FormInput
                        type="switch"
                        id="colDataScopeFundDetail"
                        name="colDataScopeFundDetail"
                        label={'Fund Details'}
                        aria-label={
                            values?.colDataScopeFundDetail === true ? 'Scope Fund Details ON' : 'Scope Fund Details OFF'
                        }
                        errors={errors}
                        register={register}
                        disabled={tenantPermission?.colIsReportAccessible === false}
                        //defaultChecked={values?.colDataScopeRecord}
                    />
                    <FormInput
                        id="colImportFrequencyForFundDetail"
                        type="select"
                        register={register}
                        floatinglabel="false"
                        disabled={tenantPermission?.colIsReportAccessible === false || !values?.colDataScopeFundDetail}>
                        <option key={'DAILY'} value={'DAILY'}>
                            {'Import Daily'}
                        </option>
                        <option key={'WEEKLY'} value={'WEEKLY'}>
                            {'Import Weekly'}
                        </option>
                        <option key={'MONTHLY'} value={'MONTHLY'}>
                            {'Import Monthly'}
                        </option>
                    </FormInput>
                    <p className="form-select-para">
                        Last refreshed at {dateTimeFormat(props?.data?.original?.colUpdatedAt)}
                    </p>
                </Col>
            </Row>
            <Row className="border-0">
                <Col>
                    <FormInput
                        type="switch"
                        id="colDataScopeFundPerformance"
                        name="colDataScopeFundPerformance"
                        label={'Fund Performance'}
                        aria-label={
                            values?.colDataScopeFundPerformance === true
                                ? 'Scope FY Fund Performance ON'
                                : 'Scope FY Fund Performance OFF'
                        }
                        errors={errors}
                        register={register}
                        disabled={tenantPermission?.colIsReportAccessible === false}
                        //defaultChecked={values?.colDataScopeRecord}
                    />
                    <FormInput
                        id="colImportFrequencyForFundPerformance"
                        type="select"
                        register={register}
                        floatinglabel="false"
                        disabled={
                            tenantPermission?.colIsReportAccessible === false || !values?.colDataScopeFundPerformance
                        }>
                        <option key={'DAILY'} value={'DAILY'}>
                            {'Import Daily'}
                        </option>
                        <option key={'WEEKLY'} value={'WEEKLY'}>
                            {'Import Weekly'}
                        </option>
                        <option key={'MONTHLY'} value={'MONTHLY'}>
                            {'Import Monthly'}
                        </option>
                    </FormInput>
                    <p className="form-select-para">
                        Last refreshed at {dateTimeFormat(props?.data?.original?.colUpdatedAt)}
                    </p>
                </Col>
            </Row>
            <Row className="border-0">
                <Col>
                    <FormInput
                        type="switch"
                        id="colDataScopeFundDonor"
                        name="colDataScopeFundDonor"
                        label={'Fund Donor'}
                        aria-label={
                            values?.colDataScopeFundDonor === true
                                ? 'Scope FY Fund Donor ON'
                                : 'Scope FY Fund Donor OFF'
                        }
                        errors={errors}
                        register={register}
                        disabled={tenantPermission?.colIsReportAccessible === false}
                        //defaultChecked={values?.colDataScopeRecord}
                    />
                    <FormInput
                        id="colImportFrequencyForFundDonor"
                        type="select"
                        register={register}
                        floatinglabel="false"
                        disabled={tenantPermission?.colIsReportAccessible === false || !values?.colDataScopeFundDonor}>
                        <option key={'DAILY'} value={'DAILY'}>
                            {'Import Daily'}
                        </option>
                        <option key={'WEEKLY'} value={'WEEKLY'}>
                            {'Import Weekly'}
                        </option>
                        <option key={'MONTHLY'} value={'MONTHLY'}>
                            {'Import Monthly'}
                        </option>
                    </FormInput>
                    <p className="form-select-para">
                        Last refreshed at {dateTimeFormat(props?.data?.original?.colUpdatedAt)}
                    </p>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col>
                    <FormInput
                        type="switch"
                        id="colDataScopeFundImpact"
                        name="colDataScopeFundImpact"
                        label={'Fund Impact'}
                        aria-label={
                            values?.colDataScopeFundImpact === true
                                ? 'Scope FY Fund Impact ON'
                                : 'Scope FY Fund Impact OFF'
                        }
                        errors={errors}
                        register={register}
                        disabled={tenantPermission?.colIsReportAccessible === false}
                        //defaultChecked={values?.colDataScopeRecord}
                    />
                    <FormInput
                        id="colImportFrequencyForFundImpact"
                        type="select"
                        register={register}
                        floatinglabel="false"
                        disabled={tenantPermission?.colIsReportAccessible === false || !values?.colDataScopeFundImpact}>
                        <option key={'DAILY'} value={'DAILY'}>
                            {'Import Daily'}
                        </option>
                        <option key={'WEEKLY'} value={'WEEKLY'}>
                            {'Import Weekly'}
                        </option>
                        <option key={'MONTHLY'} value={'MONTHLY'}>
                            {'Import Monthly'}
                        </option>
                    </FormInput>
                    <p className="form-select-para">
                        Last refreshed at {dateTimeFormat(props?.data?.original?.colUpdatedAt)}
                    </p>
                </Col>
            </Row>

            <div className="button-list d-flex justify-content-center pt-2">
                <ButtonElement name="Save" variant="primary" type="submit" />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) => {
                        props.hideModal();
                    }}
                />
            </div>
        </form>
    );
};

export default ConfigureDataModal;
