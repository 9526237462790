import { ROLE_ENUM } from '../constants/enumConstant';

export const configState = {
    API_URL: process.env.REACT_APP_API_URL,
    ADMIN: localStorage.getItem('ADMIN') ? JSON.parse(localStorage.getItem('ADMIN')) : {},
    USER: localStorage.getItem('USER') ? JSON.parse(localStorage.getItem('USER')) : {},
    SUPER_USER: localStorage.getItem('SUPER_USER') ? JSON.parse(localStorage.getItem('SUPER_USER')) : {},
    REPORT_ADMIN: localStorage.getItem('REPORT_ADMIN') ? JSON.parse(localStorage.getItem('REPORT_ADMIN')) : {},
    BRAND_ADMIN: localStorage.getItem('BRAND_ADMIN') ? JSON.parse(localStorage.getItem('BRAND_ADMIN')) : {},
    CONTENT_ADMIN: localStorage.getItem('CONTENT_ADMIN') ? JSON.parse(localStorage.getItem('CONTENT_ADMIN')) : {},
    DATA_ADMIN: localStorage.getItem('DATA_ADMIN') ? JSON.parse(localStorage.getItem('DATA_ADMIN')) : {},
    ROLE_ID: localStorage.getItem('ROLE_ID') ? localStorage.getItem('ROLE_ID') : undefined,
};

export const ConfigReducer = (state = configState, action) => {
    const roleType = Object.keys(ROLE_ENUM).find((key) => ROLE_ENUM[key] === action.type);
    switch (action.type) {
        case 0:
            localStorage.setItem('ROLE_ID', roleType);
            return {
                ...state,
            };
        case 1:
            localStorage.setItem('ROLE_ID', roleType);
            return {
                ...state,
            };
        case 2:
            localStorage.setItem('ROLE_ID', roleType);
            return {
                ...state,
            };
        case 3:
            localStorage.setItem('ROLE_ID', roleType);
            return {
                ...state,
            };
        case 4:
            localStorage.setItem('ROLE_ID', roleType);
            return {
                ...state,
            };
        case 5:
            localStorage.setItem('ROLE_ID', roleType);
            return {
                ...state,
            };
        case 'RESET': {
            localStorage.setItem('ROLE_ID', 'PLATFORM_ADMIN');
            localStorage.removeItem('ADMIN');
            return {
                ...state,
            };
        }
        default:
            throw new Error();
    }
};
