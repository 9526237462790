// @flow
import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import ButtonElement from '../../../components/button/ButtonElement';

const OperationalBar = ({ name, icons, actions, className, ...otherProps }) => {
    return (
        <ButtonGroup className={className + ' operational-bar'}>
            {name && (
                <div className="bg-secondary text-white d-flex align-items-center ps-2 pe-1">
                    <strong>{name} </strong>
                </div>
            )}
            {icons.map((iconItem, index) => {
                return (
                    <ButtonElement
                        key={index}
                        title={
                            otherProps?.disableClass?.find((f) => f?.index === index)
                                ? otherProps?.disableClass?.find((f) => f?.index === index)?.disableTitle
                                : iconItem?.title
                        }
                        icon={
                            iconItem.icon === 'mdi-plus-thick-svg' ? (
                                <svg height="24px" width="24px">
                                    <rect width="24" height="24" fill="none" />
                                    <path
                                        d="m19,13.587c.707,0,1.381.14,2,.386V5c0-1.11-.9-2-2-2H5c-1.11,0-2,.89-2,2v14c0,1.105.895,2,2,2h8.966c-.245-.618-.384-1.289-.384-1.995,0-2.992,2.426-5.418,5.418-5.418Zm-6-.587v4h-2v-4h-4v-2h4v-4h2v4h4v2h-4Zm8.836,6.273l-2.836,2.836-2.836-2.836h1.891v-3.781h1.891v3.781h1.891Z"
                                        fill="#fff"
                                    />
                                </svg>
                            ) : (
                                <i className={iconItem.icon + ' mdi font-18'}></i>
                            )
                        }
                        className={`action-icon ${otherProps?.disableClass?.find((f) => f?.index === index)?.disable} ${
                            iconItem.className && iconItem.className
                        }`}
                        variant="secondary"
                        onClick={(e) => {
                            !e.target.closest('button').classList.contains('maximum-disabled') &&
                                actions(e, iconItem.icon);
                        }}
                    />
                );
            })}
        </ButtonGroup>
    );
};

export default OperationalBar;
