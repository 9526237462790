// @flow
import React, { useState } from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const DeleteFolderModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(true);
    const disabledButton = () => {
        props?.data?.delete();
        setToggle(false);
    };

    return (
        <>
            <p>
                {props?.data?.name == 'folderDelete'
                    ? 'Are you sure you want to delete this folder?'
                    : 'Are you sure you want to delete the selected Content Panels?'}
            </p>
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    onClick={() => disabledButton()}
                    disabled={!toggle ? true : false}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};
export default DeleteFolderModal;
