import { Col, Row } from 'react-bootstrap';
import { FormInput } from '../../../../components/form/input';
import { useEffect, useState } from 'react';

const DatabaseField = (props) => {
    const [colReadOnlyFields, setcolReadOnlyFields] = useState(null);

    useEffect(() => {
        if (props?.data?.info?.colReadOnlyFields) {
            setcolReadOnlyFields(JSON.parse(props?.data?.info?.colReadOnlyFields));
        }
    }, [props?.data?.info?.colReadOnlyFields]);

    useEffect(() => {
        if (colReadOnlyFields) {
            props.data.setValue('mandatory', colReadOnlyFields?.mandatory?.value);
        }
    }, [colReadOnlyFields]);

    return (
        <>
            <Row className="DatabaseField">
                {props?.data?.info?.colOvrtureDatabaseFieldName && (
                    <Col lg={12} xl={4}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>Ovrture Database Field Name</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type="text"
                                id="colOvrtureDatabaseFieldName"
                                label={props?.data?.info?.colOvrtureDatabaseFieldName}
                                aria-label={props?.data?.info?.colOvrtureDatabaseFieldName}
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                className="mb-sm-0"
                                floatinglabel="false"
                                maxLength="500"
                                value={props?.data?.info?.colOvrtureDatabaseFieldName}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
                {colReadOnlyFields?.ovrtureDatabseFieldTitle && (
                    <Col lg={12} xl={4}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>{colReadOnlyFields?.ovrtureDatabseFieldTitle?.label}</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type={colReadOnlyFields?.ovrtureDatabseFieldTitle?.type}
                                id="ovrtureDatabseFieldTitle"
                                label={colReadOnlyFields?.ovrtureDatabseFieldTitle?.label}
                                aria-label={colReadOnlyFields?.ovrtureDatabseFieldTitle?.label}
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                className="mb-sm-0"
                                floatinglabel="false"
                                maxLength={colReadOnlyFields?.ovrtureDatabseFieldTitle?.max_length}
                                value={colReadOnlyFields?.ovrtureDatabseFieldTitle?.value}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
                {colReadOnlyFields?.limitation && (
                    <Col lg={12} xl={4}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>{colReadOnlyFields?.limitation?.label}</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type={colReadOnlyFields?.limitation?.type}
                                id="limitation"
                                label={colReadOnlyFields?.limitation?.label}
                                aria-label={colReadOnlyFields?.limitation?.label}
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                className="mb-sm-0"
                                floatinglabel="false"
                                maxLength={colReadOnlyFields?.limitation?.max_length}
                                value={colReadOnlyFields?.limitation?.value}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
                {colReadOnlyFields?.mandatory && (
                    <Col lg={12} xl={3}>
                        <Row>
                            <Col md={12} className="mb-1">
                                <span>{colReadOnlyFields?.mandatory?.label}</span>
                            </Col>
                        </Row>
                        <Col md={12}>
                            <FormInput
                                type={colReadOnlyFields?.mandatory?.type}
                                id="mandatory"
                                className="mb-3"
                                errors={props?.data?.errors}
                                register={props?.data?.register}
                                label={colReadOnlyFields?.mandatory?.value === true ? 'ON' : 'OFF'}
                                aria-label={colReadOnlyFields?.mandatory?.value === true ? 'ON' : 'OFF'}
                                defaultChecked={colReadOnlyFields?.mandatory?.value}
                                readOnly={true}
                            />
                        </Col>
                    </Col>
                )}
            </Row>
        </>
    );
};
export default DatabaseField;
