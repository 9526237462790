// @flow
import React from 'react';
import { Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const NavigationModal = (props) => {
    const { modalDispatch } = useModalContext();
    const {
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({});
    const values = watch();

    const onSubmit = (data) => {};

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Card>
                <img src={props?.data?.src}></img>
                {/* <iframe className="previewOnly" id="previewOnly" srcDoc={sitePreview?.data}></iframe> */}
            </Card>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default NavigationModal;
