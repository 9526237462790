// @flow
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import React, { useEffect, useState } from 'react';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { RedirectTo } from '../../../routes/role/path';
import { arrowInitHandler, indicatorsInitHandler } from '../migration/TenantMigration';
import { setEditorChanges } from '../storage';
import { tinymceInlineInit, tinymceRemove } from '../tinymce/TinyMceInlineTextEditor';
import OperationalBar from './ui';
import { setContentPanelIsReplace } from '../../retainHistory';

export const icons = {
    ADD: { icon: 'mdi-plus-thick', title: 'Add slides to the right', className: 'slideAdd' },
    LEFT: { icon: 'mdi-arrow-left-thick', title: 'Move slide left', className: 'slideLeft' },
    RIGHT: { icon: 'mdi-arrow-right-thick', title: 'Move slide right', className: 'slideRight' },
    REPLACE: { icon: 'mdi-collage', title: 'Change slide layout', className: 'slideReplace' },
    DELETE: { icon: 'mdi-delete', title: 'Delete slide', className: 'slideDelete' },
};

export const iconsDisable = {
    ADD: { icon: 'mdi-plus-thick', title: 'Can not exceeds six slides', className: 'slideAdd' },
};

const indexs = {
    ADD: 0,
    LEFT: 1,
    RIGHT: 2,
    REPLACE: 3,
    DELETE: 4,
};

const Slide = (props) => {
    const { Toast, setAutoClose } = useNotification();
    const { modalDispatch } = useModalContext();
    const [disableClass, setDisableClass] = useState(null);
    const [cpId, setCpId] = useState(null);

    useEffect(() => {
        if (window.location.pathname.includes(RedirectTo('/content-panel-edit'))) {
            if (
                document.querySelectorAll('.item').length > window?.noOfSlideLayoutCount
                    ? window?.noOfSlideLayoutCount - 1
                    : 6 - 1
            ) {
                setDisableClass([
                    { index: indexs.ADD, disable: 'disabled' },
                    { index: indexs.LEFT, disable: 'disabled' },
                ]);
            } else {
                if (document.querySelectorAll('.item').length === 1) {
                    setDisableClass([
                        { index: indexs.LEFT, disable: 'disabled' },
                        { index: indexs.RIGHT, disable: 'disabled' },
                        { index: indexs.DELETE, disable: 'disabled' },
                    ]);
                } else {
                    setDisableClass([{ index: indexs.LEFT, disable: 'disabled' }]);
                }
            }
        }
        try {
            document.getElementById('rootHtml').addEventListener('mousemove', (e) => {
                // return for text node
                if (e.target.nodeType === 3) return;
                let slideDiv = e?.target?.closest('[cpid]')?.querySelector('.slideDiv');
                let slideEditDiv =
                    e?.target?.closest('[cpid]')?.querySelector('.carousel-cp') ||
                    e?.target?.querySelector('#MAIN-TEMPLATE')?.closest('[cpid]') ||
                    e?.target?.querySelector('#VIDEO_CAROUSEL')?.closest('[cpid]') ||
                    e?.target?.querySelector('#VIDEO_CAROUSEL')?.closest('.content-panel-editor') ||
                    e?.target?.querySelector('#Thankyou-TEMPLATE')?.closest('[cpid]');

                if (
                    window.location.pathname.includes(RedirectTo('/site-edit')) ||
                    window.location.pathname.includes(RedirectTo('/report-edit')) ||
                    window.location.pathname.includes(RedirectTo('/content-panel-edit'))
                ) {
                    const slideOperationalBar = document?.querySelector('.slide-operational-bar');
                    if (slideOperationalBar && document?.querySelector('.slide-operational-bar')?.style) {
                        if (
                            slideEditDiv &&
                            !e?.target?.closest('[cpid]')?.classList?.contains('report-cp-panel') &&
                            !slideEditDiv.closest('#MAIN-TEMPLATE')?.classList.contains('custom-panel-parent') &&
                            !slideEditDiv.closest(
                                '.PARTNER_IN_THE_PROMISE, .PROMISES_WE_MAKE, .RESPONDING_TO_THE_CHALLENGE, .PROMISE_TESTED, .FLIP_BOOK, .CALL_TO_ACTION, .POSITIONING, .PRIORITY'
                            ) &&
                            !slideEditDiv.closest('#MAIN-TEMPLATE')?.classList.contains('ovrture-special') &&
                            !slideEditDiv.closest('#MAIN-TEMPLATE')?.classList.contains('special-download') &&
                            !slideEditDiv.closest('#CP_THE_DAY_IT_ALL_CHANGED_ONE') &&
                            !slideEditDiv.closest('#CP_LEADERSHIP_DONOR') //For TCU LEADERSHIP_DONOR Only
                        ) {
                            if (
                                e?.target?.classList.contains('content-panel-editor') ||
                                e?.target?.closest('.content-panel-editor')
                            ) {
                                document.querySelector('.slide-operational-bar').style.cssText =
                                    'display: flex;visibility: visible;top: ' +
                                    '70px;right: 0;position: absolute;z-index: 10';
                            } else {
                                document.querySelector('.slide-operational-bar').style.cssText =
                                    'display: flex;visibility: visible;top: ' +
                                    slideEditDiv.parentNode.offsetTop +
                                    'px;right: 0;position: absolute;z-index: 10';
                            }
                        } else {
                            document.querySelector('.slide-operational-bar').style.cssText += 'display: none;';
                        }
                    }
                    let cpid = e?.target?.closest('[cpid]') || e?.target?.querySelector('[cpid]');
                    if (cpid) {
                        setCpId(cpid?.getAttribute('cpid'));
                    } else if (e?.target?.querySelector('#MAIN-TEMPLATE')?.closest('[cpid]')?.getAttribute('cpid')) {
                        setCpId(e.target.querySelector('#MAIN-TEMPLATE').closest('[cpid]').getAttribute('cpid'));
                    }
                    let carouselDiv = cpid?.querySelector('[cpid] .carousel');
                    let carouselDivInner = carouselDiv?.querySelector('.carousel-inner');
                    let items = carouselDivInner?.querySelectorAll('.item');
                    if (
                        cpid.classList.contains('centerally-managed-cp') &&
                        !document?.getElementById('rootHtml')?.classList?.contains('content-panel-editor')
                    ) {
                        let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                        for (let index = 0; index < buttons.length; index++) {
                            const element = buttons[index];
                            element.classList.add('disabled');
                        }
                    } else {
                        if (items?.length > 1) {
                            const first = carouselDiv?.querySelector('.item.active:nth-child(1)');
                            const last = carouselDiv?.querySelector('.item.active:nth-child(' + items.length + ')');
                            if (first) {
                                let buttons = document.querySelectorAll(
                                    '.slide-operational-bar.operational-bar button'
                                );
                                for (let index = 0; index < buttons.length; index++) {
                                    const element = buttons[index];
                                    element.classList.remove('disabled');
                                    element.classList.remove('maximum-disabled');
                                    element.title === 'Last slide in panel' && (element.title = 'Move slide right');
                                    element.title === 'Can not exceeds six slides' &&
                                        (element.title = 'Add slides to the right');
                                    element.title === 'Can not delete; must have at least one slide' &&
                                        (element.title = 'Delete slide');
                                    if (element?.classList?.contains('slideLeft')) {
                                        element.classList.add('disabled');
                                        element.classList.add('maximum-disabled');
                                        element.title = 'First slide in panel';
                                    }
                                    if (
                                        items?.length > window?.noOfSlideLayoutCount - 1 &&
                                        element?.classList?.contains('slideAdd')
                                    ) {
                                        element.classList.add('disabled');
                                        element.classList.add('maximum-disabled');
                                        element.title = 'Can not exceeds six slides';
                                    }
                                }
                            } else if (last) {
                                let buttons = document.querySelectorAll(
                                    '.slide-operational-bar.operational-bar button'
                                );
                                for (let index = 0; index < buttons.length; index++) {
                                    const element = buttons[index];
                                    element.classList.remove('disabled');
                                    element.classList.remove('maximum-disabled');
                                    element.title === 'First slide in panel' && (element.title = 'Move slide left');
                                    element.title === 'Can not exceeds six slides' &&
                                        (element.title = 'Add slides to the right');
                                    element.title === 'Can not delete; must have at least one slide' &&
                                        (element.title = 'Delete slide');
                                    if (
                                        element?.title === icons.RIGHT.title ||
                                        element?.title === 'Last slide in panel'
                                    ) {
                                        element.classList.add('disabled');
                                        element.classList.add('maximum-disabled');
                                        element.title = 'Last slide in panel';
                                    }
                                    if (
                                        items?.length > window?.noOfSlideLayoutCount - 1 &&
                                        element?.classList?.contains('slideAdd')
                                    ) {
                                        element.classList.add('disabled');
                                        element.classList.add('maximum-disabled');
                                        element.title = 'Can not exceeds six slides';
                                    }
                                }
                            } else {
                                let buttons = document.querySelectorAll(
                                    '.slide-operational-bar.operational-bar button'
                                );
                                for (let index = 0; index < buttons.length; index++) {
                                    const element = buttons[index];
                                    element.classList.remove('disabled');
                                    element.classList.remove('maximum-disabled');
                                    element.title === 'First slide in panel' && (element.title = 'Move slide left');
                                    element.title === 'Last slide in panel' && (element.title = 'Move slide right');
                                    if (element.title === 'Move slide left') {
                                        element.classList.remove('disabled');
                                        element.classList.remove('maximum-disabled');
                                    }
                                    element.title === 'Can not exceeds six slides' &&
                                        (element.title = 'Add slides to the right');
                                    element.title === 'Can not delete; must have at least one slide' &&
                                        (element.title = 'Delete slide');
                                    if (
                                        items?.length > window?.noOfSlideLayoutCount - 1 &&
                                        (element?.title === icons.ADD.title ||
                                            element?.title === 'Can not exceeds six slides')
                                    ) {
                                        element.classList.add('disabled');
                                        element.classList.add('maximum-disabled');
                                        element.title = 'Can not exceeds six slides';
                                    }
                                }
                            }
                        } else if (items?.length === 1) {
                            let buttons = document.querySelectorAll('.slide-operational-bar.operational-bar button');
                            for (let index = 0; index < buttons.length; index++) {
                                const element = buttons[index];
                                element.classList.remove('disabled');
                                element.classList.remove('maximum-disabled');
                                element.title === 'Can not exceeds six slides' &&
                                    (element.title = 'Add slides to the right');
                                if (element?.classList?.contains('slideRight')) {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'Last slide in panel';
                                }
                                if (element?.classList?.contains('slideLeft')) {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'First slide in panel';
                                }
                                if (element?.classList?.contains('slideDelete')) {
                                    element.classList.add('disabled');
                                    element.classList.add('maximum-disabled');
                                    element.title = 'Can not delete; must have at least one slide';
                                }
                            }
                        }
                    }
                } else {
                    if (slideDiv && document?.querySelector('.slide-operational-bar')?.style) {
                        document.querySelector('.slide-operational-bar').style.cssText =
                            'display: flex;visibility: visible;top: ' +
                            slideDiv.parentNode.offsetTop +
                            ';right: 0;position: absolute;z-index: 1';
                    }
                }
            });
            document.getElementById('rootComponent').addEventListener('mouseleave', (e) => {
                if (
                    !(
                        e?.toElement?.className?.includes('slide-operational-bar') ||
                        e?.toElement?.className?.includes(
                            'bg-secondary text-white d-flex align-items-center ps-2 pe-1'
                        ) ||
                        e?.toElement?.className?.includes('asset-replacer') ||
                        e?.toElement?.className?.includes('action-icon') ||
                        e?.toElement?.className?.includes('slide-operational-bar') ||
                        e?.toElement?.className?.includes('documentImg') ||
                        e?.toElement?.className?.includes('mdi') ||
                        e?.toElement?.className?.includes('documentImg') ||
                        e?.toElement?.className === ''
                    )
                ) {
                    if (document?.querySelector('.slide-operational-bar')?.style)
                        document.querySelector('.slide-operational-bar').style.cssText += 'display: none;';
                }
                document?.querySelectorAll('div.slide-operational-bar').forEach((element) => {
                    element?.addEventListener('mouseleave', (ev) => {
                        if (
                            !(
                                e?.toElement?.className?.includes('slide-operational-bar') ||
                                e?.toElement?.className?.includes(
                                    'bg-secondary text-white d-flex align-items-center ps-2 pe-1'
                                ) ||
                                e?.toElement?.className?.includes('asset-replacer') ||
                                e?.toElement?.className?.includes('action-icon') ||
                                e?.toElement?.className?.includes('slide-operational-bar') ||
                                e?.toElement?.className?.includes('mdi') ||
                                e?.toElement?.className?.includes('documentImg') ||
                                e?.toElement?.className === ''
                            )
                        ) {
                            if (document?.querySelector('.slide-operational-bar')?.style)
                                document.querySelector('.slide-operational-bar').style.cssText += 'display: none;';
                        }
                    });
                });
            });
        } catch (ex) {}
        return () => {
            setDisableClass(null);
            setCpId(null);
        };
    }, []);

    const Actions = (e, key) => {
        switch (key) {
            case icons.ADD.icon:
                setContentPanelIsReplace(false);
                openSlideLayout(props?.original);
                break;
            case icons.LEFT.icon:
                slideShift('carousel-control-prev');
                break;
            case icons.RIGHT.icon:
                slideShift('carousel-control-next');
                break;
            case icons.REPLACE.icon:
                setContentPanelIsReplace(true);
                openSlideLayout(props?.original, true);
                break;
            case icons.DELETE.icon:
                slideDelete();
                break;
        }
    };
    const openSlideLayout = (original, isReplace) => {
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalVariant: 'primary',
                modalDataKey: ['editor', 'addOrChangeSlide'],
                data: {
                    original: original,
                    isReplace: isReplace,
                    slideModified: slideModified,
                    slideHtml: props?.slideHtml,
                    cpId:
                        window.location.pathname.includes(RedirectTo('/site-edit')) ||
                        window.location.pathname.includes(RedirectTo('/report-edit'))
                            ? cpId
                            : document?.querySelector('[cpid]')?.getAttribute('cpid'),
                },
            },
        });
    };

    const slideModified = (slide) => {
        if (slide === undefined) {
            setTimeout(() => {
                tinymceRemove();
                tinymceInlineInit({ Toast, setAutoClose });
            }, 200);
        }
    };

    const slideShift = (direction) => {
        const ele = document.getElementById('rootComponent');
        const replacerId = ele.getAttribute('slideSecClass-id');
        if (replacerId) {
            const ele1 = document.getElementById(replacerId);
            var activeSlide = ele1.querySelector('.carousel-inner .active');
        } else {
            var activeSlide = document.querySelector('.carousel-inner .active');
        }
        var currentObj = null;
        direction === 'carousel-control-next'
            ? (currentObj = activeSlide.nextElementSibling)
            : (currentObj = activeSlide.previousElementSibling);
        var cnvrtDomNext = currentObj?.children[0];
        var cnvrtDomActive = activeSlide?.children[0];
        if (activeSlide?.children?.length > 0) activeSlide.children[0].remove();
        activeSlide.append(cnvrtDomNext);
        if (currentObj?.children?.length > 0) currentObj.children[0].remove();
        currentObj.append(cnvrtDomActive);
        let msg = '';
        if (direction === 'carousel-control-next') {
            msg = 'Slide moved right.';
        } else if (direction === 'carousel-control-prev') {
            msg = 'Slide moved left.';
        }
        setTimeout(() => {
            msg && Toast.success({ description: msg });
        }, 200);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    const slideDelete = () => {
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) => deleteSlide(),
                modalVariant: 'danger',
                modalDataKey: ['editor', 'delete'],
                data: {
                    name: 'slide',
                    modalActionOnClickCancelButton: () =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                            payload: { noActive: true },
                        }),
                },
            },
        });
    };
    const deleteSlide = () => {
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: { noActive: true },
        });
        let items;
        let index = 0;
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            // let node = document?.querySelector('#' + slideId + ' .item.active');
            // index = [].indexOf.call(node.parentNode.children, node) - 1;
            let slideId = document.querySelector('#rootComponent').getAttribute('slidesecclass-id');
            document?.querySelector('#' + slideId + ' .item.active')?.remove();
            document?.querySelector('#' + slideId + ' .item.active')?.remove();
            items = document?.querySelectorAll('#' + slideId + ' .carousel-inner .item')
                ? document?.querySelectorAll('#' + slideId + ' .carousel-inner .item')
                : document?.querySelectorAll('#' + slideId + ' .carousel-inner .item');
        } else {
            // let node = document?.querySelector('.item.active');
            // index = [].indexOf.call(node.parentNode.children, node) - 1;
            document?.querySelector('.item.active')?.remove();
            document?.querySelector('.item.active')?.remove();
            items = document?.querySelectorAll('.carousel-inner .item');
        }
        if (items.length > 0) items[index].classList.add('active');
        indicatorsInitHandler(index, 'delete');
        slideModified();
        arrowInitHandler();
        setTimeout(() => {
            Toast.success({ description: 'Slide deleted.' });
        }, 200);
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
    };

    return (
        <OperationalBar
            name="Slide:"
            className="slide-operational-bar"
            icons={[icons.ADD, icons.LEFT, icons.RIGHT, icons.REPLACE, icons.DELETE]}
            disableClass={disableClass}
            actions={Actions}
        />
    );
};

export default Slide;
