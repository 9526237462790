// @flow
import React, { useEffect, useState } from 'react';
import { Col, Card } from 'react-bootstrap';
// components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { deviceInfoList, deviceInfoDelete, authGetUserLatestDetail } from '@ovrture/react-redux';
import config from '../../../config';
import { dateTimeFormat } from '../../../utility';

const RegisteredDevicesModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const { list, deleted, latestDetail, resetPasswordCommon } = useSelector((state) => ({
        list: state?.deviceInfo?.list,
        deleted: state?.deviceInfo?.delete,
        latestDetail: state?.auth?.userLatestDetail,
        resetPasswordCommon: state?.user?.resetPasswordCommon,
    }));

    useEffect(() => {
        dispatch(authGetUserLatestDetail());
    }, []);

    useEffect(() => {
        if (list?.data?.content) {
            list.data.content.forEach((item) => {
                if (item?.colDeviceSuccessLoginAt)
                    item.colDeviceSuccessLoginAt = dateTimeFormat(item.colDeviceSuccessLoginAt);
                item.colLoginType =
                    item.colLoginType === null ? '-' : item.colLoginType === 'SSO' ? 'Organization ID' : 'Account';
                item.colDeviceName = <ButtonElement as="text" name={item.colDeviceName} title={item.colDeviceName} />;
            });
            setData(list.data.content);
        }
        if (deleted) {
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
            dispatch(
                deviceInfoList({
                    isAnd: true,
                    page: list?.data?.content?.length > 1 ? list?.data?.number : list?.data?.number - 1,
                    size: config.PAGE_SIZE,
                    isForLoggedInUser: true,
                })
            );
        }
    }, [list, deleted]);

    useEffect(() => {
        resetPasswordCommon?.code == 202 &&
            setTimeout(() => {
                dispatch(authGetUserLatestDetail());
            }, 500);
    }, [resetPasswordCommon]);

    const ActionColumn = ({ row }) => {
        return (
            <ButtonElement
                icon={<i className="mdi mdi-delete"></i>}
                tooltip="Delete"
                className="action-icon hover-danger"
                disabled={props?.details?.deviceId === row?.original?.colDeviceUniqueId ? true : false}
                onClick={() =>
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalAction: (e) =>
                                dispatch(
                                    deviceInfoDelete({
                                        deviceInfoId: row.original.pkDeviceId,
                                    })
                                ),
                            modalVariant: 'danger',
                            modalDataKey: ['platformAdmin', 'deleteDevice'],
                        },
                    })
                }
            />
        );
    };
    return (
        <>
            <Col>
                <Card>
                    <Table
                        columns={TableColumn.accounts.accounts([ActionColumn])}
                        data={data || []}
                        pageSizes={list?.data?.size}
                        pageNumber={list?.data?.number}
                        totalElements={list?.data?.totalElements}
                        totalPages={list?.data?.totalPages}
                        loading={list?.loading}
                        paginationDispatch={deviceInfoList}
                        customPaginationProps={{
                            isForLoggedInUser: true,
                        }}
                        sizePerPageList={TableColumn.sizePerPageList(data)}
                        isSortable={true}
                        pagination={true}
                        isSearchable={false}
                        isFilter={false}
                        isSelectable={false}
                        theadClass={TableColumn.table_light}
                        tableClass="registered-devices"
                        tableParentClass={
                            list?.data?.content?.length <= 3 ? 'table-scroll-top reduce-height' : 'table-scroll-top'
                        }
                    />
                </Card>
            </Col>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};
export default RegisteredDevicesModal;
