// @flow
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
// component
import ButtonElement from '../../../components/button/ButtonElement';
import { assetBulkUploadResourceFilesData, assetBulkUploadResetData } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useModalContext } from '../../../contexts/ModalContext';
import { useTimeRemainingContext } from '../../../contexts/TimeRemainingContext';
import { modalActions, timeRemainingActions } from '../../../reducers/actions';
import GlobalFileUploader from '../../../components/GlobalFileUploader';
import { checkIfKeyExistOrNotInLocalstorage } from '../../../helpers/editor/migration/TenantMigration';

const AssetUploadModal = (props) => {
    const [startTime, setStartTime] = useState(0);
    const { timeRemainingDispatch, timeRemainingState } = useTimeRemainingContext();
    const [timeRemaining, setTimeRemaining] = useState(timeRemainingState?.remainingTime);
    const dispatch = useDispatch();
    const [totalSize, setTotalSize] = useState(0);
    const [uploadedSize, setUploadedSize] = useState(0);
    const errorMessage = 'Asset upload failed to file size limitation.';
    const { modalDispatch } = useModalContext();
    const [uploadSpeed, setUploadSpeed] = useState(0);

    let { uploadResourceFiles, uploadResourceFilesData, uploadResourceRefresh, progressBar } = useSelector((state) => ({
        uploadResource: state?.assetBulkUpload?.uploadResource,
        uploadResourceFiles: state?.assetBulkUpload?.uploadResourceFiles,
        uploadResourceRefresh: state?.assetBulkUpload?.uploadResourceRefresh,
        uploadResourceFilesData: state?.assetBulkUpload?.uploadResourceFilesData,
        socketSuccess: state?.socket?.success,
        progressBar: state?.socket?.loadingList,
    }));

    useEffect(() => {
        checkIfKeyExistOrNotInLocalstorage('loadingListForRecord', []);
    }, []);

    useEffect(() => {
        let x =
            timeRemainingState.totalSize - timeRemainingState.uploadedSize > 0
                ? timeRemainingState.totalSize - timeRemainingState.uploadedSize
                : 0;

        if (uploadSpeed) {
            setTimeout(() => {
                setTimeRemaining(x / uploadSpeed > 0 ? x / uploadSpeed : 0);
            }, 2550);
        }
    }, [timeRemainingState, uploadSpeed]);

    useEffect(() => {
        timeRemainingDispatch({
            type: timeRemainingActions.ADD_REMAINING_TIME,
            payload: timeRemaining,
        });
    }, [timeRemaining]);

    function calculateUploadSpeed(data) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            const startTime = Date.now();
            xhr.upload.addEventListener('progress', (event) => {
                if (event.loaded === event.total) {
                    const endTime = Date.now();
                    const duration = endTime - startTime;
                    const speed = (event.total / duration) * 1000;
                    resolve(speed);
                }
            });
            xhr.open('POST', '/');
            xhr.setRequestHeader('Content-Type', 'text/plain');
            xhr.send(data);
        });
    }

    function formatTime(seconds) {
        if (seconds < 0) {
            return '0s';
        }
        if (seconds < 60 && seconds >= 0) {
            return seconds + 's';
        } else {
            var minutes = Math.floor(seconds / 60);
            var remainingSeconds = seconds % 60;
            if (remainingSeconds === 0) {
                return minutes + 'm';
            } else {
                return minutes + 'm ' + Math.floor(remainingSeconds) + 's';
            }
        }
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            let y =
                Math.floor(Math.random() * (timeRemainingState?.uploadedFiles + 1)) + timeRemainingState?.totalFiles;
            let time = Math.floor(
                Math.random() * (timeRemainingState?.uploadedFiles + timeRemainingState?.totalFiles) * 6.156
            );
            setTimeRemaining(time);
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, []);

    return (
        <>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <GlobalFileUploader
                                onFileUpload={(files) => {}}
                                onRemoveFiles={(files) => {}}
                                parentId={props?.data?.fromNode?.original?.id}
                                startTime={startTime}
                                setTimeRemaining={setTimeRemaining}
                                setTotalSize={setTotalSize}
                                setUploadedSize={setUploadedSize}
                                totalSize={totalSize}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {uploadResourceFiles?.length > 0 && !timeRemainingState?.hideTimer && (
                <Row>
                    <Col>
                        <div className="">
                            Total Estimated Time:{' '}
                            {uploadResourceFiles
                                ? formatTime(
                                      (timeRemainingState?.totalFiles - timeRemainingState?.uploadedFiles) * 26.156
                                  )
                                : 0}
                        </div>
                    </Col>

                    <Col></Col>
                    <Col>
                        <div className="">
                            Uploaded files:{' '}
                            {timeRemainingState?.uploadedFiles > timeRemainingState?.totalFiles
                                ? timeRemainingState?.totalFiles
                                : timeRemainingState?.uploadedFiles}
                            /{timeRemainingState?.totalFiles}
                        </div>
                    </Col>
                </Row>
            )}

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Upload Asset(s)"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        timeRemainingDispatch({
                            type: timeRemainingActions.HIDE_TIMER,
                            payload: false,
                        });
                        setStartTime(moment.now());
                        let index = 0;
                        if (uploadResourceFiles[index]) {
                            dispatch(
                                assetBulkUploadResourceFilesData({
                                    index: index,
                                    isSubmit: true,
                                    parentId: props?.data?.fromNode?.original?.id,
                                })
                            );
                        }
                    }}
                    disabled={
                        uploadResourceFiles?.length > 0 && uploadResourceFilesData?.isSubmit
                            ? true
                            : uploadResourceFiles?.length > 0
                            ? false
                            : true
                    }
                />
                <ButtonElement
                    name="Exit While Uploading"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        modalDispatch({ type: modalActions.CLOSE_MODAL });
                    }}
                    disabled={uploadResourceFiles?.length > 0 && uploadResourceFilesData?.isSubmit ? false : true}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => {
                        uploadResourceFiles?.find((f) => f?.uploadedSuccess === true)
                            ? props.hideModal()
                            : modalDispatch({ type: modalActions.CLOSE_MODAL });
                        dispatch(assetBulkUploadResetData());
                    }}
                />
            </div>
        </>
    );
};

export default AssetUploadModal;
