// @flow
import React, { useReducer, useEffect } from 'react';
import { Col, ListGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
// components
import ListItemSelection from '../../../components/listItem/ListItem';
import { selectLeft, ListShiftReducer, updateLeftArray, ShiftInitialState } from '../../../reducers/ListShifterReducer';
import { surveyField, surveyFieldReset, surveyReset, surveyInfo } from '@ovrture/react-redux';

const DataSchemaFields = (props) => {
    const dispatchAction = useDispatch();
    const [state, dispatch] = useReducer(ListShiftReducer, ShiftInitialState);
    const { list } = useSelector((state) => ({
        list: state?.survey?.field?.data,
    }));

    useEffect(() => {
        dispatchAction(surveyFieldReset());
        dispatchAction(
            surveyField({ searchText: '', isAnd: true, isUnpaged: true, dataScopeId: props?.data?.tab?.DATASCOPEID })
        );
    }, []);

    useEffect(() => {
        if (list?.content?.length > 0) {
            list.content.forEach((item) => {
                item['id'] = item?.pkSurveyDataMapId;
                item['name'] = item?.colSurveyFieldName;
            });
            dispatch(updateLeftArray([...list.content]));
        } else {
            dispatch(updateLeftArray([]));
        }
    }, [list]);

    useEffect(() => {
        if (state?.leftArry?.length > 0) {
            selectOneItem(state?.leftArry[0]);
        }
    }, [state?.leftArry]);

    useEffect(() => {
        if (props?.data?.closeTab) {
            props?.data?.setcloseTab(null);
            props?.data?.setSelectedField(null);
            dispatch(selectLeft([]));
            dispatchAction(surveyFieldReset());
            dispatchAction(
                surveyField({
                    searchText: '',
                    isAnd: true,
                    isUnpaged: true,
                    dataScopeId: props?.data?.tab?.DATASCOPEID,
                })
            );
        }
    }, [props?.data?.closeTab]);

    const selectOneItem = (item, isFieldSelected) => {
        if (isFieldSelected) {
            state?.leftArry.forEach((selectedItemLeft) => {
                if (selectedItemLeft === item) {
                    selectedItemLeft.selectedLeft = true;
                    selectedItemLeft.selectedLeftId = 'Application';
                } else {
                    selectedItemLeft.selectedLeft = false;
                    selectedItemLeft.selectedLeftId = '';
                }
            });
            dispatch(selectLeft(item));
            props?.data?.setSelectedField(item);
        }
    };

    let movedRightItem = state.leftArry.filter((letfArryItem) =>
        state?.rightArry?.find((shiftedItemId) => letfArryItem?.id === shiftedItemId?.id)
    );

    return (
        <>
            <Col lg={3} sm={12} md={3} className="DataSchemaFields">
                <ListGroup className="listheader custom-list-header-color">
                    <ListGroup.Item className="active disabled">
                        <b>Data Field</b>
                    </ListGroup.Item>
                </ListGroup>
                <ListGroup>
                    {state?.leftArry?.length > 0 && state.leftArry && (
                        <ListItemSelection
                            id="Application"
                            list={state?.leftArry}
                            isSearchFilter={true}
                            searchData="User to make application admin"
                            onClick={(item) => selectOneItem(item, true)}
                            isLeftArray={true}
                            disabled={movedRightItem}
                            getHandleSearch={() => {}}
                        />
                    )}
                </ListGroup>
            </Col>
        </>
    );
};

export default DataSchemaFields;
