import { timeRemainingActions } from './actions';

export const TimeRemainingIntialState = {
    totalSize: 1,
    uploadedSize: 1,
    uploadSpeed: 1,
    remainingTime: 0,
    hideTimer: true,
    totalFiles: 0,
    uploadedFiles: 0,
    startTime: 0,
};

export const TimeRemainingReducer = (state = TimeRemainingIntialState, action) => {
    switch (action.type) {
        case timeRemainingActions.ADD_TOTAL_SIZE:
            return {
                ...state,
                totalSize: action.payload,
            };
        case timeRemainingActions.ADD_REMAINING_TIME:
            return {
                ...state,
                remainingTime: action.payload,
            };
        case timeRemainingActions.ADD_UPLOADED_SIZE:
            return {
                ...state,
                uploadedSize: action.payload,
            };
        case timeRemainingActions.ADD_UPLOADED_SPEED:
            return {
                ...state,
                uploadSpeed: action.payload,
            };
        case timeRemainingActions.HIDE_TIMER:
            return {
                ...state,
                hideTimer: action.payload,
            };
        case timeRemainingActions.UPLOADED_FILES:
            return {
                ...state,
                uploadedFiles: action.payload,
            };
        case timeRemainingActions.TOTAL_FILES:
            return {
                ...state,
                totalFiles: action.payload,
            };
        case timeRemainingActions.START_TIME:
            return {
                ...state,
                startTime: action.payload,
            };
        case timeRemainingActions.RESET:
            return {
                totalSize: 1,
                uploadedSize: 1,
                uploadSpeed: 1,
                remainingTime: 0,
                hideTimer: true,
                totalFiles: 0,
                uploadedFiles: 0,
                startTime: 0,
            };
        default:
            return { ...state };
    }
};
