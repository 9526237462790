import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    assetBulkUploadResource,
    assetBulkUploadReset,
    assetBulkUploadResourceFiles,
    assetBulkUploadResourceFilesData,
    assetBulkUploadResetData,
    assetBulkUploadResourceFilesRefresh,
    socketResponse,
} from '@ovrture/react-redux';
import service from '../../constants/service.constant';
import { useModalContext } from '../../contexts/ModalContext';
import { modalActions, timeRemainingActions } from '../../reducers/actions';
import { useTimeRemainingContext } from '../../contexts/TimeRemainingContext';

const HandleGlobalUploader = (props) => {
    const errorMessage = 'Asset upload failed to file size limitation.';
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    const { timeRemainingDispatch, timeRemainingState } = useTimeRemainingContext();
    let { uploadResource, socketSuccess, progressBar, uploadResourceFiles, uploadResourceFilesData } = useSelector(
        (state) => ({
            uploadResource: state?.assetBulkUpload?.uploadResource,
            uploadResourceFiles: state?.assetBulkUpload?.uploadResourceFiles,
            socketSuccess: state?.socket?.success,
            progressBar: state?.socket?.loadingList,
            uploadResourceFilesData: state?.assetBulkUpload?.uploadResourceFilesData,
        })
    );

    useEffect(() => {
        if (uploadResourceFilesData) {
            eventAssetBulkUploadResource();
        }
    }, [uploadResourceFilesData]);

    useEffect(() => {
        if (uploadResourceFilesData?.index) {
            eventAssetBulkUploadResource();
        }
    }, [uploadResourceFilesData?.index]);

    const eventAssetBulkUploadResource = () => {
        localStorage.setItem('skipBulkOperation', true);
        const userId = sessionStorage.getItem('userId');
        const socketFailedData = {
            status: 'error',
            message: uploadResourceFiles[uploadResourceFilesData?.index].path + ' upload failed.',
            createAt: new Date(),
            userId,
            unread: true,
            data: null,
        };

        if (
            uploadResourceFiles[uploadResourceFilesData?.index].typeFile === 'image' &&
            uploadResourceFiles[uploadResourceFilesData?.index].fileSizeMore32MB
        ) {
            dummyUpload(uploadResourceFilesData?.index);
            dispatch(socketResponse({ data: socketFailedData }));
        } else if (
            uploadResourceFiles[uploadResourceFilesData?.index].typeFile === 'video' &&
            uploadResourceFiles[uploadResourceFilesData?.index].fileSizeMore1GB
        ) {
            dummyUpload(uploadResourceFilesData?.index);
            dispatch(socketResponse({ data: socketFailedData }));
        } else {
            localStorage.setItem(
                'globalUploaderPath',
                JSON.stringify(uploadResourceFiles[uploadResourceFilesData?.index].path)
            );
            dispatch(
                assetBulkUploadResource(
                    { parentId: uploadResourceFilesData?.parentId },
                    { multipartFiles: [uploadResourceFiles[uploadResourceFilesData?.index]] }
                )
            );
        }
    };

    useEffect(() => {
        if (progressBar?.length > 0 && uploadResourceFilesData?.index >= 0) {
            if (uploadResourceFilesData) {
                let index = uploadResourceFilesData.index;
                uploadResourceFiles[index].progressBar = progressBar;
                if (!(uploadResourceFiles[index]?.fileSizeMore1GB || uploadResourceFiles[index]?.fileSizeMore32MB)) {
                    timeRemainingDispatch({
                        type: timeRemainingActions.ADD_UPLOADED_SIZE,
                        payload: timeRemainingState?.uploadedSize + uploadResourceFiles[index].size,
                    });
                    if (progressBar[0]?.progress?.percent === 100) {
                        timeRemainingDispatch({
                            type: timeRemainingActions.UPLOADED_FILES,
                            payload: timeRemainingState?.uploadedFiles + 1,
                        });
                    }
                }
            }

            dispatch(assetBulkUploadResourceFiles(uploadResourceFiles));
        }
    }, [progressBar]);

    useEffect(() => {
        if (uploadResource?.code === service.bulkUpdated && socketSuccess) {
            setTimeout(() => {
                if (uploadResourceFilesData) {
                    let index = uploadResourceFilesData.index;
                    uploadResourceFiles[index].uploaded = true;
                    uploadResourceFiles[index].uploadedSuccess = true;
                    dispatch(assetBulkUploadResourceFiles(uploadResourceFiles));
                    dispatch(assetBulkUploadReset());
                    fileUploaded(index);
                }
            }, 1000);
        }
    }, [uploadResource, socketSuccess]);

    const fileUploaded = (index) => {
        let newIndex = index + 1;
        if (newIndex <= uploadResourceFiles.length - 1) {
            uploadResourceFilesData.index = newIndex;
            dispatch(assetBulkUploadResourceFilesData(uploadResourceFilesData));
        } else {
            setTimeout(() => {
                // if (!uploadResourceFiles.find((f) => f?.progressBar?.error === errorMessage)) {
                //     localStorage.removeItem('skipBulkOperation');
                //     dispatch(assetBulkUploadResetData());
                //     dispatch(assetBulkUploadResourceFilesRefresh({ completed: true }));
                // }
                dispatch(
                    assetBulkUploadResourceFilesRefresh({ completed: true, uploadResourceFiles: uploadResourceFiles })
                );
                localStorage.removeItem('skipBulkOperation');
                modalDispatch({ type: modalActions.CLOSE_MODAL });
                timeRemainingDispatch({
                    type: timeRemainingActions.RESET,
                });
                dispatch(assetBulkUploadResetData());
            }, 1500);
        }
    };

    const dummyUpload = (index) => {
        uploadResourceFiles[index].progressBar = { error: errorMessage };
        uploadResourceFiles[index].uploaded = true;
        dispatch(assetBulkUploadResourceFiles(uploadResourceFiles));
        setTimeout(() => {
            fileUploaded(index);
        }, 2000);
    };

    return props.children;
};

export default HandleGlobalUploader;
