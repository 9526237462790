// @flow
import React, { useEffect, useState } from 'react';
// components
import Table from '../../../components/table/Table';
import ButtonElement from '../../../components/button/ButtonElement';
import { table as TableColumn } from '../../../constants/table';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import moment from 'moment';

const CreateAccessKeyModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [secretKeyType, setSecretKeyType] = useState('hide');
    const [dataList, setDataList] = useState([]);
    const toggleSecretKey = () => {
        if (secretKeyType === 'hide') {
            setSecretKeyType('show');
        } else {
            setSecretKeyType('hide');
        }
    };
    useEffect(() => {
        if (props?.data?.list) {
            props?.data?.list.forEach((d) => {
                d['accessKeyId'] = d.apiKey;
                d['created'] = moment(d.dateCreated).format('DD/MM/YYYY HH:mm');
                d['secretKey'] = d?.secret;
            });
            setDataList(props?.data?.list);
        }
    }, [props?.data?.list]);
    const SecretKeyColumn = ({ row }) => {
        return (
            <>
                <span title={secretKeyType === 'hide' ? '' : row?.original?.secretKey}>
                    {secretKeyType === 'hide' ? '................' : row?.original?.secretKey}
                </span>
            </>
        );
    };
    const ActionColumn = () => {
        return (
            <div>
                <ButtonElement
                    icon={
                        secretKeyType === 'hide' ? (
                            <i className="icon-large mdi mdi-eye"></i>
                        ) : (
                            <i className="icon-large mdi mdi-eye-off"></i>
                        )
                    }
                    // name={secretKeyType === 'hide' ? 'Show' : 'Hide'}
                    onClick={toggleSecretKey}
                />
            </div>
        );
    };

    return (
        <>
            <Table
                columns={TableColumn.systemAdmin.createdAccessKey([SecretKeyColumn, ActionColumn])}
                data={dataList}
                pageSize={TableColumn.pageSize}
                sizePerPageList={TableColumn.sizePerPageList(dataList)}
                isSortable={true}
                pagination={false}
                isSearchable={false}
                isFilter={false}
                theadClass={TableColumn.table_light}
                tableClass="created-access-key"
                tableParentClass="table-auto-height"
            />
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Done"
                    variant="primary"
                    type="button"
                    onClick={(e) => {
                        props?.data?.setIsCreated(true);
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default CreateAccessKeyModal;
