//@flow
import React, { useEffect, useState } from 'react';
import MultiStepForm from '../../helpers/multiStep/Index';
import { useSelector } from 'react-redux';
import ButtonElement from '../../components/button/ButtonElement';

const CrmTestMultiStepForm = (props) => {
    const [active, setActive] = useState(0);
    const [error, setError] = useState(null);

    const { socketLoadingSteps } = useSelector((state) => ({
        socketLoadingSteps: state?.socket?.socketLoadingSteps,
    }));

    const steps = [
        {
            name: 'AUTHORIZATION',
            icon: 'mdi-cloud-check-variant',
        },
        {
            name: 'API_CALL',
            icon: 'mdi-api',
        },
        {
            name: 'VALIDATE_MAPPING',
            icon: 'mdi-map-search-outline',
        },
        {
            name: 'ALL_COMPLETED',
            icon: 'mdi-checkbox-marked-circle',
        },
    ];
    useEffect(() => {
        if (socketLoadingSteps) {
            let AUTHORIZATION = socketLoadingSteps.find((f) => f.name === 'AUTHORIZATION');
            let API_CALL = socketLoadingSteps.find((f) => f.name === 'API_CALL');
            let VALIDATE_MAPPING = socketLoadingSteps.find((f) => f.name === 'VALIDATE_MAPPING');
            if (AUTHORIZATION?.status === 'success') {
                if (API_CALL?.status === 'success') {
                    if (VALIDATE_MAPPING?.status === 'success') {
                        setActive(4);
                        setError(null);
                    } else {
                        setActive(2);
                        setError(VALIDATE_MAPPING?.message);
                    }
                } else {
                    setActive(1);
                    setError(API_CALL?.message);
                }
            } else {
                setActive(0);
                setError(AUTHORIZATION?.message);
            }
        }
    }, [socketLoadingSteps]);

    return (
        <>
            <MultiStepForm activeStep={active} showNavigation={false} steps={steps} />
            {error && (
                <div class="footer-text">
                    <b class="text-danger">Error: </b>
                    <span>{error}</span>
                </div>
            )}
            <div className="button-list d-flex justify-content-center pt-2">
                <ButtonElement
                    name="Close"
                    variant="outline-primary"
                    type="button"
                    onClick={() => {
                        props.hideModal();
                    }}
                />
            </div>
        </>
    );
};

export default CrmTestMultiStepForm;
