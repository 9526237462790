// @flow
import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

const FileInput = ({
    name,
    placeholder,
    errors,
    register,
    className,
    changeEvent,
    defaultValue,
    accept,
    buttonValue,
    actionHander,
    onclickHandler,
    clickable,
    ariaLabel,
    type,
}) => {
    const hiddenFileInput = React.useRef(null);
    const handleClick = () => {
        if ((buttonValue === 'file' || clickable === 'select') && !actionHander) hiddenFileInput.current.click();
        if (buttonValue === 'onClickModal' || clickable === 'false') onclickHandler();
        if (actionHander) {
            hiddenFileInput.current.value = '';
            actionHander();
        }
    };
    return (
        <>
            <InputGroup className="mb-0 custom-file" onClick={() => handleClick()}>
                <div className="input-group-text">
                    <span>
                        {buttonValue === 'file'
                            ? 'Choose file'
                            : buttonValue === 'onClickModal'
                            ? 'Choose file'
                            : buttonValue}
                    </span>
                </div>
                <Form.Control
                    type="text"
                    placeholder={accept === '.vcf' ? 'No file chosen' : clickable ? '' : placeholder}
                    aria-label={ariaLabel ? ariaLabel : ' '}
                    name={name}
                    id={name}
                    as="input"
                    className={className}
                    isInvalid={errors && errors[name] ? true : false}
                    {...(register ? register(name) : {})}
                    value={clickable ? '' : defaultValue}
                />
                {type ? (
                    <></>
                ) : clickable ? (
                    <>
                        <div className="icon-block">
                            <i
                                className={
                                    clickable === 'remove'
                                        ? 'h3 text-danger dripicons-trash'
                                        : 'h3 text-muted dripicons-cloud-upload'
                                }></i>
                            <h5 className="mt-3">{defaultValue ? defaultValue : 'Click to select/upload.'}</h5>
                        </div>
                    </>
                ) : (
                    <></>
                )}

                <input
                    type="file"
                    ref={hiddenFileInput}
                    style={{ display: 'none' }}
                    onChange={changeEvent}
                    onClick={onclickHandler}
                    accept={accept}
                />
            </InputGroup>
        </>
    );
};

export default FileInput;
