// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';

// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions, treeViewActions } from '../../../../reducers/actions';
import TreeView from '../../../../components/treeview';
import { useTreeViewContext } from '../../../../contexts/TreeViewContext';
import { treeData } from '../../../../constants/treeData';
import { cpGetAllInitialFolder, cpFolderReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { COMMON_PATH, RedirectTo } from '../../../../routes/role/path';
import { parentTemplate } from '../../../../components/treeview/react-simple-jstree';
import { getTempData, spacePreventDefault } from '../../../../helpers/retainHistory';
import { manageTreeData, treeLimit } from '../../../../helpers/genericFunction';
import { getAllPathIds } from '../../../../components/treeview/react-simple-jstree';
import { RemoveTenantStyleSheet } from '../../../../helpers/editor/script/ScriptHandler';

const SelectContentPanelModal = (props) => {
    const [keydown, setKeydown] = useState(false);
    const { modalDispatch, modal } = useModalContext();
    const { treeState, treeDispatch } = useTreeViewContext();
    const [treeListData, setTreeListData] = useState(null);
    const [currentSelectedData, setCurrentSelectedData] = useState(null);
    const [contentData, setContentData] = useState();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [data, setData] = useState([]);
    const [isSelectable, setIsSelectable] = useState(false);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [isParentTemplate, setIsParentTemplate] = useState(false);
    const [movedPath, setMovedPath] = useState(null);
    const { initialList, propsData } = useSelector((state) => ({
        initialList: state?.cpFolder?.getAllInitialFolder,
        propsData: state?.brand?.tempData,
    }));

    useEffect(() => {
        if (previewModalOpen) {
            // setPreviewModalOpen(false);
            modalDispatch({
                type: modalActions.OPEN_MODAL,
                payload: {
                    modalAction: () => {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                    },
                    data: {
                        data: contentData,
                        jstreeData: data,
                        handleData: propsData?.handleData,
                        info: propsData?.info,
                        pkRecordsId: propsData?.pkRecordsId,
                        slotInfo: propsData?.slotInfo,
                        slotName: propsData?.slotName,
                        page: propsData?.page,
                        year: propsData?.year,
                        isParentTemplate: isParentTemplate,
                    },
                    modalVariant: 'primary',
                    modalDataKey: ['common', 'contentPanelPreview'],
                },
            });
        }
    }, [previewModalOpen]);

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            setLoading(false);
            async function createInitialData() {
                // await manageTreeData(data);
                // await manageTreeData(initialList?.data, setData, data, 'SelectContentPanelJstreeRendaring');
                await manageTreeData(
                    initialList?.data,
                    setData,
                    data,
                    'SelectContentPanelJstreeRendaring',
                    movedPath,
                    setMovedPath,
                    getInitialFolderList
                );
                dispatch(cpFolderReset());
            }
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.text || el?.name,
                        icon: el.icon,
                        children: el.child ? [] : [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        canBeDeleted: el.canBeDeleted,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                    });
                });
                setData([...data]);
            }
            dispatch(cpFolderReset());
        }
    }, [initialList]);

    // let countTotalJsTreeChildren = 0;

    // const manageTreeData = (list) => {
    //     const initialListData = initialList?.data;
    //     for (let i = 0; i < initialListData.length; i++) {
    //         const el = initialListData[i];
    //         list?.forEach((d) => {
    //             if (el?.parentId === d?.id) {
    //                 d.children.push({
    //                     id: el.id,
    //                     parent_id: el.parentId,
    //                     text: el?.text || el?.name,
    //                     icon: el.icon,
    //                     children: [],
    //                     child: el.child,
    //                     adminId: el.adminId,
    //                     assetId: el.assetId,
    //                     brandId: el.brandId,
    //                     canBeDeleted: el.canBeDeleted,
    //                     fkRoleId: el.fkRoleId,
    //                     fkOrgId: el.fkOrgId,
    //                     fkPaletteId: el.fkPaletteId,
    //                     assetRootNodeId: el.assetRootNodeId,
    //                 });
    //                 const filterArry = d.children.filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i);
    //                 d.children = [...filterArry];
    //             } else {
    //                 if (d.children.length > 0) {
    //                     manageTreeData(d.children);
    //                 }
    //             }
    //         });
    //         countTotalJsTreeChildren++;
    //         if (countTotalJsTreeChildren > treeLimit) {
    //             // countTotalJsTreeChildren = 0;
    //             break;
    //         }
    //     }
    //     setData([...list]);
    // };
    // const manageTreeData = (initialListData, setData, data, ContentPanelJstreeRendaring) => {
    //     if (initialListData?.length > 0) {
    //         const propsDataFlag = propsData?.flag || 0;
    //         let elemnt_ = document.getElementById(initialListData[0]?.parentId);
    //         let elemntArray_ = elemnt_.parentNode.childNodes;
    //         var index_ = Array.from(elemntArray_).indexOf(elemnt_);
    //         const clonedArray_ = JSON.parse(localStorage.getItem(ContentPanelJstreeRendaring)) || [];
    //         if (!clonedArray_?.find((f) => f.id === elemnt_.id)) {
    //             clonedArray_.push({ index: index_, id: elemnt_.id });
    //         }
    //         localStorage.setItem(ContentPanelJstreeRendaring, JSON.stringify(clonedArray_));
    //         let pathArray = getAllPathIds(initialListData[0]?.parentId);
    //         let obj = null;
    //         for (let index = 0; index < pathArray?.length; index++) {
    //             const clonedArray = JSON.parse(localStorage.getItem(ContentPanelJstreeRendaring));
    //             let new_ = clonedArray?.find((f) => f.id === pathArray[index]);
    //             if (index === 0) {
    //                 obj = data[new_.index];
    //             } else {
    //                 obj = obj.children[new_.index];
    //             }
    //             if (index === pathArray?.length - 1) {
    //                 initialListData?.forEach((el) => {
    //                     if (!obj?.children?.find((f) => f?.id === el?.id)) {
    //                         // d.child = false;
    //                         let disabled = false;
    //                         // if (
    //                         //     propsDataFlag === 2 &&
    //                         //     (el.icon == 'glyphicon glyphicon-doc-file' ||
    //                         //         el.icon == 'glyphicon glyphicon-video' ||
    //                         //         el.icon == 'glyphicon glyphicon-pdf-file')
    //                         // ) {
    //                         //     // image only
    //                         //     disabled = true;
    //                         // }
    //                         // if (
    //                         //     propsDataFlag === 3 &&
    //                         //     (el.icon == 'glyphicon glyphicon-image' || el.icon == 'glyphicon glyphicon-video')
    //                         // ) {
    //                         //     // doc only
    //                         //     disabled = true;
    //                         // }
    //                         // if (
    //                         //     propsDataFlag === 4 &&
    //                         //     (el.icon == 'glyphicon glyphicon-doc-file' || el.icon == 'glyphicon glyphicon-pdf-file')
    //                         // ) {
    //                         //     // doc not only
    //                         //     disabled = true;
    //                         // }
    //                         obj.children.push({
    //                             id: el.id,
    //                             parent_id: el.parentId,
    //                             text: el?.text || el?.name,
    //                             icon: el.icon,
    //                             children: [],
    //                             child: el.child,
    //                             adminId: el.adminId,
    //                             assetId: el.assetId,
    //                             brandId: el.brandId,
    //                             canBeDeleted: el.canBeDeleted,
    //                             fkRoleId: el.fkRoleId,
    //                             fkOrgId: el.fkOrgId,
    //                             fkPaletteId: el.fkPaletteId,
    //                             assetRootNodeId: el.assetRootNodeId,
    //                             state: {
    //                                 disabled,
    //                             },
    //                         });
    //                     }
    //                 });
    //             }
    //         }
    //         setData([...data]);
    //     }
    // };

    useEffect(() => {
        window.addEventListener('keydown', (e) => {
            if (
                e.target.closest('.visually-visible-modal.modal.show') &&
                (window.location.pathname.includes(RedirectTo('/sites-create')) ||
                    window.location.pathname.includes(RedirectTo('/reports-create')) ||
                    window.location.pathname.includes(RedirectTo('/systemsettings-btd')))
            ) {
                if (e.key == ' ' || e.code == 'Space' || e.keyCode == 32) {
                    setKeydown(true);
                    spacePreventDefault(e);
                    if (e?.target?.closest('.visually-visible-modal.modal.show')?.querySelector('.previewOnly')) {
                        RemoveTenantStyleSheet();
                    }
                }
                window.addEventListener('keyup', function () {
                    setKeydown(false);
                    e.preventDefault();
                });
                e.preventDefault();
            }
        });
        if (propsData?.jstreeData) {
            setData(propsData?.jstreeData);
            getInitialFolderList(propsData?.data?.parent_id);
        } else {
            localStorage.removeItem('activeJstreePath');
            setData([]);
            setTimeout(() => getInitialFolderList(), 1000);
        }
    }, []);
    useEffect(() => {
        if (keydown) {
            let contentData_ = JSON.parse(localStorage.getItem('tempContentData'));
            if (
                contentData_?.icon === 'glyphicon glyphicon-image' ||
                contentData_?.icon === 'glyphicon glyphicon-doc-file' ||
                contentData_?.icon === 'glyphicon glyphicon-video' ||
                contentData_?.icon === 'glyphicon glyphicon-pdf-file' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
                contentData_?.icon === 'fa-fa-folder-palette-closed' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-draft' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-draft' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-printable-draft' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable' ||
                contentData_?.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable-draft'
            ) {
                // if (modal?.isOpen && previewModalOpen) {
                //     setPreviewModalOpen(false);
                //     return false;
                // } else {
                //     setPreviewModalOpen(true);
                //     return false;
                // }
                let isModalOpen = document?.querySelector('.visually-visible-modal.modal.show .modal-title')?.innerText;
                if (isModalOpen?.includes('Select Content Panel') && !previewModalOpen) {
                    setPreviewModalOpen(true);
                    return false;
                } else if (previewModalOpen) {
                    let array = document?.querySelectorAll('.visually-visible-modal.modal.show button');
                    array.forEach((btn) => {
                        if (btn?.innerText === 'Back') btn.click();
                        setPreviewModalOpen(false);
                    });
                    return false;
                } else {
                    return false;
                }
            }
        }
    }, [keydown]);
    useEffect(() => {
        localStorage.removeItem('SelectContentPanelJstreeRendaring');
    }, []);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.querySelector(`.jstree [id="${parentId}"]`);
        if (ele) ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        dispatch(
            cpGetAllInitialFolder({
                processId: propsData?.page === 'multi-site' || propsData?.page === 'multi-report' ? 18 : 15,
                parentId,
            })
        );
    };

    function handleGetList(e, list) {
        const isEnable =
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-printable' ||
            list?.node?.original.icon === 'glyphicon glyphicon-text-background-cp-centrally-managed-printable';
        setIsParentTemplate(parentTemplate(list?.node?.parent));
        setIsSelectable(!isEnable);
        setCurrentSelectedData(list?.node?.original);
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        localStorage.setItem(
            'tempContentData',
            JSON.stringify({ ...list?.node?.original, parentRootId: list?.node?.parent })
        );
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
    }

    const handleSelect = () => {
        propsData?.slotName
            ? propsData?.handleData(currentSelectedData, propsData?.slotName, propsData?.pkRecordsId)
            : propsData?.handleData(currentSelectedData, propsData);
    };

    return (
        <Row>
            {treeListData && (
                <div className="my-2">
                    {loading && (
                        <div className="jstree-default">
                            <i className="loading jstree-icon jstree-ocl" />
                            <span className="loading-text">Loading...</span>
                        </div>
                    )}
                    <TreeView data={data} handleChange={handleGetList} />
                </div>
            )}
            <p className="mb-0 mt-1">
                <span className="ms-2 w-auto">Select content panel and press spacebar for preview</span>
            </p>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Select"
                    variant="outline-primary"
                    type="button"
                    disabled={isSelectable}
                    onClick={() => handleSelect()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.HIDE_MODAL,
                        })
                    }
                />
            </div>
        </Row>
    );
};

export default SelectContentPanelModal;
