// @flow
import React, { useEffect, useReducer, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
//actions
import {
    authReset,
    authLogin,
    authSSO,
    userAuthorizeDevice,
    userReset,
    useNotification,
    platformTenantDetails,
    publicReset,
    publicGetTenantFromEmail,
} from '@ovrture/react-redux';
import { loginValidation, publicLoginValidation } from '../../components/form/validation';
// components
import AccountLayout from '../account/AccountLayout';
import { FormInput } from '../../components/form/input';
import ButtonElement from '../../components/button/ButtonElement';
import { ConfigReducer, configState } from '../../reducers/ConfigReducer';
import { RecaptchaProvider, ReCaptchaVerify } from '../../recaptcha';
import service from '../../constants/service.constant';
import { getCurrentHost } from '../../helpers/retainHistory';
import { clearFilterStore } from '../../helpers/dataFiltering';

const UserLogin = () => {
    const [config] = useReducer(ConfigReducer, configState);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');

    // const [recaptchaSiteKey, setRecaptchaSiteKey] = useState(null);
    // const [ssoLoginURL, setSsoLoginURL] = useState(null);

    const { loading, user, error, lockedOut, authorizeDevice, emailSent, tenantDetails, publicState } = useSelector(
        (state) => ({
            loading: state?.auth?.loading,
            user: state?.auth?.user,
            error: state?.auth?.error,
            lockedOut: state?.auth?.lockedOut,
            authorizeDevice: state?.user?.authorizeDevice,
            emailSent: state?.general?.emailSent,
            tenantDetails: state?.platformKey?.tenantDetails,
            publicState: state?.publicReducer?.tenant,
        })
    );

    useEffect(() => {
        if (publicState?.data?.domain) {
            let newUrl = `${publicState?.data?.domain}/public/login`;
            window.open(newUrl + `?email=${email}`, '_self');
            dispatch(publicReset());
        }
    }, [publicState]);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({ resolver: publicLoginValidation });

    useEffect(() => {
        clearFilterStore();
        dispatch(platformTenantDetails({ serverName: getCurrentHost() }));
        document.querySelector('body').classList.add('user-login-body');
    }, []);

    useEffect(() => {
        if (tenantDetails?.code === service.success) {
            RecaptchaProvider('recaptcha_key', tenantDetails?.data?.recaptchaSiteKey, function () {});
        }
    }, [tenantDetails]);

    const onSubmit = (formData) => {
        ReCaptchaVerify(tenantDetails?.data?.recaptchaSiteKey, 'login_submit', function (token) {
            dispatch(
                publicGetTenantFromEmail({
                    email: formData['username'],
                    recaptchaResponse: token,
                })
            );
        });
        setEmail(formData['username']);
    };

    return (
        <>
            <div className="user-login">
                <AccountLayout userLogin="true">
                    <div className="text-left m-auto">
                        <p className="mb-3 pb-1 text-secondary login-text">
                            Enter your email below to be redirected to your organization’s specific Ovrture system
                            sign-in page.
                        </p>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormInput
                            className="mb-1"
                            type="text"
                            id="username"
                            label="Username/Email"
                            errors={errors}
                            register={register}
                        />
                        <div className="mt-2 mb-0 text-center">
                            <ButtonElement
                                name="REDIRECT WITH EMAIL"
                                variant="primary"
                                type="submit"
                                disabled={loading}
                                loading={loading}
                            />
                        </div>
                    </form>
                    <div className="text-left m-auto ">
                        <p className="mt-4 mb-0 text-secondary text-left login-text footer-text p-2">
                            We suggest you <b>bookmark the subsequent page/URL</b> for your organizations system to
                            avoid this step in the future.
                        </p>
                    </div>
                </AccountLayout>
            </div>
        </>
    );
};

export default UserLogin;
