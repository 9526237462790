// @flow
import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions, notificationActions } from '../../../reducers/actions';
import { useNotificationContex } from '../../../contexts/NotificationContext';
import { recordsReset, recordsFetchMagicLinkSite, recordsFetchMagicLinkReport } from '@ovrture/react-redux';

const MagicLinkCopyUrlModal = (props) => {
    const { notificationDispatch } = useNotificationContex();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();

    let { copySiteUrl, copyReportUrl } = useSelector((state) => ({
        copySiteUrl: state?.records?.fetchMagicLinkSite?.data,
        copyReportUrl: state?.records?.fetchMagicLinkReport?.data,
    }));

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        if (props?.data?.params?.type === 'Site') {
            sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'SKIP_LOADER');
            dispatch(recordsFetchMagicLinkSite({ pkRecordsId: props?.data?.params.recordId }));
        } else if (props?.data?.params.type === 'Report') {
            dispatch(
                recordsFetchMagicLinkReport({
                    pkRecordsId: props?.data?.params.recordId,
                    reportYear: props?.data?.original?.colFiscalYear,
                })
            );
        }
        return () => {
            dispatch(recordsReset());
        };
    }, []);

    const onSubmit = (data) => {};

    const handleCopyUrl = (text, msg) => {
        navigator.clipboard.writeText(text);
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: {
                modalAction: notificationDispatch({
                    type: notificationActions.OPEN_NOTIFICATION,
                    payload: {
                        role: 'success',
                        title: 'Success',
                        iconClass: 'mdi mdi-check-circle h2',
                        className: 'icon',
                        message: msg,
                    },
                }),
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <b className="d-block mb-1">Donor-Facing Magic Link URL (Analytics Enabled):</b>
                <Col lg={10}>
                    <FormInput
                        type="text"
                        id="urlEnabled"
                        className="urlEnabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.copyURL || copyReportUrl?.copyURL}
                    />
                </Col>
                <Col lg={2} className="px-1">
                    <ButtonElement
                        name="Copy"
                        variant="outline-primary"
                        type="button"
                        className="mb-3"
                        onClick={() =>
                            copySiteUrl?.copyURL || copyReportUrl?.copyURL
                                ? handleCopyUrl(
                                      copySiteUrl?.copyURL || copyReportUrl?.copyURL,
                                      'Donor-Facing magic link copied.'
                                  )
                                : modalDispatch({
                                      type: modalActions.CLOSE_MODAL,
                                  })
                        }
                    />
                </Col>
            </Row>
            <Row>
                <b className="d-block mb-1">Internal Review Magic Link URL (Analytics Disabled):</b>
                <Col lg={10} className="internal-review-input">
                    <FormInput
                        type="text"
                        id="urlDisabled"
                        register={register}
                        floatinglabel="false"
                        readOnly
                        value={copySiteUrl?.reviewURL || copyReportUrl?.reviewURL}
                    />
                </Col>
                <Col lg={2} className="px-1">
                    <ButtonElement
                        name="Copy"
                        variant="outline-primary"
                        type="button"
                        onClick={() =>
                            copySiteUrl?.reviewURL || copyReportUrl?.reviewURL
                                ? handleCopyUrl(
                                      copySiteUrl?.reviewURL || copyReportUrl?.reviewURL,
                                      'Internal review magic link copied.'
                                  )
                                : modalDispatch({
                                      type: modalActions.CLOSE_MODAL,
                                  })
                        }
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={(e) =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </form>
    );
};

export default MagicLinkCopyUrlModal;
