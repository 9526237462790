import { Col, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import TinyMceTextEditor from '../../../../components/form/TinyMceTextEditor';
import { useEffect, useState } from 'react';

const TinymceFieldReport = (props) => {
    const [mandatoryNullvalueText, setmandatoryNullvalueText] = useState(null);
    const [delay, setdelay] = useState(false);

    useEffect(() => {
        if (props?.data?.info?.colReportRenderingFields) {
            setmandatoryNullvalueText(JSON.parse(props?.data?.info?.colReportRenderingFields).mandatoryNullvalueText);
        }
    }, [props?.data?.info?.colReportRenderingFields]);

    useEffect(() => {
        if (mandatoryNullvalueText) {
            props.data.setValue('mandatoryNullvalueText', mandatoryNullvalueText?.value);
            setTimeout(() => {
                setdelay(true);
            }, 1000);
        }
    }, [mandatoryNullvalueText]);

    return (
        <>
            <Row className="mt-2 mb-2">
                {props?.data?.showTitle && (
                    <Col md={12} className="mb-1">
                        <b>{props?.data?.title}</b>
                    </Col>
                )}
                <Col md={7}>
                    <Controller
                        control={props?.data?.control}
                        name="mandatoryNullvalueText"
                        render={({ field }) =>
                            delay && (
                                <TinyMceTextEditor
                                    disableEnter={false}
                                    maxLength={mandatoryNullvalueText?.max_length}
                                    height="200"
                                    errors={props?.data?.errors}
                                    id="mandatoryNullvalueText"
                                    className="editor-height"
                                    register={props?.data?.register}
                                    value={field?.value}
                                    disabled={!props?.data?.tinymceToggle || false}
                                    setValue={(val, id) => {
                                        props.data.setValue(id, val);
                                    }}
                                />
                            )
                        }
                    />
                </Col>
            </Row>
        </>
    );
};
export default TinymceFieldReport;
