import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { store } from '@ovrture/react-redux';
import { BrowserRouter } from 'react-router-dom';
import { TreeViewProvider } from './contexts/TreeViewContext';
import { TimeRemainingProvider } from './contexts/TimeRemainingContext';
import { CustomProvider } from './contexts/CustomContext';
import { NotificationProvider } from '@ovrture/react-redux';
import { unregister as unregisterServiceWorker } from 'register-service-worker';

ReactDOM.render(
    <BrowserRouter basename={process.env.REACT_APP_ROUTE_PREFIX + '/'}>
        <Provider store={store({})}>
            <NotificationProvider dismissTime={3000}>
                <TimeRemainingProvider>
                    <CustomProvider>
                        <TreeViewProvider>
                            <App />
                        </TreeViewProvider>
                    </CustomProvider>
                </TimeRemainingProvider>
            </NotificationProvider>
        </Provider>
    </BrowserRouter>,
    document.getElementById('rootComponent')
);
unregisterServiceWorker();
