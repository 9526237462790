// @flow
import React from 'react';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { modalActions } from '../../../reducers/actions';
import { useDispatch } from 'react-redux';
import { useModalContext } from '../../../contexts/ModalContext';
import {
    userAdminPlatformBulkHardDelete,
    userAdminPlatformBulkSoftDelete,
    userAdminPlatformBulkRestore,
    emailTemplateBulkRestore,
} from '@ovrture/react-redux';
const RestoreDeletedDataActionModalPA = (props) => {
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    return (
        <>
            {props?.data?.name === 'bulkUserRestore' ? (
                <p>Are you sure you want to restore this/these user(s)?</p>
            ) : props?.data?.name === 'UserRestore' ? (
                <p>Are you sure you want to restore this user?</p>
            ) : props?.data?.name === 'bulkEmailRestore' ? (
                <p>Are you sure you want to restore this/these email template(s)?</p>
            ) : props?.data?.name === 'bulkRestoreBroadcast' ? (
                <p>Are you sure you want to restore this/these broadcast(s)?</p>
            ) : (
                <p>Are you sure you want to delete this user(s) permanently?</p>
            )}
            <div className="modal-footer d-flex justify-content-center">
                <ButtonElement
                    name="Proceed"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        const isSelectAll = props.data.isSelectAll;
                        if (props.data.name === 'bulkUserRestore') {
                            modalDispatch({ type: modalActions.CLOSE_MODAL });
                            dispatch(
                                userAdminPlatformBulkRestore({
                                    userIdList: isSelectAll ? [] : props.data.selectedUserArr.map((item) => item.id),
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        } else if (props.data.name === 'bulkSoftDelete') {
                            modalDispatch({ type: modalActions.CLOSE_MODAL });
                            dispatch(
                                userAdminPlatformBulkSoftDelete({
                                    userIdList: isSelectAll ? [] : props.data.selectedUserArr.map((item) => item.id),
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        } else if (props.data.name === 'bulkEmailRestore') {
                            modalDispatch({ type: modalActions.CLOSE_MODAL });
                            dispatch(
                                emailTemplateBulkRestore({
                                    userIdList: isSelectAll ? [] : props.data.selectedUserArr.map((item) => item.id),
                                    isSelectAll,
                                    isAnd: true,
                                    filterCriteria: {},
                                    idsToSkip: props?.data?.idsToSkip,
                                })
                            );
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default RestoreDeletedDataActionModalPA;
