// @flow
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { FormInput } from '../../../../components/form/input';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions, customActions } from '../../../../reducers/actions';
import { reportingYearReportNotExistList, reportingYearAdd } from '@ovrture/react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { useCustomContext } from '../../../../contexts/CustomContext';

const AddNewFyData = (props) => {
    const { modalDispatch } = useModalContext();
    const { customDispatch, customState } = useCustomContext();
    const { handleSubmit, watch, register } = useForm({});
    const watchAll = watch();
    const dispatch = useDispatch();
    const { years, me } = useSelector((state) => ({
        years: state?.reportDataReportingYear?.reportNotExistList,
        me: state?.auth?.me,
    }));

    const onSubmit = (data) => {
        const year = data?.fiscalyear != '0' ? data?.fiscalyear : years[0];
        const payload = {
            fkOrgId: me?.organaisationId,
            colReportYear: year,
        };
        dispatch(reportingYearAdd(payload));
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
        });
        customDispatch({
            type: customActions.ADD_REPORT_YEAR,
            payload: year,
        });
    };

    useEffect(() => {
        dispatch(reportingYearReportNotExistList());
    }, []);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="custom-padding">
                    <p>Manually add new fiscal year to report data.</p>
                    <Row>
                        <Col md={6} className="w-auto mt-2">
                            <FormInput
                                type="select"
                                register={register}
                                id="fiscalyear"
                                label="Select"
                                className="mt-1 custom-select">
                                <option value="0">Fiscal Year</option>
                                {years &&
                                    years.length > 0 &&
                                    years.map((year) => (
                                        <option key={year} value={year}>
                                            {year}
                                        </option>
                                    ))}
                            </FormInput>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex justify-content-center modal-footer">
                    <ButtonElement name="Add" variant="primary" type="submit" />
                    <ButtonElement
                        name="Cancel"
                        variant="light"
                        type="button"
                        onClick={(e) =>
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            })
                        }
                    />
                </div>
            </form>
        </>
    );
};

export default AddNewFyData;
