// @flow
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import { NewFundValidation } from '../../../../components/form/validation';
import { modalActions } from '../../../../reducers/actions';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import { useDispatch, useSelector } from 'react-redux';
import { fundTypeAdd, fundTypeList } from '@ovrture/react-redux';
import { useModalContext } from '../../../../contexts/ModalContext';

const NewFundModal = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const { add, paletteList, orgFetch } = useSelector((state) => ({
        add: state?.fundType?.add,
        paletteList: state?.palette?.list?.data,
        orgFetch: state?.organization?.fetch,
    }));

    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({ resolver: NewFundValidation, mode: 'onBlur' });

    const watchAll = watch();

    const [isValidated, setIsValidated] = useState(false);

    useEffect(() => {
        if (add) {
            dispatch(fundTypeList({ orgId: props?.data?.orgId }));
            setTimeout(() => {
                props?.hideModal();
                modalDispatch({
                    type: modalActions.OPEN_MODAL,
                    payload: {
                        modalAction: (e) => props.hideModal(),
                        modalVariant: 'primary',
                        modalDataKey: ['platformAdmin', 'fundType'],
                        data: {
                            title: orgFetch?.data?.name,
                        },
                    },
                });
            }, 1000);
        }
        if (props?.data?.orgId) setValue('orgId', props.data.orgId);
    }, [props?.data, add]);

    useEffect(() => {
        if (
            watchAll &&
            watchAll.colFundKey !== '' &&
            watchAll.colFundTypeName !== '' &&
            watchAll.fkTblPaletteId !== 'Select Palette'
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);

    const onSubmit = (data) => {
        if (props?.data?.orgId) dispatch(fundTypeAdd(data));
    };

    const toggle = ({ row }) => {
        props?.hideModal();
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) => props.hideModal(),
                modalVariant: 'primary',
                modalDataKey: ['platformAdmin', 'fundType'],
                data: {
                    title: orgFetch?.data?.name,
                    original: row?.original,
                },
            },
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Col md={6}>
                <FormInput type="hidden" id="orgId" register={register} />
                <FormInput type="select" id="fkTblPaletteId" label="Palette *" register={register}>
                    <option value="">Select</option>
                    {paletteList?.map((item, index) => {
                        return (
                            <option key={index} value={item?.pkPaletteId}>
                                {item?.colPaletteName}
                            </option>
                        );
                    })}
                </FormInput>
            </Col>
            <FormInput
                type="text"
                id="colFundTypeName"
                label="Fund Type *"
                maxLength="75"
                errors={errors}
                register={register}
            />

            <FormInput
                type="text"
                id="colFundKey"
                label="Uploader Fund Code *"
                errors={errors}
                register={register}
                maxLength="10"
            />
            <div className="button-list  d-flex justify-content-center">
                <ButtonElement name="Save" variant="primary" type="submit" disabled={!isValidated} />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default NewFundModal;
