// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import {
    templateContentsFetch,
    templateContentsUpdate,
    templateContentsReset,
    templateReportContentsFetch,
    templateContentsAdd,
    templateContentsSoftDelete,
    templateReportContentsSoftDelete,
} from '@ovrture/react-redux';
// components
import { SiteEditElementValidation } from '../../../../components/form/validation';
import { FormInput } from '../../../../components/form/input';
import ButtonElement from '../../../../components/button/ButtonElement';
import service from '../../../../constants/service.constant';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';

const EditBrandDetails = (props) => {
    const dispatch = useDispatch();
    const { modalDispatch } = useModalContext();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        watch,
        reset,
        formState: { errors },
    } = useForm({ resolver: SiteEditElementValidation, mode: 'onBlur' });
    const watchPalette = watch('paletteId');
    const watchAll = watch();
    const [isValidated, setIsValidated] = useState(false);
    const [paletteID, setPaletteID] = useState(null);
    const [templateContentId, setTemplateContentId] = useState();

    const { paletteList, fetch, error, update, add, deleted, deletedReport } = useSelector((state) => ({
        paletteList: state?.palette?.list?.data,
        error: state?.palette?.error,
        fetch: state?.templateContents?.fetch || state?.templateContents?.reportFetch,
        update: state?.templateContents?.update,
        add: state?.templateContents?.add,
        deleted: state?.templateContents?.softDelete,
        deletedReport: state?.templateContents?.softDeleteReport,
    }));
    const onSubmit = (data) => {
        if (props?.data?.isForSite) {
            if (fetch?.data?.id) {
                dispatch(
                    templateContentsUpdate({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: true,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: false,
                    })
                );
            } else {
                dispatch(
                    templateContentsAdd({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: true,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: false,
                    })
                );
            }
        } else {
            if (fetch?.data?.pkReportTemplateContentsId) {
                dispatch(
                    templateContentsUpdate({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: false,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: data?.reportUpdateCompatibilityAccessible,
                    })
                );
            } else {
                dispatch(
                    templateContentsAdd({
                        colCampaign: data?.colCampaign,
                        colContact: data?.colContact,
                        colFooter: data?.colFooter,
                        colGallery: data?.colGallery,
                        messageMaxCount: data?.messageMaxCount,
                        colHeader: data?.colHeader,
                        colIndex: data?.colIndex,
                        paletteId: Number(data?.paletteId),
                        templateId: props?.data?.original?.id,
                        orgId: props?.data?.original?.fkOrgId,
                        isForSite: false,
                        emailBugPath: data?.emailBugPath,
                        bugTextDetails: data?.bugTextDetails,
                        reportUpdateCompatibilityAccessible: data?.reportUpdateCompatibilityAccessible,
                    })
                );
            }
        }
    };

    useEffect(() => {
        if (paletteID && paletteID !== '0' && paletteID != 0) {
            if (props?.data?.isForSite) {
                dispatch(
                    templateContentsFetch({
                        templateId: props?.data?.original?.id,
                        paletteId: paletteID,
                        orgId: props?.data?.original?.fkOrgId,
                    })
                );
            } else {
                dispatch(
                    templateReportContentsFetch({
                        templateId: props?.data?.original?.id,
                        paletteId: paletteID,
                        orgId: props?.data?.original?.fkOrgId,
                    })
                );
            }
        }
    }, [paletteID]);

    useEffect(() => {
        setValue('colCampaign', null);
        setValue('colHeader', null);
        setValue('colFooter', null);
        setValue('messageMaxCount', null);
        setValue('templateId', null);
        setValue('colIndex', null);
        setValue('colContact', null);
        setValue('messageMaxCount', null);
        setValue('emailBugPath', null);
        setValue('bugTextDetails', null);
        setValue('reportUpdateCompatibilityAccessible', null);
    }, [paletteID]);

    useEffect(() => {
        if (
            watchAll &&
            watchAll.bugTextDetails !== '' &&
            watchAll.colContact !== '' &&
            watchAll.colIndex !== '' &&
            watchAll.emailBugPath !== '' &&
            watchAll.messageMaxCount !== '' &&
            watchAll.paletteId !== '0'
        ) {
            setIsValidated(true);
        } else {
            setIsValidated(false);
        }
    }, [watchAll]);
    useEffect(() => {
        if (fetch && fetch?.code === service.success) {
            setValue('paletteId', fetch?.data.fkPalletId);
            setValue('colCampaign', fetch?.data.colCampaign);
            setValue('colHeader', fetch?.data.colHeader);
            setValue('colFooter', fetch?.data.colFooter);
            setValue('isForSite', props?.data.isForSite);
            setValue('colGallery', fetch?.data.colGallery);
            setValue('templateId', fetch?.data.templateId || fetch?.data.fkTemplateId);
            setValue('orgId', fetch?.data.orgId || fetch?.data.fkOrgId);
            setValue('colIndex', fetch?.data?.colIndex);
            setValue('colContact', fetch?.data?.colContact);
            setValue('messageMaxCount', fetch?.data?.colGreetiingMsgMaxCount);
            setValue('emailBugPath', fetch?.data?.emailBugPath);
            setValue('bugTextDetails', fetch?.data?.bugTextDetails);
            setValue('reportUpdateCompatibilityAccessible', fetch?.data?.colReportUpdateCompatibilityAccessible);
            setTemplateContentId(fetch?.data?.pkReportTemplateContentsId || fetch?.data?.id);
        }

        if (update || deleted || deletedReport) {
            reset();
            props.hideModal();
            dispatch(templateContentsReset());
        }

        if (add) {
            reset();
            props.hideModal();
            dispatch(templateContentsReset());
        }
    }, [error, fetch, update, add, deleted, deletedReport]);

    const toggle = () => {
        props.hideModal();
        dispatch(templateContentsReset());
    };
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col md={6}>
                    <FormInput id="templateId" register={register} />
                    <FormInput id="orgId" register={register} />
                    <Col
                        md={fetch && fetch?.code === service.success ? 8 : 6}
                        className={fetch && fetch?.code === service.success ? 'change-log-custom-style' : ''}>
                        <FormInput
                            onChange={(e) => setPaletteID(e.target.value)}
                            type="select"
                            id="paletteId"
                            label="Palette *"
                            register={register}
                            className={fetch?.data?.colIsDeleted === true ? 'mb-0' : 'mb-3'}
                            errors={errors}>
                            <option value="0">Select</option>
                            {paletteList?.map((item, index) => {
                                return (
                                    <option key={index} value={item?.pkPaletteId}>
                                        {item?.colPaletteName}
                                    </option>
                                );
                            })}
                        </FormInput>
                        {fetch && fetch?.code === service.success && (
                            <ButtonElement
                                icon={
                                    <i
                                        className={
                                            'mdi icon-large change-log-icon ' + process.env.REACT_APP_CHANGE_LOG_ICON
                                        }></i>
                                }
                                className={
                                    process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === true ||
                                    process.env.REACT_APP_CHANGE_LOG_HIDE_PLATFORM === 'true'
                                        ? 'd-none'
                                        : 'action-icon hover-primary custom-svg change-log'
                                }
                                tooltip="Change Log"
                                onClick={() => {
                                    let contentTemplateKey = props?.data?.isForSite
                                        ? 'fkTemplateContentsId'
                                        : 'fkReportTemplateContentsId';
                                    let contentTemplatevalue = props?.data?.isForSite
                                        ? fetch?.data?.id
                                        : fetch?.data?.pkReportTemplateContentsId;
                                    modalDispatch({
                                        type: modalActions.OPEN_MODAL,
                                        payload: {
                                            modalAction: (e) =>
                                                modalDispatch({
                                                    type: modalActions.CLOSE_MODAL,
                                                }),
                                            modalVariant: 'primary',
                                            modalDataKey: ['changeLog', 'changeLogCommonModulePlatform'],
                                            data: {
                                                changeLogObj: {
                                                    key: contentTemplateKey,
                                                    value: contentTemplatevalue,
                                                    orgId: fetch?.data?.orgId
                                                        ? fetch?.data?.orgId
                                                        : fetch?.data?.fkOrgId,
                                                },
                                            },
                                        },
                                    });
                                }}
                            />
                        )}
                        {fetch?.data?.colIsDeleted === true && (
                            <p className="mb-0 text-danger mb-3 soft-delete">(SOFT DELETED)</p>
                        )}
                    </Col>
                </Col>
                {(fetch?.data?.id || fetch?.data?.pkReportTemplateContentsId) && (
                    <Col md={6} className="id-block">
                        <div className="float-right text-end">
                            <h4 className="text-end mt-0">
                                Element # {fetch?.data?.pkReportTemplateContentsId || fetch?.data?.id}
                            </h4>
                        </div>
                    </Col>
                )}

                {!props?.data?.isForSite && (
                    <Row>
                        <Col lg={12}>
                            <span className="me-2">Report Update Compatibility Access:</span>
                            <FormInput
                                className="me-4 mb-3"
                                type="switch"
                                id="reportUpdateCompatibilityAccessible"
                                label={watchAll?.reportUpdateCompatibilityAccessible === true ? 'ON' : 'OFF'}
                                register={register}
                                disabled={fetch?.data?.colIsDeleted === true}
                            />
                        </Col>
                    </Row>
                )}
                <Col md={12}>
                    <FormInput
                        className="custom-textarea mb-3"
                        type="textarea"
                        as="textarea"
                        id="colIndex"
                        label="Index Html *"
                        errors={errors}
                        register={register}
                        height="true"
                        disabled={fetch?.data?.colIsDeleted === true}
                    />
                    <FormInput
                        className="custom-textarea mb-3"
                        type="textarea"
                        as="textarea"
                        id="colContact"
                        label="Contact Html *"
                        errors={errors}
                        register={register}
                        height="true"
                        disabled={fetch?.data?.colIsDeleted === true}
                    />
                    <FormInput
                        type="text"
                        id="messageMaxCount"
                        label="Greeting Max Limit *"
                        errors={errors}
                        register={register}
                        disabled={fetch?.data?.colIsDeleted === true}
                    />
                    <FormInput
                        type="text"
                        id="emailBugPath"
                        label="Email Bug Path *"
                        maxLength="300"
                        errors={errors}
                        register={register}
                        disabled={fetch?.data?.colIsDeleted === true}
                    />
                    <FormInput
                        className="custom-textarea"
                        type="textarea"
                        as="textarea"
                        id="bugTextDetails"
                        label="Email Bug Details *"
                        errors={errors}
                        register={register}
                        height="true"
                        disabled={fetch?.data?.colIsDeleted === true}
                    />
                </Col>
            </Row>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Update"
                    variant="primary"
                    type="submit"
                    disabled={!isValidated || fetch?.data?.colIsDeleted === true}
                />
                <ButtonElement
                    name="Delete"
                    variant="danger"
                    type="button"
                    className={templateContentId && fetch?.data?.colIsDeleted === false ? '' : 'd-none'}
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.OPEN_MODAL,
                            payload: {
                                modalAction: (e) => {
                                    {
                                        props?.data?.isForSite
                                            ? dispatch(
                                                  templateContentsSoftDelete({
                                                      orgId: fetch?.data?.orgId,
                                                      templateContentId: templateContentId,
                                                  })
                                              )
                                            : dispatch(
                                                  templateReportContentsSoftDelete({
                                                      orgId: fetch?.data?.fkOrgId,
                                                      reportTemplateContentsId: templateContentId,
                                                  })
                                              );
                                    }
                                },
                                modalVariant: 'danger',
                                modalDataKey: ['platformAdmin', 'deleteActionsPA'],
                                data: {
                                    name: 'softDeleteContentElement',
                                    title: 'Delete Brand Elements',
                                    message: 'Are you sure you want to delete this brand elements?',
                                },
                            },
                        })
                    }
                />
                <ButtonElement name="Cancel" variant="light" type="button" onClick={toggle} />
            </div>
        </form>
    );
};

export default EditBrandDetails;
