import React from 'react';
import ButtonElement from '../../../components/button/ButtonElement';
import MFA from '../../public/MFA';

const ResetMFAModal = (props) => {
    let obj = {
        deviceRegistered: false,
        // mfaToken: 'X4G36SXSP',
        profile: true,
    };
    return (
        <>
            <MFA mfaEnforced={obj} hideModal={props.hideModal()} data={props?.data} />
        </>
    );
};

export default ResetMFAModal;
