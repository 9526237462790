// @flow
import React, { useState, useEffect } from 'react';
import { Col, Row, ListGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
// components
import ButtonElement from '../../../../components/button/ButtonElement';
import { FormInput } from '../../../../components/form/input';
import { useModalContext } from '../../../../contexts/ModalContext';
import { modalActions } from '../../../../reducers/actions';
import HyperDatepicker from '../../../../components/datepicker/Datepicker';
import {
    recordsList,
    fundLinkSingle,
    recordFundReset,
    donorsCreate,
    donorsUpdate,
    donorsListByIdYear,
    recordsReset,
    donorsReset,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import ListItem from '../../../../components/listItem';
import { fundDonorsValidation } from '../../../../components/form/validation';

const AddDonorModal = (props) => {
    const { modalDispatch } = useModalContext();
    const [selectedDate, setSelectedDate] = useState(null);
    const [enteredRecordVal, setEnteredRecordVal] = useState([]);
    const [recordId, setrecordId] = useState(null);
    const [recordList, setRecordList] = useState(null);
    const [addUpdateDonorButtonClass, setAddUpdateDonorButtonClass] = useState('disabled');
    const dispatch = useDispatch();
    const { records, donorDetail, donorItems } = useSelector((state) => ({
        funds: state?.fund?.list,
        records: state?.records?.list,
        linkSingle: state?.fund?.linkSingle,
        unlinkSingle: state?.fund?.unlinkSingle,
        donorItems: state?.donors?.donorSearchList?.data,
        donorDetail: state?.donors?.donorList?.data,
    }));

    const {
        register,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ resolver: fundDonorsValidation, mode: 'onBlur' });
    const onAddBtnHandler = (e) => {
        setEnteredRecordVal(e.target.value);
    };
    const onDateChange = (date) => {
        setSelectedDate(date);
    };

    useEffect(() => {
        if (donorDetail) {
            setValue('colFdAmount', donorDetail.colFdAmount);
            setValue('colFdDateOfLastGift', moment(donorDetail.colFdDateOfLastGift).format('MM/DD/YYYY'));
            setValue('colFdTotalAmountGivenToFund', donorDetail.colFdTotalAmountGivenToFund);
            setValue('colFdMatchFy0', donorDetail.colFdMatchFy0);
            donorDetail?.colFdDateOfLastGift && setSelectedDate(new Date(donorDetail.colFdDateOfLastGift));
        }
    }, [donorDetail]);

    const onSubmit = (data) => {
        const donorData = {
            colFdReportYear: data.colFdReportYear || props?.data.colFdReportYear,
            fkFundId: props?.data.pkFundId,
            colFdAmount: data.colFdAmount,
            colFdDateOfLastGift: selectedDate ? moment(selectedDate).format('MM/DD/YYYY') : null,
            colFdTotalAmountGivenToFund: data.colFdTotalAmountGivenToFund,
            colFdMatchFy0: data.colFdMatchFy0,
        };
        const lst = props?.data?.donorList
            ? props?.data?.donorList.filter((d) => d.tblRecords.colRecordReferenceNo == data?.recordNumber)
            : [];
        if (props.data.type !== 'add' || lst.length > 0) {
            if (lst.length > 0) {
                donorData.pkFundDonorsId = parseInt(lst[0].pkFundDonorsId);
                donorData.fkRecordsId = lst[0]?.tblRecords?.pkRecordsId;
                sessionStorage.setItem(process.env.REACT_APP_DASHBOARD_SESSION_GENERIC_MSG_KEY, 'Donor created.');
            } else {
                donorData.pkFundDonorsId = parseInt(props?.data?.pkFundDonorsId);
                donorData.fkRecordsId = donorDetail?.tblRecords?.pkRecordsId;
            }
            dispatch(donorsUpdate({ ...donorData }));
        } else {
            donorData.fkRecordsId = recordId;
            recordId && dispatch(donorsCreate({ ...donorData }));
        }

        props.hideModal();
    };

    useEffect(() => {
        props?.data.pkFundDonorsId &&
            dispatch(
                donorsListByIdYear({
                    pkFundDonorsId: props?.data.pkFundDonorsId,
                    reportYear: props?.data.colFdReportYear,
                })
            );
    }, [props?.data?.pkFundDonorsId]);

    useEffect(() => {
        enteredRecordVal != '' &&
            dispatch(
                recordsList({
                    isAnd: true,
                    isUnpaged: true,
                    searchText: enteredRecordVal,
                    donorFundIdForSoftDeletedCheck: props.data.pkFundId,
                    userSoftDeletedCheck: true,
                })
            );
    }, [enteredRecordVal]);

    useEffect(() => {
        if (records?.content) {
            setRecordList(records?.content);
            dispatch(recordsReset());
        }
    }, [records?.content]);

    const handleAddRecord = (pkRecordsId) => {
        dispatch(recordFundReset());
        pkRecordsId && dispatch(fundLinkSingle({ fkRecordsId: pkRecordsId, fkFundId: props.data.pkFundId }));
    };
    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                {props.data.type === 'add' ? (
                    <Row>
                        <Col md={12} className="mb-3">
                            <FormInput
                                type="text"
                                id="recordNumber"
                                label="Record Reference Number for Donor"
                                className="mb-0"
                                maxLength="30"
                                errors={errors}
                                feedback="hide"
                                register={register}
                                onChange={(e) => setEnteredRecordVal(e.target.value)}
                                disabled={enteredRecordVal && recordId ? true : false}
                            />
                            {enteredRecordVal.length > 0 && !recordId && (
                                <>
                                    <ListGroup className="listheader custom-list-group-ellipsis custom-listgroup h-auto">
                                        {recordList?.map((rcd) => {
                                            return (
                                                <ListItem
                                                    onClick={() => {
                                                        setEnteredRecordVal(rcd.colRecordFullName);
                                                        setValue('recordNumber', rcd.colRecordReferenceNo);
                                                        setValue('colRecordFullName', rcd.colRecordFullName);
                                                        setrecordId(rcd.pkRecordsId);
                                                        setAddUpdateDonorButtonClass(
                                                            rcd.colRecordFullName.length > 0 ? '' : 'disabled'
                                                        );
                                                    }}>
                                                    {rcd.colRecordFullName}
                                                </ListItem>
                                            );
                                        })}
                                        {recordList && recordList?.length === 0 && (
                                            <b className="p-2">No record found.</b>
                                        )}
                                    </ListGroup>
                                </>
                            )}
                        </Col>
                        {enteredRecordVal && recordId && (
                            <>
                                <Col md={12}>
                                    <FormInput
                                        type="text"
                                        id="colRecordFullName"
                                        className="mb-2"
                                        label="Donor - Record Name"
                                        errors={errors}
                                        register={register}
                                        readOnly
                                    />
                                </Col>
                                <Col md={12}>
                                    <Row className="mb-2">
                                        <Col md={9} className="mb-1">
                                            <b>Donor - Amount</b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            Limit: 15 numerals
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type="number"
                                                id="colFdAmount"
                                                className="mb-2"
                                                floatinglabel="false"
                                                register={register}
                                                errors={errors}
                                                maxLength="15"
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                </Col>

                                <Col md={12} className="mb-4">
                                    <b>Donor - Date Of Last Gift</b>
                                    <Col md={12}>
                                        <HyperDatepicker
                                            value={selectedDate}
                                            inputClass="form-control-light"
                                            onChange={(date) => {
                                                onDateChange(date);
                                            }}
                                        />
                                    </Col>
                                </Col>
                                <Col md={12}>
                                    <Row className="mb-2">
                                        <Col md={9} className="mb-1">
                                            <b>Donor - Total Amount Given To Fund</b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            Limit: 15 numerals
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type="number"
                                                id="colFdTotalAmountGivenToFund"
                                                className="mb-2"
                                                floatinglabel="false"
                                                register={register}
                                                errors={errors}
                                                maxLength="15"
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12}>
                                    <Row className="mb-2">
                                        <Col md={9} className="mb-1">
                                            <b>Donor - Match FY</b>
                                        </Col>
                                        <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                            Limit: 15 numerals
                                        </Col>
                                        <Col md={12}>
                                            <FormInput
                                                type="number"
                                                id="colFdMatchFy0"
                                                floatinglabel="false"
                                                register={register}
                                                errors={errors}
                                                maxLength="15"
                                                negative="true"
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </>
                        )}
                    </Row>
                ) : (
                    <Row>
                        <Col md={12}>
                            <FormInput
                                type="text"
                                id="recordNumber"
                                label="Record Reference Number for Donor"
                                className="mb-0"
                                maxLength="30"
                                value={donorDetail?.tblRecords?.colRecordReferenceNo}
                                readOnly
                            />
                        </Col>

                        <Col md={12}>
                            <FormInput
                                type="text"
                                id="donorRecordName"
                                className="mb-2"
                                label="Donor - Record Name"
                                value={donorDetail?.tblRecords?.colRecordFullName}
                                readOnly
                            />
                        </Col>
                        <Col md={12}>
                            <Row className="mb-2">
                                <Col md={9} className="mb-1">
                                    <b>Donor - Amount</b>
                                </Col>
                                <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                    Limit: 15 numerals
                                </Col>
                                <Col md={12}>
                                    <FormInput
                                        type="number"
                                        id="colFdAmount"
                                        className="mb-2"
                                        floatinglabel="false"
                                        register={register}
                                        errors={errors}
                                        maxLength="15"
                                        negative="true"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12} className="mb-4">
                            <b>Donor - Date Of Last Gift</b>
                            <Col md={12}>
                                <HyperDatepicker
                                    value={selectedDate}
                                    inputClass="form-control-light"
                                    onChange={(date) => {
                                        onDateChange(date);
                                    }}
                                />
                            </Col>
                        </Col>
                        <Col md={12}>
                            <Row className="mb-2">
                                <Col md={9} className="mb-1">
                                    <b>Donor - Total Amount Given To Fund</b>
                                </Col>
                                <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                    Limit: 15 numerals
                                </Col>
                                <Col md={12}>
                                    <FormInput
                                        type="number"
                                        id="colFdTotalAmountGivenToFund"
                                        className="mb-2"
                                        floatinglabel="false"
                                        register={register}
                                        errors={errors}
                                        maxLength="15"
                                        negative="true"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={12}>
                            <Row className="mb-2">
                                <Col md={9} className="mb-1">
                                    <b>Donor - Match FY</b>
                                </Col>
                                <Col md={3} className="text-md-end text-sm-start mb-1 custom-font">
                                    Limit: 15 numerals
                                </Col>
                                <Col md={12}>
                                    <FormInput
                                        type="number"
                                        id="colFdMatchFy0"
                                        floatinglabel="false"
                                        register={register}
                                        errors={errors}
                                        maxLength="15"
                                        negative="true"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
                <div className="button-list d-flex justify-content-center">
                    <ButtonElement
                        name={props.data.type === 'add' ? 'Add' : 'Update'}
                        variant="primary"
                        type="submit"
                        className={props.data.type === 'add' && addUpdateDonorButtonClass}
                    />
                    <ButtonElement
                        name="Close"
                        variant="light"
                        type="button"
                        onClick={(e) => {
                            dispatch(donorsReset());
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            });
                        }}
                    />
                </div>
            </form>
        </>
    );
};

export default AddDonorModal;
