// @flow
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import { contentSlides } from '@ovrture/react-redux';
import service from '../../../constants/service.constant';
import {
    arrowInitHandler,
    indicatorsSingleInitHandler,
    updateCarouselId,
    updateDublicateElementForEditContentPanel,
} from '../migration/TenantMigration';
import { setEditorChanges } from '../storage';
import { RedirectTo } from '../../../routes/role/path';
import { useNotification } from '@ovrture/react-redux/dist/helpers/toasterNotification/NotificationContext';
import { getContentPanelIsReplace } from '../../retainHistory';
import { cmpNonEditablePanel } from '../notification/lockUnlock';

const AddOrChangeSlide = (props) => {
    const { Toast } = useNotification();
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [sliderList, setSliderList] = useState([]);

    const { details, slides, loading } = useSelector((state) => ({
        details: state?.auth?.me,
        slides: state?.content?.slides,
        loading: state?.content?.loading,
    }));

    useEffect(() => {
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            dispatch(contentSlides({ defaultContentPanelId: props?.data?.cpId }));
        }
    }, []);

    useEffect(() => {
        if (
            props?.data?.slideHtml ||
            (slides?.code === service.success &&
                (window.location.pathname.includes(RedirectTo('/site-edit')) ||
                    window.location.pathname.includes(RedirectTo('/report-edit'))))
        ) {
            sliderViewList();
        }
    }, [props?.data?.slideHtml, slides]);

    const sliderViewList = () => {
        let isReplace = getContentPanelIsReplace();
        let items = parseDomFromString();
        let list = [];
        for (let index = 0; index < items?.length; index++) {
            let dataSpecialId = '';
            const slidesNumber = document?.querySelector('#rootComponent')?.getAttribute('slidesecclass-id');
            let isSpecials;
            if (slidesNumber === null) {
                isSpecials =
                    document?.querySelector(`[cpid="${props?.data?.cpId}"] .carousel.isSpecials`) ||
                    document?.querySelector(`[cpid="${props?.data?.cpId}"] .carousel`);
            } else {
                isSpecials =
                    document?.querySelector(`#${slidesNumber} [cpid="${props?.data?.cpId}"] .carousel.isSpecials`) ||
                    document?.querySelector(`#${slidesNumber} [cpid="${props?.data?.cpId}"] .carousel`);
            }

            // const isSpecials = document?.querySelector(`#${slidesNumber} [cpid="${props?.data?.cpId}"] .carousel`);
            if (isSpecials) {
                if (isSpecials?.getAttribute('data-special-id'))
                    dataSpecialId = '-' + isSpecials?.getAttribute('data-special-id');
            }
            list.push({
                index: isReplace ? index + 1 : index,
                // url: `${fetchS3Url(details?.organaisationId)}/assets/slider-view/SliderView${index + 1}.png`,
                url: `https://asset.common.ovrture.com/Org_${
                    details?.organaisationId
                }/slider-view${dataSpecialId}/SliderView${index + 1}.png`,
            });
        }
        setSliderList(list);
    };

    const parseDomFromString = () => {
        // let doc = new DOMParser().parseFromString(slides?.data, 'text/html');
        let doc = new DOMParser().parseFromString(
            props?.data?.slideHtml ? props?.data?.slideHtml : slides?.data,
            'text/html'
        );
        return doc.querySelectorAll('.item.lastItem');
    };

    const slideAddOrReplace = (item) => {
        let index = null;
        let items = parseDomFromString();
        let isReplace = getContentPanelIsReplace();
        // let doc = new DOMParser().parseFromString(slideHtml, 'text/html');
        // let items = doc.querySelectorAll('.carousel .carousel-inner .item.lastItem');
        if (isReplace) {
            let activeElement = document?.querySelector('.carousel .carousel-inner .item.active');
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                const ele = document.getElementById('rootComponent');
                const replacerId = ele.getAttribute('slideSecClass-id');
                //const slideSecClass = document.getElementById(replacerId);
                //index = getActiveIndex(slideSecClass?.querySelectorAll('.carousel .carousel-inner .item'));
                activeElement = document?.querySelector('#' + replacerId + ' .carousel .carousel-inner .item.active');
            }
            index = [].indexOf.call(activeElement.parentNode.children, activeElement);
            activeElement?.children[0].remove();
            if (item?.index === 0) {
                removeClassStd(items[item?.index].children[0]);
                activeElement.appendChild(items[item?.index].children[0]);
            } else {
                removeClassStd(items[item?.index - 1].children[0]);
                activeElement.appendChild(items[item?.index - 1].children[0]);
            }
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                setEditorChanges(true);
            }
        } else {
            if (
                window.location.pathname.includes(RedirectTo('/site-edit')) ||
                window.location.pathname.includes(RedirectTo('/report-edit'))
            ) {
                const ele = document.getElementById('rootComponent');
                const replacerId = ele.getAttribute('slideSecClass-id');
                const slideSecClass = document.getElementById(replacerId);
                const carouselItems = slideSecClass?.querySelectorAll('.carousel .carousel-inner .item')?.length;
                let carouselItemActive = slideSecClass.querySelector('.carousel .carousel-inner .item.active');
                index = [].indexOf.call(carouselItemActive.parentNode.children, carouselItemActive) + 1 || 0;
                carouselItemActive.classList.remove('active');
                if (!items[item?.index].classList.contains('carousel-item'))
                    items[item?.index].classList.add('carousel-item');
                items[item?.index].classList.add('active');
                if (carouselItems === 1) {
                    removeClassStd(items[item?.index]);
                    document
                        .getElementById(replacerId)
                        .querySelector('.carousel .carousel-inner')
                        .appendChild(items[item?.index]);
                } else {
                    removeClassStd(items[item?.index]);
                    document
                        .getElementById(replacerId)
                        .querySelector('.carousel .carousel-inner')
                        .insertBefore(items[item?.index], carouselItemActive.nextSibling);
                }
                //index = getActiveIndex(slideSecClass?.querySelectorAll('.carousel .carousel-inner .item'));
            } else {
                let activeElement = document?.querySelector('.carousel .carousel-inner .item.active');
                if (activeElement === null || activeElement === undefined) {
                    activeElement = document?.querySelector('.carousel .carousel-inner .item');
                    activeElement.classList.add('active');
                }
                document.querySelector('.carousel .carousel-inner .item.active').classList.remove('active');
                let doc = new DOMParser().parseFromString(
                    props?.data?.slideHtml ? props?.data?.slideHtml : slides?.data,
                    'text/html'
                );
                items = doc.querySelectorAll('.carousel .carousel-inner .item.lastItem');
                if (!items[item?.index]?.classList.contains('carousel-item'))
                    items[item?.index]?.classList.add('carousel-item');
                items[item?.index]?.classList.add('active');
                if (items[item?.index]) removeClassStd(items[item?.index]);
                //document.querySelector('.carousel .carousel-inner').appendChild(items[item?.index]);
                activeElement.insertAdjacentHTML('afterend', items[item?.index].outerHTML);
                let carouselItemActive = document.querySelector('.carousel .carousel-inner .item.active');
                index = [].indexOf.call(carouselItemActive.parentNode.children, carouselItemActive);
            }
        }
        Toast?.success({ description: isReplace ? 'Slide layout changed.' : 'Slide added.' });
        props?.data?.slideModified();
        updateCarouselId(true);
        cmpNonEditablePanel();
        indicatorsSingleInitHandler(index);
        arrowInitHandler();
        updateDublicateElementForEditContentPanel();
        if (
            window.location.pathname.includes(RedirectTo('/site-edit')) ||
            window.location.pathname.includes(RedirectTo('/report-edit'))
        ) {
            setEditorChanges(true);
        }
        modalDispatch({
            type: modalActions.CLOSE_MODAL,
            payload: {
                modalDataKey: ['editor', 'addOrChangeSlide'],
            },
        });
    };

    const removeClassStd = (item) => {
        if (item?.classList?.contains('lastItem')) {
            item.classList.remove('lastItem');
        }
    };

    // const getActiveIndex = (items) => {
    //     for (let i = 0; i < items.length; i++) items[i].setAttribute('childitemid', i.toString());
    //     for (let i = 0; i < items.length; i++) {
    //         const element = items[i];
    //         if (element?.classList?.contains('active')) {
    //             return i;
    //         }
    //     }
    // };

    return (
        <>
            <div className={sliderList.length === 1 ? 'add-change-slide justifyContent' : 'add-change-slide'}>
                {sliderList?.map((item) => (
                    <img
                        key={item?.index}
                        className={getContentPanelIsReplace() ? 'm-2 replace' : 'm-2 add'}
                        alt="image"
                        src={item?.url}
                        onClick={() => {
                            console.log('item', item);
                            if (!loading) {
                                getContentPanelIsReplace()
                                    ? modalDispatch({
                                          type: modalActions.OPEN_MODAL,
                                          payload: {
                                              modalAction: (e) => {
                                                  modalDispatch({
                                                      type: modalActions.CLOSE_MODAL,
                                                      payload: { noActive: true },
                                                  });
                                                  slideAddOrReplace(item);
                                              },
                                              modalVariant: 'primary',
                                              modalDataKey: ['editor', 'changeSlideConfirmation'],
                                              data: {
                                                  modalActionOnClickCancelButton: () =>
                                                      modalDispatch({
                                                          type: modalActions.CLOSE_MODAL,
                                                          payload: { noActive: true },
                                                      }),
                                              },
                                          },
                                      })
                                    : slideAddOrReplace(item);
                            }
                        }}
                    />
                ))}
            </div>

            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => modalDispatch({ type: modalActions.CLOSE_MODAL, payload: { noActive: true } })}
                />
            </div>
        </>
    );
};

export default AddOrChangeSlide;
