// @flow
import React, { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
// components
import ButtonElement from '../../../components/button/ButtonElement';
import TreeView from '../../../components/treeview';
import {
    assePreview,
    assetReset,
    assetFolderViewReset,
    assetFolderViewInitialListChange,
    assetFolderViewInitialListChangeReset,
} from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { useTreeViewContext } from '../../../contexts/TreeViewContext';
import { modalActions, treeViewActions } from '../../../reducers/actions';
import { treeData } from '../../../constants/treeData';
import { useModalContext } from '../../../contexts/ModalContext';
import { spacePreventDefault } from '../../../helpers/retainHistory';
import { getAllPathIds } from '../../../components/treeview/react-simple-jstree';

const ManageDownloadReplaceAssetModal = (props) => {
    const { modalDispatch } = useModalContext();
    const { treeState, treeDispatch } = useTreeViewContext();
    const [addfolderDisabled, setAddFolderDisabled] = useState(true);
    const [treeListData, setTreeListData] = useState(null);
    const [data, setData] = useState([]);
    const [toNode, setToNode] = useState(null);
    const [selectedNode, setSelectedNode] = useState(false);
    const dispatch = useDispatch();
    const [flag, setFlag] = useState(false);
    const [loading, setLoading] = useState(true);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [contentData, setContentData] = useState();

    const { initialList, assetPreview } = useSelector((state) => ({
        initialList: state?.assetFolderView?.initialListChange,
        assetPreview: state?.asset?.preview,
    }));

    useEffect(() => {
        localStorage.removeItem('ManageDownloadJstreeRendaring');
        if (props?.data?.contentData) {
            setData(props?.data?.contentData);
            let activeJstreePath = JSON.parse(localStorage.getItem('activeJstreePath'));
            if (activeJstreePath?.length > 0) {
                setTimeout(() => {
                    const ele = document.getElementById(activeJstreePath[0]);
                    if (ele) {
                        ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
                    }
                }, 100);
                let id = activeJstreePath[activeJstreePath?.length - 1];
                setTimeout(() => {
                    getInitialFolderList(id);
                }, 1000);
                setTimeout(() => {
                    let element = document?.getElementById(id)?.querySelector('.jstree-anchor');
                    if (element) element.click();
                }, 2500);
            }
        } else {
            getInitialFolderList();
        }
    }, []);

    // useEffect(() => {
    //     if (selectedNode && assetPreview?.data) {
    //         dispatch(assetReset());
    //         setFlag(false);
    //         window.open(assetPreview?.data, '_blank');
    //     }
    // }, [assetPreview]);

    const getInitialFolderList = (parentId = '#') => {
        const ele = document.getElementById(parentId);
        if (ele) ele.getElementsByClassName('jstree-icon jstree-ocl')[0].classList.add('loading');
        dispatch(assetFolderViewInitialListChange({ processId: '', parentId }));
    };

    useEffect(() => {
        if (treeState?.treeData) {
            treeDispatch({
                type: treeViewActions.GET_TREE_DATA,
                payload: {
                    treeData: treeData,
                },
            });
        }
        if (treeState?.treeData) {
            setTreeListData(treeState);
        }
    }, [treeState?.treeData]);

    useEffect(() => {
        if (initialList?.data) {
            async function createInitialData() {
                //await manageTreeData(data);
                await manageTreeData(initialList?.data, setData, data, 'ManageDownloadJstreeRendaring');
            }
            setLoading(false);
            if (data.length > 0) {
                createInitialData(initialList?.data);
            } else {
                initialList?.data?.forEach((el) => {
                    if (el?.text === 'RECYCLE BIN') return;
                    data.push({
                        id: el.id,
                        parent_id: el.id,
                        text: el?.name || el?.text,
                        icon: el.icon,
                        children: [],
                        child: el.child,
                        adminId: el.adminId,
                        assetId: el.assetId,
                        brandId: el.brandId,
                        fkRoleId: el.fkRoleId,
                        fkOrgId: el.fkOrgId,
                        fkPaletteId: el.fkPaletteId,
                        assetRootNodeId: el.assetRootNodeId,
                        userId: el.userId,
                        permissionbyId: el.permissionbyId,
                        canBeDeleted: el.canBeDeleted,
                    });
                });
                setData([...data]);
            }
            dispatch(assetFolderViewReset());
        }
    }, [initialList]);

    const manageTreeData = (initialListData, setData, data, ManageDownloadJstreeRendaring) => {
        if (initialListData?.length > 0) {
            let elemnt_ = document.getElementById(initialListData[0]?.parentId);
            let elemntArray_ = elemnt_.parentNode.childNodes;
            var index_ = Array.from(elemntArray_).indexOf(elemnt_);
            const clonedArray_ = JSON.parse(localStorage.getItem(ManageDownloadJstreeRendaring)) || [];
            if (!clonedArray_?.find((f) => f.id === elemnt_.id)) {
                clonedArray_.push({ index: index_, id: elemnt_.id });
            }
            localStorage.setItem(ManageDownloadJstreeRendaring, JSON.stringify(clonedArray_));
            let pathArray = getAllPathIds(initialListData[0]?.parentId);
            let obj = null;
            for (let index = 0; index < pathArray?.length; index++) {
                const clonedArray = JSON.parse(localStorage.getItem(ManageDownloadJstreeRendaring));
                let new_ = clonedArray?.find((f) => f.id === pathArray[index]);
                if (index === 0) {
                    obj = data[new_.index];
                } else {
                    obj = obj.children[new_.index];
                }
                if (index === pathArray?.length - 1) {
                    initialListData?.forEach((el) => {
                        if (!obj?.children?.find((f) => f?.id === el?.id)) {
                            obj.child = false;
                            let disabled = false;
                            if (props?.data?.isImageDisable === false) {
                                if (
                                    !el.child &&
                                    (el.icon !== 'glyphicon glyphicon-image' ||
                                        el.icon !== 'glyphicon glyphicon-video') &&
                                    (el.icon === 'glyphicon glyphicon-doc-file' ||
                                        el.icon === 'glyphicon glyphicon-pdf-file')
                                ) {
                                    disabled = true;
                                }
                            } else if (
                                !el.child &&
                                el.icon !== 'glyphicon glyphicon-doc-file' &&
                                el.icon !== 'glyphicon glyphicon-pdf-file' &&
                                (el.icon === 'glyphicon glyphicon-image' || el.icon === 'glyphicon glyphicon-video')
                            ) {
                                disabled = true;
                            }
                            obj.children.push({
                                id: el.id,
                                parent_id: el.parentId,
                                text: el?.text || el?.name,
                                icon: el.icon,
                                children: [],
                                child: el.child,
                                adminId: el.adminId,
                                assetId: el.assetId,
                                brandId: el.brandId,
                                fkRoleId: el.fkRoleId,
                                fkOrgId: el.fkOrgId,
                                fkPaletteId: el.fkPaletteId,
                                userId: el.userId,
                                assetRootNodeId: el.assetRootNodeId,
                                permissionbyId: el.permissionbyId,
                                canBeDeleted: el.canBeDeleted,
                                state: {
                                    disabled,
                                },
                            });
                        }
                    });
                }
            }
            setData([...data]);
        }
    };

    function handleGetList(e, list) {
        setToNode(list?.node);
        setSelectedNode(false);
        if (list.event?.type === 'dblclick' && list?.node?.original?.id && list?.node?.original?.child) {
            getInitialFolderList(list?.node?.original?.id);
        }
        setContentData({ ...list?.node?.original, parentRootId: list?.node?.parent });
        if (list?.node?.original?.id) {
            if (list?.node.parent === '#' && list?.node?.original.text === 'SYSTEM CLOUD') {
                setAddFolderDisabled(true);
            } else if (
                (list?.node?.original.icon === 'mdi mdi-cloud' && list?.node?.original.text === 'BRAND CLOUD') ||
                list?.node?.original.icon === 'mdi mdi-folder-lock'
            ) {
                setAddFolderDisabled(true);
            } else if (
                list?.node?.original.icon === 'fa-fa-folder-brand-closed' ||
                list?.node?.original.icon === 'fa-fa-folder-palette-closed' ||
                list?.node?.original.icon === 'mdi mdi-folder-account'
            ) {
                setAddFolderDisabled(true);
            } else if (list?.node.children.length) {
                setAddFolderDisabled(true);
            } else if (list?.node.icon === 'mdi mdi-folder') {
                setAddFolderDisabled(true);
            } else if (list?.node.icon === 'fa-fa-folder-brand-closed') {
                setAddFolderDisabled(true);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-video' ||
                list?.node.icon === 'glyphicon glyphicon-image'
            ) {
                const status =
                    props?.data?.isImageDisable === false &&
                    (list?.node.icon === 'glyphicon glyphicon-image' || list?.node.icon === 'glyphicon glyphicon-video')
                        ? false
                        : true;
                setAddFolderDisabled(status);
            } else if (
                list?.node.icon === 'glyphicon glyphicon-doc-file' ||
                list?.node.icon === 'glyphicon glyphicon-pdf-file'
            ) {
                setAddFolderDisabled(false);
                setFlag(false);
                if (list?.node.icon === 'glyphicon glyphicon-pdf-file') {
                    setSelectedNode(list?.node);
                } else {
                    setSelectedNode(false);
                }
            }
        }
    }

    const onSubmit = () => {
        setSelectedNode(false);
        dispatch(assetFolderViewInitialListChangeReset());
        props.data.handleActions(toNode?.original, props?.data?.name, props?.data?.currentYear);
        modalDispatch({
            type: modalActions.OPEN_MODAL,
            payload: {
                modalAction: (e) =>
                    modalDispatch({
                        type: modalActions.CLOSE_MODAL,
                    }),
                modalVariant: 'primary',
                modalDataKey: ['systemAdmin', 'manageDownloads'],
                data: {
                    recordId: props?.data?.recordId,
                    currentYear: props?.data?.currentYear,
                },
            },
        });
    };

    useEffect(() => {
        if (flag && selectedNode) {
            dispatch(assePreview({ assetFolderViewId: selectedNode?.id }));
        }
    }, [flag]);

    useEffect(() => {
        if (previewModalOpen === true) {
            dispatch(assetReset());
            setTimeout(() => {
                if (
                    contentData?.icon === 'glyphicon glyphicon-image' ||
                    contentData?.icon === 'glyphicon glyphicon-pdf-file' ||
                    contentData?.icon === 'glyphicon glyphicon-video'
                ) {
                    modalDispatch({
                        type: modalActions.OPEN_MODAL,
                        payload: {
                            modalAction: (e) => {
                                modalDispatch({
                                    type: modalActions.CLOSE_MODAL,
                                });
                            },
                            modalVariant: 'primary',
                            modalDataKey: ['common', 'assetPanelPreview'],
                            data: {
                                contentData: data,
                                data: contentData,
                                activeJstreePath: JSON.parse(localStorage.getItem('activeJstreePath')),
                                setPreviewModalOpen: setPreviewModalOpen,
                                propsData: props?.data,
                            },
                        },
                    });
                } else {
                    // if (contentData?.icon === 'glyphicon glyphicon-pdf-file') {
                    //     dispatch(assePreview({ assetFolderViewId: contentData?.id }));
                    // }
                    setPreviewModalOpen(false);
                }
            }, 200);
        }
    }, [previewModalOpen]);

    document.addEventListener('keydown', (event) => {
        if (document.getElementsByClassName('custom-card-height')[0])
            document.getElementsByClassName('custom-card-height')[0].style.overflow = 'hidden';
        if (selectedNode && event.keyCode === 32 && !flag) {
            setFlag(true);
        }
        setTimeout(() => {
            if (document.getElementsByClassName('custom-card-height')[0])
                document.getElementsByClassName('custom-card-height')[0].style.overflow = 'auto';
        }, 800);
        if (event.key === ' ' || event.code === 'Space' || event.keyCode === 32) {
            if (
                (contentData?.icon === 'glyphicon glyphicon-image' ||
                    contentData?.icon === 'glyphicon glyphicon-video' ||
                    contentData?.icon === 'glyphicon glyphicon-pdf-file') &&
                document?.querySelector('.modal-dialog.asset-preview') === null &&
                document?.querySelector('.modal-dialog.asset-rename') === null &&
                document?.querySelector('.modal-dialog.folder-rename') === null &&
                !previewModalOpen
            ) {
                setPreviewModalOpen(true);
            }
            spacePreventDefault(event);
            return false;
        }
    });

    return (
        <Row className="jstree-custom-style">
            {loading && (
                <div className="jstree-default">
                    <i className="loading jstree-icon jstree-ocl" />
                    <span className="loading-text">Loading...</span>
                </div>
            )}
            {treeListData && <TreeView data={data} handleChange={handleGetList} />}
            <p className="mb-0 mt-1">
                <span className="ms-2 w-auto">Select asset and press spacebar for preview</span>
            </p>
            <div className="button-list d-flex justify-content-center mt-3">
                <ButtonElement
                    name="Replace"
                    variant="outline-primary"
                    type="button"
                    disabled={addfolderDisabled}
                    onClick={() => onSubmit()}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() => {
                        if (props?.data?.page === 'edit-impact') {
                            dispatch(assetFolderViewReset());
                            modalDispatch({
                                type: modalActions.CLOSE_MODAL,
                            });
                        } else {
                            dispatch(assetFolderViewInitialListChangeReset());
                            modalDispatch({
                                type: modalActions.OPEN_MODAL,
                                payload: {
                                    modalAction: (e) =>
                                        modalDispatch({
                                            type: modalActions.CLOSE_MODAL,
                                        }),
                                    modalVariant: 'primary',
                                    modalDataKey: ['systemAdmin', 'manageDownloads'],
                                    data: {
                                        recordId: props?.data?.recordId,
                                        currentYear: props?.data?.currentYear,
                                    },
                                },
                            });
                        }
                    }}
                />
            </div>
        </Row>
    );
};

export default ManageDownloadReplaceAssetModal;
