// @flow
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useTable } from 'react-table';
// components
import { analyticReset, changeLogViewDetailsList } from '@ovrture/react-redux';
import ButtonElement from '../../../components/button/ButtonElement';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';

const ChangeLogViewDetails = (props) => {
    const { modalDispatch, modal } = useModalContext();
    const dispatch = useDispatch();
    const { id } = useParams();

    let { listChangeLogDetails } = useSelector((state) => ({
        listChangeLogDetails: state?.analyticSession?.changeLogViewDetails?.data,
        // loading: state?.analytic?.loading,
    }));

    useEffect(() => {
        let payload = { activityLogId: props?.data?.activityLogId };
        if (props?.data?.orgId) {
            payload = { activityLogId: props?.data?.activityLogId, orgId: props?.data?.orgId };
        }
        dispatch(changeLogViewDetailsList(payload));
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Field Name',
                accessor: 'key',
            },
            {
                Header: 'Updated Value',
                accessor: 'value',
            },
        ],
        []
    );

    // Create the table instance
    let dataArray;
    if (props?.data?.pageName === 'BtdSite' || props?.data?.pageName === 'BtdReport') {
        dataArray = listChangeLogDetails
            ? Object.keys(listChangeLogDetails)
                  .sort((a, b) => {
                      const numA = parseInt(a.replace(/[^0-9]/g, ''));
                      const numB = parseInt(b.replace(/[^0-9]/g, ''));
                      return numA - numB;
                  })
                  .map((key) => ({ key, value: listChangeLogDetails[key] }))
            : [];
    } else {
        dataArray = listChangeLogDetails
            ? Object.entries(listChangeLogDetails).map(([key, value]) => ({
                  key,
                  value: typeof value === 'boolean' ? value.toString() : value,
              }))
            : [];
    }
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
        columns,
        data: dataArray,
    });

    return (
        <>
            <Card>
                <Card.Body>
                    <div className="table-responsive" role="table">
                        <table className="table table-centered react-table view-details-table" {...getTableProps()}>
                            <thead className="table-light">
                                {headerGroups.map((headerGroup) => (
                                    <tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                                        ))}
                                    </tr>
                                ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>
                                {rows.map((row, index) => {
                                    prepareRow(row);
                                    return (
                                        <tr key={index} {...row.getRowProps()}>
                                            {row.cells.map((cell, cellIndex) => (
                                                <td key={cellIndex} {...cell.getCellProps()}>
                                                    {cell.render('Cell')}
                                                </td>
                                            ))}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </Card.Body>
            </Card>
            {/* {props?.name === 'change-log' && ( */}
            <div className="button-list d-flex justify-content-center">
                <ButtonElement
                    name="Close"
                    variant="light"
                    type="button"
                    onClick={() => {
                        // if (
                        //     !window.location.href.includes('/system-log') &&
                        //     !window.location.href.includes('/account')
                        // ) {
                        //     modalDispatch({
                        //         type: modalActions.OPEN_MODAL,
                        //         payload: {
                        //             modalAction: (e) =>
                        //                 modalDispatch({
                        //                     type: modalActions.CLOSE_MODAL,
                        //                 }),
                        //             modalVariant: 'primary',
                        //             modalDataKey: ['changeLog', 'changeLogCommonModule'],
                        //             data: {
                        //                 changeLogObj: props?.data['changeLogObj'],
                        //             },
                        //         },
                        //     });
                        // } else {
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        });
                        // }
                    }}
                />
            </div>
            {/* )} */}
        </>
    );
};

export default ChangeLogViewDetails;
