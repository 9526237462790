import {
    engagementModule,
    exportsModule,
    accountModule,
    reportDataModule,
    privacyPolicyModule,
    profileModule,
    deletedDataModule,
} from '../module/generalModules';
import { portfolioModule } from '../module/portfolioModule';
import { systemSettingsModule } from '../module/systemSettingsModule';

// System Admin left sidebar
export const DataAdminRoutes = [
    { ...portfolioModule },
    { ...reportDataModule },
    { ...profileModule },
    { ...systemSettingsModule },
    { ...engagementModule },
    { ...exportsModule },
    { ...deletedDataModule },
    { ...accountModule },
    { ...privacyPolicyModule },
];
