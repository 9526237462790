// @flow
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
//actions
import { totpPublic, totpValidate, authLogin, totpFetch, totpValidateProfile, totpReset } from '@ovrture/react-redux';
// components
import { mfaValidation } from '../../components/form/validation';
import { FormInput } from '../../components/form/input';
import ButtonElement from '../../components/button/ButtonElement';
import service from '../../constants/service.constant';
import { useModalContext } from '../../contexts/ModalContext';
import { modalActions } from '../../reducers/actions';

const MFA = (props) => {
    const { modalDispatch } = useModalContext();
    const dispatch = useDispatch();
    const [qrCode, setQrCode] = useState(null);
    const [qrImage, setQrImage] = useState(null);

    const { publicData, validate, validateProfile } = useSelector((state) => ({
        publicData: state?.totp?.public?.data || state?.totp?.fetch?.data,
        validate: state?.totp?.validate,
        validateProfile: state?.totp?.validateProfile,
    }));

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({ resolver: mfaValidation, criteriaMode: 'all', reValidateMode: 'onChange', mode: 'onChange' });

    useEffect(() => {
        if (props?.mfaEnforced?.profile) {
            dispatch(totpFetch());
        } else {
            !props?.mfaEnforced?.deviceRegistered && dispatch(totpPublic({ token: props?.mfaEnforced?.mfaToken }));
        }
    }, []);

    useEffect(() => {
        if (publicData) {
            setQrImage(publicData?.qrCodeData);
            setQrCode(publicData?.code);
        }
    }, [publicData]);

    useEffect(() => {
        if (validate?.code === service.accepted) {
            dispatch(totpReset());
            setTimeout(() => {
                dispatch(authLogin({ code: props?.mfaEnforced?.mfaToken, otp: getValues('mfaCode') }));
            }, 2000);
        }
    }, [validate]);

    useEffect(() => {
        if (validateProfile?.code === service.accepted) {
            dispatch(totpReset());
            modalDispatch({
                type: modalActions.CLOSE_MODAL,
            });
        }
    }, [validateProfile]);

    useEffect(() => {
        if (validate?.code === service.accepted) {
            setTimeout(() => {
                window.location.reload();
            }, 2000);
        }
    }, [validate]);

    const onSubmit = (formData) => {
        if (props?.mfaEnforced?.profile) {
            dispatch(totpValidateProfile({ otp: formData?.mfaCode }));
        } else {
            props?.mfaEnforced?.deviceRegistered
                ? dispatch(authLogin({ code: props?.mfaEnforced?.mfaToken, otp: getValues('mfaCode') }))
                : dispatch(totpValidate({ code: props?.mfaEnforced?.mfaToken, otp: formData?.mfaCode }));
        }
    };

    return (
        <>
            <div className={props?.mfaEnforced?.profile ? 'mfa profile-mfa-margin' : 'mfa mt-0'}>
                {props?.mfaEnforced?.deviceRegistered ? (
                    <>
                        <p>Enter the code generated by the Authenticator app below.</p>
                    </>
                ) : (
                    <>
                        <p className="mfa-configuration-title">Configure multi-factor authentication (MFA)</p>
                        <div className="mfa-content">
                            <ol>
                                <li>
                                    <p>
                                        Download an authenticator app that supports Time-Based One-Time Password (TOTP)
                                        on your mobile device.
                                    </p>
                                </li>
                                <li>
                                    <p>Open the app and scan the QR code below to pair your mobile device.</p>
                                    <div style={{ textAlign: 'center' }}>
                                        {qrImage && <img src={qrImage} alt="QR code" />}
                                    </div>
                                    <p>Or type in: {qrCode}</p>
                                </li>
                                <li>
                                    <p>Enter the code generated by the Authenticator app below.</p>
                                </li>
                            </ol>
                        </div>
                    </>
                )}

                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={props?.mfaEnforced?.deviceRegistered ? 'mt-3 deviceRegistered' : 'mt-3'}>
                    <FormInput
                        type="number"
                        id="mfaCode"
                        label="6 digit verification code"
                        maxLength="6"
                        errors={errors}
                        register={register}
                    />
                    <div className="d-flex justify-content-center  modal-footer">
                        <ButtonElement
                            name={props?.mfaEnforced?.deviceRegistered ? 'Verify' : 'Pair Device'}
                            variant={props?.mfaEnforced?.profile ? 'outline-primary' : 'primary'}
                            type="submit"
                            className={props?.mfaEnforced?.profile ? '' : 'w-100'}
                        />
                        {props?.mfaEnforced?.profile && (
                            <ButtonElement
                                name="Cancel"
                                variant="light"
                                type="button"
                                onClick={(e) => {
                                    modalDispatch({
                                        type: modalActions.CLOSE_MODAL,
                                    });
                                }}
                            />
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default MFA;
