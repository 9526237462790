export const errorHandler = () => {
    window.onerror = (err) => {
        if (err.includes('Node cannot be null')) return true;
        if (err.includes('Cannot read properties of null')) return true;
        if (err.includes("reading 'reset'")) return true;
        if (err.includes('process is not defined')) return true;
        else return false;
    };
    return () => {
        window.onerror = null;
    };
};
