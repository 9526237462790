import { RedirectTo } from '../../../routes/role/path';
import { checkForLockIconPositionOnLeft } from '../../checkers';
import { getTenantOrgId } from '../../retainHistory';
import { removeTox, tinymceInlineInitFactory } from '../tinymce/TinyMceInlineTextEditor';

export const lockedAndUnlocked = (e, tinymce) => {
    try {
        if (
            (e.offsetX > e.target.offsetWidth ||
                (checkForLockIconPositionOnLeft?.includes(Number(getTenantOrgId())) &&
                    e.offsetX < 0 &&
                    e.offsetX < e.target.offsetWidth &&
                    e.srcElement.classList.contains('lock-position-left'))) &&
            e.srcElement.classList.contains('assetseditor')
        ) {
            removeTox();
            if (e.target.classList.contains('non-editable')) {
                e.target.setAttribute('contenteditable', 'true');
                e.target.classList.remove('lock', 'non-editable');
                // tinymce edit
                // tinymce.EditorManager.execCommand('mceAddEditor', true, e.target.id);
                e?.target?.id
                    ? tinymceInlineInitFactory('#' + e.target.id)
                    : tinymceInlineInitFactory('.inline-textEditor:not(.non-editable)');
            } else {
                // this method has to be on top
                tinymce.EditorManager.execCommand('mceRemoveEditor', true, e.target.id);
                e.target.setAttribute('contenteditable', 'false');
                e.target.classList.remove(
                    'mce-content-body',
                    'mce-edit-focus',
                    'tinymceEditorAllowed',
                    'mce-visual-caret'
                );
                e.target.classList.add('lock', 'non-editable');
                document.activeElement.blur();
            }
            if (e?.target?.getAttribute('onblur')?.trim() === 'copytext(this);') {
                window?.copytext && window.copytext(e?.target);
            }
            if (e?.target?.getAttribute('onblur')?.trim() === 'subHeadingCopyText(this);') {
                window?.subHeadingCopyText && window.subHeadingCopyText(e?.target);
            }
        }
    } catch (err) {
        console.log(err);
    }
};

export const cmpNonEditablePanel = () => {
    if (!window?.location?.pathname?.includes(RedirectTo('/content-panel-edit'))) {
        lockPanel();
        setTimeout(() => lockPanel(), 2000);
    }
};

const lockPanel = () => {
    const nonEditable = document.querySelectorAll('.centerally-managed-cp');
    nonEditable.forEach((cmp) => {
        cmp?.querySelectorAll('.inline-textEditor').forEach((element) => {
            element.setAttribute('contenteditable', 'false');
            element.classList.remove('mce-content-body', 'mce-edit-focus', 'tinymceEditorAllowed', 'mce-visual-caret');
            element.classList.add('lock', 'non-editable');
        });
    });
};

export const cmpMakeEditablePanel = () => {
    if (window?.location?.pathname?.includes(RedirectTo('/content-panel-edit'))) {
        setTimeout(() => {
            const nonEditable = document.querySelectorAll('.centerally-managed-cp');
            nonEditable.forEach((cmp) => {
                cmp?.querySelectorAll('.non-editable.lock').forEach((element) => {
                    element.setAttribute('contenteditable', 'true');
                    element.classList.add('mce-content-body');
                    element.classList.remove('lock', 'non-editable');
                });
                cmp?.querySelectorAll('.assetseditor.non-editable').forEach((element) => {
                    element.classList.remove('non-editable');
                });
            });
        }, 2000);
    }
};
