import { Row, Col, Card } from 'react-bootstrap';

const TableFooterAction = (props) => {
    const {
        selectedValue,
        title,
        action,
        isSelectAll,
        totalElements,
        uncheckedSelection,
        tempCheckedList,
        isAllCheckboxSelectedOfAnyPage,
    } = props;

    return (
        (tempCheckedList?.length > 0 || isSelectAll) && (
            <Card.Footer>
                <Row className="gutter-5 align-items-center">
                    <Col xs="auto">
                        {
                            <strong>
                                {isSelectAll
                                    ? totalElements - uncheckedSelection || totalElements
                                    : tempCheckedList?.length}
                            </strong>
                        }{' '}
                        {title}
                    </Col>
                    {action?.map((actionItem, index) => {
                        return (
                            <Col xs="auto" key={index}>
                                {actionItem}
                            </Col>
                        );
                    })}
                </Row>
            </Card.Footer>
        )
    );
};

export default TableFooterAction;
