//@flow
import React, { useState } from 'react';
import MultiStepForm from '../../../helpers/multiStep/Index';
//components
import CreateNewEditableContentPanelModal from './CreateNewEditableContentPanelModal';
import CreateNewContentPanelModal, { TYPE_NAME } from './CreateNewContentPanelModal';
import SelectTemplateWithPreview from './selectTemplateWithPreviewModal';
import SelectImpactDataFieldsModal from './SelectImpactDataFieldsModal';

const CreateDynamicContentPanelMultiStepForm = (props) => {
    const [active, setActive] = useState(0);
    const [cpFolderViewId, setCpFolderViewId] = useState(null);
    const [contentPanelType, setcontentPanelType] = useState(null);

    const steps = [
        {
            name: 'Content Panel Type',
            component: (
                <CreateNewContentPanelModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                    setcontentPanelType={setcontentPanelType}
                    contentPanelType={contentPanelType}
                />
            ),
        },
        {
            name:
                contentPanelType === TYPE_NAME.IMPACT_SURVEYOR.ID
                    ? TYPE_NAME.IMPACT_SURVEYOR.NAME + ' Content Panel'
                    : contentPanelType === TYPE_NAME.INTERACTION.ID
                    ? TYPE_NAME.INTERACTION.NAME + ' Content Panel'
                    : contentPanelType === TYPE_NAME.INHERITED.ID
                    ? TYPE_NAME.INHERITED.NAME + ' Content Panel'
                    : TYPE_NAME.EDITABLE.NAME + ' Content Panel',
            component: (
                <CreateNewEditableContentPanelModal
                    active={active}
                    setActive={setActive}
                    data={props.data}
                    hideModal={props.hideModal}
                    name="contentPanel"
                    setCpFolderViewId={setCpFolderViewId}
                    cpFolderViewId={cpFolderViewId}
                    setcontentPanelType={setcontentPanelType}
                    contentPanelType={contentPanelType}
                />
            ),
        },
        contentPanelType === TYPE_NAME.IMPACT_SURVEYOR.ID
            ? {
                  name: 'Select Impact Data Fields to Survey',
                  component: (
                      <SelectImpactDataFieldsModal
                          active={active}
                          setActive={setActive}
                          data={props.data}
                          hideModal={props.hideModal}
                          name="contentPanel"
                          setCpFolderViewId={setCpFolderViewId}
                          cpFolderViewId={cpFolderViewId}
                          setcontentPanelType={setcontentPanelType}
                          contentPanelType={contentPanelType}
                      />
                  ),
              }
            : {
                  name: 'Select Template',
                  component: (
                      <SelectTemplateWithPreview
                          active={active}
                          setActive={setActive}
                          data={props.data}
                          hideModal={props.hideModal}
                          name="contentPanel"
                          setCpFolderViewId={setCpFolderViewId}
                          cpFolderViewId={cpFolderViewId}
                          setcontentPanelType={setcontentPanelType}
                          contentPanelType={contentPanelType}
                      />
                  ),
              },
    ];

    return (
        <>
            <MultiStepForm
                activeStep={active}
                showNavigation={false}
                steps={steps}
                className={props?.data?.state?.duplicate}
            />
        </>
    );
};

export default CreateDynamicContentPanelMultiStepForm;
