export const copyRightYearOldTenantReplacer = ['1', '7', '18', '28', '29'];
export const multiRowAllowSlickReplacer = ['40', '46'];
export const menuToggle = ['74', '62', '77', '72', '64', '69', '46', '66'];
export const menuToggleForSpecialTenant = ['44', '66'];
export const menuToggleClose = ['47'];
export const zindexForTenant = ['28'];
export const classNamesForTenant = ['47'];
export const contactPageHideParentIfBioNotPresent = ['38'];
export const footerAddressReplacerOrHideInnerChild = ['37'];
// export const customYearRendering = ['58'];
export const learnMoreClass = ['learn-more-wrapper', 'pb-learn-more-text'];
export const ccTitleForTenant = ['7', '8', '17', '1', '16'];
export const renderSocialIconAsText = [72];
