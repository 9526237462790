import {
    sitesModule,
    reportsModule,
    engagementModule,
    accountModule,
    reviewAndApprovalModule,
    assetsModule,
    privacyPolicyModule,
    profileModule,
    deletedDataModule,
} from '../module/generalModules';
import { portfolioModule } from '../module/portfolioModule';
import { contentPanelModule } from '../module/contentPanelModule';
import { systemSettingsModule } from '../module/systemSettingsModule';

// System Admin left sidebar
export const BrandAdminRoutes = [
    { ...reviewAndApprovalModule },
    { ...portfolioModule },
    { ...sitesModule },
    { ...reportsModule },
    { ...assetsModule },
    { ...contentPanelModule },
    { ...profileModule },
    { ...systemSettingsModule },
    { ...engagementModule },
    { ...deletedDataModule },
    { ...accountModule },
    { ...privacyPolicyModule },
];
