// @flow
import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
// components
import { FormInput } from '../../../components/form/input';
import ButtonElement from '../../../components/button/ButtonElement';
import HyperDatepicker from '../../../components/datepicker/Datepicker';
import { useModalContext } from '../../../contexts/ModalContext';
import { modalActions } from '../../../reducers/actions';
import Moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    exportDownloadChangeLog,
    userUnpagedList,
    changeLogActorList,
    changeLogMediumList,
    changeLogModuleList,
    changeLogComponentList,
    systemDataExportReset,
} from '@ovrture/react-redux';

const ExportChangeLogData = (props) => {
    const roleList = [
        { label: 'Application Admin', value: 10 },
        { label: 'Report Admin', value: 5 },
        { label: 'Content Admin', value: 6 },
        { label: 'Brand Admin', value: 2 },
        { label: 'Data Admin', value: 7 },
        { label: 'User', value: 4 },
        { label: 'System Admin', value: 1 },
        { label: 'Records', value: 11 },
    ];
    const statusList = [
        { label: 'Success', value: 'actionSucceeded' },
        { label: 'Failed', value: 'actionNotSucceeded' },
    ];
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [isDate, setIsDate] = useState('all');
    const { modalDispatch } = useModalContext();
    const [toggle, setToggle] = useState(true);
    const [owner, setOwner] = useState('');
    const [actor, setActor] = useState('');
    const [role, setRole] = useState('');
    const [status, setStatus] = useState('');
    const [medium, setMedium] = useState('');
    const [module, setModule] = useState('');
    const [component, setComponent] = useState('');
    const dispatch = useDispatch();
    const { changeLogDataExport, listOwner, listActor, listMedium, listModule, listComponent } = useSelector(
        (state) => ({
            changeLogDataExport: state?.systemDataExport?.changeLogExportDownload,
            listMedium: state?.analytic?.changeLogMediumList?.data,
            listModule: state?.analytic?.changeLogModuleList?.data,
            listComponent: state?.analytic?.changeLogComponentList?.data,
            listOwner: state?.user?.userUnpagedList,
            listActor: state?.analytic?.changeLogActorList?.data,
        })
    );

    useEffect(() => {
        dispatch(userUnpagedList({ isAnd: true, isUnpaged: true, sortObj: [{ sort: ['colLastName,ASC'] }] }));
        dispatch(changeLogMediumList());
        dispatch(changeLogModuleList());
        dispatch(changeLogComponentList());
        dispatch(changeLogActorList());
    }, []);

    useEffect(() => {
        if (changeLogDataExport) {
            dispatch(systemDataExportReset());
            modalDispatch({ type: modalActions.CLOSE_MODAL });
        }
    }, [changeLogDataExport]);

    const onSelectStartDate = (date) => {
        setSelectedStartDate(date);
    };
    const onSelectEndDate = (date) => {
        setSelectedEndDate(date);
    };

    const downloadWideDashboardData = () => {
        const payload = {
            fkOwnerIdList: owner,
            fkLoggedInUserIdList: actor,
            loggedInUserRoleIdList: role,
            fkActivityLogMediumIdList: medium,
            fkActivityLogModuleIdList: module,
            fkActivityLogComponentIdList: component,
        };

        if (status === 'actionSucceeded') {
            payload.actionSucceeded = true;
        } else if (status === 'actionNotSucceeded') {
            payload.actionNotSucceeded = true;
        }

        if (isDate === 'date') {
            selectedStartDate && (payload.startDate = Moment(selectedStartDate).format('MM/DD/YYYY'));
            selectedEndDate && (payload.endDate = Moment(selectedEndDate).format('MM/DD/YYYY'));
        }
        dispatch(exportDownloadChangeLog(payload));
    };

    return (
        <>
            <div className="custom-padding">
                <Row>
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Owner(s)"
                            value={owner}
                            onChange={(e) => setOwner(e.target.value)}>
                            <option>All Owners</option>
                            {listOwner?.content?.map((usr) => {
                                return (
                                    <option key={usr.id} value={usr.id} data-email-id={usr.email}>
                                        {usr.colFullName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Actor(s)"
                            value={actor}
                            onChange={(e) => setActor(e.target.value)}>
                            <option>All Actors</option>
                            {listActor?.map((usr) => {
                                return (
                                    <option key={usr.id} value={usr.id}>
                                        {usr.name}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Role(s)"
                            value={role}
                            onChange={(e) => setRole(e.target.value)}>
                            <option>All Roles</option>
                            {roleList?.map((usr) => {
                                return (
                                    <option key={usr.value} value={usr.value}>
                                        {usr.label}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Status(s)"
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}>
                            <option>All Status</option>
                            {statusList?.map((usr) => {
                                return (
                                    <option key={usr.value} value={usr.value}>
                                        {usr.label}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Medium(s)"
                            value={medium}
                            onChange={(e) => setMedium(e.target.value)}>
                            <option>All Mediums</option>
                            {listMedium?.map((usr) => {
                                return (
                                    <option key={usr.pkActivityLogMediumId} value={usr.pkActivityLogMediumId}>
                                        {usr.colMediumName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Module(s)"
                            value={module}
                            onChange={(e) => setModule(e.target.value)}>
                            <option>All Modules</option>
                            {listModule?.map((usr) => {
                                return (
                                    <option key={usr.pkActivityLogModuleId} value={usr.pkActivityLogModuleId}>
                                        {usr.colModuleName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <FormInput
                            type="select"
                            className="custom-select change-log"
                            label="Component(s)"
                            value={component}
                            onChange={(e) => setComponent(e.target.value)}>
                            <option>All Components</option>
                            {listComponent?.map((usr) => {
                                return (
                                    <option key={usr.pkActivityLogComponentId} value={usr.pkActivityLogComponentId}>
                                        {usr.colComponentName}
                                    </option>
                                );
                            })}
                        </FormInput>
                    </Col>
                </Row>
                <Col md={12} className="mt-3">
                    <p>
                        <b>Date Range:</b>
                    </p>
                    <FormInput
                        type="radio"
                        id="selectedTimeBitForAll"
                        name="selectedTimeBit"
                        label="All"
                        value="all"
                        className="ms-1 mt-1"
                        onChange={(e) => setIsDate(e.target.value)}
                        checked={isDate === 'all' ? true : false}
                    />
                    <Row className="mt-1">
                        <Col md={1}>
                            <FormInput
                                type="radio"
                                id="selectedTimeBitForSelectedDates"
                                name="selectedTimeBit"
                                value="date"
                                label=" "
                                className="ms-1 mt-1"
                                onChange={(e) => setIsDate(e.target.value)}
                                checked={isDate === 'date' ? true : false}
                            />
                        </Col>
                        <Col md={5} className="mt-2 mt-md-0 ps-md-0 ps-2">
                            <HyperDatepicker
                                value={selectedStartDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectStartDate(date);
                                }}
                                disabled={isDate === 'all' ? true : false}
                            />
                        </Col>
                        <Col md={1} className="m-auto">
                            to
                        </Col>
                        <Col md={5} className="ps-md-1 ps-2">
                            <HyperDatepicker
                                value={selectedEndDate}
                                inputClass="form-control-light"
                                onChange={(date) => {
                                    onSelectEndDate(date);
                                }}
                                disabled={isDate === 'all' ? true : false}
                            />
                        </Col>
                    </Row>
                </Col>
            </div>
            <div className="d-flex justify-content-center modal-footer">
                <ButtonElement
                    name="Export"
                    variant="primary"
                    type="button"
                    onClick={() => {
                        if (toggle) {
                            setToggle(false);
                            downloadWideDashboardData();
                        }
                    }}
                />
                <ButtonElement
                    name="Cancel"
                    variant="light"
                    type="button"
                    onClick={() =>
                        modalDispatch({
                            type: modalActions.CLOSE_MODAL,
                        })
                    }
                />
            </div>
        </>
    );
};

export default ExportChangeLogData;
