import { Col, ListGroup, Row } from 'react-bootstrap';
import ListItemSelection from '../../../../components/listItem/ListItem';
import {
    selectLeft,
    selectRight,
    shiftLeft,
    shiftRight,
    clearAll,
    ListShiftReducer,
    updateLeftArray,
    ShiftInitialState,
    updateRightArray,
} from '../../../../reducers/ListShifterReducer';
import { useEffect, useReducer, useState } from 'react';
import ButtonElement from '../../../../components/button/ButtonElement';
import { fundTypeList, impactTypeGetList, fundTypeReset, impactTypeReset } from '@ovrture/react-redux';
import { useDispatch, useSelector } from 'react-redux';
import MandatoryField from './MandatoryField';
import TinymceFieldReport from './TinymceFieldReport';
import { TABS } from '../../settings/DataSchema';

const ReportRendaringField = (props) => {
    const [tinymceToggle, settinymceToggle] = useState(false);
    const dispatchAction = useDispatch();
    const [state, dispatch] = useReducer(ListShiftReducer, ShiftInitialState);
    let movedRightItem = state.leftArry.filter((letfArryItem) =>
        state.rightArry?.find((shiftedItemId) => letfArryItem?.id === shiftedItemId?.id)
    );

    const { fundType, impactType, authInfo } = useSelector((state) => ({
        fundType: state?.fundType?.list?.data,
        impactType: state?.impactType?.list?.data,
        authInfo: state?.auth?.me,
    }));

    useEffect(() => {
        if (props?.data?.data?.tab?.DATASCOPEID) {
            if (
                props?.data?.data?.tab?.DATASCOPEID === TABS.FUND_DETAILS.DATASCOPEID ||
                props?.data?.data?.tab?.DATASCOPEID === TABS.FY_PERFORMANCE.DATASCOPEID ||
                props?.data?.data?.tab?.DATASCOPEID === TABS.FY_DONORS.DATASCOPEID
            ) {
                dispatchAction(fundTypeReset());
                dispatchAction(fundTypeList({ orgId: authInfo?.organaisationId }));
            } else if (props?.data?.data?.tab?.DATASCOPEID === TABS.FY_IMPACT.DATASCOPEID) {
                dispatchAction(impactTypeReset());
                dispatchAction(impactTypeGetList({ orgId: authInfo?.organaisationId }));
            }
        }
    }, [props?.data?.data?.tab?.DATASCOPEID]);

    useEffect(() => {
        if (state.rightArry) {
            props?.data?.setreportRenderingLinkedIdList(state.rightArry);
        }
    }, [state.rightArry]);

    useEffect(() => {
        if (
            fundType?.length > 0 &&
            (props?.data?.data?.tab?.DATASCOPEID === TABS.FUND_DETAILS.DATASCOPEID ||
                props?.data?.data?.tab?.DATASCOPEID === TABS.FY_PERFORMANCE.DATASCOPEID ||
                props?.data?.data?.tab?.DATASCOPEID === TABS.FY_DONORS.DATASCOPEID)
        ) {
            fundType.forEach((item) => {
                item.id = item.pkFundTypeId;
                item.name = item.colFundTypeName;
            });
            dispatch(updateLeftArray([...fundType]));
            const assignedFundType = fundType.filter((f) =>
                props?.data?.info?.reportRenderingLinkedIdList.includes(f.pkFundTypeId)
            );
            assignedFundType.forEach((selectedItemLeft) => {
                selectedItemLeft.selectedLeft = false;
            });
            dispatch(updateRightArray([...assignedFundType]));
            dispatchAction(fundTypeReset());
        } else if (impactType?.length > 0 && props?.data?.data?.tab?.DATASCOPEID === TABS.FY_IMPACT.DATASCOPEID) {
            impactType.forEach((item) => {
                item.id = item.pkImpactTypeId;
                item.name = item.colImpactName;
            });
            dispatch(updateLeftArray([...impactType]));
            const assignedImpactType = impactType.filter((f) =>
                props?.data?.info?.reportRenderingLinkedIdList.includes(f.pkImpactTypeId)
            );
            assignedImpactType.forEach((selectedItemLeft) => {
                selectedItemLeft.selectedLeft = false;
            });
            dispatch(updateRightArray([...assignedImpactType]));
            dispatchAction(impactTypeReset());
        }
    }, [fundType, impactType, props?.data?.data?.tab?.DATASCOPEID]);

    return (
        <>
            <Row className="mt-2">
                <h5 className="text-primary">REPORT RENDERING:</h5>
                <Col xl={10} lg={10} md={12} sm={12}>
                    <Row className="pb-3 create-user-group">
                        <Col lg md={12} sm={12} className="col-12">
                            <ListGroup className="listheader custom-list-header-color">
                                <ListGroup.Item className="active disabled">
                                    <b>
                                        {props?.data?.data?.tab?.DATASCOPEID === TABS.FY_IMPACT.DATASCOPEID
                                            ? 'Impact Types:'
                                            : 'Fund Types:'}
                                    </b>
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup>
                                {state?.leftArry?.length === 0 && (
                                    <div className="custom-listgroup list-group">
                                        <div className="text-center">
                                            <h5>
                                                {props?.data?.data?.tab?.DATASCOPEID === TABS.FY_IMPACT.DATASCOPEID
                                                    ? 'No Impact Types found.'
                                                    : 'No Fund Types found.'}
                                            </h5>
                                        </div>
                                    </div>
                                )}
                                {state?.leftArry?.length > 0 && state.leftArry && (
                                    <ListItemSelection
                                        list={state?.leftArry}
                                        name="user-group"
                                        searchData="Group name"
                                        isSearchFilter={true}
                                        onClick={(item) => {
                                            state.leftArry &&
                                                state?.leftArry.forEach((selectedItemLeft) => {
                                                    if (selectedItemLeft === item) {
                                                        selectedItemLeft.selectedLeft = true;
                                                    } else {
                                                        selectedItemLeft.selectedLeft = false;
                                                    }
                                                });
                                            dispatch(selectLeft(item));
                                        }}
                                        isLeftArray={true}
                                        disabled={movedRightItem}
                                        getHandleSearch={() => {
                                            state?.leftArry.forEach((selectedItemLeft) => {
                                                selectedItemLeft.selectedLeft = false;
                                            });
                                            dispatch(selectLeft(null));
                                        }}
                                    />
                                )}
                            </ListGroup>
                        </Col>
                        <Col
                            lg={1}
                            className="text-center col-12 mt-lg-5 d-flex d-lg-block justify-content-center custom-shift-btn-at-mob">
                            <Col className="mt-lg-4 mt-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-right" />}
                                    className="action-icon"
                                    variant="outline-primary"
                                    as="button"
                                    tooltip="Move Right"
                                    onClick={() => {
                                        if (state?.leftArry.find((f) => f.selectedLeft === true)) {
                                            state?.leftArry.forEach((selectedItemLeft) => {
                                                selectedItemLeft.selectedLeft = false;
                                            });
                                            dispatch(shiftRight());
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="mt-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-left" />}
                                    className="action-icon"
                                    variant="outline-primary"
                                    as="button"
                                    tooltip="Move Left"
                                    onClick={() => {
                                        if (state?.rightArry.find((f) => f.selectedRight === true)) {
                                            state?.rightArry.forEach((selectedItemRight) => {
                                                selectedItemRight.selectedRight = false;
                                            });
                                            dispatch(shiftLeft());
                                        }
                                    }}
                                />
                            </Col>

                            <Col className="mb-2 mt-2 custom-shift-btn">
                                <ButtonElement
                                    icon={<i className="mdi mdi-chevron-double-left" />}
                                    className="action-icon"
                                    variant="outline-primary"
                                    as="button"
                                    tooltip="Clear All"
                                    onClick={() => {
                                        state?.rightArry.forEach((selectedItemRight) => {
                                            selectedItemRight.selectedRight = false;
                                        });
                                        dispatch(clearAll('Right'));
                                    }}
                                />
                            </Col>
                        </Col>
                        <Col lg md={12} sm={12} className="col-12">
                            <ListGroup className="listheader custom-list-header-color">
                                <ListGroup.Item className="active disabled">
                                    <b>
                                        {props?.data?.data?.tab?.DATASCOPEID === TABS.FY_IMPACT.DATASCOPEID
                                            ? 'Assigned Impact Types:'
                                            : 'Assigned Fund Types:'}
                                    </b>
                                </ListGroup.Item>
                            </ListGroup>
                            <ListGroup>
                                {state.rightArry && (
                                    <ListItemSelection
                                        list={state?.rightArry}
                                        name="user-group"
                                        searchData="Assigned groups"
                                        isSearchFilter={true}
                                        onClick={(item) => {
                                            state.rightArry &&
                                                state?.rightArry.forEach((selectedItemRight) => {
                                                    if (selectedItemRight === item) {
                                                        selectedItemRight.selectedRight = true;
                                                    } else {
                                                        selectedItemRight.selectedRight = false;
                                                    }
                                                });

                                            dispatch(selectRight(item));
                                        }}
                                        isRightArray={true}
                                        getHandleSearch={() => {
                                            state?.rightArry.forEach((selectedItemRight) => {
                                                selectedItemRight.selectedRight = false;
                                            });
                                            dispatch(selectRight(null));
                                        }}
                                    />
                                )}
                            </ListGroup>
                        </Col>
                    </Row>
                </Col>
                <MandatoryField
                    data={{
                        ...props?.data,
                        settinymceToggle,
                    }}
                />
                <TinymceFieldReport
                    data={{
                        ...props?.data,
                        tinymceToggle,
                    }}
                />
            </Row>
        </>
    );
};
export default ReportRendaringField;
