import React from 'react';
import Slider from 'react-slick';
import ReactDOM from 'react-dom';
import { getTenantOrgId } from '../../retainHistory';
import { multiRowAllowSlickReplacer } from '../../tenantReplacer';

var settings = {
    draggable: false,
    arrows: true,
    rows: 1,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 5000,
    infinite: false,
    dots: false,
    centerPadding: '0',
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
    onReInit: (e) => {
        let ispreviewOnly = document?.querySelector('#previewOnly');
        let sectionAttr;
        if (ispreviewOnly) {
            sectionAttr = 'previewOnly';
        } else {
            sectionAttr =
                document?.querySelector('#renderHtml')?.getAttribute('slidesecclass-id') ||
                document?.querySelector('#rootComponent')?.getAttribute('slidesecclass-id');
        }

        let slickSlideArray = document.querySelectorAll('#' + sectionAttr + ' [aria-hidden="false"]');
        if (window.location.href.includes('/content-panel-edit')) {
            slickSlideArray = document.querySelectorAll('[aria-hidden="false"]');
        }
        if (slickSlideArray.length === 3) {
            for (let index = 0; index < slickSlideArray.length; index++) {
                const element = slickSlideArray[index];
                if (index === 1) {
                    element.classList.add('slick-center');
                } else {
                    element.classList.remove('slick-center');
                }
            }
        }
    },
    afterChange: (e) => {},
    beforeChange: (current, next) => {},
};

const setupConfig = (props) => {
    if (multiRowAllowSlickReplacer?.includes(getTenantOrgId()) && window.innerWidth > 991) {
        if (props?.target?.classList?.contains('caption-gallery')) {
            settings.initialSlide = 0;
            settings.rows = 1;
            settings.arrows = true;
            settings.centerMode = false;
        } else if (props?.target?.classList?.contains('center-gallery')) {
            settings.initialSlide = 0;
            settings.centerMode = true;
            settings.rows = 1;
            settings.arrows = true;
        } else {
            settings.initialSlide = 0;
            settings.rows = 2;
            settings.arrows = false;
            settings.centerMode = false;
        }
    }
    if (
        (Number(getTenantOrgId()) === 19 || Number(getTenantOrgId()) === 8 || Number(getTenantOrgId()) === 12) &&
        window.innerWidth > 991
    ) {
        if (Number(getTenantOrgId()) === 19) {
            settings.initialSlide = 0;
            settings.centerMode = false;
        } else {
            settings.initialSlide = 1;
            settings.centerMode = true;
        }
    }
    // if (Number(getTenantOrgId()) === 83 || Number(getTenantOrgId()) === 74  || Number(getTenantOrgId()) === 69) {
    //     settings.accessibility = false;
    // }
    settings.accessibility = false;
};

const SlickSlider = (props) => {
    setupConfig(props);
    settings.slidesToShow = props?.slidesToShow ? props?.slidesToShow : 3;
    const arr = [];
    for (const element of props?.html) {
        arr.push(element);
    }
    return props?.flag ? (
        <Slider {...settings}>
            {arr.map((a, i) => {
                return <div key={i} dangerouslySetInnerHTML={{ __html: a.outerHTML }}></div>;
            })}
        </Slider>
    ) : (
        <>
            {arr.map((a, i) => {
                return <div key={i} dangerouslySetInnerHTML={{ __html: a.outerHTML }}></div>;
            })}
        </>
    );
};

export const addSlickSliderTarget = (target, sliderReplacer) => {
    sliderReplacer?.length > 0 && ReactDOM.render(<SlickSlider html={sliderReplacer} flag={true} />, target);
};

export const addSlickSlider = (sliderReplacer) => {
    sliderReplacer?.length > 0 &&
        ReactDOM.render(
            <SlickSlider html={sliderReplacer} flag={true} target={document?.querySelector('#photoGalleryCarousel')} />,
            document.querySelector('#photoGalleryCarousel')
        );
};
export const addSlickSliderCarouselTwo = (sliderReplacer) => {
    sliderReplacer?.length > 0 &&
        ReactDOM.render(
            <SlickSlider html={sliderReplacer} flag={true} slidesToShow={2} />,
            document.querySelector('#photoGalleryCarouselTwo')
        );
};

export const removeSlickSlider = () => {
    let sliderReplacer = document.querySelectorAll(
        '#photoGalleryCarousel .slick-slide:not(.slick-cloned) .frame_adjuster'
    );
    sliderReplacer?.length > 0 &&
        ReactDOM.render(<SlickSlider html={sliderReplacer} />, document.getElementById('photoGalleryCarousel'));
};

export const addSlickSliderMultiPanel = (photoGalleryList, previewOnly) => {
    for (let index = 0; index < photoGalleryList.length; index++) {
        const photoGallery = photoGalleryList[index];
        adjustCentralModeSlick(photoGallery);
        let frames = photoGallery?.querySelectorAll('.frame_adjuster');
        if (
            ['18'].includes(localStorage.getItem('TenantOrgId')) &&
            photoGallery?.querySelectorAll('.latest-news-wrapper').length > 0
        ) {
            frames = photoGallery?.querySelectorAll('.latest-news-wrapper');
        }
        if (frames?.length > 0) {
            if (previewOnly) {
                ReactDOM.render(
                    <SlickSlider html={frames} flag={true} target={photoGallery} />,
                    document.querySelectorAll(previewOnly + ' #photoGalleryCarousel')[index]
                );
            } else {
                document?.querySelectorAll('#photoGalleryCarousel')?.length > 0 &&
                    ReactDOM.render(
                        <SlickSlider html={frames} flag={true} target={photoGallery} />,
                        document.querySelectorAll('#photoGalleryCarousel')[index]
                    );
            }
        }
    }
};

export const addSlickSliderMultiPanelCarouselTwo = (photoGalleryList, previewOnly) => {
    for (let index = 0; index < photoGalleryList.length; index++) {
        const photoGallery = photoGalleryList[index];
        const frames = photoGallery?.querySelectorAll('.frame_adjuster');
        if (frames?.length > 0) {
            if (previewOnly) {
                ReactDOM.render(
                    <SlickSlider html={frames} flag={true} slidesToShow={2} />,
                    document.querySelectorAll(previewOnly + ' #photoGalleryCarouselTwo')[index]
                );
            } else {
                ReactDOM.render(
                    <SlickSlider html={frames} flag={true} slidesToShow={2} />,
                    document.querySelectorAll('#photoGalleryCarouselTwo')[index]
                );
            }
        }
    }
};

export const initialSlideCenter = (previewOnly) => {
    if (multiRowAllowSlickReplacer?.includes(getTenantOrgId()) && window.innerWidth > 991) {
        let array = previewOnly
            ? document.querySelectorAll('#previewOnly .pos-rel.center-gallery .slick-arrow.slick-next')
            : document.querySelectorAll('.pos-rel.center-gallery .slick-arrow.slick-next');
        array?.forEach((element) => {
            element && element.click();
        });
    }
};

export const onLoadAddSlickSliderCenterClass = () => {
    let ispreviewOnly = document?.querySelector('#previewOnly');
    let sectionAttr;
    if (ispreviewOnly) {
        sectionAttr = ispreviewOnly;
    } else {
        sectionAttr = document;
    }
    let slickList = sectionAttr.querySelectorAll('.slideSecClass .slick-list');
    if (window.location.href.includes('/content-panel-edit')) {
        slickList = document.querySelectorAll('.slick-list');
    }
    slickList.forEach((slider) => {
        let slickSlideArray = slider.querySelectorAll('[aria-hidden="false"]');
        if (slickSlideArray.length === 3) {
            for (let index = 0; index < slickSlideArray.length; index++) {
                const element = slickSlideArray[index];
                if (index === 1) {
                    element.classList.add('slick-center');
                } else {
                    element.classList.remove('slick-center');
                }
            }
        }
    });
};

export const adjustCentralModeSlick = (photoGallery) => {
    if (
        photoGallery?.classList?.contains('center-gallery') &&
        photoGallery?.querySelectorAll('.customDiv')?.length === 0 &&
        window.innerWidth > 991
    ) {
        let customDiv1 = document.createElement('div');
        customDiv1.classList.add('col-xs-12', 'col-md-12', 'frame_adjuster', 'no-padding', 'customDiv');
        customDiv1.style.visibility = 'hidden';
        photoGallery.appendChild(customDiv1);
        let customDiv2 = document.createElement('div');
        customDiv2.classList.add('col-xs-12', 'col-md-12', 'frame_adjuster', 'no-padding', 'customDiv');
        customDiv2.style.visibility = 'hidden';
        photoGallery.appendChild(customDiv2);
    } else if (
        photoGallery?.classList?.contains('center-gallery') &&
        photoGallery?.querySelectorAll('.customDiv')?.length > 0 &&
        window.innerWidth < 992
    ) {
        photoGallery?.querySelectorAll('.slick-slide')?.forEach((element) => {
            if (element?.querySelector('.frame_adjuster')?.classList?.contains('customDiv')) {
                element.remove();
            }
        });
    }
};
