import React, { createContext, useReducer, useContext } from 'react';
import { TimeRemainingReducer, TimeRemainingIntialState } from '../reducers/TimeRemainingReducer';

const TimeRemainingContext = createContext('');

export const TimeRemainingProvider = (props) => {
    const [timeRemainingState, timeRemainingDispatch] = useReducer(TimeRemainingReducer, TimeRemainingIntialState);
    let timeRemainingData = { timeRemainingState, timeRemainingDispatch };

    return <TimeRemainingContext.Provider value={timeRemainingData}>{props.children}</TimeRemainingContext.Provider>;
};

export function useTimeRemainingContext() {
    const context = useContext(TimeRemainingContext);
    if (context === undefined) {
        throw new Error('useTimeRemainingContext must be used within a TimeRemainingProvider');
    }
    return context;
}
